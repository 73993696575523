export class NavItems {
  constructor(
    public readonly authId: string, // 权限标识符，和后端存储保持一致
    public readonly title: string,
    public readonly path: string,
    public readonly pathMatch: string[],
    public readonly icon?: string,
    public children?: NavItems[],
    public isDivider = false
  ) {}
}
