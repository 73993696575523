import { LocaleFile } from "../..";

const noticeCenter: LocaleFile = {
  successDetail: "{{userName}} performed {{operationType}} to {{entityName}} successfully",
  failDetail: "{{userName}} failed to perform {{operationType}} to {{entityName}}",
  noEntityNameOperationFailed: "{{userName}} failed to perform {{operationType}}",
  noEntityNameOperationSuccessed: "{{userName}} successed to perform {{operationType}}",
  failureReason: "Failure Reason",
  alarmSuccess: "{{userName}} performed {{operationType}} successfully",
  alarmFailed: "{{userName}} failed to perform {{operationType}}",
};

export default noticeCenter;
