import { FormLabel, SxProps } from "@mui/material";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

export default function MatFormLabel(props: PropsWithChildren<{ error?: boolean; sx?: SxProps }>) {
  const { children, error, sx } = props;
  const { t } = useTranslation();
  return (
    <FormLabel error={error} sx={{ mb: 0.5, color: (theme) => theme.palette.text.secondary, ...(sx ?? {}) }}>
      {/* <Typography component={"span"} color="text.secondary" variant="subtitle2"> */}
      {children && typeof children === "string" && t(children as string)}
      {children && typeof children !== "string" && children}
      {/* </Typography> */}
    </FormLabel>
  );
}
