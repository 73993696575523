import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { selectConfirmConfig, closeConfirmAction } from "../../../store/toolSlice";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { Box, Typography, alpha, useTheme } from "@mui/material";
import ic_warn from "../../../assets/imgs/ic_warn.svg";
import { useState } from "react";
import Iconfont from "../tools/Iconfont";
import IconButton from "@mui/material/IconButton";

export default function MatConfirm() {
  const config = useSelector(selectConfirmConfig);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { custom } = useTheme();

  const handleClose = () => {
    if (config.onCancel) {
      config.onCancel();
    }
    setLoading(false);
    dispatch(closeConfirmAction());
  };

  const handleOk = () => {
    if (config.onOk) {
      const re = config.onOk();
      if (re instanceof Promise) {
        setLoading(true);
        re.then(() => {
          handleClose();
          setLoading(false);
        }).catch(() => {
          setLoading(false);
        });
      } else {
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  // const okBtnVariant = config.okBtnType === "error" ? "text" : "contained";
  const okBtnVariant = "contained";

  return (
    <Box>
      <Dialog
        open={config.open || false}
        onClose={handleClose}
        sx={{ "& .MuiPaper-root": { maxWidth: 480, borderRadius: 1 } }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ px: 2, pt: 2, pb: 1, columnGap: 1 }} id="alert-dialog-title" className="flex-v-center">
          {config.showWarningIcon && <Box component={"img"} src={ic_warn} alt="" sx={{ width: 24, height: 24 }}></Box>}
          <Typography variant="title2">{t(config.title) as string}</Typography>
          {config.showClose && (
            <IconButton sx={{position: 'absolute', right: 16, p: 0.5}} onClick={handleClose}>
              <Iconfont icon="ic_close" mr={0} fontSize={20} color={alpha(custom.fontBaseColor, 0.6)} />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent sx={{ px: 5, pb: 2 }}>
          {config.customContent ? config.content : <DialogContentText id="alert-dialog-description" sx={{pl: 1}}>{t(config.content as string)}</DialogContentText>}
        </DialogContent>
        <DialogActions sx={{ px: 2, pt: 0, pb: 2, height: 32 }}>
          {config.showCancelButton && (
            <Button sx={{ height: 32 }} onClick={handleClose}>
              {t(config.cancelText || "common.cancel")}
            </Button>
          )}
          <LoadingButton
            disabled={config.okBtnDisabled}
            variant={okBtnVariant}
            loading={loading}
            onClick={handleOk}
            autoFocus
            color={config.okBtnType || "primary"}
            sx={{ color: "#fff", height: 32 }}
          >
            <span>{t(config.okText || "common.confirm")}</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
