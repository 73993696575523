import { MatSelectOptionFactory } from "../components/common/mui/MatSelect";
import { BaseData, BaseNameData, MatRadioOption, SortOrder } from "./base.model";
import { PageLink } from "./request.model";
import { EDeviceStatus, ITopology } from "./topology.model";
import { Theme } from "@mui/material";

export enum DeviceTypeEnum {
  GATEWAY = "Gateway",
  ROUTER = "Router",
  DTU = "DTU",
  IPC = "IPC",
  Dongle = "Dongle",
}

export enum DeviceModels {
  R102 = "R102",
  R105 = "R105",
  G202 = "G202",
  G335 = "G335",
  C335 = "C335",
  C335L = "C335L",
  G304 = "G304",
  IBOX66 = "IBOX66", // Debian
  // 以下设备暂未上线
  MM6801AP = "MM6801-AP",
  DE20 = "DE20",
  DE20B = "DE20-B",
  DE20BT = "DE20-BT",
  DGLAH = "DGL-AH",
  IOTDONGLE = "IOT-DONGLE",
}

export enum DeviceStatus {
  ALL = undefined,
  OFFLINE = 0,
  ONLINE = 1,
  CONFIGURING = 2,
}

export const deviceModelTypeMap: Record<DeviceTypeEnum, DeviceModels[]> = {
  [DeviceTypeEnum.GATEWAY]: [DeviceModels.G202, DeviceModels.G304, DeviceModels.G335, DeviceModels.C335, DeviceModels.C335L, DeviceModels.MM6801AP],
  [DeviceTypeEnum.ROUTER]: [DeviceModels.R102, DeviceModels.R105],
  [DeviceTypeEnum.DTU]: [DeviceModels.DE20, DeviceModels.DE20B, DeviceModels.DE20BT],
  [DeviceTypeEnum.IPC]: [DeviceModels.IBOX66],
  [DeviceTypeEnum.Dongle]: [DeviceModels.DGLAH, DeviceModels.IOTDONGLE],
};

export const deviceStatusMap = new Map<DeviceStatus, { bgcolor: (theme: Theme) => string; text: string }>([
  [DeviceStatus.ONLINE, { bgcolor: (theme: Theme) => theme.palette.success.main, text: "device.constants.ONLINE" }],
  [DeviceStatus.OFFLINE, { bgcolor: (theme: Theme) => theme.palette.grey[500], text: "device.constants.OFFLINE" }],
  [
    DeviceStatus.CONFIGURING,
    {
      bgcolor: (theme: Theme) => theme.palette.info.main,
      text: "device.constants.CONFIGURING",
    },
  ],
]);

export function parseDeviceStatus(status: number) {
  return deviceStatusMap.get(status);
}

export enum DeviceProtocols {
  MQTT = "MQTT",
  HTTP = "HTTP",
}

export const ConnectionTypeMap = new Map<string, string>([
  ["wifi", "device.connectionType.wifi"],
  ["cellular", "device.connectionType.cellular"],
  ["wan", "device.connectionType.wan"],
  ["lan", "device.connectionType.lan"],
  ["Ethernet", "device.connectionType.Ethernet"],
]);
export const allDeviceModelSelectOptions = [
  new MatSelectOptionFactory(DeviceModels.R102, "R102"),
  new MatSelectOptionFactory(DeviceModels.R105, "R105"),
  new MatSelectOptionFactory(DeviceModels.G202, "G202"),
  new MatSelectOptionFactory(DeviceModels.G335, "G335"),
  new MatSelectOptionFactory(DeviceModels.C335, "C335"),
  new MatSelectOptionFactory(DeviceModels.IBOX66, "IBOX66"),
];
export const vtDeviceModelSelectOptions = [
  new MatSelectOptionFactory(DeviceModels.R102, "R102"),
  new MatSelectOptionFactory(DeviceModels.R105, "R105"),
  new MatSelectOptionFactory(DeviceModels.G202, "G202"),
  new MatSelectOptionFactory(DeviceModels.G335, "G335"),
  new MatSelectOptionFactory(DeviceModels.C335, "C335"),
  new MatSelectOptionFactory(DeviceModels.IBOX66, "IBOX66"),
];

export const deviceProtocolSelectOptions = [new MatSelectOptionFactory(DeviceProtocols.MQTT, "MQTT"), new MatSelectOptionFactory(DeviceProtocols.HTTP, "HTTP")];

export interface CreateDeviceInfo {
  name: string;
  type: DeviceModels;
  protocol: DeviceProtocols;
  sn: string;
  token: string;
  version: string;
  timeZone: string;
  location: string;
  description?: string;
  groupName?: string;
  groupId?: string;
  innerDevice?: boolean;
  encryption?: string;
}

export interface DeviceInfo extends CreateDeviceInfo, BaseData {}

export interface DeviceInfoDto extends CreateDeviceInfo, BaseData {
  updatedBy: string;
  updateTime: string;
  createTime: string;
  createdBy: string;
}

export class DeviceInfoFactory implements CreateDeviceInfo {
  constructor(
    public name: string = "",
    public type: DeviceModels = undefined,
    public protocol: DeviceProtocols = DeviceProtocols.MQTT,
    public sn: string = "",
    public token: string = "",
    public encryption: string = "",
    public version: string = "",
    public timeZone: string = "",
    public location: string = "",
    public description: string = "",
    public groupName: string = "",
    public groupId: string = "",
    public innerDevice: boolean = true
  ) {}
}

export enum DeviceLinkToGroupType {
  NO_TARGET_GROUP = "NO_TARGET_GROUP",
  TO_AN_EXISTING_GROUP = "TO_AN_EXISTING_GROUP",
  TO_A_NEW_GROUP = "TO_A_NEW_GROUP",
}

export const deviceLinkToGroupOptions: MatRadioOption<DeviceLinkToGroupType>[] = [
  { value: DeviceLinkToGroupType.NO_TARGET_GROUP, label: "device.NO_TARGET_GROUP" },
  { value: DeviceLinkToGroupType.TO_AN_EXISTING_GROUP, label: "device.TO_AN_EXISTING_GROUP" },
  { value: DeviceLinkToGroupType.TO_A_NEW_GROUP, label: "device.TO_A_NEW_GROUP" },
];

export interface DeviceGroupInfo extends BaseNameData {
  deviceNum?: number;
}

// 后端返回的设备组数据结构
export type TDeviceListItem = Pick<ITopology, "id" | "name" | "sn" | "location" | "type" | "lat" | "lng" | "plcVersion" | "isActive">;

export interface IDeviceTreeResItem {
  groupId: string;
  groupName: string;
  deviceList: TDeviceListItem[];
}

export interface RemoteAccessDto {
  enable: boolean;
  remoteTerminalUrl: string;
  remoteWebUrl: string;
  cookie: any;
}

export interface IDeviceGroup {
  name: string;
  id: string;
}

// Dashboard
export interface DeviceRegist {
  sn: string;
  token: string;
  innerDevice: boolean;
}

export interface DeviceCountStatistics {
  initializeCount: number;
  offlineCount: number;
  onlineCount: number;
}
export interface DeviceStatusStatistic {
  day: string;
  onlineCount: number;
  offlineCount: number;
  initializingCount: number;
}
export interface DeviceStatusStatistics {
  deviceStatusStatisticByDays: DeviceStatusStatistic[];
}
export interface CityLocationSum extends BaseData {
  city: string;
  latitude: number;
  longitude: number;
  sum: number;
}

export interface CityResponse {
  citySumList: CityLocationSum[];
}
export interface IAgentVersionCount {
  agentVersion: string;
  count: number;
  // statistic
  name: string;
  value: number;
}
export interface IOSVersionCount {
  agentVersionCounts: IAgentVersionCount[];
  count: number;
  osVersion: string;
  // statistic
  name: string;
  value: number;
  children: any[];
}
export interface IDeviceModelCounts {
  model: string;
  count: number;
  osVersionCounts: IOSVersionCount[];
  // statistic
  name: string;
  value: number;
  children: any[];
}
export interface IDeviceTypeStatistics {
  total: number;
  modelcounts: IDeviceModelCounts[];
}

export class GetDevicePageLink extends PageLink {
  constructor(
    public page: number = 0,
    public pageSize: number = 10,
    public searchValue?: string,
    public sortBy?: string,
    public direction: SortOrder = "Descending",
    // Filter-----start
    public deviceTypeList: string[] = [],
    public activedList: EDeviceStatus[] = [],
    public authorizationStatusList: (LicenseTokenStatus | null)[] = [],
    public licenseNameList: string[] = [],
    public fwVersionList: string[] = [],
    public currentSimList: string[] = [],
    public plcVersionList: string[] = [],
    public agentVersionList: string[] = [],
    public cpuTypeList: string[] = [],
    public connectionTypeList: string[] = [],
    // Filter-----end
    public deviceGroupIds: string[] = [],
    public startTime?: number,
    public endTime?: number
  ) {
    super(page, pageSize, searchValue, sortBy, direction);
  }
}

export enum EDeviceActions {
  MODIFY_DEVICE,
  DELETE,
  RESTART_DEVICE,
  MOVE_TO,
  SWITCH_SIM,
  PING,
  DIAGNOSTICS,
}
export enum EDeviceListOperations {
  SEARCH,
  EXPORT,
  TIME_CHANGE,
  ADD,
  CUSTOM_COLUMNS,
}
export enum ExportDevicesActions {
  ALL,
  SELECTED,
}

export interface ISaveSearchResult {
  id: string;
  name: string;
  condition: string; // JSON字符串
}

export enum SaveSearchMoudleName {
  ALARM_OVERVIEW = "alarmOverviewPage",
  DEVICE_MANAGEMENT = "deviceManagementPage",
}
export interface ISaveSearchResultParams {
  searchName: string;
  moduleName: SaveSearchMoudleName;
  searchCondition: {
    deviceGroupIds: string[];
    direction: SortOrder;
    sortBy: string;
    searchValue: string;
    deviceTypeList: string[];
    activedList: EDeviceStatus[];
    authorizationStatusList: (LicenseTokenStatus | null)[];
    licenseNameList: string[];
    fwVersionList: string[];
    currentSimList: string[];
    plcVersionList: string[];
    agentVersionList: string[];
    cpuTypeList: string[];
    connectionTypeList: string[];
  };
}
export enum LicenseTokenStatus {
  // ASSIGN 和 INIT 暂时没用  属于服务端属性
  // INIT = "00",
  // ASSIGN = "01",
  // ACTIVE = "02",
  // EXPIRE = "03",
  // INVALID = "04",
  // ERROR = "0",

  // 修改 为 0 和 1
  /**
   * 使用 Status  0 1
   * 0 表示 NO LICENSE
   * 1 表示 ACTIVATED
   */
  // ACTIVE = 1,
  // INVALID = 0,
  ACTIVE = "ENABLE",
  INVALID = "DISABLE",
}

export interface DeviceModelStatistic {
  modelName: string;
  number: number;
  percentage: number;
}
export interface IDeviceGroupRes {
  groupId: string;
  groupName: string;
  deviceCount: number;
}

export const deviceLicenseInvalid = (device: ITopology) => {
  return device.status !== LicenseTokenStatus.ACTIVE;
};

export const LicenseTokenStatusColorTextMap = new Map([
  [LicenseTokenStatus.ACTIVE, { color: (theme: Theme) => theme.palette.success.main, text: "device.license.onUsing" }],
  // [LicenseTokenStatus.EXPIRE, { color: (theme: Theme) => theme.palette.grey[500], text: "license.expired" }],
  [LicenseTokenStatus.INVALID, { color: (theme: Theme) => theme.palette.grey[500], text: "device.license.ineffective" }],
  // [LicenseTokenStatus.ERROR, { color: (theme: Theme) => theme.palette.grey[500], text: "license.expired" }],
  [null, { color: (theme: Theme) => theme.palette.grey[500], text: "device.license.noLienseBind" }],
]);

export function parseDeviceLicenseToken(device: ITopology): { color: (theme: Theme) => string; text: string } {
  return LicenseTokenStatusColorTextMap.get(device.status);
}
export enum EDeviceTableHeadFilterType {
  OS_VERSION,
  LICENSE_NAME,
  CURRENT_SIM,
  PLC_VERSION,
  AGENT_VERSION,
  CPU_TYPE,
  CONNECTION_TYPE,
  MODEL_TYPE,
}

export enum DeviceType {
  GATEWAY,
  PLC,
  Lora,
}

export interface LoraDeviceDto {
  id: string;
  loraDevEui: string;
  name: string;
  description: string;
  tenantId: string;
  loraGatewayId: string;
  createdTime: string;
  updatedTime: string;
  loraBatteryLevel: number;
  loraDeviceClassEnabled: string;
  loraDeviceProfileId: string;
  loraDeviceProfileName: string;
  loraDevAddr: string;
  loraOtaaKeyNwkKey: string;
  loraOtaakeyAppKey: string;
  loraLastSeen: string;
}

export interface LoraDataDto {
  id: string;
  data: string;
  rawEvent: any;
  time: number;
  object: any;
}

export enum LoraDeviceActions {
  EDIT,
  DELETE,
}

export enum DeviceDisplayType {
  NAME,
  SN,
}

export interface PlcDeviceDto {
  id: string;
  sn: string;
  name: string;
  description: string;
  tenantId: string;
  deviceId: string;
  createdTime: string;
  updatedTime: string;
}

export interface PlcDataDto {
  id: string;
  name: string;
  data: string;
  time: any;
}
