import { Box, FormControl, InputLabel, MenuItem, Select, SxProps, Typography, useTheme } from "@mui/material";
import { isArray, isString, isUndefined } from "lodash";
import { nanoid } from "nanoid";
import { memo, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { MatFormItemProps } from "../../../models/base.model";
import { isNull } from "../../../utils";
import MatFormLabel from "./MatFormLabel";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 280,
      // width: 250,
    },
  },
};

export interface MatSelectOption<T = string | number | readonly string[] | any> {
  value: T;
  label: string;
  ignoreTranslate?: boolean;
  disabled?: boolean;
}

export class MatSelectOptionFactory<T = string | number | readonly string[] | any> implements MatSelectOption<T> {
  constructor(public value: MatSelectOption["value"], public label: string, public ignoreTranslate = false, public disabled = false) {}
}

export interface MatSelectProps<T = string | number | readonly string[] | any> extends MatFormItemProps<MatSelectOption["value"]> {
  options: MatSelectOption<T>[];
  size?: "small" | "medium";
  customRender?(data?: T): ReactNode;
  sx?: SxProps;
  selectSx?: SxProps;
  variant?: "standard" | "outlined" | "filled";
  loading?: boolean;
  horizontal?: boolean;
}

const initNoneValue = "@@INIT" + nanoid();

const horizontalSx: SxProps = { flexDirection: "row", alignItems: "center", maxWidth: 1, "& label": { mr: 1 } };
export default memo(function MatSelect(props: MatSelectProps) {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { variant = "outlined", loading = false, matstyle = "false", horizontal = false } = props;

  // 初始化默认值  根据是否传入placeholder来判断
  const defaultValue = props.placeholder ? initNoneValue : "";

  const value = isUndefined(props.value) || isNull(props.value) ? (props.multiple ? [] : defaultValue) : props.value;
  const optionHeight = props.size === "medium" ? 48 : 40;

  return (
    <FormControl
      className={"border-box" + horizontal ? " flex-nowrap" : ""}
      error={props.error}
      size={props.size || "small"}
      variant={variant}
      fullWidth
      sx={{ maxWidth: props.width || 1 / 1, width: props.width, ...(horizontal ? horizontalSx : {}), ...(props.sx || {}) }}
    >
      {Boolean(props.label && matstyle === "true") && <InputLabel sx={{ whiteSpace: "nowrap" }}>{isString(props.label) ? t(props.label as string) : props.label}</InputLabel>}
      {Boolean(props.label && !(matstyle === "true")) && <MatFormLabel sx={{ whiteSpace: "nowrap" }}>{isString(props.label) ? t(props.label as string) : props.label}</MatFormLabel>}
      <Select
        ref={props.ref}
        onChange={props.onChange}
        disabled={props.disabled}
        onBlur={props.onBlur}
        name={props.name}
        error={props.error}
        MenuProps={MenuProps}
        value={value}
        multiple={Boolean(props.multiple)}
        renderValue={
          props.renderValue
            ? props.renderValue
            : (selected: string | string[]) => {
                if (!props.children && props.placeholder && (selected.length === 0 || selected === initNoneValue)) {
                  return (
                    <Typography color={"text.disabled"} component={"span"}>
                      {t(props.placeholder)}
                    </Typography>
                  );
                }
                if (isArray(selected)) {
                  return (props.options ?? [])
                    .filter((op) => selected.includes(op.value))
                    .map((op) => (op.ignoreTranslate ? op.label : t(op.label)))
                    .join(",");
                } else {
                  const opt = (props.options ?? []).find((op) => op.value === selected);
                  return (opt?.ignoreTranslate ? opt?.label : t(opt?.label)) ?? selected;
                }
              }
        }
        label={matstyle === "true" && props.label ? (isString(props.label) ? t(props.label as string) : props.label) : undefined}
        sx={{
          width: horizontal ? props.width : "auto",
          "& .Mui-disabled": props.disabled
            ? {
                bgcolor: palette.other.input,
              }
            : {},
          "& .MuiSelect-select": { bgcolor: "#ffffff" },
          ...props.selectSx,
        }}
      >
        {props.children && props.children}
        {!props.children && loading && <Typography sx={{ px: 2, py: 1 }}>loading...</Typography>}
        {!props.children && props.placeholder && (
          <MenuItem disabled value={initNoneValue}>
            <Typography color={"text.secondary"}>{t(props.placeholder)}</Typography>
          </MenuItem>
        )}
        {!props.children && !(props.options?.length > 0) && loading !== true && <Box sx={{ px: 2, py: 1 }}>{t("common.noDataFound")}</Box>}
        {!props.children &&
          !loading &&
          props.options?.map((option, index) => (
            <MenuItem key={index} sx={{ height: optionHeight }} value={option.value} disabled={option.disabled}>
              {props.customRender && props.customRender(option.value)}
              {!props.customRender && (option.ignoreTranslate ? option.label : t(option.label))}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
});
