import { EventEmitter } from "./event";

export interface WsCallbacks {
  onclose?: ((this: WebSocket, ev: CloseEvent) => any) | null;
  onerror?: ((this: WebSocket, ev: Event) => any) | null;
  onmessage?: ((this: WebSocket, ev: MessageEvent) => any) | null;
  onopen?: ((this: WebSocket, ev: Event) => any) | null;
}
export enum MessageType {
  RemoteAccessTimeoutCountDown = "remoteAccessTimeoutCountdown",
  NOTICE = "notice",
  ALARM = "alarm",
}
export interface Message {
  type: MessageType;
  data: any;
}
enum EProtocol {
  http = "http:",
  https = "https:",
}
const protocolWsMap = {
  [EProtocol.http]: "ws://",
  [EProtocol.https]: "wss://",
};

const protocol = window.location.protocol;
// 根据环境变量确定ws的主机域名：
// 开发环境-amazonaws
// 生产环境-生产环境域名本身
const host = process.env.NODE_ENV === "development" ? "192.168.18.190" : window.location.host;
export const defaultWsServerAddr = protocolWsMap[protocol] + host;
export const defaultRoom = "/gwm";

// WebSocket Service
export class WsService {
  public socket: WebSocket;

  constructor(public server = defaultWsServerAddr, public room = defaultRoom) {
    this.socket = new WebSocket(server + room);
  }

  public registEvents(cbs: WsCallbacks) {
    const { onclose, onerror, onopen, onmessage } = cbs;
    this.socket.onclose = onclose;
    this.socket.onerror = onerror;
    this.socket.onopen = onopen;
    this.socket.onmessage = onmessage;
  }
}
export class Ws extends EventEmitter {
  public socket: WebSocket = null;
  public server: string;
  public room: string;
  constructor(serverAddr: string, roomName: string) {
    super();
    this.server = serverAddr;
    this.room = roomName;
  }

  public connect() {
    const wsService = new WsService(this.server, this.room);
    const { socket } = wsService;
    this.socket = socket;

    // Ws对象实例
    const _this = this;

    wsService.registEvents({
      onopen(ev) {
        // 建立连接
        console.log(ev);
      },
      onclose(ev) {
        // 连接关闭
        console.log(ev);
        // _this.reconnect();
      },
      onerror(ev) {
        // 连接异常（网络信号不好、服务器异常等）
        console.log(ev);
        // _this.reconnect();
      },
      onmessage(ev) {
        // 不同消息类型予以不同处理事件
        const data = JSON.parse(ev.data);
        _this.emit("getMessage", data);
      },
    });

    this.on("getMessage", "getMessage", (data: Message) => {
      // data包含消息的类型和消息的内容
      switch (data.type) {
        case MessageType.RemoteAccessTimeoutCountDown:
          // 远程访问
          this.emit("remoteTimeoutMessage", data.data);
          break;
        default:
          break;
      }
    });
    // 远程访问超时
    this.on("remoteTimeoutMessage", "remoteTimeoutMessage", (data) => {});
  }

  public send(data: any, type: "object" | "others") {
    let msg;
    if (type === "object") {
      msg = JSON.stringify(data);
    } else {
      msg = data;
    }
    this.socket.send(msg);
  }

  public reconnect() {
    this.connect();
  }

  public close() {
    this.socket.close();
    this.events = {};
    this.socket = null;
  }
}
export const ws = new Ws(defaultWsServerAddr, defaultRoom);
