import HttpController from "./http.controller";
import {TreeDataNotype} from "../models/base.model";
import {PasswordPolicyDto} from "../models/user.model";

export default class SecurityController extends HttpController {
    getPermissionMenus = () => super.get<TreeDataNotype[]>("/gwm/permission/app/menus"); // 权限树

    // 查询当前用户的权限
    getUserPermission = () => this.get<TreeDataNotype[]>("/gwm/permission/current_user");

    getPasswordPolicy = () => super.get<PasswordPolicyDto>("/gwm/password");

    editPasswordPolicy = (data: PasswordPolicyDto) => super.put("/gwm/password", data);
}

export const securityController = new SecurityController();
