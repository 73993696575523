const help = {
  helpcard: {
    QuickStartGuidance: "快速入门指南",
    NetworkCommunicationMatrix: "网络通信矩阵",
    OTAUpgradeUserGuidence: "OTA升级用户指南",
    RemoteAccessUserGuidence: "远程访问用户指南",
    SupportedAlarmList: "支持的报警列表",
    SupportedDeviceMatrix: "支持的设备矩阵",
    APIReference: "Api参考",
  },

  desc: {
    QuickStartGuidance: "BlueSphere GWM新手？从这里开始。",
    NetworkCommunicationMatrix: "用于网络连接故障排除。",
    OTAUpgradeUserGuidence: "了解如何对设备进行OTA升级。",
    RemoteAccessUserGuidence: "远程配置设备的简单方法。",
    SupportedAlarmList: "BlueSphere GWM支持的报警列表。",
    SupportedDeviceMatrix: "BlueSphere GWM支持的设备列表。",
    APIReference: "面向开发人员的API。",
  },

  isHelpfulToyou: "这份文件的内容对你有帮助吗?",
  helpful: "有帮助的",
  nohelp: "没有帮助",
  helpTips: "如果您遇到与产品相关的问题, 请在此处提供",
  feedback: "反馈",
  here: "",
  thankComment: "感谢您的评论!",

  networkTable: {
    appLayerPotocol: "应用层协议",
    transportLayerProtocol: "传输层协议",
    port: "端口",
    address: "地址",
    purpose: "用途",
  },

  alarmTable: {
    order: "序号",
    speciality: "专业",
    manufactor: "厂家",
    alarmObjectType: "告警对象类型",
    way: "方式",
    mappingMethod: "映射方法",
    alarmTitle: "告警标题",
    alarmDescription: "告警描述",
    alarmID: "告警ID",
    alarmLocationInformation: "告警定位信息",
    alarmLevel: "告警级别",
    hardwareVersion: "适用硬件版本号",
    softwareVersion: "适用软件版本号",
    GWMalarmID: "GWM 告警ID",
    alarmExplanation: "告警解释",
    alarmLogicClassification: "告警逻辑分类",
    alarmLogicSubclass: "告警逻辑子类",
    impactOnDevice: "对设备的影响",
    impactOnBusiness: "对业务的影响",
  },

  deviceTable: {
    order: "序号",
    deviceModel: "万创设备型号",
    weblinkAddress: "官网链接地址",
    deviceOs: "设备操作系统",
    deviceConnect: "设备链接",
    dashboard: "仪表盘",
    alarmOverview: "告警概览",
    alarmRouting: "告警路由",
    deviceReport: "设备报告",
    topogyViewerMap: "拓扑视图地图",
    topogyViewerLink: "拓扑视图链接",
    MQTTTracing: "MQTT Tracing",
    deviceManagement: "设备管理",
    pingRestart: "Ping/Restart",
    changeSIM: "切换 SIM",
    deviceConfig: "设备配置",
    remoteTerminal: "远程终端",
    remoteDesktop: "远程桌面",
    edgeComputing: "边缘计算",
    heathCheck: "健康检查",
    imageOTA: "Image OTA",
    agentOTA: "Agent OTA",
    appOTA: "App OTA",
    childDevice: "子设备",
    lora: "Lora",
    PLCTelemetryData: "PLC 遥测数据",
    PLCWidgetPlatform: "PLC Widget(平台)",
  },
};
export default help;
