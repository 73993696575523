import { SnackbarOrigin, SnackbarProvider } from "notistack";

const MAX_SNACKBAR = 5;
const AUTO_HIDE_DURATION = 3000;
const POSITION: SnackbarOrigin = {
  vertical: "bottom",
  horizontal: "right",
};
export default function NoticeStackWrapper({ children }) {
  return (
    <SnackbarProvider
      maxSnack={MAX_SNACKBAR}
      autoHideDuration={AUTO_HIDE_DURATION}
      anchorOrigin={POSITION}
      style={{
        width: 360,
        background: "#ffffff",
        borderRadius: 4,
      }}
    >
      {children}
    </SnackbarProvider>
  );
}
