export enum PermissionType {
  MONITORING = "monitoring",
  DASHBOARD = "dashboard_module",
  ALARM_OVERVIEW = "alarm_overview_module",
  ALARM_ROUTING = "alarm_route_module",
  MQTT_TRACING = "mqtt_tracing_module",
  DEVICE_REPORT = "device_report_module",
  TOPOLOGY = "topology_module",
  PLC_DATA_WIDGETS = "plc_data_widgets_module",

  PROVISIONING = "provisioning",
  CONFIGURATION = "configuration_module",
  DEVICE_MANAGEMENT = "device_management_module",
  EDGE_COMPUTING = "edge_computing_module",
  SOFTWARE_MANAGEMENT = "software_management_module",

  SYSTEM = "system",
  LOG_VIEW = "log_viewer_module",
  SECURITY = "security",
  HELP_CENTER = "help_center_module",
}


export enum ButtonPermissionType {
  ALARM_SETTING = "alarm_setting",
  EXPORT_ALARMS = "export_alarms",
  CLEAR_ALARMS = "clear_alarms",
  ACK_ALARMS = "ack_alarms",
  UNACK_ALARMS = "unack_alarms",
  ALARM_FILTER = "alarm_filter",
  ROUTE_ALARMS = "route_alarms",
  ADD_ALARM_ROUTE_RULE = "add_alarm_route_rule",
  MODIFY_ALARM_ROUTE_RULE = "modify_alarm_route_rule",
  DELETE_ALARM_ROUTE_RULE = "delete_alarm_route_rule",
  SET_DEVICE_LOCATION = "set_device_location",
  CREATE_TRACE_TASK = "create_trace_task",
  MODIFY_TRACE_TASK = "modify_trace_task",
  EXPORT_TRACE_TASK_LOG = "export_trace_task_log",
  DELETE_TRACE_TASK = "delete_trace_task",
  DEVICE_REPORT_DOWNLOAD = "device_report_download",
  ROUTE_DEVICE_REPORT = "route_device_report",
  DELETE_DEVICE_REPORT = "delete_device_report",
  ADD_DEVICE_REPORT_TASK = "add_device_report_task",
  MODIFY_DEVICE_REPORT_TASK = "modify_device_report_task",
  DELETE_DEVICE_REPORT_TASK = "delete_device_report_task",
  CONFIGURATION_SAVE_SYS = "configuration_save_sys",
  CONFIGURATION_SAVE_PLC = "configuration_save_plc",
  CONFIGURATION_SYNC = "configuration_sync",
  CONFIGURATION_EXPORT_DATA = "configuration_export_data",
  CONFIGURATION_REMOTE = "configuration_remote",
  DISTRIBUTE_TO_DEVICE = "distribute_to_device",
  ACTIVATE_TASK = "activate_task",
  DEACTIVATE_TASK = "deactivate_task",
  DELETE_TASK = "delete_task",
  DISTRIBUTE_SCRIPT_TO_DEVICE = "distribute_script_to_device",
  DEVICE_MANAGEMENT_EXPORT_DEVICES = "device_management_export_devices",
  DEVICE_MANAGEMENT_MODIFY_DEVICE = "device_management_modify_device",
  DEVICE_MANAGEMENT_RESTART_DEVICE = "device_management_restart_device",
  DEVICE_MANAGEMENT_DELETE_DEVICE = "device_management_delete_device",
  DEVICE_GROUP_MANAGEMENT_MODULE = "device_group_management_module",
  ADD_DEVICE_GROUP = "add_device_group",
  MODIFY_DEVICE_GROUP = "modify_device_group",
  DELETE_DEVICE_GROUP = "delete_device_group",
  ADD_DEVICE_MODULE = "add_device_module",
  ADD_OTA_TASK = "add_ota_task",
  ADD_HEALTH_CHECK = "add_health_check",
  ADD_LICENSE = "add_license",
  MODIFY_OTA_TASK = "modify_ota_task",
  MODIFY_HEALTH_CHECK = "modify_health_check",
  CREATE_COMPARISON_REPORT = "create_comparison_report",
  DELETE_OTA_TASK = "delete_ota_task",
  DELETE_HEALTH_CHECK = "delete_health_check",
  EXPORT_OTA_TASK = "export_ota_task",
  EXPORT_LICENSE = "export_license",
  EXPORT_HEALTH_CHECK = "export_health_check",
  EXPORT_OPERATION_SECURE_LOGS = "export_operation_secure_logs",
  USER_MANAGEMENT =  "user_management_module",
  USERGROUP_MANAGEMENT =  "usergroup_management",
  PASSWORD_POLICY =  "password_policy_module",
  ADD_USER =  "add_user",
  DELETE_USER =  "delete_user",
  ADD_USERGROUP =  "add_usergroup",
  DELETE_USERGROUP =  "delete_usergroup",
  MODIFY_USER =  "modify_user",
  MODIFY_USERGROUP =  "modify_usergroup",
  MODIFY_PASSWORD_POLICY =  "modify_password_policy",
}

// dashboard的id，所有环境固定且唯一
export const DASHBOARD_PERMISSION_ID = "de52a1ff-1ae3-4822-a720-68707f381006";

export type PermissionMenu = {
  id: string;
  name: string;
  pid: null | string;
  type: number;
  webIdentifier: PermissionType | ButtonPermissionType;
  child: PermissionMenu[];
}
