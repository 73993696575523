export class EventEmitter {
  events: { [propName: string]: { funcName: string; func: any }[] } = {};

  // funcName: 对于react依赖变化导致事件回调更新的情况，funcName可以作为唯一标识进行事件回调的替换，而非重复添加事件回调，避免产生意外的bug
  //           同一个组件中，同一事件可以添加多个funcName不同的回调，触发事件时，EventEmitter实例将按添加顺序逐一执行回调函数
  on(eventName: string, funcName: string, callback: (...args: any) => any) {
    this.events[eventName] = this.events[eventName] || [];
    const idx = this.events[eventName].findIndex((item) => item.funcName === funcName);
    if (idx > -1) {
      // 回调已存在，直接替换
      this.events[eventName][idx].func = callback;
    } else {
      this.events[eventName].push({ funcName, func: callback });
    }
  }

  emit(eventName: string, ...args: any) {
    const events = this.events[eventName];

    if (!events) {
      return;
    }
    for (let i = 0, m = events.length; i < m; i++) {
      events[i].func(...Array.from(args));
    }
  }

  removeEventsByName(eventName: string, funcName: string) {
    const idx = this.events[eventName]?.findIndex((item) => item.funcName === funcName);
    if (idx > -1) {
      this.events[eventName].splice(idx, 1);
    }
  }
}
