import { FormControl, InputBaseProps, SxProps, TextField, Typography, useTheme } from "@mui/material";
import { useCallback } from "react";
import { KeyboardEvent, memo } from "react";
import { useTranslation } from "react-i18next";
import { MatFormItemProps } from "../../../models/base.model";
import { isNull } from "../../../utils";
import MatFormLabel from "./MatFormLabel";

interface MatInputProps extends MatFormItemProps<string | number> {
  multiline?: boolean;
  maxRows?: number;
  minRows?: number;
  inputProps?: InputBaseProps["inputProps"];
  sx?: SxProps;
  variant?: "standard" | "outlined" | "filled";
  fullWidth?: boolean;
  // matstyle?: boolean;
}

export default memo(function MatInput(props: MatInputProps) {
  const { matstyle = "false", width, size = "small", fullWidth = true, onKeyPress, variant = "outlined", sx = {}, helperText = "" } = props;
  const { t } = useTranslation();
  const { palette } = useTheme();
  const value = isNull(props.value) ? "" : props.value;

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (onKeyPress) {
        onKeyPress(e);
      }
    },
    [onKeyPress]
  );

  if (matstyle === "true") {
    return (
      <FormControl error={props.error} sx={{ maxWidth: width || 1 / 1, width }} fullWidth={fullWidth}>
        <TextField
          variant={variant}
          {...props}
          label={typeof props.label === "string" ? t(props.label) : props.label}
          size={size}
          placeholder={props.placeholder && t(props.placeholder)}
          value={value}
          onKeyPress={(e) => handleKeyPress(e)}
          sx={{
            "& .MuiInputBase-root": { bgcolor: "#ffffff" },
            "& .Mui-disabled": props.disabled
              ? {
                  bgcolor: palette.other.input,
                }
              : {},
            ...sx,
          }}
        ></TextField>
      </FormControl>
    );
  }

  return (
    <FormControl className="border-box" error={props.error} sx={{ ...sx, "& .MuiFormHelperText-root": { ml: 0 } }} fullWidth={fullWidth}>
      <MatFormLabel error={props.error}>{props.label}</MatFormLabel>
      <TextField
        variant={variant}
        {...props}
        label={null}
        helperText={helperText && t(helperText)}
        size={size}
        placeholder={props.placeholder && t(props.placeholder)}
        value={value}
        onKeyPress={(e) => handleKeyPress(e)}
        sx={{
          maxWidth: width || 1 / 1,
          width,
          "& .MuiInputBase-root": {
            bgcolor: "#ffffff",
          },
          "& .Mui-disabled": props.disabled
            ? {
                bgcolor: palette.other.input,
              }
            : {},
        }}
      ></TextField>
    </FormControl>
  );
});
