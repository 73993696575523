const security = {
  userManage: "User Management",
  userGroupManager: "User Group Management",
  addUser: "Add User",
  modifyUser: "Modify User",
  modifyUserGroup: "Modify User Group",
  addUserGroup: "Add User Group",
  deleteUser: "Delete User",
  menu: "Menu",
  assigned: "Assigned",
  deleteUserGroup: "Delete User Group",
  user: "User",
  passwordPolicy: "Password Policy",
  name: "Name",
  surname: "Surname",
  password: "Password",
  newPassword: "New Password",
  passwordConfirm: "Confirm Password",
  role: "Role",
  email: "Email",
  status: "Status",
  userStatus: "User Status",
  permission: "Permission",
  permissions: "Permissions",
  userGroup: "User Group",
  userGroups: "User Groups",
  lastLogin: "Last Login",
  lastLoginIp: "Last Login IP",
  forcePasswordChange: "Next Login Force Password Change",
  passwordDuration: "Password Duration",
  changePassword: "Change Password",
  devices: "Devices",
  device: "Device",
  deviceGroups: "Device groups",
  deviceGroup: "Device group",
  createdTime: "Created Time",
  sessionLabel: "Session Label",
  creator: "Creator",
  phoneNumber: "Phone Number",
  forever: "Forever",
  userGroupName: "User group name",
  userInfo: "User Info",
  groupInfo: "Group Info",
  selectPermission: "Select Permissions",
  selectDevices: "Select devices",
  selectDeviceGroups: "Select Device Groups",
  selectUserGroups: "Select User Groups",
  selectUser: "Select Users",
  online: "Online",
  offline: "Offline",
  // expandDetails: "Expand details",
  // collapseDetails: "Collapse details",
  // selectedUsers: "Selected Users",
  // permissionOverview: "Permission overview",
  details: "Details",
  useSystem: "Use system settings",
  customizeAgeing: "Customize password ageing",
  enablePwdAgeing: "Enable password ageing",
  authentication: "Authentication",
  label: {
    pwdAgeingLabel: "Number of days password is valid",
    expirationWarningLabel: "Number of days to password expiration warning",
    emailInvalid: "The email address is invalid",
  },
  auth: {
    monitoring: "Monitoring",
    provisioning: "Provisioning",
    ota: "OTA", // 一级目录和二级目录同名
    system: "System",
    // 二级目录
    // // 监控
    dashboard: "Dashboard",
    alarmOverview: "Alarm Overview",
    alarmRouting: "Alarm Routing",
    topology: "Topology Viewer",
    mqttTracing: "MQTT Tracing",
    deviceReport: "Device Report",
    PLCDataWidgets: "PLC Data Widgets",
    // // 预配置
    configuration: "Configuration",
    batchCommand: "Batch Command",
    edgeComputing: "Edge Computing",
    softwareManagement: "Software Management",
    deviceManagement: "Device Management",
    // // 安全管理
    addUser: "Add User",
    passwordPolicy: "Password Policy",
    userManagement: "User Management",
    // // 系统管理
    log: "Log Viewer",
    security: "Security",
    helpCenter: "Help Center",
    // 二级目录下的子路由或者非侧边栏的路由菜单
    alarmDetail: "Alarm Detail",
    userGroupManagement: "User Group Management",
    addOrModifyUser: "Add/Modify User",
    addUserGroupPage: "Add/Modify User Group",
    taskManagement: "Task Management",
    addTrackTask: "Create Tracing Task",
    taskDetails: "Task Details",
    taskList: "Task List",
    profile: "User Profile",
    notice: "Notice",
    // 按钮权限级别的翻译（用于选择权限树的渲染）
    informationDisplay: "Information display",
    alarm_setting: "Alarm setting",
    export_alarms: "Export alarms",
    clear_alarms: "Clear alarms",
    ack_alarms: "Ack alarms",
    unack_alarms: "Unack alarms",
    route_alarms: "Route alarms",
    alarm_filter: "Alarm filter",
    alarm_route_module: "Alarm routing",
    add_alarm_route_rule: "Add alarm route rule",
    modify_alarm_route_rule: "Modify alarm route rule",
    delete_alarm_route_rule: "Delete alarm route rule",
    add_device_report_task: "Create task",
    modify_device_report_task: "Modify task",
    delete_device_report_task: "Delete task",
    device_report_download: "Export device report",
    delete_device_report: "Delete device report",
    route_device_report: "Route device report",
    device_management_export_devices: "Export devices",
    device_management_modify_device: "Modify devices",
    device_management_restart_device: "Restart devices",
    device_management_delete_device: "Delete devices",
    add_device_module: "Add device",
    configuration_save_sys: "Modify system configuration",
    configuration_save_plc: "Modify PLC configuration",
    configuration_sync: "Sync configuration",
    configuration_export_data: "Export configuration data",
    configuration_remote: "Remote control",
    add_device_group: "Add device group",
    modify_device_group: "Modify device group",
    delete_device_group: "Delete device group",
    change_users_in_device_group: "Modify users in device group",
    change_devices_in_device_group: "Modify devices in device group",
    distribute_to_device: "Distribute to device and activate it",
    activate_task: "Activate task",
    deactivate_task: "Deactivate task",
    delete_task: "Delete task",
    distribute_script_to_device: "Distribute script to device",
    addOtaTask: "Create OTA task",
    modifyOtaTask: "Modify OTA task",
    deleteOtaTask: "Delete OTA task",
    exportOtaTask: "Export OTA task",
    addHealthCheck: "Create health check",
    modifyHealthCheck: "Modify health check",
    deleteHealthCheck: "Delete health check",
    exportHealthCheck: "Export health check",
    createComparisonReport: "Generate Comparison Report",
    addLicense: "Upload license",
    modifyLicense: "Modify license",
    deleteLicense: "Delete license",
    exportLicense: "Export license",
    add_user: "Add user",
    modify_user: "Modify user",
    delete_user: "Delete user",
    usergroup_management: "Usergroup management",
    modify_usergroup: "Modify usergroup",
    add_usergroup: "Add usergroup",
    delete_usergroup: "Delete usergroup",
    modify_password_policy: "Modify password policy",
    export_operation_secure_logs: "Export operation and security logs",
    create_trace_task: "Create trace task",
    modify_trace_task: "Modify trace task",
    delete_trace_task: "Delete trace task",
    export_trace_task_log: "Export trace task logs",
    set_device_location: "Dragging devices to the map",
    device_management_diagnostics: "Device diagnostics",
  },
};

export default security;
