import "./App.scss";
import { ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import Routes from "./router";
import MatSnackBar from "./components/common/mui/MatSnackBar";
import MatConfirm from "./components/common/mui/MatConfirm";
import "leaflet/dist/leaflet.css";
import useLanguage from "./hooks/useLanguage";
import { generateTheme } from "./theme/ThemeProvider";

export default function App() {
  const [theme] = useState(generateTheme());

  const { initLanguage } = useLanguage();

  useEffect(() => {
    initLanguage();
  }, [initLanguage]);

  return (
    <ThemeProvider theme={theme}>
      <MatSnackBar />
      <MatConfirm />
      <Routes />
    </ThemeProvider>
  );
}
