import { LocaleFile } from "../..";

const log: LocaleFile = {
  operationLog: "Operation Log",
  securityLog: "Security Log",
  mqttLog: "MQTT Log",
  filter: "Filter",
  startTime: "Start time",
  endTime: "End time",
  exportSelectedLogs: "Export Selected Logs",
  exportAllLogs: "Export All Logs",
  clearConditions: "Clear",
  logout: "Logout",
  create: "Create",
  update: "Update",
  operation: "operation",
  operationType: "Operation type",
  moduleName: "Module name",
  target: "Target",
  view: "View",
  ack: "Ack",
  nack: "Nack",
  issue: "Issue",
  export: "Export",
  import: "Import",
  time: "Time",
  message: "Message",
  messages: "Messages",
  user: "User",
  expandDetails: "Expand Details",
  foldDetails: "Fold Details",
  userIp: "Login source IP",
  ipAddress: "IP Address",
  operationContent: "Operation content",
  eventLevel: "Event level",
  logLevel: "Log level",
  searchTask: "Search for tasks",
  addTrackTask: "Create tracing task",
  start: "Start",
  stop: "Stop",
  taskName: "Task name",
  selectDevice: "Select device",
  taskConfig: "Tracing task configuration",
  keyword: "Keyword",
  eventType: "Event type",
  warning: "Warning",
  info: "Info",
  debug: "Debug",
  error: "Error",
  timedTask: "Scheduled task",
  createTask: "Create task",
  taskId: "Task ID",
  associatedDevices: "Associated devices",
  isScheduledTask: "Scheduled task",
  yes: "Yes",
  no: "No",
  noDevice: "No device selected",
  creator: "Creator",
  running: "Running",
  stopped: "Stopped",
  units: "",
  operationSuccess: "Operation successful",
  refreshTime: "Refresh time",
  never: "Never",
  seconds: " seconds",
  minute: " minute",
  minutes: " minutes",
  pageLoading: "Page refresh in progress...",
  all: "All",
  activatedTasks: "Current number of activated tasks:",
  activatedTasksTip: "The total number of activated tasks is all the activated tasks under the tenant, which may not match what you are seeing.",
  keywordTip: "Please use keywords to filter the logs you need to trace",
  httpPayload: "Payload",
  timeError: "Time Error",
  file: "File",
  eventTypeTip: "Please select the event level first",
};
export default log;
