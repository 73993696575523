import { LocaleFile } from "../..";

const noticeCenter: LocaleFile = {
  successDetail: "{{userName}}对{{entityName}}进行{{operationType}}操作成功",
  failDetail: "{{userName}}对{{entityName}}进行{{operationType}}操作失败",
  noEntityNameOperationFailed: "{{userName}}执行{{operationType}}失败",
  noEntityNameOperationSuccessed: "{{userName}}执行{{operationType}}成功",
  failureReason: "失败原因",
  alarmSuccess: "{{userName}}执行{{operationType}}成功",
  alarmFailed: "{{userName}}执行{{operationType}}失败",
};
export default noticeCenter;
