// 左侧菜单导航

import { NavItems } from "../models/nav.model";
import { Pathnames } from "./route.config";
import { PermissionType } from "../models/security.model";

// 二级菜单
const dashboard = new NavItems(PermissionType.DASHBOARD, "dashboard", Pathnames.Dashboard, [Pathnames.Dashboard]);
export const AlarmPathMatch = [Pathnames.AlarmOverview, Pathnames.AlarmDetail];
const alarmOverview = new NavItems(PermissionType.ALARM_OVERVIEW, "alarmOverview", Pathnames.AlarmOverview, AlarmPathMatch);
const alarmRouting = new NavItems(PermissionType.ALARM_ROUTING, "alarmRouting", Pathnames.AlarmRouting, [Pathnames.AlarmRouting]);
const deviceReport = new NavItems(PermissionType.DEVICE_REPORT, "deviceReport", Pathnames.DeviceReport, [Pathnames.DeviceReport, Pathnames.DeviceReportDetail]);
const topology = new NavItems(PermissionType.TOPOLOGY, "topology", Pathnames.Topology, [Pathnames.Topology]);
const configuration = new NavItems(PermissionType.CONFIGURATION, "configuration", Pathnames.Configuration, [Pathnames.Configuration, Pathnames.TaskList]);
const edgeComputing = new NavItems(PermissionType.EDGE_COMPUTING, "edgeComputing", Pathnames.EdgeComputing, [Pathnames.EdgeComputing]);
const deviceManagement = new NavItems(PermissionType.DEVICE_MANAGEMENT, "deviceManagement", Pathnames.DeviceManagement, [
  Pathnames.DeviceManagement,
  Pathnames.PlcDeviceDetails,
  Pathnames.LoraDeviceDetails,
]);
const batchCommand = new NavItems("batch_command_module", "batchCommand", Pathnames.BatchCommand, [Pathnames.BatchCommand]);
const softwareManagement = new NavItems(PermissionType.SOFTWARE_MANAGEMENT, "softwareManagement", Pathnames.SoftwareManagement, [
  Pathnames.SoftwareManagement,
  Pathnames.OtaTaskDetail,
  Pathnames.LicenseDetail,
  Pathnames.HealthCheckDetail,
  Pathnames.HealthCheckDeviceDetail,
  Pathnames.ComparisonReport,
]);
const log = new NavItems(PermissionType.LOG_VIEW, "log", Pathnames.Log, [Pathnames.Log]);
const helpCenter = new NavItems(PermissionType.HELP_CENTER, "helpCenter", Pathnames.HelpCenter, [Pathnames.HelpCenter, Pathnames.HelpCenterDtls]);
const profile = new NavItems("profile_module", "profile", Pathnames.Profile, [Pathnames.Profile]);
const mqttTracing = new NavItems(PermissionType.MQTT_TRACING, "mqttTracing", Pathnames.MqttTracing, [Pathnames.MqttTracing, Pathnames.AddTrackTask, Pathnames.TaskDetails]);
const plcDataWidgets = new NavItems(PermissionType.PLC_DATA_WIDGETS, "plcDataWidgets", Pathnames.PlcDataWidgets, [Pathnames.PlcDataWidgets]);
const security = new NavItems(PermissionType.SECURITY, "security", Pathnames.Security, [Pathnames.Security]);

// 一级菜单
const monitoring = new NavItems(
  PermissionType.MONITORING,
  "monitoring",
  Pathnames.Monitoring,
  [Pathnames.Dashboard, Pathnames.AlarmOverview, Pathnames.AlarmDetail, Pathnames.AlarmRouting, Pathnames.DeviceReport, Pathnames.Topology, Pathnames.MqttTracing, Pathnames.PlcDataWidgets],
  "ic_menu_monitoring",
  // [dashboard, alarmOverview, alarmRouting, deviceReport, topology, mqttTracing, plcDataWidgets]
  [dashboard, alarmOverview, alarmRouting, deviceReport, topology, mqttTracing]
);
const provisioning = new NavItems(
  PermissionType.PROVISIONING,
  "provisioning",
  Pathnames.Provisioning,
  [Pathnames.DeviceManagement, Pathnames.Configuration, Pathnames.BatchCommand, Pathnames.EdgeComputing, Pathnames.TaskList, Pathnames.SoftwareManagement],
  "ic_menu_configuration",
  [deviceManagement, batchCommand, configuration, edgeComputing, softwareManagement]
);

const system = new NavItems(PermissionType.SYSTEM, "system", Pathnames.System, [Pathnames.Log, Pathnames.Security, Pathnames.HelpCenter], "ic_menu_log", [log, security, helpCenter]);

// // 分割线
// const divider = new NavItems(ALL_ROLE, null, null, null, null, null, true);

export const allNavItems = [monitoring, provisioning, system];

export const systemNavItems = [new NavItems("packageManagement", "packageManagement", Pathnames.PackageManagement, [Pathnames.PackageManagement])];

// 可收藏菜单title和navItem实例的映射
export const navTitleMap: Record<string, NavItems> = {
  dashboard,
  alarmOverview,
  alarmRouting,
  deviceReport,
  topology,
  configuration,
  batchCommand,
  edgeComputing,
  deviceManagement,
  softwareManagement,
  log,
  security,
  helpCenter,
  mqttTracing,
  plcDataWidgets,
};

// 侧边栏菜单中不涉及权限的authId集合
export const navsWithoutPermission = [PermissionType.DASHBOARD, PermissionType.HELP_CENTER];

// 登录后系统中不涉及权限的功能模块集合（不存在于侧边栏菜单中）
export const routesWithoutPermission = [profile];

// 不需要匹配的路由
export const notNavPaths = [Pathnames.MainLayout, Pathnames.Login, Pathnames.SignUpPage, Pathnames.ResetPassword, Pathnames.Profile, Pathnames.PackageManagement];
// 非功能模块路由(未登录时可访问)
export const notFunctionPaths = [Pathnames.MainLayout, Pathnames.Login, Pathnames.SignUpPage, Pathnames.ResetPassword];

export function getFavorites(navPermissions: string[]) {
  const favorites: string[] = JSON.parse(localStorage.getItem("GWM_Favorites") ?? "[]");
  const authFavorites = favorites
    .map((f) => {
      return navTitleMap[f];
    })
    .filter((nav) => navPermissions.includes(nav.authId))
    .sort((a, b) => {
      let str1 = a.title;
      let str2 = b.title;
      if (str1 > str2) return 1;
      if (str1 < str2) return -1;
      return 0;
    });
  const Favorites = new NavItems("favorites", "favorites", Pathnames.Favorites, [Pathnames.Favorites], "ic_menu_favorites1", authFavorites);
  return Favorites;
}
