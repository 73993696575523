import { LocaleFile } from "../..";

const topology: LocaleFile = {
  MAP_VIEW: "地图视图",
  LINK_VIEW: "拓扑图",
  LIST_VIEW: "列表视图",
  headers: {
    placeholder: "设备名称,序列号",
    custom: "自定义",
    auto: "自动",
  },
  switchSIM: {
    success: "Sim卡更改成功！",
    tip: "更改当前Sim卡设置？",
  },
  map: {
    deviceInfo: "设备信息",
    deviceList: "设备列表",
    mapViewedAndTotal: "可见设备/总数",
  },
  dragDeviceTip: "拖拽此图标至地图中以设置设备经纬度",
  linkRootNode: "网关",
  removeDeviceLocationTitle: "移除设备",
  removeDeviceLocationContent: "此操作会从地图上移除该设备, 确认继续吗？",
  removeSuccessed: "移除设备成功!",
};
export default topology;
