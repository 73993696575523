import { BaseData, SortOrder } from "./base.model";

export interface IResponseMessageData<T = any> {
  success: boolean;
  code: number;
  message: string | null;
  data: T;
}
// 带分页数据的返回值
export interface BaseResponseData {
  pageOffset: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
  hasNext: boolean;
}
// 分页数据键值为data
export interface ResponseData<T = BaseData> extends BaseResponseData {
  data: T[];
}
export interface FetchDataFunction<T = BaseData> {
  (pageLink: PageLink): Promise<ResponseData<T>>;
}
// 分页查询参数pageLink
export interface PageLinkInterface<S = string> {
  pageOffset: number;
  pageSize: number;
  sortBy?: S;
  direction?: SortOrder;
  searchValue?: string;
  [propName: string]: any;
}
export class BasePageLink {
  constructor(public pageOffset: number = 0, public pageSize: number = 20) {}
}
/**
 *
 * @description 分页查询参数pageLink的实现类
 * @class PageLink
 * @implements {PageLinkInterface}
 */
export class PageLink<S = string> extends BasePageLink implements PageLinkInterface<S> {
  constructor(
    public pageOffset: number = 0,
    public pageSize: number = 10,
    public searchValue?: string,
    public sortBy?: S,
    public direction?: SortOrder,
    public fetchTrigger?: (p?: PageLinkInterface) => boolean,
    ...rest: any[]
  ) {
    super(pageOffset, pageSize);
    try {
      rest?.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
          this[key] = value;
        });
      });
    } catch (error) {}
  }

  // public get params() {
  //   return JSON.parse(JSON.stringify(this));
  // }
}

export const MAX_SAFE_PAGE_SIZE = 2147483647;

export const tokenIgnoredUrls = ["/gwm/login"];

export function ignoreToken(url: string) {
  return tokenIgnoredUrls.includes(url);
}
