const reports = {
  taskManagement: "Task Management",
  customizedReport: "Customized Report",
  deviceReport: "Device Report",
  groups: "Groups",
  devices: "Devices",
  device: "Device",
  deviceGroup: "Device Group",
  createTask: "Create Task",
  deleteTask: "Delete Task",
  deleteReport: "Delete Report",
  taskDetail: "Task Detail",
  fields: "Fields",
  execute: "Execute",
  routeReport: "Route Report",
  deleteTaskContent: "This operation cannot be undone. Are you sure you want to delete the selected tasks?",
  deleteReportContent: "This operation cannot be undone. Are you sure you want to delete the selected reports?",
  routeMax10: "Report route supports up to 10 reports",
  table: {
    reportName: "Report Name",
    taskName: "Task Name",
    reportType: "Report Type",
    createdTime: "Created Time",
    deviceNumber: "Device Number",
    deviceAssociated: "Device Associated",
    creator: "Creator",
    friendlyName: "Device Name",
    model: "Model",
    status: "Status",
    androidVersion: "Android Version",
    batteryLevel: "Battery Level",
    authorizationStatus: "Authorization Status",
    serialNumber: "Serial Number",
    license: "License",
    enrolledTime: "Enrollment Time",
    buildVersion: "Build Version",
    agentVersion: "Agent Version",
    groupName: "Group Name",
    lastOnlineTime: "Last Online Time",
    storage: "Storage",
    licenseExpireDay: "License Expiration",
    whitelist: "Whitelist",
    apps: "Apps",
    appList: "App Installed",
    appEvent: "App Event",
    SSID: "Wi-Fi SSID",
    ADB: "ADB",
    bluetooth: "Bluetooth",
    geofence: "Geofence",
    offline: "Offline",
    online: "Online",
    installed: "Installed",
    updated: "Updated",
    uninstalled: "Uninstalled",
    routeNum: "Route User Number",
  },
  createForm: {
    taskInfo: "Task Info",
    selectDevices: "Select Devices",
    selectedDevices: "Selected Devices",
    executePeriod: "Execution Period",
    selectFields: "Select Fields",
    selectedFields: "Selected Fields",
    routeSetting: "Route Setting",
    taskNameTip: "Current date & time could be used as default rule name",
    daily: "Daily Report",
    weekly: "Weekly Report",
    customized: "Customized Report",
    field: "Field",
    selectDeviceError: "Please select at least one device or device group",
    selectFieldError: "Please select at least one field",
    selectUserError: "Please select at least one tenant",
  },
  detail: {
    reportName: "Report Name",
    reportType: "Report Type",
    deviceNumber: "Devices",
    sn: "SN",
    deviceSn: "Device Sn",
    createdTime: "Created Time",
    friendlyName: "Device Name",
    model: "Model",
    status: "Status",
    authorizationStatus: "Authorization Status",
    serialNumber: "Serial Number",
    license: "License",
    enrollmentTime: "Enrollment Time",
    buildVersion: "Build Version",
    agentVersion: "Agent Version",
    plcVersion: "PLC Version",
    osVersion: "Os Version",
    groupName: "Group Name",
    lastOnlineTime: "Last Online Time",
    storage: "Storage",
    licenseExpireDay: "License Expiration",
    whitelist: "Whitelist",
    WifiSSID: "Wi-Fi SSID",
    geofence: "Geofence",
    offline: "Offline",
    online: "Online",
    buildTime: "Build Time",
    currentSim: "Current Sim",
    cpuType: "CPU Type",
    connectionType: "Connection Type",
    active: "Activate",
    unactive: "No License",
    depletion: "Expired",
    ipAddress: "IP Address",
    ipAddress$: "IP Address{{index}}",
    macAddress: "MAC Address",
    macAddress$: "MAC Address{{index}}",
    cellularSignalStrength: "Cellular Signal Strength (RSRP)",
    apDevList: "AP Device List",
    location: "Location",
  },
};

export default reports;
