/*
 * Copyright © 2018-2021 Chengdu Vantron Technology Co., Ltd. All rights reserved.
 */
import axios from "axios"; // 引入axios模块
import { AxiosRequestConfig } from "axios";
import { ignoreToken } from "../models/request.model";
import { $message, isProd } from "../utils";
import { Pathnames } from "../router/route.config";
import { isEmpty, isUndefined } from "lodash";

axios.defaults.baseURL = "http://192.168.16.197:8080/"; // wyj
// axios.defaults.baseURL = "http://192.168.16.146:8080"; // wmj
// axios.defaults.baseURL = "http://192.168.16.189:8080"; // cwh
// axios.defaults.baseURL = "http://192.168.16.162:8080"; // zjl
// axios.defaults.baseURL = "http://192.168.16.120:8080"; // zld
// axios.defaults.baseURL = "http://192.168.18.190"; // local test
// axios.defaults.baseURL = "https://gatewaymanager-cn.bluesphere.cloud/"; // cn
// axios.defaults.baseURL = "https://gatewaymanager-qa.bluesphere.cloud/"; // qa

if (isProd) {
  axios.defaults.baseURL = window.location.origin;
}

axios.defaults.timeout = 60000; // 设置请求超时时间

axios.interceptors.request.use(
  (config) => {
    if (!ignoreToken(config.url) && config.headers) {
      const token = window.sessionStorage.getItem("jwt_token");
      // config.headers["authorization"] = "Bearer " + token;
      config.headers["authorization"] = token || config?.headers?.authorization;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (isUndefined(response)) return;
    if (response?.data?.errorCode) {
      $message.error(response.data.errorMessage);
      return Promise.reject(response.data);
    }
    return Promise.resolve(response.data);
  },
  (error) => {
    const errMsg = error?.response?.data?.errorMessage || error.message;
    const status = error?.response?.status;
    if (status >= 400) {
      if (status === 401) {
        $message.warning(errMsg);
        window.location.hash = "#" + Pathnames.Login;
        return Promise.reject(error.response ?? error);
      } else {
        $message.error(errMsg || error.statusText);
        return Promise.reject(error.response ?? error);
      }
    } else {
      $message.warning(errMsg || error.statusText);
      return Promise.reject(error.response ?? error);
    }
  }
);

export default class HttpController {
  token(valid: boolean) {
    return new Promise<void>((resolve) => {
      resolve(null);
    });
  }

  get<T>(url: string, config: AxiosRequestConfig<any> = {}, valid = true) {
    return new Promise<T>((resolve, reject) => {
      this.token(valid).then(() => {
        axios
          .get(url, config)
          .then((res) => {
            resolve(res as unknown as T);
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  }

  post<T>(url: string, data: any = {}, config: AxiosRequestConfig<any> = {}, valid = true) {
    return new Promise<T>((resolve, reject) => {
      this.token(valid).then(() => {
        axios
          .post(url, data, config)
          .then((res) => {
            resolve(res as unknown as T);
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  }

  put<T>(url: string, params?: any, config?: AxiosRequestConfig<any>, valid = true) {
    return new Promise<T>((resolve, reject) => {
      this.token(valid).then(() => {
        axios
          .put(url, params, config)
          .then((res) => {
            resolve(res as unknown as T);
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  }

  delete<T>(url: string, params?: any, valid = true) {
    return new Promise<T>((resolve, reject) => {
      this.token(valid).then(() => {
        axios
          .delete(url, { data: params })
          .then((res) => {
            resolve(res as unknown as T);
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  }

  all(promiseArray: Promise<any>[], valid = true) {
    return new Promise((resolve, reject) => {
      this.token(valid).then(() => {
        Promise.all(promiseArray)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  }
}
