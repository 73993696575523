import { useNavigate } from "react-router-dom";
import { ADMIN_ROLE, Role } from "../models/user.model";
import { useEffect } from "react";
import LazyImport from "../components/common/tools/LazyImport";
import { Pathnames } from "./route.config";

const NotFound = LazyImport(() => import("../components/common/tools/NotFound"));
const MainLayout = LazyImport(() => import("../components/layouts/MainLayout"));
const Login = LazyImport(() => import("../pages/Login"));
const SignUpPage = LazyImport(() => import("../pages/SignUpPage"));
const ResetPassword = LazyImport(() => import("../components/common/tools/ResetPassword"));
const Dashboard = LazyImport(() => import("../components/dashboard/Dashboard"));
const AlarmOverview = LazyImport(() => import("../pages/AlarmOverview"));
const AlarmDetail = LazyImport(() => import("../components/alarms/AlarmDetail"));
const AlarmRouting = LazyImport(() => import("../pages/AlarmRouting"));
const DeviceReport = LazyImport(() => import("../pages/DeviceReport"));
const DeviceReportDetail = LazyImport(() => import("../components/deviceReport/DeviceReportDetail"));
const Topology = LazyImport(() => import("../pages/Topology"));
const MqttTracing = LazyImport(() => import("../pages/MqttTracing"));
const PlcDataWidgets = LazyImport(() => import("../pages/PlcDataWidgets"));
const Log = LazyImport(() => import("../pages/Log"));
const AddTrackTask = LazyImport(() => import("../components/mqttTracing/AddTask"));
const TaskDetails = LazyImport(() => import("../components/mqttTracing/TaskDetails"));
const Configuration = LazyImport(() => import("../pages/Configuration"));
const EdgeComputing = LazyImport(() => import("../pages/EdgeComputing"));
//
const BatchCommand = LazyImport(() => import("../pages/BatchCommand"));
const DeviceManagement = LazyImport(() => import("../pages/DeviceManagement"));
const LoraDeviceDetails = LazyImport(() => import("../components/device/LoraDeviceDetails"));
const PlcDeviceDetails = LazyImport(() => import("../components/device/PlcDeviceDetails"));
const TaskList = LazyImport(() => import("../pages/TaskList"));

const SoftwareManagement = LazyImport(() => import("../pages/SoftwareManagement"));
const OtaTaskDetail = LazyImport(() => import("../components/software-management/ota/TaskDetails"));
const LicenseDetail = LazyImport(() => import("../components/software-management/license/LicenseDetails"));
const HealthCheckDetail = LazyImport(() => import("../components/software-management/HealthCheck/HealthCheckDetail"));
const HealthCheckDeviceDetail = LazyImport(() => import("../components/software-management/HealthCheck/HealthCheckDeviceDetail"));
const ComparisonReport = LazyImport(() => import("../components/software-management/HealthCheck/ComparisonReport"));
const PackageManagement = LazyImport(() => import("../pages/PackageManagement"));
//
const Security = LazyImport(() => import("../pages/Security"));
const Profile = LazyImport(() => import("../pages/Profile"));
const HelpCenter = LazyImport(() => import("../pages/HelpCenter"));
const HelpCenterDtls = LazyImport(() => import("../components/helpCenterDtls/helpCenterDtls"));

// 自定义react-router v5之前的Redirect组件
export function Redirect({ to }) {
  let navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  });
  return null;
}

export interface RouteWithArgs {
  path: Pathnames;
  auth?: Role[];
  element?: React.ReactNode | null;
  children?: RouteWithArgs[];
}

export const allRoutes: RouteWithArgs[] = [
  {
    element: Login,
    path: Pathnames.Login,
  },
  {
    element: SignUpPage,
    path: Pathnames.SignUpPage,
  },
  {
    element: ResetPassword,
    path: Pathnames.ResetPassword,
  },
  {
    element: MainLayout,
    path: Pathnames.MainLayout,
    children: [
      // { path: Pathnames.MainLayout, element: <Redirect to={Pathnames.AlarmOverview} /> },
      {
        element: Dashboard,
        path: Pathnames.Dashboard,
      },
      { path: Pathnames.AlarmOverview, element: AlarmOverview },
      { path: Pathnames.AlarmDetail, element: AlarmDetail },
      { path: Pathnames.AlarmRouting, element: AlarmRouting },
      { path: Pathnames.DeviceReport, element: DeviceReport },
      { path: Pathnames.DeviceReportDetail, element: DeviceReportDetail },
      { path: Pathnames.Topology, element: Topology },
      { path: Pathnames.NotFound, element: NotFound },
      { path: Pathnames.MqttTracing, element: MqttTracing },
      { path: Pathnames.PlcDataWidgets, element: PlcDataWidgets },
      { path: Pathnames.AddTrackTask, element: AddTrackTask },
      { path: Pathnames.TaskDetails, element: TaskDetails },
      { path: Pathnames.Log, element: Log },
      { path: Pathnames.Configuration, element: Configuration },
      { path: Pathnames.EdgeComputing, element: EdgeComputing },
      { path: Pathnames.DeviceManagement, element: DeviceManagement },
      { path: Pathnames.LoraDeviceDetails, element: LoraDeviceDetails },
      { path: Pathnames.PlcDeviceDetails, element: PlcDeviceDetails },
      { path: Pathnames.BatchCommand, element: BatchCommand },
      { path: Pathnames.TaskList, element: TaskList },
      { path: Pathnames.Security, element: Security, auth: ADMIN_ROLE },
      { path: Pathnames.Profile, element: Profile },
      { path: Pathnames.HelpCenter, element: HelpCenter },
      { path: Pathnames.HelpCenterDtls, element: HelpCenterDtls },
      { path: Pathnames.SoftwareManagement, element: SoftwareManagement },
      { path: Pathnames.OtaTaskDetail, element: OtaTaskDetail },
      { path: Pathnames.LicenseDetail, element: LicenseDetail },
      { path: Pathnames.HealthCheckDetail, element: HealthCheckDetail },
      { path: Pathnames.HealthCheckDeviceDetail, element: HealthCheckDeviceDetail },
      { path: Pathnames.ComparisonReport, element: ComparisonReport },
      { path: Pathnames.PackageManagement, element: PackageManagement },
    ],
  },
];

// export function createRoutes(auth: Role): RouteWithArgs[] {
//   // 没权限的时候展示404页面就需要过滤路由
//   const matchedRoutes = allRoutes.filter((route) => !route.auth || route.auth.includes(auth));
//   return matchedRoutes;
//   // 没权限的时候直接展示没权限页面而不是切换到404页面就不需要过滤，直接返回所有的路由，然后在路由组件里面去判断是否有权限
//   // return allRoutes;
// }
