import { LocaleFile } from "../..";

const edgeComputing: LocaleFile = {
  scriptLoad: "Script Load",
  taskManagement: "Task Management",
  selectDevices: "Select Devices",
  uploadFile: "Upload File",
  analysisScript: "Analysis script",
  uploadTooltip: "Only supports .bin format files",
  selectedDevices: "Selected Devices",
  verifying: "Verifying...",
  verifyFaild: "Verification failed during verification, please upload again",
  verifySuccess: "Verification successful",
  executor: "Executor",
  distribute: "Distribute to device",
  distributeAndActivate: "Distribute and activate",
  taskList: "Task List",
  variable: "Variable",
  activate: "Activate",
  deactivate: "Deactivate",
  delete: "Delete",
  searchForTask: "Search for task",
  channelNameExist: "script has existed",
  variables: {
    scriptName: "Script Name",
    Executor: "Executor",
    editVariable: "Edit Variable",
    editInputVariable: "Edit Input Variable",
    variableName: "Variable Name",
    execution: "Execution",
    variableValue: "Variable Value",
    editOutputVariable: "Edit Output Variable",
    calcResultName: "Calc Result Name",
    title: "Title",
    scriptVariable: "Script Variable",
    dataType: "Data Type",
    resultValue: "Result Value",
    outputPoint: "Output Point",
  },
  table: {
    name: "Script Name",
    size: "File size(Byte)",
    type: "Script Type",
    device: "Device",
    distributeTime: "Distribute Time",
    progress: "Progress",
    status: "Status",
    createdTime: "Created Time",
  },
  status: {
    awaitingActivate: "Waiting for Activation",
    activating: "Activating",
    activated: "Activated",
    deactivated: "Deactivated",
    failed: "Failed",
  },
};

export default edgeComputing;
