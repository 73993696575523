import { ReactNode } from "react";
import { BaseOtherData, BaseData, IntBool, BaseAnyData, OperationMenu, TreeDataType } from "./base.model";

export interface IConfigTree {
  title: string;
  id: string;
  parentId?: string;
  children?: IConfigTree[];
  type: TreeDataType;
  formNode?: ReactNode;
  isEdit?: boolean;
  operationIcons?: ReactNode;
  syncStatus?: EConfigSyncStatus;

  [propsName: string]: any;
}

export enum EControllerType {
  "select" = "select",
  "string" = "string",
  "dynamic" = "dynamic",
  "array" = "array",
  "multiple" = "multiple",
  "time" = "time",
  "file" = "file",
}

export type TSyncRange = "all" | "current";

export enum EConfigurationOperations {
  BATCH_COMMAND,
  SYNC,
  EXPORT,
  ACTIVATE,
  REMOTE_WEB,
  REMOTE_TERMINAL,
}

export enum ESyncStatus {
  "success" = "success",
  "failed" = "failed",
  "progress" = "progress",
}

export interface ISyncResultItem extends BaseData {
  deviceSn: string;
  deviceName: string;
  status: ESyncStatus;
}

export type TUploadStatus = "wait" | "progress" | "success" | "failed";
export type TTaskStatus = "Scheduling" | "Progressing" | "Finished";

export interface ITaskItem extends BaseData {
  fileName: string;
  fileSize: string;
  runtime: string;
  status: TTaskStatus;
  createTime: string;
}

export type TConfigType = "FW" | "PLC" | "";

export interface IThingModelRes {
  attributes: any;

  [propsName: string]: any;
}


export interface PlcPointStatusModelRes {
  jsonPath: null,
  jsonValue: Record<string, PlcPointStatusModelJsonValue>;
}

export interface PlcPointStatusModelJsonValue {
  code: number;
  result: PlcDevicePointStatusModel;
}

export interface PlcDevicePointStatusModel {
  lastReadError: string;
  lastConnectTime: number;
  offline: number;
  point_table: PlcVariationPointStatusModel;
  status: number;
  total: number;
}

export type  PlcChannelPointStatusModel = Record<string, PlcDevicePointStatusModel>;
export type  PlcVariationPointStatusModel = Record<string, Omit<PlcDevicePointStatusModel, "total" | "offline">>;

export interface IAppConfigData {
  // SYS
  value: any;
  // PLC
  jsonV: { schema: VPTDriversConfigDescription; hardware_desc: any; version: string, protocol?: VPTConfigInfo };

  [propsName: string]: any;
}

export enum EConfigSyncStatus {
  UN_SYNC = 0, // 未同步
  SYNCING = 1, // 同步中
  SUCCEEDED = 2, // 同步成功
  FAILED = 3, // 同步失败
  EXPIRED = 4, // 同步超时
}

export interface ISysSyncStatus {
  sn: string;
  states: {
    configName: string;
    syncStatus: EConfigSyncStatus;
  }[];
  latestSyncTime: number;
}

export interface IPlcSyncStatus {
  sn: string;
  states: {
    jsonPath: string;
    syncStatus: EConfigSyncStatus;
  }[];
  latestSyncTime: number;
}

export interface IAppConfigDataFromBackend {
  id: string;
  sn: string;
  config: DeviceConfigurationInfo;
}

export interface ISaveSysConfigurationData {
  sn: string;
  name: string; // appName 带_config后缀
  config: any; // 配置数据
}

export interface ISavePlcConfigurationData {
  sn: string;
  name: string; // 当前写死为channel，后续可能精确到device、point粒度
  jsonPath: string;
  jsonValue: VPTSouthChannelConfig | null;
}

export interface IDevicePropertyParam {
  sn: string | string[];
  commands: any;
}

// 下发指令的类型名称
export type TCommandMethods = "setConfig" | "syncConfig";

export enum ERemoteControlActions {
  WEB,
  TERMINAL,
  START,
  CLOSE,
}

export const remoteMenus = [
  // new OperationMenu(ERemoteControlActions.START, "device.startRemoteAccess", "ic_remote_on"),
  new OperationMenu(ERemoteControlActions.TERMINAL, "device.remoteTerminal", "ic_terminal"),
  new OperationMenu(ERemoteControlActions.WEB, "device.remoteWeb", "ic_web")
  // new OperationMenu(ERemoteControlActions.CLOSE, "device.closeRemoteAccess", "ic_remote_off"),
];

/********************************************** 协议软件有关的接口定义 start **************************************************************/
/**
 * @description 协议软件支持的协议，可能直接用string类型标识，因为在代码运行前，你并不能用枚举把他们枚举出来，换句话说这个是不可枚举的
 */
export type VPTDrivers = string;

/**
 * @description 协议软件中的driver类型，南向北向告警边缘计算等类型
 */
export enum VPTDriverType {
  south = "s",
  north = "n",
  compute = "compute_c",
}

/**
 * @description 协议软件中的driver类型，南向北向告警边缘计算等类型
 */
export enum VPTDriverDireaction {
  south = "south",
  north = "north",
  compute = "compute_c",
}

export type VPTVariableInfo = [string, string, ...any];

/**
 * @description 协议软件的数据采集子设备的数据配置类型
 */
export interface VPTDeviceInfo extends BaseOtherData {
  point_table: VPTVariableInfo[];
  device_name?: string;
  enabled: boolean;
}

/**
 * @description 协议软件南向的每个通道的driver_settings字段里面的数据格式
 */
export interface VPTSouthChannelConfigDriverSettings extends BaseOtherData {
  communication: {
    communication_type?: VPTDrivers;
    [key: string]: any;
  };
  inputs?: {};
  outputs: {
    [key: string]: VPTDeviceInfo;
  };
}

/**
 * @description 协议软件南向通道配置
 */
export interface VPTSouthChannelConfig extends BaseOtherData {
  description?: string;
  channel_name?: string;
  driver: VPTDrivers;
  driver_type: VPTDriverType;
  enabled: boolean;
  driver_settings: VPTSouthChannelConfigDriverSettings;
  driver_direction?: VPTDriverDireaction;
}

/**
 * @description 协议软件的配置文件格式
 */
export interface VPTConfigInfo extends BaseOtherData {
  function_blocks: {
    [key: string]: VPTSouthChannelConfig;
  };
  output_groups: {
    [propName: string]: { title: string; description: string };
  };
}

export interface DeviceConfigurationInfo extends BaseOtherData {
  protocol: VPTConfigInfo;
}

/************************************************* 协议软件有关接口定义 end *************************************************** */
/************************************************* 协议软件Drivers动态配置描述文件有关接口定义 start *************************************************** */
export type VPTDynamicFormItemType = "select" | "string" | "int" | "bool" | "password";

export interface VPTFormItemDescrption<T = any> {
  check?: RegExp | string;
  default?: T;
  required?: boolean;
  title?: VPTTitleDescription;
  placeholder?: VPTTitleDescription;
  type?: VPTDynamicFormItemType;
  enum?: VPTSelectionEnum<T>[] | any[];
  show_on_table?: boolean;
  show?: string;
  index?: number;
  min?: number;
  max?: number;
}

export interface VPTTitleDescriptionSchema {
  en: string;
  zh: string;
}

export type VPTTitleDescriptionType = VPTTitleDescriptionSchema | string;

export interface VPTTitleObjectDescription {
  title: VPTTitleDescriptionType;
}

export type VPTTitleDescription = string | VPTTitleDescriptionType;

/**
 * @description 协议软件中的选择框的enum配置
 */
export interface VPTSelectionEnum<T = any> extends VPTTitleObjectDescription {
  value: T;
}

/**
 * @description 协议软件中每个协议的channel_schem中的communication_type中的channel_config配置
 */
export interface VPTChannelConfig extends BaseOtherData<VPTFormItemDescrption> {
}

/**
 * @description 协议软件中每个协议的channel_schem中的communication_type中的device_config配置
 */
export interface VPTDeviceConfig extends BaseAnyData {
  required: string[];
}

/**
 * @description 协议软件中每个协议的channel_schem中的communication_type配置
 */
export interface VPTCommunicationTypeInfo extends BaseOtherData {
  channel_config?: VPTChannelConfig;
  device_config?: VPTDeviceConfig;
  required?: string[];
}

/**
 * 协议软件动态配置中的通道配置的描述
 */
export interface VPTChannelChema extends BaseOtherData {
  communication_type: BaseOtherData<VPTCommunicationTypeInfo>;
  show_communication_type?: boolean;
  enabled: {
    default: IntBool;
    enum: VPTSelectionEnum[] | any[];
    type: string;
    [propName: string]: any;
  };
}

/**
 * @decription 协议软件动态配置中的内容
 * @param {number} attribute 指定这个diver是南向的还是北向的亦或是其他的
 */
export interface VPTDriverDescription extends BaseOtherData {
  attribute: VPTDriverDireaction;
  driver_name: string;
  title: VPTTitleDescriptionSchema;
  channel_schema?: VPTChannelChema;
}

/**
 * @description 后端给到的协议软件南向的可配置项的文件
 */
export interface VPTDriversConfigDescription extends BaseOtherData<VPTDriverDescription> {
}

/**
 * @description 创建南向通道的模板
 */
export const VPTSouthChannelTemplate: VPTSouthChannelConfig = {
  description: "",
  callup_interval: 100,
  channel_name: "",
  driver_type: VPTDriverType.south,
  driver: "",
  driver_direction: VPTDriverDireaction.south,
  enabled: true,
  driver_settings: {
    communication: {
      timeout_ms: 5000,
      serial: {} as any,
      tcp: {},
      communication_type: "",
      service_type: "master"
    },
    inputs: {},
    outputs: {},
    output_groups: {}
  }
};
export const deviceTypeLabel = { zh: "设备类型", en: "Device Type" };

export const defaultVariableTemplate = ["", "", "", "", 0, "", "", 0, "", "", null, "default_group"];

export const VPTDeviceTemplate: VPTDeviceInfo = {
  point_table: [],
  use_json: true,
  enabled: true,
  inputs: {
    write_data: {
      enabled: false,
      source: ""
    }
  }
};

export const defaultVPTGroupList = [{ title: { zh: "默认分组", en: "Default Group" }, value: "default_group" }];
/************************************************* 协议软件Drivers动态配置描述文件有关接口定义 end *************************************************** */
