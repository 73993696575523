import { LocaleFile } from "..";
import device from "./en/device";
import user from "./en/user";
import alarm from "./en/alarm";
import configuration from "./en/configuration";
import topology from "./en/topology";
import log from "./en/log";
import edgeComputing from "./en/edgeComputing";
import noticeCenter from "./en/noticeCenter";
import software from "./en/software";
import dashboard from "./en/dashboard";
import security from "./en/security";
import reports from "./en/reports";
import help from "./en/help";

const en_US: LocaleFile = {
  dashboard,
  device,
  user,
  noticeCenter,
  alarm,
  configuration,
  topology,
  log,
  edgeComputing,
  software,
  security,
  reports,
  help,
  title: "English",
  container: "EnglishContainer",
  common: {
    all: "All",
    help: "Help",
    noChanges: "There are currently no changes",
    yes: "Yes",
    no: "No",
    ok: "Ok",
    success: "Success",
    failed: "Failed",
    cancel: "Cancel",
    confirm: "Confirm",
    prevStep: "Prev",
    nextStep: "Next",
    prev: "Prev",
    next: "Next",
    back: "Back",
    addUp: "ADD",
    name: "Name",
    filters: "Filters",
    details: "Details",
    reset: "Reset",
    description: "Description",
    settings: "Settings",
    resetToDefault: "Reset to default configure",
    mode: "Mode",
    letterLessThan12: "enter up to 12 characters",
    noDataFound: "No data found",
    delete: "Delete",
    upload: "Upload",
    enabled: "Enabled",
    disabled: "Disabled",
    displayed: "Enable",
    show: "Show",
    hidden: "Hide",
    fillRequiredFields: "Please fill all required fields correctly",
    requiredFields: "This field is required",
    select: "Select",
    createdTime: "Created Time",
    add: "Add",
    send: "Send",
    type: "Type",
    status: "Status",
    deleteContent: "It cannot be recovered after deletion, please operate with caution!",
    save: "Save",
    enable: "Enable",
    disable: "Disable",
    uploadTime: "Upload Time",
    edit: "Edit",
    copy: "Copy",
    editSuccessed: "Edit successed",
    editFailed: "Edit failed",
    deleteSuccessed: "Delete successed",
    deleteFailed: "Delete failed",
    action: "Action",
    updatedTime: "Updated Time",
    user: "User",
    users: "Users",
    admin: "Admin",
    close: "close",
    emails: "Emails",
    remove: "remove",
    cardView: "Card view",
    listView: "List view",
    selectAll: "Select All",
    selected: "Selected",
    backHome: "Back to homepage",
    notFoundPage: "It looks like you're lost",
    addDesc: "Add Description",
    addDescSuccessed: "Add description successed",
    addDescFailed: "Add description failed",
    operation: "Operation",
    none: "None",
    apply: "Apply",
    refresh: "Refresh",
    activate: "activate",
    skip: "Skip this time",
    modifyNow: "Modify now",
    changePasswordSuccess: "Password modification successful!",
    changePasswordFailed: "Failed to modify password!",
    clear: "Clear",
    clearAll: "Clear All",
    copySuccess: "Copy Success!",
    copyFailed: "Copy Failed!",
    search: "Search",
    rowsPerPage: "Rows per page",
    days: "days",
    day: "day",
    return: "Return",
    leave: "Leave",
    leavePageTitle: "Leave page",
    leavePageContent: "After leaving, the system will not save the page content. Do you want to leave this page?",
    loading: "Loading",
    noPermission: "You currently do not have permission to access this page",
    loadError: "Loading failed",
    tableReload: "and reload",
    intotal: "In total",
    ascending: "Ascending",
    descending: "Descending",
    currentVersion: "Current version",
    maxChars: "Enter up to {{count}} characters",
    date: "Date",
    time: "Time",
    filterBy: "Filter by",
    deployNow: "Deploy Now",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    everyday: "Everyday",
    everyweek: "Every week",
    download: "Download",
    feedback: "Feedback",
    loadMore: "Load More",
    route: "Route"
  },
  content: {
    searchFile: "Search file",
    uploadFile: "Upload file",
  },
  nav: {
    mainLayout: "",
    // 一级目录
    favorites: "Favorites",
    monitoring: "Monitoring",
    provisioning: "Provisioning",
    ota: "OTA", // 一级目录和二级目录同名
    security: "Security",
    system: "System",
    // 二级目录
    dashboard: "Dashboard",
    alarmOverview: "Alarm Overview",
    alarmRouting: "Alarm Routing",
    deviceReport: "Device Report",
    reportDetail: "Report Detail",
    topology: "Topology Viewer",
    mqttTracing: "MQTT Tracing",
    configuration: "Configuration",
    batchCommand: "Batch Command",
    deviceGroup: "Device Group Management",
    edgeComputing: "Edge Computing",
    softwareManagement: "Software Management",
    healthCheck: "Health Check",
    healthCheckDeviceDetail: "Device Detail",
    comparisonReport: "Comparison Report",
    otaTaskDetail: "Task Details",
    LicenseDetail: "License Details",
    deviceManagement: "Device Management",
    deviceDetails: "Device Overview",
    log: "Log Viewer",
    helpCenter: "Help Center",
    alarmDetail: "Alarm Detail",
    taskManagement: "Task Management",
    taskDetails: "Task Details",
    addTrackTask: "Create Tracing Task",
    taskList: "Task List",
    profile: "User Profile",
    notice: "Notice",
    packageManagement: "Package Management",
    helpCenterDtls: "Details",
    plcDataWidgets: "PLC Data Widgets",
  },
  tenants: {
    addUser: "Add tenant user",
  },
};
export default en_US;
