import { FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, FormControl, Input, useTheme } from "@mui/material";
import { memo, PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { MatFormItemProps } from "../../../models/base.model";
import Iconfont from "../tools/Iconfont";
import MatFormLabel from "./MatFormLabel";

interface MatPasswordProps extends MatFormItemProps {
  type?: "standard" | "outlined";
}

export default memo(function MatPassword(props: PropsWithChildren<MatPasswordProps>) {
  const [showPassword, setShowPwd] = useState<boolean>(false);
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { onChange = () => {}, onBlur = () => {}, onKeyPress = () => {}, width, value = "", type = "outlined", sx = {}, matstyle = "false", placeholder = "" } = props;

  const handleClickShowPassword = () => {
    setShowPwd(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const InputComponent = type === "outlined" ? OutlinedInput : Input;
  const label = typeof props.label === "string" ? t(props.label) : props.label;
  return (
    <FormControl size={props.size || "small"} variant={type} sx={{ maxWidth: props.width || 1 / 1, width, "& .MuiFormHelperText-root": { ml: 0 }, ...sx }} fullWidth>
      {matstyle === "true" && Boolean(label) && <InputLabel error={props.error}>{label}</InputLabel>}
      {!(matstyle === "true") && Boolean(label) && <MatFormLabel error={props.error}>{label}</MatFormLabel>}
      <InputComponent
        disabled={props.disabled}
        value={value}
        error={props.error}
        name={props.name}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        type={showPassword ? "text" : "password"}
        inputProps={{ autoComplete: "new-password" }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton size="small" aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
              {!showPassword ? <Iconfont icon="ic_eyes_open" mr={0} /> : <Iconfont icon="ic_eyes_close" mr={0} />}
            </IconButton>
          </InputAdornment>
        }
        label={type === "outlined" && matstyle === "true" ? label : null}
        sx={{
          width,
          maxWidth: props.width || 1 / 1,
          "& .MuiInputBase-root": { bgcolor: "#ffffff" },
          "& .Mui-disabled": props.disabled
            ? {
                bgcolor: palette.other.input,
              }
            : {},
        }}
      />
      {props.helperText && (
        <FormHelperText error={props.error}>
          <span>{props.helperText}</span>
        </FormHelperText>
      )}
    </FormControl>
  );
});
