import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/icon/iconfont.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store";
import "overlayscrollbars/styles/overlayscrollbars.css";
// import { BrowserRouter } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import "leaflet";
import "./i18n";
import NoticeStackWrapper from "./components/layouts/NoticeStackWrapper";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  //<React.StrictMode>
  <Provider store={store}>
    <HashRouter>
      {/* App component the main component */}
      <NoticeStackWrapper>
        <App />
      </NoticeStackWrapper>
    </HashRouter>
  </Provider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
