const device = {
  device: "设备",
  devices: "设备",
  allDevices: "全部设备",
  deviceGroup: "设备组",
  addDeviceGroup: "添加设备组",
  modifyDeviceGroup: "修改设备组",
  deviceGroups: "设备组",
  searchPlaceholder: "搜索设备",
  sync: "同步",
  Detype: "设备类型",
  DefirmwareVersion: "设备固件版本",
  DesoftwareVersion: "设备软件版本",
  Desn: "SN",
  Deposition: "经纬度",
  Deackstate: "状态",
  DeackTime: "确认时间",
  DeackType: "确认类型",
  addDevice: "创建设备",
  moreOptions: "更多",
  deviceInfo: "设备信息",
  linkToDeviceGroup: "链接至设备组",
  selectTargetGroup: "选择目标分组",
  createTargetGroup: "创建目标分组",
  name: "设备名称",
  type: "设备类型",
  administrator: "管理员",
  password: "密码",
  sn: "SN",
  protocol: "通信协议",
  encryption: "加密方式",
  plcVersion: "系统版本",
  version: "固件版本",
  timeZone: "时区",
  location: "地理位置",
  description: "描述",
  displayAsSn: "显示序列号",
  displayAsName: "显示名称",
  connectionType: {
    wifi: "无线网",
    cellular: "蜂窝网",
    wan: "WAN",
    lan: "LAN",
    Ethernet: "以太网",
  },
  NO_TARGET_GROUP: "不链接",
  TO_AN_EXISTING_GROUP: "连接至已存在的组",
  TO_A_NEW_GROUP: "连接至新的组",
  //
  addGroup: "添加设备组",
  root: "根源",
  addDevices: "添加设备",
  addUsers: "添加用户",
  modifyDevices: "修改设备列表",
  modifyUsers: "修改用户列表",
  currentDeviceList: "当前设备",
  systemDeviceList: "系统设备",
  currentUserList: "当前用户",
  systemUserList: "系统用户",
  groupName: "组名称",
  groupNameRequired: "请输入组名称",
  deleteGroupTitle: '确定删除设备组 "{{name}}" 吗？',
  deleteDeviceContent: "当前操作属于高危操作，需要二次授权。请联系系统管理员输入密码。",
  editGroup: "编辑设备组",
  remoteAccess: "远程访问",
  url: "URL",
  port: "端口号",
  accessFailure: "访问失败",
  startRemoteAccess: "启动远程访问",
  closeRemoteAccess: "关闭远程访问",
  remoteWeb: "远程桌面",
  remoteTerminal: "远程终端",
  connectionStatus: "连接状态",
  formatError: "格式错误",
  example: "示例",
  timeout: "连接超时时间",
  timeoutNote: "请注意，除非您关闭远程访问，否则它将始终处于连接状态！",
  deviceList: "设备列表",
  userList: "用户列表",
  restart: "重启设备",
  restartDeviceContent: "确定要重启该设备吗？",
  deviceGroupNameMaxTip: "设备组名超过最大限制",
  addDeviceFormTokenTip: "Token输入仅适用于非万创设备",
  addDeviceRegistLoading: "正在获取设备注册...",
  addDeviceRegistSuccess: "获取设备注册成功!",
  addDeviceRegistFailed: "获取设备注册失败!",
  addDeviceVersiontLoading: "正在获取系统版本...",
  addDeviceVersionSuccess: "获取系统版本成功!",
  addDeviceVersionFailed: "获取系统版本失败!",
  addDeviceVersionSuccessTips: "系统版本号是 ",
  constants: {
    VIEW_DETAIL: "查看详情",
    RENAME: "重命名组",
    MOVE: "移动组",
    DELETE: "删除组",
    RESTART_DEVICES: "重启设备",
    DELETE_DEVICES: "删除设备",
    ONLINE: "在线",
    OFFLINE: "离线",
  },
  ungrouped: "未分组",
  multipleGroups: "{{data}} 设备组",
  modifyDevice: "修改设备",
  restartDevice: "重启",
  pingDevice: "Ping",
  moveTo: "移动到",
  switchSIM: "切换SIM卡",
  sim: "SIM卡",
  deleteDevice: "删除设备",
  batchSwitchSIMTip: "批量切换SIM卡可能会导致意外错误，您确定要继续吗？",
  addDeviceGroupSuccessed: "添加设备组成功",
  deleteDeviceGroupSuccessed: "删除设备组成功",
  renameDeviceGroupSuccessed: "修改设备组名称成功",
  // save search result
  saveSearchResult: "保存查询结果",
  clearFilters: "清除筛选",
  searchName: "查询名称",
  saveSearchResultSuccessed: "保存查询结果成功",
  saveSearchResultFailed: "保存查询结果失败",
  searchResultLimitTip: "保存上限是10个，您已超出这个限制",
  savedSearch: "已保存查询",
  deleteSavedSearchTitle: "删除查询结果",
  deleteSavedSearchContent: "该操作不可逆，您确定要删除选中的查询结果吗？",
  deleteSavedSearchSuccessed: "删除查询结果成功",
  token: "Token",
  // custom table columns
  customColumnTitle: "列的标题，请拖动或选择以重新排序",
  unSelectedColumn: "未选择表头",
  customColumnsSuccessed: "自定义展示项保存成功",
  table: {
    name: "设备名称",
    type: "设备类型",
    isActive: "设备状态",
    licenseName: "所属许可",
    lastLogin: "上次在线时间",
    ipAddress: "IP地址",
    sn: "序列号",
    groupName: "所属设备组",
    protocol: "协议",
    macAddress: "MAC地址",
    fwVersion: "固件版本",
    currentSim: "SIM卡",
    cellularSignalStrength: "蜂窝信号强度(RSRP)",
    cellularSignalPercentage: "蜂窝信号强度(%)",
    connectionType: "连接类型",
    plcVersion: "PLC版本",
    lng: "经度",
    lat: "纬度",
    criticalAlarms: "严重告警",
    majorAlarms: "主要告警",
    minorAlarms: "次要告警",
    warningAlarms: "警告告警",
    location: "坐标",
    alarms: "告警",
    status: "授权状态",
    createTime: "注册时间",
    storage: "存储",
    clientSsid: "无线网络SSID",
    cpuType: "CPU类型",
    buildTime: "构建时间",
    agentVersion: "代理版本",
    ipAddress1: "IP地址1",
    ipAddress2: "IP地址2",
    ipAddress3: "IP地址3",
    ipAddress4: "IP地址4",
    ipAddress5: "IP地址5",
    macAddress1: "MAC地址1",
    macAddress2: "MAC地址2",
    macAddress3: "MAC地址3",
    macAddress4: "MAC地址4",
    macAddress5: "MAC地址5",
    lora: "是否Lora",
    loraGatewayId: "Lora网关ID",
    loraState: "Lora状态",
  },
  license: {
    onUsing: "使用中",
    expired: "已过期",
    ineffective: "已失效",
    noLienseBind: "未绑定许可",
  },
  diagnostics: "诊断",
  domainName: "域名",
  diagnose: "诊断",
  result: "结果",
  invalidDomain: "无效的域名或IP",
  loraDevEui: "DevEUI",
  loraDeviceProfileId: "Profile",
  otaaKey: "OTAA key",
  loraDeviceClassEnabled: "启用Class",
  loraDevAddr: "设备Addr",
  information: "信息",
  data: "数据",
  method: "命令",
  deletePlcDeviceTip: "是否确认删除此设备？",
  lastSeen: "最后连接时间",
  neverSeen: "未连接",
};

export default device;
