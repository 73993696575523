import { LocaleFile } from "../..";

const software: LocaleFile = {
  name: "Name",
  softName: "软件平台",
  firmwareName: "硬件平台",
  lastVersion: "最新版本",
  RUNNING: "Running",
  ERROR: "Error",
  COMPLETED: "Completed",
  STOPED: "Stopped",
  createTask: "Create Task",
  searchTask: "Search for tasks...",
  taskName: "Task Name",
  creator: "Creator",
  device: "Device",
  status: "Status",
  softwareType: "Software Type",
  packageName: "Package Name",
  targetVersion: "Target Version",
  currentVersion: "Current Version",
  createTime: "Created Time",
  desciption: "Description",
  action: "Action",
  viewDetails: "View Details",
  copyTask: "Copy Task",
  start: "Start",
  stop: "Stop",
  report: "Report",
  delete: "Delete",
  deleteTask: "Delete Task",
  deleteTaskTip: "This operation cannot be undone. Are you sure you want to delete the selected task?",
  deleteTasksTip: "This operation cannot be undone. Are you sure you want to delete the selected tasks?",
  taskDetails: "Task Details",
  startTime: "Start Time",
  endTime: "End Time",
  progress: "Progress",
  successful: "Successful",
  failed: "Failed",
  taskInfo: "Task Info",
  devices: "Devices",
  package: "Package",
  description: "Description",
  releaseNote: "Release Note",
  deviceGroups: "Device Groups",
  selected: "Selected",
  clearAll: "Clear All",
  searchGroups: "Search for groups...",
  searchDevices: "Search for devices...",
  system: "System",
  application: "Application",
  agent: "Agent",
  uploadPackage: "Upload Package",
  uploadPackageTip: 'Click "Upload Package" button to upload',
  uploadLicenseTip: 'Click "Select File" button to upload license',
  selectFile: "Select File",
  upgradeMode: "Upgrade Mode",
  userTriggerUpgrade: "Define job and execute later",
  forceUpgrade: "Define job and execute immediately",
  scheduleUpgrade: "Define job and schedule execution",
  upgradeTime: "Upgrade Time",
  agentName: "Agent Name",
  version: "Version",
  releaseDate: "Release Date",
  changeLog: "Change Log",
  linked: "Linked: ",
  offline: "Offline",
  online: "Online",
  running: "Running",
  completed: "Completed",
  partially: "Partially Completed",
  wating: "Wating",
  productName: "Product Name",
  uploadTime: "Upload Time",
  copyTaskTip: "Reusing exist software package in a new task",
  uploadLicense: "Upload License",
  customerId: "Customer ID",
  appliedScenario: "Applied Scenario",
  runnintStatus: "Running Status in Basic License",
  licenseActivateDate: "License Activate Date",
  resourceName: "Resource Name",
  dimension: "Dimension",
  authorized: "Authorized",
  authInfo: "Authorization information in basic feature license",
  dynamicResource: "Dynamic resource info in capacity license",
  deviceLicenseInfo: "Device License info",
  activated: "Activated",
  normal: "Normal",
  usedNum: "Used Num",
  productNumber: "Product Number",
  percent: "Percent (Used)",
  deviceName: "Device Name",
  activationTime: "Activation Time",
  expiration: "Expiration",
  installedLicense: "Installed License",
  createdTime: "Created Time",
  appName: "Name",
  addSuccess: "Create successful",
  copySuccess: "Copy successful",
  numberResult: "Number of results",
  selectSavedSearch: "Select From Saved Search",
  deviceAssociated: "Device Associated",
  taskNameTip: "Current date & time could be used as default task name",
  selectSavedSearchFrom: "Select Saved Search From",
  table: "Table",
  platformBulletin: "Platform Bulletin",
  agentBulletin: "Agent Bulletin",
  OSBulletin: "OS Bulletin",
  priority: "Priority",
  high: "High",
  medium: "Medium",
  low: "Low",
  advancedSettings: "Advanced Settings",
  permanent: "Permanent",
  permanentTip: "Turn valid period of task from 72 hours (default value) to permanent",
  invalid: "Invalid",
  deployOldVersion: "You are attempting to deploy an old version, are you sure to continue?",
  healthCheck: "Health Check",
  filePath: "File Path",
  fileSize: "File Size",
  tenants: "Tenants",
  buildTime: "Build Time",
  packageType: "Package Type",
  title: "Title",
  merging: "Merging",
  tenant: "Tenant",
  linkTenant: "Link Tenant",
  deletePackage: "Delete Package",
  deletePackageTip: "This operation cannot be undone. Are you sure you want to delete the selected package?",
  linkSuccess: "Link successful",
  keepSetting: "Keep Setting",
  friendlyName: "Device Name",
  download: "Download",
  executeMode: "Execute Mode",
  executeTime: "Execute Time",
  period: "Period",
  date: "Date",
  property: "property",
  trend: "trend",
  customizedCheck: "Customized Check",
  periodicCheck: "Periodic Check",
  selectDevices: "Select Devices",
  healthCheckDetail: {
    condition: "condition",
    threshold: "threshold",
    watched: "watched",
    comparisonReport: "Comparison Report",
    generateComparisonReport: "Generate Comparison Report",
    systemVersion: "System Version",
    currentSystemVersion: "Current System Version",
    appVersion: "App Version",
    appStatus: "App Status",
    appDuration: "App Duration",
    hardware: "Hardware",
    hardwareModuleName: "Hardware Module Name",
    temperature: "Temperature",
    interfaceNetworkStatus: "Interface Network Status",
    kernel: "Kernel",
    cpu: "CPU",
    arch: "Architecture",
    bogomips: "Bogomips (ten thousand)",
    rtc: "RTC",
    hostName: "Host Name",
    boardName: "Board Name",
    model: "Model",
    bluetooth: "Bluetooth",
    gps: "GPS",
    cellular: "Cellular Network",
    croute: "Current Route",
    version: "Version",
    capacity: "Capacity",
    disk: "Disk",
    memory: "Memory",
    trafficStatistics: "Traffic Statistics",
    systemException: "System Exception",
    abnormalPowerOff: "Abnormal Power Off",
    ">": "Is greater than (>)",
    "<": "Is less than (<)",
  },
  offlineStrategy: "Offline strategy",
  failureImmediately: "Failure immediately",
  onlineResend: "Online resend",
};

export default software;
