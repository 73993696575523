import { Theme, createTheme, lighten } from "@mui/material";

// #3f51b5
export function generateTheme(): Theme {
  return createTheme({
    nav: {
      bgcolor: "rgba(0, 69, 118, 1)",
      subItemBg: "rgba(6, 59, 98, 1)",
      subItemOpacity: 1,
      hoverColor: "rgb(61, 190, 255)",
      activeMatchColor: "rgb(46, 148, 220)",
      subItemColor: "rgba(225, 255, 255, 0.68)",
      color1: "rgb(241, 161, 0)",
      color2: "rgb(241, 161, 0)",
    },
    palette: {
      mode: "light",
      primary: { main: "#007CD4", light: "#8AC3EB", dark: "#2E94DC", press: "#0066AE" },
      success: { main: "#76C704", light: "#C0E58C", dark: "#8FD131", press: "#61A303" },
      warning: { main: "#F1A100", light: "#F9D48A", dark: "#F4B22E", press: "#C68400" },
      error: { main: "#EA3510", light: "#F5A291", dark: "#EE593B", press: "#C02B0D" },
      info: { main: "#2196F3", light: "#64B6F7" },
      action: {
        active: "#080A16A3",
        disabled: "rgba(0, 10, 17, 0.36)",
      },
      text: {
        primary: "rgba(0, 10, 17, 0.82)",
        secondary: "rgba(0, 10, 17, 0.6)",
        disabled: "rgba(0, 10, 17, 0.36)",
      },
      other: {
        main: "",
        background: lighten("#2196F3", 0.96),
        divider: "rgba(0, 10, 17, 0.12)",
        input: lighten("#080A16", 0.98),
        cardbg: "#080A1605",
        cardbg1: "#080A160A",
      },
    },
    typography: {
      fontSize: 14,
      fontFamily: "IBMPlexSans-Regular",
      htmlFontSize: (16 / 14) * 16,
      button: { fontSize: "0.875rem", lineHeight: "22px" },
      subtitle1: { fontSize: "1rem", lineHeight: "24px", fontWeight: 500, color: "rgba(0, 10, 17, 0.82)" },
      subtitle2: { fontSize: "0.875rem", lineHeight: "22px", fontWeight: 500, color: "rgba(0, 10, 17, 0.82)" },
      title1: { fontSize: "1rem", lineHeight: "24px", fontWeight: 500, color: "rgba(0, 10, 17, 0.82)" },
      title2: { fontSize: "0.875rem", lineHeight: "22px", fontWeight: 500, color: "rgba(0, 10, 17, 0.82)" },
      title3: { fontSize: "0.75rem", lineHeight: "20px", fontWeight: 500, color: "rgba(0, 10, 17, 0.82)" },
      body1: { fontSize: "0.875rem", lineHeight: "22px", color: "rgba(0, 10, 17, 0.82)" },
      body2: { fontSize: "0.875rem", lineHeight: "22px", color: "rgba(0, 10, 17, 0.82)" },
      body1_bold: {
        fontSize: "0.875rem",
        lineHeight: "22px",
        fontWeight: 500,
        color: "rgba(0, 10, 17, 0.82)",
        textDecoration: "underline",
      },
      body1_underline: {
        fontSize: "0.875rem",
        lineHeight: "22px",
        color: "rgba(0, 10, 17, 0.82)",
        textDecoration: "underline",
      },
      label: { fontSize: "0.75rem", lineHeight: "20px", color: "rgba(0, 10, 17, 0.6)" },
      caption: { fontSize: "0.75rem", lineHeight: "20px", color: "rgba(0, 10, 17, 0.6)" },
      h1: { fontSize: "64px", lineHeight: "72px", fontWeight: 500, color: "rgba(0, 10, 17, 0.82)" },
      h2: { fontSize: "48px", lineHeight: "56px", fontWeight: 500, color: "rgba(0, 10, 17, 0.82)" },
      h3: { fontSize: "40px", lineHeight: "48px", fontWeight: 500, color: "rgba(0, 10, 17, 0.82)" },
      h4: { fontSize: "32px", lineHeight: "40px", fontWeight: 500, color: "rgba(0, 10, 17, 0.82)" },
      h5: { fontSize: "24px", lineHeight: "32px", fontWeight: 500, color: "rgba(0, 10, 17, 0.82)" },
      h6: { fontSize: "20px", lineHeight: "28px", fontWeight: 500, color: "rgba(0, 10, 17, 0.82)" },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        w1590: 1590,
        w1800: 1800,
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            title1: "p",
            title2: "p",
            title3: "p",
            body1_bold: "p",
            body1_underline: "p",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "14px", // 自定义字体大小
            lineHeight: "20px",
            fontWeight: 500,
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: "1rem",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: "0.75rem",
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          text: {
            fontSize: "1rem",
          },
        },
      },
    },
    commonBoxShadow: "0px 2px 8px rgba(12, 24, 90, 0.12)",
    lightBoxShadow: "0px 1px 4px rgba(12, 24, 90, 0.06)",
    custom: {
      fontBaseColor: "#000A11",
      contentPadding: 16,
      baseBgcolor: "#FAFCFE",
      commonBg: "#fff",
      background: "#F5F9FF",
      headerAvatarColor: "red",
      tableBgColor: "red",
      borderColor: "rgba(0, 10, 17, 0.12)",
      cardBgcolor: "#FFFFFF",
      hoverBgColor: "rgba(0, 10, 17, 0.02)",
      iconColor: "rgba(0, 10, 17, 0.6)",
      cardBoxShadow: "0px 1px 6px rgba(0, 10, 17, 0.1)",
    },
  });
}
