import { AlertProps } from "@mui/material";
import { cloneDeep, isEmpty, isNil, isUndefined } from "lodash";
import moment, { Moment } from "moment";
import { TreeData, TreeDataNotype } from "../models/base.model";
import { IDevicePropertyParam, TCommandMethods } from "../models/configuration.model";
import { DeviceModels, deviceModelTypeMap, DeviceTypeEnum, IDeviceTreeResItem, TDeviceListItem } from "../models/device.model";
import { ConfirmConfigData, DeleteConfirmConfigData, RestartConfirmConfigData } from "../models/material.models";
import { store } from "../store";
import { addSnackBarMsg, openConfirmAction } from "../store/toolSlice";
import { TFunction } from "i18next";
import L from "leaflet";
import marker from "../assets/imgs/marker.png";

export * as Yup from "yup";

export const isProd = process.env.NODE_ENV === "production";

export function isNull(param: any): boolean {
  return [null, undefined, "", NaN].includes(param);
}

export function isValidString(param: string): boolean {
  return ![null, undefined, "", "-"].includes(param);
}

export function timeFormat(date: string | number | Moment, format = "MMM/DD/YYYY HH:mm:ss") {
  if (isNull(date)) {
    return "";
  }
  return moment(date).format(format);
}

export function dateFormat(date: string | number | Moment, format = "YYYY/MM/DD") {
  return timeFormat(date, format);
}

export function message(type = "info" as AlertProps["severity"], content = "", duration = 3) {
  store.dispatch(addSnackBarMsg({ type, content, duration }));
}

/**
 *
 * @param {string} content 显示的内容 可以直接写翻译参数
 * @param {number} [duration=3] 显示的时间 默认3秒
 */
export const $message = {
  success(content: string, duration = 3) {
    message("success", content, duration);
  },
  info(content: string, duration = 3) {
    message("info", content, duration);
  },
  warning(content: string, duration = 3) {
    message("warning", content, duration);
  },
  error(content: string, duration = 3) {
    message("error", content, duration);
  },
};

/**
 * @param {ConfirmConfigData} config
 * @description 对话确认框 用showWarningIcon来控制是否展示警告图表
 */
export function $confirm(config: ConfirmConfigData) {
  // 给定默认值
  const { showWarningIcon = true, showCancelButton = true, okText = "common.confirm", okBtnType = "primary", customContent = false, okBtnDisabled = false, showClose = true } = config;
  store.dispatch(
    openConfirmAction({
      ...config,
      open: true,
      showWarningIcon,
      showCancelButton,
      showClose,
      okText,
      okBtnType,
      customContent,
      okBtnDisabled,
    })
  );
}

export function $deleteConfirm(config: DeleteConfirmConfigData) {
  const {
    showWarningIcon = true,
    showCancelButton = true,
    okText = "common.delete",
    okBtnType = "error",
    customContent = false,
    okBtnDisabled = false,
    content = "common.deleteContent",
    showClose = true,
  } = config;
  store.dispatch(
    openConfirmAction({
      open: true,
      showWarningIcon,
      showCancelButton,
      showClose,
      okText,
      okBtnType,
      customContent,
      okBtnDisabled,
      content,
      ...config,
    })
  );
}

export function $restartConfirm(config: RestartConfirmConfigData) {
  const {
    showWarningIcon = true,
    showCancelButton = true,
    okText = "common.confirm",
    okBtnType = "error",
    customContent = false,
    okBtnDisabled = false,
    content = "device.restartDeviceContent",
    showClose = true,
  } = config;

  store.dispatch(
    openConfirmAction({
      open: true,
      showWarningIcon,
      showCancelButton,
      showClose,
      okText,
      okBtnType,
      customContent,
      okBtnDisabled,
      content,
      ...config,
    })
  );
}

/**
 * @param {ConfirmConfigData} config
 * @description 信息提示确认框
 */
export function $info(config: ConfirmConfigData) {
  store.dispatch(
    openConfirmAction({
      okBtnType: "primary",
      open: true,
      showCancelButton: false,
      showClose: true,
      okText: "common.confirm",
      onOk() {},
      ...config,
    })
  );
}

export const ROOT_GROUP_ID = "group_id_demo";

// export function initTreeData(data: TreeData[]) {
//   const name = "ALL";
//   const rootNode: TreeData = {
//     root_node: true,
//     path: null,
//     children: data,
//     id: ROOT_GROUP_ID,
//     name,
//     parentId: null,

//   };
//   return { groups: [rootNode], name, id: ROOT_GROUP_ID };
// }

export function calcGroupId(id: string) {
  if (id === ROOT_GROUP_ID) {
    return null;
  }
  return id;
}

export function deepClone<T>(target: T, ignoreFields?: string[]): T {
  // 先判断是否为空
  if (target === null) {
    return target;
  }
  // 判断是否为时间对象
  if (target instanceof Date) {
    return new Date(target.getTime()) as any;
  }
  // 再判断是不是数组，如果是需要递归遍历克隆
  if (target instanceof Array) {
    const cp = [] as any[];
    (target as any[]).forEach((v) => {
      cp.push(v);
    });
    return cp.map((n: any) => deepClone<any>(n)) as any;
  }
  // 判断是不是object 如果是就需要一层层递归遍历
  if (typeof target === "object") {
    const cp = { ...(target as { [key: string]: any }) } as {
      [key: string]: any;
    };
    Object.keys(cp).forEach((k) => {
      // 判断遍历的key是否为需要忽略的key 不是就继续递归调用
      if (!ignoreFields || ignoreFields.indexOf(k) === -1) {
        cp[k] = deepClone<any>(cp[k]);
      }
    });
    return cp as T;
  }
  return target;
}

export function shallowClone<T = any>(obj: T): T {
  var clone = Object.create(Object.getPrototypeOf(obj));

  var props = Object.getOwnPropertyNames(obj);
  props.forEach(function (key) {
    var desc = Object.getOwnPropertyDescriptor(obj, key);
    Object.defineProperty(clone, key, desc);
  });

  return clone;
}

// 根据字符串路径获取嵌套对象的值
export const deepGet = (obj: any, keys: string) => {
  if (typeof obj === "object") {
    return keys
      .replace(/\[/g, ".")
      .replace(/\[/g, "")
      .split(".")
      .reduce((o, k) => (o || {})[k], obj);
  }
};
// 根据字符串路径修改嵌套对象的值
export const deepSet = (obj: any, keys: string, value: any) => {
  if (typeof obj === "object") {
    const keysArr = keys.split(".");
    const lastkey = keysArr[keysArr.length - 1];
    const target = keys
      .replace(/\[/g, ".")
      .replace(/\[/g, "")
      .split(".")
      .reduce((o, k, i, arr) => {
        if (k === lastkey) {
          return o;
        }
        const nextNumK = Number(arr[i + 1]);
        const isArr = !isNaN(nextNumK) && nextNumK >= 0;
        if (isUndefined(o[k]) && k !== lastkey) {
          o[k] = isArr ? [] : {};
        }
        return (o || (isArr ? [] : {}))[k];
      }, obj);
    target[lastkey] = value;
  }
};

export function isNullObject(data: any) {
  try {
    return Object.keys(data).length === 0;
  } catch (error) {
    return true;
  }
}

export function downloadFromWeb(url: string, filename?: string) {
  var pom = document.createElement("a");
  pom.setAttribute("href", url);
  pom.setAttribute("download", filename);
  document.body.appendChild(pom);
  if (document.createEvent) {
    var event = document.createEvent("MouseEvents");
    event.initEvent("click", true, true);
    pom.dispatchEvent(event);
  } else {
    pom.click();
  }
}

/**
 * 下载文件流
 * @param name 文件名
 * @param contentType
 * @param data 文件流
 */
export const downloadFileStream = (name: string, data: any, type = "application/octet-stream") => {
  const blob = new Blob([data], { type });

  const link = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("download", name);
  a.setAttribute("href", link);
  a.click();
  if (document.contains(a)) document.removeChild(a);
};
// 给日期数字补首位0
const timeFill0 = (num: number) => {
  return num >= 10 ? num : `0${num}`;
};
export const getNowTimeString = () => {
  const nowDate = new Date();
  const year = nowDate.getFullYear();
  const month = nowDate.getMonth() + 1;
  const day = nowDate.getDate();
  const hour = nowDate.getHours();
  const minute = nowDate.getMinutes();
  const second = nowDate.getSeconds();

  return "" + year + timeFill0(month) + timeFill0(day) + timeFill0(hour) + timeFill0(minute) + timeFill0(second);
};
// 根据设备组数据结构构造树结构
export const getTreeByRes = (res: IDeviceTreeResItem[]) => {
  const tree: TreeData[] = [];
  for (const item of res) {
    const group: TreeData = {
      treeDataType: undefined,
      name: item.groupName,
      sn: item.groupName,
      id: item.groupId,
      root_node: false,
      children: [],
      level: 0,
    };

    for (const device of item.deviceList) {
      const { id, name, sn, location, isActive } = device;
      // 对location处理，分割为lat lng
      const latlng = location?.split(",") ?? [];
      const hasLocation = latlng.filter((str) => str !== "").length === 2;
      const child: TreeData = {
        treeDataType: undefined,
        id,
        name,
        sn,
        root_node: false,
        children: [],
        level: 1,
        parentId: item.groupId,
        isActive,
        info: hasLocation ? { ...device, lat: isNaN(Number(latlng[0])) ? undefined : Number(latlng[0]), lng: isNaN(Number(latlng[1])) ? undefined : Number(latlng[1]) } : device,
      };
      group.children.push(child);
    }

    group.name === "Ungrouped" ? tree.unshift(group) : tree.push(group);
  }
  return tree;
};

/**
 * @export 前端导出
 * @param {string} filename
 * @param {string} text
 */
export function download(filename: string, text: string) {
  var pom = document.createElement("a");
  pom.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
  pom.setAttribute("download", filename);
  if (document.createEvent) {
    var event = document.createEvent("MouseEvents");
    event.initEvent("click", true, true);
    pom.dispatchEvent(event);
  } else {
    pom.click();
  }
}

export function getCascadedObjectValue<T extends Object = any>(value: T, key: string) {
  const keyArr = key.split(".");
  return keyArr.reduce((p, c) => {
    return p && p[c];
  }, value);
}

// 生成下发指令的参数
export const getConfigParams = (sn: string | string[], method: TCommandMethods, config: any): IDevicePropertyParam => {
  return {
    sn,
    commands: {
      method,
      params: config,
    },
  };
};

export const formatEmptyStr = (val: string) => {
  if (isUndefined(val) || isEmpty(val) || isNull(val)) {
    return "-";
  }
  return val;
};
export const getWebUrl = (remoteWebUrl: string, inputWebUrl: string) => {
  try {
    const inputData = inputWebUrl.split("://");
    const url = remoteWebUrl
      // .replace("${schema}:", "https")
      // .replace("${host}", "127.0.0.1")
      .replace("${schema_input}", inputData[0]) // http
      .replace("${url_input}", inputData[1].split(":")[0] + encodeURIComponent(":" + inputData[1].split(":")[1]));
    return url;
  } catch (error) {
    $message.error("device.accessFailure");
    return inputWebUrl;
  }
};

// 指定元素全屏
export function setElementFullScreen(ele: HTMLElement) {
  if (ele.requestFullscreen) {
    ele.requestFullscreen();
  }
}

// 删除某个cookie
export function deleteCookie(name: string, options: { secure?: true; sameSite?: string; domain?: string } = {}) {
  const { secure, sameSite, domain } = options;
  let cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; ";
  if (domain) {
    cookie += `domain=${domain};`;
  }
  if (secure) {
    cookie += `Secure;`;
  }
  if (sameSite) {
    cookie += `SameSite=${sameSite};`;
  }
  document.cookie = cookie;
}

// 动态引入外部js
export function addExternalScript(src: string) {
  return new Promise<void | void>((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = () => reject();
    document.body.appendChild(script);
  });
}

/**
 * 获取节点下所有子项(包括嵌套子项)
 * @param children 当前节点的children
 * @param allChildren 结果集合
 * @returns
 */
export const getAllChildren = (children: (TreeData | TreeDataNotype)[], allChildren: (TreeData | TreeDataNotype)[]) => {
  for (const c of children ?? []) {
    if ((c.children ?? []).length > 0) {
      getAllChildren(c.children!, allChildren);
    }
    if (!allChildren.map((ac) => ac.id).includes(c.id)) {
      allChildren.push(c);
    }
  }
};

/**
 * 根据关键字过滤树(保留原有的结构)
 * @param treeData
 * @param keyword
 * @param t 翻译函数
 * @param tPrefix 翻译路由前缀，如果关键字需要先翻译再过滤，则需要同时传递t和该字段
 * @returns
 */
export function searchTreeAndKeepParent(treeData: (TreeData | TreeDataNotype)[], keyword: string, t?: TFunction<"translation", undefined, "translation">, tPrefix?: string) {
  function search(node) {
    if (!isUndefined(t) ? t(tPrefix + node.name).includes(keyword) : node.name.includes(keyword)) {
      return true; // 当前节点包含关键字
    }
    // 初始化包含关键字的子节点数组
    let containsKeywordInChildren = false;
    if (node.children) {
      let newChildren = [];
      for (const child of node.children) {
        if (search(child)) {
          // 递归搜索子节点
          newChildren.push(child);
          containsKeywordInChildren = true;
        }
      }
      if (containsKeywordInChildren) {
        // 保留包含关键字的子节点
        node.children = newChildren;
      }
    }
    return containsKeywordInChildren; // 子节点是否包含关键字
  }

  let filteredTreeData = [];
  for (const root of treeData) {
    // 拷贝每个树的根节点，以保留原始数据不变
    const rootCopy = JSON.parse(JSON.stringify(root));
    if (search(rootCopy)) {
      // 如果根节点或其子节点包含关键字，添加到结果数组中
      filteredTreeData.push(rootCopy);
    }
  }
  return filteredTreeData;
}

// 根据id在树中找到匹配的节点
export function findNodeById(treeArray: (TreeData | TreeDataNotype)[], id: string) {
  for (const node of treeArray) {
    if (node.id === id) {
      // 如果当前节点的id匹配，返回当前节点
      return node;
    } else if (node.children && node.children.length > 0) {
      // 如果当前节点有子节点，递归搜索子节点
      const result = findNodeById(node.children, id);
      if (result) {
        // 如果在子节点中找到了匹配的节点，返回结果
        return result;
      }
    }
  }
  // 如果没有找到匹配的节点，返回null
  return null;
}

// 打平一个树  children
export function flattenTreesChildren(trees: []) {
  let result = [];
  function flattenTree(node, parentId?: string) {
    // 将当前节点的信息和其parentId一起存储
    result.push({
      ...node,
      parentId: parentId,
      children: [],
    });
    // 如果当前节点有子节点，则递归遍历子节点
    if (node.children && node.children.length > 0) {
      node.children.forEach((childNode) => {
        flattenTree(childNode, node.id);
      });
    }
  }
  // 对传入的每棵树执行打平操作
  trees.forEach((tree) => flattenTree(tree));
  return result;
}

// 将数组 转化成 一颗树 id, parentId
export function convertArrToTree(data) {
  let arr = cloneDeep(data);
  let tree = [];
  tree = arr.filter((node) => !node.parentId);
  tree.forEach((ele) => {
    const id = ele.id;
    ele.children = [];
    arr.forEach((element) => {
      if (element.parentId === id) {
        ele.children.push(element);
      }
    });
  });

  return tree;
}

// 为每个树节点的child和pid字段重命名，使其符合前端接口规范(临时方案)
export function transformNodes(nodes: TreeDataNotype[]): TreeDataNotype[] {
  return nodes.map((node) => {
    const { pid, child, ...rest } = node;
    return {
      ...rest,
      parentId: pid, // 重命名 pid 为 parentId
      children: child ? transformNodes(child) : [], // 递归处理子节点
    };
  });
}

// 打平一个数组
export function flattenTrees(trees: (TreeData | TreeDataNotype)[]) {
  let result = [];

  function flattenTree(node: TreeData | TreeDataNotype, parentId?: string) {
    // 将当前节点的信息和其parentId一起存储
    result.push({
      ...node,
      parentId: parentId,
    });
    // 如果当前节点有子节点，则递归遍历子节点
    if (node.children && node.children.length > 0) {
      node.children.forEach((childNode) => {
        flattenTree(childNode, node.id);
      });
    }
  }

  // 对传入的每棵树执行打平操作
  trees.forEach((tree) => flattenTree(tree));

  return result;
}

export function flattenDeviceTree(trees: IDeviceTreeResItem[]): TDeviceListItem[] {
  const result = [];
  for (const node of trees) {
    result.push({
      ...node,
      id: node.groupId,
      name: node.groupName,
    });
    result.push(...node.deviceList);
  }
  return result;
}

// 在平铺树中找到当前节点的所有父节点
export function findAllParentsInFlatTree(flatTree: (TreeData | TreeDataNotype)[], nodeId: string) {
  let parentNode = flatTree.find((node) => node.id === nodeId);
  const parents = [];

  while (parentNode && parentNode.parentId) {
    parentNode = flatTree.find((node) => node.id === parentNode.parentId);
    if (parentNode) {
      parents.push(parentNode);
    }
  }

  return parents;
}

// 取消勾选节点（检查节点的所有父节点是否需要被移除并做对应处理）
export function removeFromSelected(nodeId: string, nodes: (TreeData | TreeDataNotype)[], selectedItems: (TreeData | TreeDataNotype)[]) {
  let selected = cloneDeep(selectedItems);

  // 递归函数，从selected中移除节点及其所有子节点
  function removeNodeAndChildren(node: TreeData | TreeDataNotype) {
    // 移除当前节点
    selected = selected.filter((s) => s.id !== node.id);
    // 移除所有子节点
    if (node.children) {
      (node.children ?? ([] as (TreeData | TreeDataNotype)[])).forEach((child) => removeNodeAndChildren(child));
    }
  }

  // 递归函数，检查是否所有子节点都没被选中
  function allChildrenUnselected(node: TreeData | TreeDataNotype) {
    return (node.children ?? ([] as (TreeData | TreeDataNotype)[])).every((child) => !selected.map((s) => s.id).includes(child.id) && (!child.children || allChildrenUnselected(child)));
  }

  // 递归函数，找到并处理节点的所有父节点
  function updateParentNodes(currentNode: TreeData | TreeDataNotype, parent: TreeData | TreeDataNotype) {
    if (parent) {
      // 如果父节点没有任何选中的子节点，则从selected移除
      if (allChildrenUnselected(parent)) {
        selected = selected.filter((s) => s.id !== parent.id);
      }
      // 继续检查上一级父节点
      if (parent.parent) {
        updateParentNodes(parent, parent.parent);
      }
    }
  }

  // 递归函数，找到节点并处理其父节点
  function findNodeAndHandleParents(nodeId: string, nodes: (TreeData | TreeDataNotype)[], parent: TreeData | TreeDataNotype) {
    nodes.forEach((node) => {
      if (node.id === nodeId) {
        // 找到节点，移除节点及其子节点
        removeNodeAndChildren(node);
        // 更新父节点
        updateParentNodes(node, parent);
      } else if (node.children) {
        // 继续在子节点中查找
        findNodeAndHandleParents(nodeId, node.children, node);
      }
    });
  }

  // 开始处理流程
  findNodeAndHandleParents(nodeId, nodes, null);
  return selected;
}

export const buildModuleListByNames = (treeData: TreeData[], moduleNames: string[]) => {
  const result = [];
  if (treeData == null || moduleNames == null) {
    return result;
  }
  for (const treeDatum of treeData) {
    const data = treeDatum.children.filter((item) => moduleNames.includes(item.value));
    if (data.length) {
      result.push(...data);
    }
  }
  return result;
};

// 根据设备型号获取设备类型
export const getDeviceTypeByModel = (model: DeviceModels) => {
  let res: DeviceTypeEnum;
  Object.keys(deviceModelTypeMap).forEach((key) => {
    const types = deviceModelTypeMap[key];
    if (types.includes(model)) {
      res = key as DeviceTypeEnum;
    }
  });
  return res;
};

// 清除锁屏定时器
export const clearLockTimer = () => {
  const timer = Number(sessionStorage.getItem("gwm_lock_timer"));
  if (!isUndefined(timer)) {
    clearTimeout(timer);
    sessionStorage.removeItem("gwm_lock_timer");
  }
};
// 将一串字符的首字母改小写
export function convertFirstLetterToLowercase(str: string) {
  // 将字符串的首字母转换为小写
  let lowercaseFirstLetter = str.charAt(0).toLowerCase();
  // 将转换后的首字母与原始字符串的剩余部分拼接起来
  let convertedStr = lowercaseFirstLetter + str.slice(1);

  return convertedStr;
}
//计算字符串长度
export const calculateStringLength = (str: string) => {
  let length = 0;
  for (let char of str) {
    if (/[\\u4e00-\\u9fa5\\u3000-\\u303f\\uff00-\\uffef]/.test(char)) {
      length += 0.5;
    } else {
      length += 1;
    }
  }
  return length;
};

export const markerIcon = L.icon({ iconUrl: marker, popupAnchor: [0, -16], tooltipAnchor: [0, -16], iconAnchor: [12, 24], iconSize: [24, 24] });

/**
 * 根据source中的顺序，重新排序target
 * @param source 排序依据
 * @param target 目标
 * @param keyName
 * @returns
 */
export function sortAccordingToSource(source: any[], target: any[], keyName: string): typeof target {
  const orderMap = new Map(); // 用于存储 arr 中每个元素的索引位置
  source.forEach((key, index) => {
    orderMap.set(key, index);
  });

  target.sort((a, b) => {
    // 比较两个元素在 source 中的索引
    return orderMap.get(a[keyName]) - orderMap.get(b[keyName]);
  });

  return target; // 返回排序后的 target
}

export function autoParseFileSize(value: number | string): string {
  try {
    if (isNull(value)) {
      return "";
    }
    // 大于1024k显示MB 否则显示 KB
    const kbSize = Number(value) / 1024 - 0;
    if (kbSize >= 1024) {
      return (kbSize / 1024).toFixed(2) + " MB";
    } else {
      return kbSize.toFixed(2) + " KB";
    }
  } catch (error) {
    return value?.toString();
  }
}

/**
 * 根据版本号 进行排序
 */
export function sortVersion(list, order: "desc" | "asc" = "desc") {
  if (list.length === 0 || list.length === 1) return list;

  const data = list.sort((a, b) => {
    const aarr = a.versionName.split(".");
    const barr = b.versionName.split(".");
    const len = Math.max(a.versionName.length, b.versionName.length);
    for (let i = 0; i < len; i++) {
      const n1 = parseFloat(aarr[i] || 0);
      const n2 = parseFloat(barr[i] || 0);
      if (n1 > n2) return order === "desc" ? -1 : 1;
      if (n1 < n2) return order === "desc" ? 1 : -1;
    }
    return 0;
  });
  return data;
}

export function getUuid() {
  var d = new Date().getTime();
  var d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export const latitudeFormat = (latitude: string) => {
  return latitudeOrLongitudeFormat(latitude, /([\d.]*)([NS\s]*)([\d.]*)/i);
};

export const longitudeFormat = (longitude: string) => {
  return latitudeOrLongitudeFormat(longitude, /([\d.]*)([WE\s]*)([\d.]*)/i);
};

const latitudeOrLongitudeFormat = (str: string, reg: RegExp) => {
  if (isNil(str) || str === "") {
    return str;
  }
  const strs = str
    .match(reg)
    .slice(1)
    .filter((value) => Boolean(value.trim()));
  return strs
    .map((str) => {
      const number = Number(str.trim());
      if (Number.isNaN(number)) {
        return str;
      }
      const dd = Math.floor(number / 100);
      const ddmmm = Math.round(((number % 100) * 100) / 6) / 1000;
      return dd + ddmmm;
    })
    .join("");
};
