import { LocaleFile } from "../..";

const configuration: LocaleFile = {
  FW: "系统配置",
  PLC: "PLC配置",
  total: "设备总数",
  notStarted: "未开始",
  inProgress: "进行中",
  success: "成功",
  failed: "失败",
  operationStatus: "操作状态",
  devices: "设备",
  results: "结果",
  distributeSuccessfully: "同步任务下发成功",
  distributeFailed: "同步任务下发失败",
  syncSuccessfully: "同步成功",
  syncFailed: "同步失败",
  actions: {
    batchCommand: "批处理",
    currentDevice: "当前设备",
    allDevices: "所有设备",
  },
  tree: {},
  detail: {
    editAttributes: "编辑属性",
  },
  batchCommand: {
    taskManagement: "任务管理",
    command: "命令",
    needTemplate: "需要一个模板",
    commandFile: "命令文件",
    uploadText: "请拖拽一个文件到这里或者",
    uploadFiles: "上传文件",
    reUpload: "重新上传",
    carryOut: "执行",
    rightNow: "立即",
    schedule: "预定时间",
    commandProgress: "命令过程",
    log: "日志",
    scheduleTitle: "预定",
    scheduleTime: "预定时间",
    taskList: "任务列表",
    taskTable: {
      fileName: "文件名",
      fileSize: "文件大小",
      runtime: "运行时",
      status: "状态",
      createTime: "创建时间",
    },
  },
  defaultGroup: "默认分组",
  addChannel: "添加通道",
  editChannel: "编辑通道",
  deleteChannel: "删除通道",
  deleteChannelContent: "你确认要删除这个通道吗?",
  addDevice: "添加设备",
  editDevice: "编辑设备",
  deleteDevice: "删除设备",
  deleteDeviceContent: "你确认要删除这个设备吗?",
  copyDevice: "复制设备",
  deleteVariable: "删除变量",
  deleteVariableContent: "你确认要删除这个变量吗?",
  channel_name: "通道名称",
  description: "描述",
  driver: "协议类型",
  enabled: "是否启用",
  communication_type: "通信方式",
  copyVariableOrNot: "是否复制变量",
  addVariable: "添加变量",
  copyVariable: "复制变量",
  editVariable: "编辑变量",
  nameRepeat: "该名称已经存在，请重新输入",
  varibleNodeIdExist: "节点ID已经存在，请重新输入",
  syncCompleted: "同步已完成",
  lastDataUpdateTime: "最后一次数据更新时间",
  synchronizing: "正在同步中...",
  pleaseAddChannel: "请添加通道",
  pleaseAddDevice: "请添加设备",
  notSelectChannel: "未选择通道!",
  notSupportDevice: "当前通道不支持添加设备",
  deviceOffline: "设备不在线，请检查设备状态",
  syncDataTip: "请再次同步配置数据",
  leaveTabTitle: "离开页面",
  leaveTabContent: "当前页面更改内容尚未保存，离开页面后编辑内容将不会自动保存，是否要离开此页面？",
  leave: "离开",
  channel: "通道",
  notSelected: "请先选择设备来查看配置详情",
  notSupport: "当前设备不支持配置。",
  noSelectedChannel: "请先选择通道",
  portAlreadyExists: "串口已经被 {{channelName}} 使用",
  dataDistribution: "数据下发",
  advanceConfig: "数据运算",
  scale: "比例转换",
  zoom: "缩放",
  maxValue: "数值上限",
  minValue: "数值下限",
  maxScale: "比例上限",
  minScale: "比例下限",
  offset: "偏移量",
  gain: "增益",
  scaleTip: "注：显示值=(比例上限-比例下限)/(数值上限-数值下限)*(PLC读取值-数值下限)+比例下限",
  zoomTip1: "注：输出值=(PLC读取值*增益)+偏移量",
  zoomTip2: "PLC值=(输出值-偏移量)/增益",
  maxLessThanMin: "上限值必须大于下限值",
  gainNotZero: '增益不能为"0"',
  configTree: {
    system: "系统",
    network: "网络",
    linkTracking: "链路跟踪",
    deviceManager: "设备管理",
    networkSecurity: "网络安全",
  },
  loadWebIframeTimeout: "连接超时，请再次尝试连接。",
  remoteIsUsingTip: "设备终端正在被其他用户使用。",
};
export default configuration;
