export interface CountryData {
  code: string;
  name: string;
  enName: string;
  phonePrefix: string;
}
const countryData: CountryData[] = [
  { code: "us", name: "美国", enName: "United States", phonePrefix: "+1" },
  { code: "gb", name: "英国", enName: "United Kingdom", phonePrefix: "+44" },
  { code: "cn", name: "中国 (中国) ", enName: "China (中国)", phonePrefix: "+86" },
  {
    code: "af",
    name: "阿富汗 (افغانستان‎) ",
    enName: "Afghanistan (‫افغانستان‬‎)",
    phonePrefix: "+93",
  },
  {
    code: "al",
    name: "阿尔巴尼亚 (Shqipëri) ",
    enName: "Albania (Shqipëri)",
    phonePrefix: "+355",
  },
  {
    code: "dz",
    name: "阿尔及利亚 (الجزائر‎) ",
    enName: "Algeria (‫الجزائر‬‎)",
    phonePrefix: "+213",
  },
  { code: "as", name: "美属萨摩亚", enName: "American Samoa", phonePrefix: "+1684" },
  { code: "ad", name: "安道尔", enName: "Andorra", phonePrefix: "+376" },
  { code: "ao", name: "安哥拉", enName: "Angola", phonePrefix: "+244" },
  { code: "ai", name: "安圭拉", enName: "Anguilla", phonePrefix: "+1264" },
  {
    code: "ag",
    name: "安提瓜和巴布达",
    enName: "Antigua and Barbuda",
    phonePrefix: "+1268",
  },
  { code: "ar", name: "阿根廷", enName: "Argentina", phonePrefix: "+54" },
  {
    code: "am",
    name: "亚美尼亚 (Հայաստան) ",
    enName: "Armenia (Հայաստան)",
    phonePrefix: "+374",
  },
  { code: "aw", name: "阿鲁巴", enName: "Aruba", phonePrefix: "+297" },
  { code: "au", name: "澳大利亚", enName: "Australia", phonePrefix: "+61" },
  {
    code: "at",
    name: "奥地利 (Österreich) ",
    enName: "Austria (Österreich)",
    phonePrefix: "+43",
  },
  {
    code: "az",
    name: "阿塞拜疆 (Azərbaycan) ",
    enName: "Azerbaijan (Azərbaycan)",
    phonePrefix: "+994",
  },
  { code: "bs", name: "巴哈马", enName: "Bahamas", phonePrefix: "+1242" },
  {
    code: "bh",
    name: "巴林 (البحرين‎) ",
    enName: "Bahrain (‫البحرين‬‎)",
    phonePrefix: "+973",
  },
  {
    code: "bd",
    name: "孟加拉国 (বাংলাদেশ) ",
    enName: "Bangladesh (বাংলাদেশ)",
    phonePrefix: "+880",
  },
  { code: "bb", name: "巴巴多斯", enName: "Barbados", phonePrefix: "+1246" },
  {
    code: "by",
    name: "白俄罗斯 (Беларусь) ",
    enName: "Belarus (Беларусь)",
    phonePrefix: "+375",
  },
  {
    code: "be",
    name: "比利时 (België) ",
    enName: "Belgium (België)",
    phonePrefix: "+32",
  },
  { code: "bz", name: "伯利兹", enName: "Belize", phonePrefix: "+501" },
  { code: "bj", name: "贝宁 (Bénin) ", enName: "Benin (Bénin)", phonePrefix: "+229" },
  { code: "bm", name: "百慕大", enName: "Bermuda", phonePrefix: "+1441" },
  {
    code: "bt",
    name: "不丹 (འབྲུག) ",
    enName: "Bhutan (འབྲུག)",
    phonePrefix: "+975",
  },
  { code: "bo", name: "玻利维亚", enName: "Bolivia", phonePrefix: "+591" },
  {
    code: "ba",
    name: "波斯尼亚和黑塞哥维那 (Босна и Херцеговина) ",
    enName: "Bosnia and Herzegovina (Босна и Херцеговина)",
    phonePrefix: "+387",
  },
  { code: "bw", name: "博茨瓦纳", enName: "Botswana", phonePrefix: "+267" },
  { code: "br", name: "巴西（巴西）", enName: "Brazil (Brasil)", phonePrefix: "+55" },
  {
    code: "io",
    name: "英属印度洋领地",
    enName: "British Indian Ocean Territory",
    phonePrefix: "+246",
  },
  {
    code: "vg",
    name: "英属维尔京群岛",
    enName: "British Virgin Islands",
    phonePrefix: "+1284",
  },
  { code: "bn", name: "文莱", enName: "Brunei", phonePrefix: "+673" },
  {
    code: "bg",
    name: "保加利亚 (България) ",
    enName: "Bulgaria (България)",
    phonePrefix: "+359",
  },
  { code: "bf", name: "布基纳法索", enName: "Burkina Faso", phonePrefix: "+226" },
  {
    code: "bi",
    name: "布隆迪（乌布隆迪）",
    enName: "Burundi (Uburundi)",
    phonePrefix: "+257",
  },
  {
    code: "kh",
    name: "柬埔寨 (កម្ពុជា) ",
    enName: "Cambodia (កម្ពុជា)",
    phonePrefix: "+855",
  },
  {
    code: "cm",
    name: "喀麦隆（喀麦隆）",
    enName: "Cameroon (Cameroun)",
    phonePrefix: "+237",
  },
  { code: "ca", name: "加拿大", enName: "Canada", phonePrefix: "+1" },
  {
    code: "cv",
    name: "佛得角（卡布威尔第）",
    enName: "Cape Verde (Kabu Verdi)",
    phonePrefix: "+238",
  },
  {
    code: "bq",
    name: "加勒比海荷兰",
    enName: "Caribbean Netherlands",
    phonePrefix: "+599",
  },
  { code: "ky", name: "开曼群岛", enName: "Cayman Islands", phonePrefix: "+1345" },
  {
    code: "cf",
    name: "中非共和国 (République centrafricaine) ",
    enName: "Central African Republic (République centrafricaine)",
    phonePrefix: "+236",
  },
  { code: "td", name: "乍得（乍得）", enName: "Chad (Tchad)", phonePrefix: "+235" },
  { code: "cl", name: "智利", enName: "Chile", phonePrefix: "+56" },
  { code: "cx", name: "圣诞岛", enName: "Christmas Island", phonePrefix: "+61" },
  {
    code: "cc",
    name: "科科斯（基林）群岛",
    enName: "Cocos (Keeling) Islands",
    phonePrefix: "+61",
  },
  { code: "co", name: "哥伦比亚", enName: "Colombia", phonePrefix: "+57" },
  {
    code: "km",
    name: "科摩罗 (جزر القمر‎) ",
    enName: "Comoros (‫جزر القمر‬‎)",
    phonePrefix: "+269",
  },
  {
    code: "cd",
    name: "刚果 (DRC) (Jamhuri ya Kidemokrasia ya Kongo) ",
    enName: "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
    phonePrefix: "+243",
  },
  {
    code: "cg",
    name: "刚果（共和国）（刚果-布拉柴维尔）",
    enName: "Congo (Republic) (Congo-Brazzaville)",
    phonePrefix: "+242",
  },
  { code: "ck", name: "库克群岛", enName: "Cook Islands", phonePrefix: "+682" },
  { code: "cr", name: "哥斯达黎加", enName: "Costa Rica", phonePrefix: "+506" },
  { code: "ci", name: "科特迪瓦", enName: "Côte d’Ivoire", phonePrefix: "+225" },
  {
    code: "hr",
    name: "克罗地亚 (Hrvatska) ",
    enName: "Croatia (Hrvatska)",
    phonePrefix: "+385",
  },
  { code: "cu", name: "古巴", enName: "Cuba", phonePrefix: "+53" },
  { code: "cw", name: "库拉索", enName: "Curaçao", phonePrefix: "+599" },
  {
    code: "cy",
    name: "塞浦路斯 (Κύπρος) ",
    enName: "Cyprus (Κύπρος)",
    phonePrefix: "+357",
  },
  {
    code: "cz",
    name: "捷克共和国 (Česká republika) ",
    enName: "Czech Republic (Česká republika)",
    phonePrefix: "+420",
  },
  {
    code: "dk",
    name: "丹麦（丹麦）",
    enName: "Denmark (Danmark)",
    phonePrefix: "+45",
  },
  { code: "dj", name: "吉布提", enName: "Djibouti", phonePrefix: "+253" },
  { code: "dm", name: "多米尼克", enName: "Dominica", phonePrefix: "+1767" },
  {
    code: "do",
    name: "多米尼加共和国 (República Dominicana) ",
    enName: "Dominican Republic (República Dominicana)",
    phonePrefix: "+1",
  },
  { code: "ec", name: "厄瓜多尔", enName: "Ecuador", phonePrefix: "+593" },
  { code: "eg", name: "埃及 (مصر‎) ", enName: "Egypt (‫مصر‬‎)", phonePrefix: "+20" },
  { code: "sv", name: "萨尔瓦多", enName: "El Salvador", phonePrefix: "+503" },
  {
    code: "gq",
    name: "赤道几内亚 (Guinea Ecuatorial) ",
    enName: "Equatorial Guinea (Guinea Ecuatorial)",
    phonePrefix: "+240",
  },
  { code: "er", name: "厄立特里亚", enName: "Eritrea", phonePrefix: "+291" },
  {
    code: "ee",
    name: "爱沙尼亚 (Eesti) ",
    enName: "Estonia (Eesti)",
    phonePrefix: "+372",
  },
  { code: "et", name: "埃塞俄比亚", enName: "Ethiopia", phonePrefix: "+251" },
  {
    code: "fk",
    name: "福克兰群岛（马尔维纳斯群岛）",
    enName: "Falkland Islands (Islas Malvinas)",
    phonePrefix: "+500",
  },
  {
    code: "fo",
    name: "法罗群岛 (Føroyar) ",
    enName: "Faroe Islands (Føroyar)",
    phonePrefix: "+298",
  },
  { code: "fj", name: "斐济", enName: "Fiji", phonePrefix: "+679" },
  {
    code: "fi",
    name: "芬兰 (Suomi) ",
    enName: "Finland (Suomi)",
    phonePrefix: "+358",
  },
  { code: "fr", name: "法国", enName: "France", phonePrefix: "+33" },
  {
    code: "gf",
    name: "法属圭亚那 (Guyane française) ",
    enName: "French Guiana (Guyane française)",
    phonePrefix: "+594",
  },
  {
    code: "pf",
    name: "法属波利尼西亚 (Polynésie française) ",
    enName: "French Polynesia (Polynésie française)",
    phonePrefix: "+689",
  },
  { code: "ga", name: "加蓬", enName: "Gabon", phonePrefix: "+241" },
  { code: "gm", name: "冈比亚", enName: "Gambia", phonePrefix: "+220" },
  {
    code: "ge",
    name: "格鲁吉亚 (საქართველო) ",
    enName: "Georgia (საქართველო)",
    phonePrefix: "+995",
  },
  {
    code: "de",
    name: "德国（德国）",
    enName: "Germany (Deutschland)",
    phonePrefix: "+49",
  },
  { code: "gh", name: "加纳 (Gaana) ", enName: "Ghana (Gaana)", phonePrefix: "+233" },
  { code: "gi", name: "直布罗陀", enName: "Gibraltar", phonePrefix: "+350" },
  {
    code: "gr",
    name: "希腊 (Ελλάδα) ",
    enName: "Greece (Ελλάδα)",
    phonePrefix: "+30",
  },
  {
    code: "gl",
    name: "格陵兰 (Kalaallit Nunaat) ",
    enName: "Greenland (Kalaallit Nunaat)",
    phonePrefix: "+299",
  },
  { code: "gd", name: "格林纳达", enName: "Grenada", phonePrefix: "+1473" },
  { code: "gp", name: "瓜德罗普岛", enName: "Guadeloupe", phonePrefix: "+590" },
  { code: "gu", name: "关岛", enName: "Guam", phonePrefix: "+1671" },
  { code: "gt", name: "危地马拉", enName: "Guatemala", phonePrefix: "+502" },
  { code: "gg", name: "根西岛", enName: "Guernsey", phonePrefix: "+44" },
  {
    code: "gn",
    name: "几内亚（几内亚）",
    enName: "Guinea (Guinée)",
    phonePrefix: "+224",
  },
  {
    code: "gw",
    name: "几内亚比绍 (Guiné Bissau) ",
    enName: "Guinea-Bissau (Guiné Bissau)",
    phonePrefix: "+245",
  },
  { code: "gy", name: "圭亚那", enName: "Guyana", phonePrefix: "+592" },
  { code: "ht", name: "海地", enName: "Haiti", phonePrefix: "+509" },
  { code: "hn", name: "洪都拉斯", enName: "Honduras", phonePrefix: "+504" },
  {
    code: "hk",
    name: "香港 (香港) ",
    enName: "Hong Kong (香港)",
    phonePrefix: "+852",
  },
  {
    code: "hu",
    name: "匈牙利 (Magyarország) ",
    enName: "Hungary (Magyarország)",
    phonePrefix: "+36",
  },
  {
    code: "is",
    name: "冰岛 (Ísland) ",
    enName: "Iceland (Ísland)",
    phonePrefix: "+354",
  },
  { code: "in", name: "印度 (भारत) ", enName: "India (भारत)", phonePrefix: "+91" },
  { code: "id", name: "印度尼西亚", enName: "Indonesia", phonePrefix: "+62" },
  {
    code: "ir",
    name: "伊朗 (ایران‎) ",
    enName: "Iran (‫ایران‬‎)",
    phonePrefix: "+98",
  },
  {
    code: "iq",
    name: "伊拉克 (العراق‎) ",
    enName: "Iraq (‫العراق‬‎)",
    phonePrefix: "+964",
  },
  { code: "ie", name: "爱尔兰", enName: "Ireland", phonePrefix: "+353" },
  { code: "im", name: "马恩岛", enName: "Isle of Man", phonePrefix: "+44" },
  {
    code: "il",
    name: "以色列 (ישראל‎) ",
    enName: "Israel (‫ישראל‬‎)",
    phonePrefix: "+972",
  },
  {
    code: "it",
    name: "意大利（意大利）",
    enName: "Italy (Italia)",
    phonePrefix: "+39",
  },
  { code: "jm", name: "牙买加", enName: "Jamaica", phonePrefix: "+1876" },
  { code: "jp", name: "日本（日本）", enName: "Japan (日本)", phonePrefix: "+81" },
  { code: "je", name: "泽西", enName: "Jersey", phonePrefix: "+44" },
  {
    code: "jo",
    name: "约旦 (الأردن‎) ",
    enName: "Jordan (‫الأردن‬‎)",
    phonePrefix: "+962",
  },
  {
    code: "kz",
    name: "哈萨克斯坦 (Казахстан) ",
    enName: "Kazakhstan (Казахстан)",
    phonePrefix: "+7",
  },
  { code: "ke", name: "肯尼亚", enName: "Kenya", phonePrefix: "+254" },
  { code: "ki", name: "基里巴斯", enName: "Kiribati", phonePrefix: "+686" },
  { code: "xk", name: "科索沃", enName: "Kosovo", phonePrefix: "+383" },
  {
    code: "kw",
    name: "科威特 (الكويت‎) ",
    enName: "Kuwait (‫الكويت‬‎)",
    phonePrefix: "+965",
  },
  {
    code: "kg",
    name: "吉尔吉斯斯坦 (Кыргызстан) ",
    enName: "Kyrgyzstan (Кыргызстан)",
    phonePrefix: "+996",
  },
  { code: "la", name: "老挝 (ລາວ) ", enName: "Laos (ລາວ)", phonePrefix: "+856" },
  {
    code: "lv",
    name: "拉脱维亚 (Latvija) ",
    enName: "Latvia (Latvija)",
    phonePrefix: "+371",
  },
  {
    code: "lb",
    name: "黎巴嫩 (لبنان‎) ",
    enName: "Lebanon (‫لبنان‬‎)",
    phonePrefix: "+961",
  },
  { code: "ls", name: "莱索托", enName: "Lesotho", phonePrefix: "+266" },
  { code: "lr", name: "利比里亚", enName: "Liberia", phonePrefix: "+231" },
  {
    code: "ly",
    name: "利比亚 (ليبيا‎) ",
    enName: "Libya (‫ليبيا‬‎)",
    phonePrefix: "+218",
  },
  { code: "li", name: "列支敦士登", enName: "Liechtenstein", phonePrefix: "+423" },
  {
    code: "lt",
    name: "立陶宛 (Lietuva) ",
    enName: "Lithuania (Lietuva)",
    phonePrefix: "+370",
  },
  { code: "lu", name: "卢森堡", enName: "Luxembourg", phonePrefix: "+352" },
  { code: "mo", name: "澳门 (澳门) ", enName: "Macau (澳門)", phonePrefix: "+853" },
  {
    code: "mk",
    name: "马其顿 (FYROM) (Македонија) ",
    enName: "Macedonia (FYROM) (Македонија)",
    phonePrefix: "+389",
  },
  {
    code: "mg",
    name: "马达加斯加 (Madagasikara) ",
    enName: "Madagascar (Madagasikara)",
    phonePrefix: "+261",
  },
  { code: "mw", name: "马拉维", enName: "Malawi", phonePrefix: "+265" },
  { code: "my", name: "马来西亚", enName: "Malaysia", phonePrefix: "+60" },
  { code: "mv", name: "马尔代夫", enName: "Maldives", phonePrefix: "+960" },
  { code: "ml", name: "马里", enName: "Mali", phonePrefix: "+223" },
  { code: "mt", name: "马耳他", enName: "Malta", phonePrefix: "+356" },
  { code: "mh", name: "马绍尔群岛", enName: "Marshall Islands", phonePrefix: "+692" },
  { code: "mq", name: "马提尼克", enName: "Martinique", phonePrefix: "+596" },
  {
    code: "mr",
    name: "毛里塔尼亚 (موريتانيا‎) ",
    enName: "Mauritania (‫موريتانيا‬‎)",
    phonePrefix: "+222",
  },
  {
    code: "mu",
    name: "毛里求斯（莫里斯）",
    enName: "Mauritius (Moris)",
    phonePrefix: "+230",
  },
  { code: "yt", name: "马约特岛", enName: "Mayotte", phonePrefix: "+262" },
  {
    code: "mx",
    name: "墨西哥（墨西哥）",
    enName: "Mexico (México)",
    phonePrefix: "+52",
  },
  { code: "fm", name: "密克罗尼西亚", enName: "Micronesia", phonePrefix: "+691" },
  {
    code: "md",
    name: "摩尔多瓦（摩尔多瓦共和国）",
    enName: "Moldova (Republica Moldova)",
    phonePrefix: "+373",
  },
  { code: "mc", name: "摩纳哥", enName: "Monaco", phonePrefix: "+377" },
  {
    code: "mn",
    name: "蒙古 (Монгол) ",
    enName: "Mongolia (Монгол)",
    phonePrefix: "+976",
  },
  {
    code: "me",
    name: "黑山 (Crna Gora) ",
    enName: "Montenegro (Crna Gora)",
    phonePrefix: "+382",
  },
  { code: "ms", name: "蒙特塞拉特", enName: "Montserrat", phonePrefix: "+1664" },
  {
    code: "ma",
    name: "摩洛哥 (المغرب‎) ",
    enName: "Morocco (‫المغرب‬‎)",
    phonePrefix: "+212",
  },
  {
    code: "mz",
    name: "莫桑比克（莫桑比克）",
    enName: "Mozambique (Moçambique)",
    phonePrefix: "+258",
  },
  {
    code: "mm",
    name: "缅甸（缅甸）（မြန်မာ）",
    enName: "Myanmar (Burma) (မြန်မာ)",
    phonePrefix: "+95",
  },
  {
    code: "na",
    name: "纳米比亚 (Namibië) ",
    enName: "Namibia (Namibië)",
    phonePrefix: "+264",
  },
  { code: "nr", name: "瑙鲁", enName: "Nauru", phonePrefix: "+674" },
  {
    code: "np",
    name: "尼泊尔 (नेपाल) ",
    enName: "Nepal (नेपाल)",
    phonePrefix: "+977",
  },
  {
    code: "nl",
    name: "荷兰（荷兰）",
    enName: "Netherlands (Nederland)",
    phonePrefix: "+31",
  },
  {
    code: "nc",
    name: "新喀里多尼亚 (Nouvelle-Calédonie) ",
    enName: "New Caledonia (Nouvelle-Calédonie)",
    phonePrefix: "+687",
  },
  { code: "nz", name: "新西兰", enName: "New Zealand", phonePrefix: "+64" },
  { code: "ni", name: "尼加拉瓜", enName: "Nicaragua", phonePrefix: "+505" },
  {
    code: "ne",
    name: "尼日尔 (Nijar) ",
    enName: "Niger (Nijar)",
    phonePrefix: "+227",
  },
  { code: "ng", name: "尼日利亚", enName: "Nigeria", phonePrefix: "+234" },
  { code: "nu", name: "纽埃", enName: "Niue", phonePrefix: "+683" },
  { code: "nf", name: "诺福克岛", enName: "Norfolk Island", phonePrefix: "+672" },
  {
    code: "kp",
    name: "朝鲜 (조선 민주주의 인민 공화국) ",
    enName: "North Korea (조선 민주주의 인민 공화국)",
    phonePrefix: "+850",
  },
  {
    code: "mp",
    name: "北马里亚纳群岛",
    enName: "Northern Mariana Islands",
    phonePrefix: "+1670",
  },
  { code: "no", name: "挪威 (Norge) ", enName: "Norway (Norge)", phonePrefix: "+47" },
  {
    code: "om",
    name: "阿曼 (عُمان‎) ",
    enName: "Oman (‫عُمان‬‎)",
    phonePrefix: "+968",
  },
  {
    code: "pk",
    name: "巴基斯坦 (پاکستان‎) ",
    enName: "Pakistan (‫پاکستان‬‎)",
    phonePrefix: "+92",
  },
  { code: "pw", name: "帕劳", enName: "Palau", phonePrefix: "+680" },
  {
    code: "ps",
    name: "巴勒斯坦 (فلسطين‎) ",
    enName: "Palestine (‫فلسطين‬‎)",
    phonePrefix: "+970",
  },
  {
    code: "pa",
    name: "巴拿马 (Panamá) ",
    enName: "Panama (Panamá)",
    phonePrefix: "+507",
  },
  {
    code: "pg",
    name: "巴布亚新几内亚",
    enName: "Papua New Guinea",
    phonePrefix: "+675",
  },
  { code: "py", name: "巴拉圭", enName: "Paraguay", phonePrefix: "+595" },
  { code: "pe", name: "秘鲁（秘鲁）", enName: "Peru (Perú)", phonePrefix: "+51" },
  { code: "ph", name: "菲律宾", enName: "Philippines", phonePrefix: "+63" },
  {
    code: "pl",
    name: "波兰（Polska）",
    enName: "Poland (Polska)",
    phonePrefix: "+48",
  },
  { code: "pt", name: "葡萄牙", enName: "Portugal", phonePrefix: "+351" },
  { code: "pr", name: "波多黎各", enName: "Puerto Rico", phonePrefix: "+1" },
  {
    code: "qa",
    name: "卡塔尔 (قطر) ",
    enName: "Qatar (‫قطر‬‎)",
    phonePrefix: "+974",
  },
  {
    code: "re",
    name: "留尼汪 (La Réunion) ",
    enName: "Réunion (La Réunion)",
    phonePrefix: "+262",
  },
  {
    code: "ro",
    name: "罗马尼亚 (România) ",
    enName: "Romania (România)",
    phonePrefix: "+40",
  },
  {
    code: "ru",
    name: "俄罗斯 (Россия) ",
    enName: "Russia (Россия)",
    phonePrefix: "+7",
  },
  { code: "rw", name: "卢旺达", enName: "Rwanda", phonePrefix: "+250" },
  {
    code: "bl",
    name: "圣巴泰勒米 (Saint-Barthélemy) ",
    enName: "Saint Barthélemy (Saint-Barthélemy)",
    phonePrefix: "+590",
  },
  { code: "sh", name: "圣赫勒拿", enName: "Saint Helena", phonePrefix: "+290" },
  {
    code: "kn",
    name: "圣基茨和尼维斯",
    enName: "Saint Kitts and Nevis",
    phonePrefix: "+1869",
  },
  { code: "lc", name: "圣卢西亚", enName: "Saint Lucia", phonePrefix: "+1758" },
  {
    code: "mf",
    name: "圣马丁 (Saint-Martin (partie française)) ",
    enName: "Saint Martin (Saint-Martin (partie française))",
    phonePrefix: "+590",
  },
  {
    code: "pm",
    name: "圣皮埃尔和密克隆 (Saint-Pierre-et-Miquelon) ",
    enName: "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
    phonePrefix: "+508",
  },
  {
    code: "vc",
    name: "圣文森特和格林纳丁斯",
    enName: "Saint Vincent and the Grenadines",
    phonePrefix: "+1784",
  },
  { code: "ws", name: "萨摩亚", enName: "Samoa", phonePrefix: "+685" },
  { code: "sm", name: "圣马力诺", enName: "San Marino", phonePrefix: "+378" },
  {
    code: "st",
    name: "圣多美和普林西比 (São Tomé e Príncipe) ",
    enName: "São Tomé and Príncipe (São Tomé e Príncipe)",
    phonePrefix: "+239",
  },
  {
    code: "sa",
    name: "沙特阿拉伯 (المملكة العربية السعودية‎) ",
    enName: "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
    phonePrefix: "+966",
  },
  {
    code: "sn",
    name: "塞内加尔 (Sénégal) ",
    enName: "Senegal (Sénégal)",
    phonePrefix: "+221",
  },
  {
    code: "rs",
    name: "塞尔维亚 (Србија) ",
    enName: "Serbia (Србија)",
    phonePrefix: "+381",
  },
  { code: "sc", name: "塞舌尔", enName: "Seychelles", phonePrefix: "+248" },
  { code: "sl", name: "塞拉利昂", enName: "Sierra Leone", phonePrefix: "+232" },
  { code: "sg", name: "新加坡", enName: "Singapore", phonePrefix: "+65" },
  { code: "sx", name: "圣马丁", enName: "Sint Maarten", phonePrefix: "+1721" },
  {
    code: "sk",
    name: "斯洛伐克 (Slovensko) ",
    enName: "Slovakia (Slovensko)",
    phonePrefix: "+421",
  },
  {
    code: "si",
    name: "斯洛文尼亚 (Slovenija) ",
    enName: "Slovenia (Slovenija)",
    phonePrefix: "+386",
  },
  { code: "sb", name: "所罗门群岛", enName: "Solomon Islands", phonePrefix: "+677" },
  {
    code: "so",
    name: "索马里（Soomaaliya）",
    enName: "Somalia (Soomaaliya)",
    phonePrefix: "+252",
  },
  { code: "za", name: "南非", enName: "South Africa", phonePrefix: "+27" },
  {
    code: "kr",
    name: "韩国 (대한민국) ",
    enName: "South Korea (대한민국)",
    phonePrefix: "+82",
  },
  {
    code: "ss",
    name: "南苏丹 (جنوب السودان‎) ",
    enName: "South Sudan (‫جنوب السودان‬‎)",
    phonePrefix: "+211",
  },
  {
    code: "es",
    name: "西班牙（西班牙）",
    enName: "Spain (España)",
    phonePrefix: "+34",
  },
  {
    code: "lk",
    name: "斯里兰卡 (ශ්‍රී ලංකාව) ",
    enName: "Sri Lanka (ශ්‍රී ලංකාව)",
    phonePrefix: "+94",
  },
  {
    code: "sd",
    name: "苏丹 (السودان‎) ",
    enName: "Sudan (‫السودان‬‎)",
    phonePrefix: "+249",
  },
  { code: "sr", name: "苏里南", enName: "Suriname", phonePrefix: "+597" },
  {
    code: "sj",
    name: "斯瓦尔巴和扬马延",
    enName: "Svalbard and Jan Mayen",
    phonePrefix: "+47",
  },
  { code: "sz", name: "斯威士兰", enName: "Swaziland", phonePrefix: "+268" },
  {
    code: "se",
    name: "瑞典（Sverige）",
    enName: "Sweden (Sverige)",
    phonePrefix: "+46",
  },
  {
    code: "ch",
    name: "瑞士（瑞士）",
    enName: "Switzerland (Schweiz)",
    phonePrefix: "+41",
  },
  {
    code: "sy",
    name: "叙利亚 (سوريا‎) ",
    enName: "Syria (‫سوريا‬‎)",
    phonePrefix: "+963",
  },
  { code: "tw", name: "台湾 (台湾) ", enName: "Taiwan (台灣)", phonePrefix: "+886" },
  { code: "tj", name: "塔吉克斯坦", enName: "Tajikistan", phonePrefix: "+992" },
  { code: "tz", name: "坦桑尼亚", enName: "Tanzania", phonePrefix: "+255" },
  { code: "th", name: "泰国 (ไทย) ", enName: "Thailand (ไทย)", phonePrefix: "+66" },
  { code: "tl", name: "东帝汶", enName: "Timor-Leste", phonePrefix: "+670" },
  { code: "tg", name: "多哥", enName: "Togo", phonePrefix: "+228" },
  { code: "tk", name: "托克劳", enName: "Tokelau", phonePrefix: "+690" },
  { code: "to", name: "汤加", enName: "Tonga", phonePrefix: "+676" },
  {
    code: "tt",
    name: "特立尼达和多巴哥",
    enName: "Trinidad and Tobago",
    phonePrefix: "+1868",
  },
  {
    code: "tn",
    name: "突尼斯 (تونس‎) ",
    enName: "Tunisia (‫تونس‬‎)",
    phonePrefix: "+216",
  },
  {
    code: "tr",
    name: "土耳其 (Türkiye) ",
    enName: "Turkey (Türkiye)",
    phonePrefix: "+90",
  },
  { code: "tm", name: "土库曼斯坦", enName: "Turkmenistan", phonePrefix: "+993" },
  {
    code: "tc",
    name: "特克斯和凯科斯群岛",
    enName: "Turks and Caicos Islands",
    phonePrefix: "+1649",
  },
  { code: "tv", name: "图瓦卢", enName: "Tuvalu", phonePrefix: "+688" },
  {
    code: "vi",
    name: "美属维尔京群岛",
    enName: "U.S. Virgin Islands",
    phonePrefix: "+1340",
  },
  { code: "ug", name: "乌干达", enName: "Uganda", phonePrefix: "+256" },
  {
    code: "ua",
    name: "乌克兰 (Україна) ",
    enName: "Ukraine (Україна)",
    phonePrefix: "+380",
  },
  {
    code: "ae",
    name: "阿拉伯联合酋长国 (الإمارات العربية المتحدة‎) ",
    enName: "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
    phonePrefix: "+971",
  },
  { code: "uy", name: "乌拉圭", enName: "Uruguay", phonePrefix: "+598" },
  {
    code: "uz",
    name: "乌兹别克斯坦 (Oʻzbekiston) ",
    enName: "Uzbekistan (Oʻzbekiston)",
    phonePrefix: "+998",
  },
  { code: "vu", name: "瓦努阿图", enName: "Vanuatu", phonePrefix: "+678" },
  {
    code: "va",
    name: "梵蒂冈城 (Città del Vaticano) ",
    enName: "Vatican City (Città del Vaticano)",
    phonePrefix: "+39",
  },
  { code: "ve", name: "委内瑞拉", enName: "Venezuela", phonePrefix: "+58" },
  {
    code: "vn",
    name: "越南 (Việt Nam) ",
    enName: "Vietnam (Việt Nam)",
    phonePrefix: "+84",
  },
  {
    code: "wf",
    name: "瓦利斯和富图纳",
    enName: "Wallis and Futuna",
    phonePrefix: "+681",
  },
  {
    code: "eh",
    name: "西撒哈拉 (الصحراء الغربية‎) ",
    enName: "Western Sahara (‫الصحراء الغربية‬‎)",
    phonePrefix: "+212",
  },
  {
    code: "ye",
    name: "也门 (اليمن‎) ",
    enName: "Yemen (‫اليمن‬‎)",
    phonePrefix: "+967",
  },
  { code: "zm", name: "赞比亚", enName: "Zambia", phonePrefix: "+260" },
  { code: "zw", name: "津巴布韦", enName: "Zimbabwe", phonePrefix: "+263" },
  { code: "ax", name: "奥兰群岛", enName: "Åland Islands", phonePrefix: "+358" },
];

export function parseCountryPhonePrefix(code) {
  return countryData.find(v => v.code === code)?.phonePrefix
}

export default countryData;
