import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { RootState } from ".";
import {
  defaultVPTGroupList,
  DeviceConfigurationInfo,
  PlcPointStatusModelJsonValue,
  VPTDriverDireaction,
  VPTDriversConfigDescription,
  VPTSouthChannelConfig,
  PlcChannelPointStatusModel,
} from "../models/configuration.model";
import { deepClone } from "../utils";
import { emptyMockConfiguration, emptyMockVPTDrivers, emptyMockPlcPointStatus } from "./mock_configuration";

export const configurationSlice = createSlice({
  name: "configuration",
  initialState: { configuration: cloneDeep(emptyMockConfiguration), driverDesc: cloneDeep(emptyMockVPTDrivers), pointStatus: cloneDeep(emptyMockPlcPointStatus), },
  reducers: {
    initConfiguration(state, data: PayloadAction<DeviceConfigurationInfo>) {
      state.configuration = data.payload ?? cloneDeep(emptyMockConfiguration);
    },
    initPlcPoints(state, data: PayloadAction<Record<string, PlcPointStatusModelJsonValue>>) {
      const pointStatus: Record<string, PlcChannelPointStatusModel> = {};
      if (data.payload) {
        const names = Object.getOwnPropertyNames(data.payload);
        for (const name of names) {
          const [channelName, deviceName] = name.split('/');
          if (pointStatus[channelName] == null) {
            pointStatus[channelName] = {};
          }
          const channel = pointStatus[channelName];
          channel[deviceName] = data.payload[name].result;
        }
      }
      state.pointStatus = pointStatus;
    },
    initDriverDesc(state, data: PayloadAction<VPTDriversConfigDescription>) {
      state.driverDesc = data.payload;
    },
    addChannel(state, data: PayloadAction<VPTSouthChannelConfig>) {
      state.configuration.protocol.function_blocks[data.payload.channel_name] = data.payload;
    },
    editChannel(state, data: PayloadAction<{ oldChannelName: string; values: VPTSouthChannelConfig }>) {
      if (data.payload.oldChannelName !== data.payload.values.channel_name) {
        // 如果更新了channel name,删除旧的channel
        delete state.configuration.protocol.function_blocks[data.payload.oldChannelName];
      }
      state.configuration.protocol.function_blocks[data.payload.values.channel_name] = data.payload.values;
    },
    editChannelInnerContent(state, data: PayloadAction<VPTSouthChannelConfig>) {
      state.configuration.protocol.function_blocks[data.payload.channel_name] = data.payload;
    },
    deleteChannelAction(state, data: PayloadAction<string>) {
      try {
        const newConfig = deepClone(state.configuration.protocol);
        delete newConfig.function_blocks[data.payload];
        state.configuration.protocol = newConfig;
      } catch (error) {}
    },
  },
});

export const { initConfiguration, initPlcPoints, initDriverDesc, addChannel, editChannel, editChannelInnerContent, deleteChannelAction } = configurationSlice.actions;

export const selectProtocolConfig = (state: RootState) => {
  return state.configuration?.configuration?.protocol;
};

export const selectPointStatus = (state: RootState) => {
  return state.configuration?.pointStatus;
};

export const selectVPTDriverConfig = (state: RootState, driverType?: string) => {
  if (driverType) {
    try {
      return state.configuration.driverDesc[driverType];
    } catch (error) {
      return null;
    }
  }
  return state.configuration.driverDesc;
};

export const vptSelectors = {
  // PLC配置数据
  PLCConfigData: (state: RootState) => {
    return state.configuration;
  },
  // 配置
  supportedChannels: (state: RootState) => {
    return Object.values(state.configuration.driverDesc || {})
      .filter((v) => v.attribute === VPTDriverDireaction.south)
      .map((v) => {
        return { type: v.driver_name, title: v.title, detail: v };
      });
  },
  //   已有配置
  existedChannels(state: RootState) {
    const channels: { name: string, channel_name: string; channel: VPTSouthChannelConfig }[] = [];
    try {
      Object.values(state.configuration.configuration.protocol.function_blocks).forEach((v) => {
        if (v.driver_direction === VPTDriverDireaction.south) {
          channels.push({ channel_name: v.channel_name, name: v.channel_name,  channel: v });
        }
      });
    } catch (error) {}
    return channels;
  },
  selectVariableGroups(state: RootState) {
    const groups = state.configuration.configuration.protocol.output_groups;
    return defaultVPTGroupList.concat(
      Object.keys(groups).map((groupName) => {
        return { title: { zh: groupName, en: groupName }, value: groupName };
      })
    );
  },
  // 给采集用，北向或者边缘计算的outputs
  selectNorthOutputs(state: RootState) {
    const results = [];
    Object.values(state.configuration.configuration.protocol.function_blocks).forEach((v) => {
      if (!(!(v.driver_direction === "north" || v.driver === "compute_c") || !v?.driver_settings?.outputs)) {
        for (const [deviceName, detail] of Object.entries(v.driver_settings.outputs)) {
          results.push({
            channel_name: v.channel_name,
            deviceName,
            enable: detail.enabled ?? false,
            driver: v.driver,
          });
        }
      }
    });
    return results;
  },
};

const configurationReducer = configurationSlice.reducer;

export default configurationReducer;
