import { BaseData, BaseNameData, MatRadioOption, SortOrder } from "./base.model";
// 系统几种不同的角色
export enum Role {
  PLANTFORM_ADMIN,
  TENANT_ADMIN,
  USER,
  SUPER_ADMIN,
}
export const ALL_ROLE = [Role.USER, Role.PLANTFORM_ADMIN, Role.TENANT_ADMIN, Role.SUPER_ADMIN];
export const ADMIN_ROLE = [Role.PLANTFORM_ADMIN, Role.TENANT_ADMIN, Role.SUPER_ADMIN];
export enum LicenseStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface BaseTenantInfo extends BaseData {
  name: string;
  email: string;
}

export interface TenantListInfo extends BaseTenantInfo {
  activate: number;
  status: number;
  deviceSum: number;
  maxDevices: number;
  createdTime: string;
  licenseExpireTime?: number;
}
// ----- //
export enum UserRole {
  Admin,
  User,
}
export const pwdPolicyLabelMap = new Map<string, string>([
  ["useSystem", "security.useSystem"],
  ["customizeAgeing", "security.customizeAgeing"],
]);
export const userPasswordPolicyOptions: MatRadioOption<boolean>[] = [
  { label: pwdPolicyLabelMap.get("useSystem"), value: true },
  { label: pwdPolicyLabelMap.get("customizeAgeing"), value: false },
];
export interface GetUsersParams {
  pageOffset: number;
  pageSize: number;
  searchValue?: string;
  sortBy?: string;
  direction?: SortOrder;
}
export interface CreateUserParams {
  email: string;
  name: string;
  surname: string;
  status: 0 | 1;
  phone: string;
  password: string;
  forcePasswordChange: boolean;
  useSystemSettings: boolean;
  passwordAgeing: number;
  passwordConfirm?: string;
  description?: string;
  additionalInfo?: string; // 存储用户元数据的JSON字符串
  permissionIds: string[]; // 添加用户表单中的权限字段
  userGroupIds: string[];
  deviceIds: string[];
  deviceGroupIds: string[];
}
export class CreateUserParamsFactory implements CreateUserParams {
  constructor(
    public email = "",
    public status = 1 as const,
    public name = "",
    public surname = "",
    public phone = "",
    public forcePasswordChange = true,
    public useSystemSettings = true,
    public passwordAgeing = 30,
    public password = "",
    public passwordConfirm = "",
    public description = "",
    public permissionIds = [],
    public userIds = [],
    public userGroupIds = [],
    public deviceIds = [],
    public deviceGroupIds = []
  ) {}
}

export enum EMenuNodeLevel {
  FIRST = 1,
  SECOND = 2,
  BUTTON = 10,
}
export interface IPermissionMenu {
  id: string;
  pid?: string;
  path?: string; // udmp后端定义的path，前端暂时一般不作使用
  href?: string;
  icon?: string;
  name: string;
  type: EMenuNodeLevel;
  sort: number;
  child: IPermissionMenu[];
}
export interface UserInfo extends BaseData, CreateUserParams {
  type: Role; // 角色（用于前端禁止对管理员角色进行删除、禁用等操作） 等价于role，由于后端原因，改名为type
  id: string;
  lastLoginTime: number;
  lastLoginIp: string;
  locked: boolean; // 是否锁屏
  menus: IPermissionMenu[]; // 列表展示的权限字段
  userGroupInfos: (BaseData & BaseNameData)[];
  lockTimeout: number;
  sessionLabel: number;
  customerId: string;
}

export enum UserStatus {
  DISABLED,
  ENABLED,
}

export interface IUserGroup extends BaseData {
  name: string;
  status: UserStatus;
  menuInfos: IPermissionMenu[]; // 权限
  userInfos: (BaseData & BaseNameData)[];
  deviceInfos: (BaseData & BaseNameData)[];
  deviceGroupInfos: (BaseData & BaseNameData)[];
  // 权限概览展开收起标志
  expanded?: boolean;
}

export interface ISelectDeviceItem extends BaseData, BaseNameData {
  sn: string;
  tenantId: string;
}
export interface ISelectDeviceTreeResItem {
  type: string;
  deviceInfos: ISelectDeviceItem[];
}
export interface IAddUserGroup {
  id?: string;
  name: string;
  status: 0 | 1;
  permissionIds?: string[]; // 权限
  deviceIds?: string[];
  deviceGroupIds?: string[];
  userIds?: string[];
  description?: string;
}
export interface IUserInfoContact extends BaseData, BaseNameData {
  phone: string;
  email: string;
  surname: string;
}
export const mockUsers = Array.from({ length: 13 }).map((v, i) => ({
  id: "id" + i,
  email: "email" + i + "@vantron.com",
  type: i >= 2 ? Role.PLANTFORM_ADMIN : Role.USER,
  status: 1,
  name: "name" + i,
  surname: "surname" + i,
  avatar: "avatar" + i,
  lastLoginTime: 165656565656,
  lastLoginIp: "lastLoginIp" + i,
  forcePasswordChange: 1,
  passwordAgeing: true,
  password: "password" + i,
  firstName: "firstName" + i,
  lastName: "lastName" + i,
  phone: "18512891630" + i,
  description: "this is description of user " + i,
}));
export interface AdminInfo extends UserInfo {}
// 租户信息
export interface TenantInfo extends BaseData {
  activate: number;
  status: number;
  name: string;
  logo: string;
  address: string;
  code: string;
  phone: number | string;
  country: string;
  zip: string;
  city: string;
  maxDevices: number;
  deviceNum: number;
  [propName: string]: any;
}
export interface PasswordRuleConfig {
  associated: boolean;
  dictionary: boolean;
  minimumLengthRequired: boolean;
  minimumLength: number;
  maximumLengthRequired: boolean;
  maximumLength: number;
  lowercaseLettersRequired: boolean;
  lowercaseLetters?: number;
  uppercaseLettersRequired: boolean;
  uppercaseLetters?: number;
  digitsRequired: boolean;
  digits?: number;
  specialCharactersRequired: boolean;
  specialCharacters?: number;
  repeatingCharactersRequired: boolean;
  repeatingCharacters?: number;
  consecutiveCharactersRequired: boolean;
  consecutiveCharacters?: number;
  expiration: number;
  expirationWarning: number;
  accountLockout: boolean;
  failedAttempts: number;
  isReset: boolean;
  reset: number;
  isLocked: boolean;
  locked: number;
  accountTimeout: boolean;
  lockTimeout: number;
}
export class PassswordRuleFactory implements PasswordRuleConfig {
  constructor(
    public associated: boolean = true,
    public dictionary: boolean = true,
    public minimumLengthRequired: boolean = true,
    public minimumLength: number = 8,
    public maximumLengthRequired: boolean = true,
    public maximumLength: number = 32,
    public lowercaseLettersRequired: boolean = true,
    public lowercaseLetters: number = 1,
    public uppercaseLettersRequired: boolean = true,
    public uppercaseLetters: number = 1,
    public digitsRequired: boolean = true,
    public digits: number = 1,
    public specialCharactersRequired: boolean = true,
    public specialCharacters: number = 1,
    public repeatingCharactersRequired: boolean = true,
    public repeatingCharacters: number = 1,
    public consecutiveCharactersRequired: boolean = true,
    public consecutiveCharacters: number = 1,
    public expiration: number = 90,
    public expirationWarning: number = 7,
    public accountLockout: boolean = true,
    public failedAttempts: number = 3,
    public isReset: boolean = true,
    public reset: number = 3,
    public isLocked: boolean = true,
    public locked: number = 3,
    public accountTimeout: boolean = true,
    public lockTimeout: number = 5
  ) {}
}

export interface UpdateUserParams extends BaseData {
  email: string;
  additionalInfo?: string;
}

// 用户设置中心修改信息
export interface UpdateUserBasicInfoParams extends UpdateUserParams {
  name?: string;
  surname?: string;
  phone?: string; // phone number
  description?: string;
  status?: number;
  forcePasswordChange?: boolean;
  passwordAgeing?: number; // duration
}

export interface PasswordPolicyModel {
  associated: boolean;
  dictionary: boolean;
  maximumLength: number;
  minimumLength: number;
  lowercaseLetters: number;
  uppercaseLetters: number;
  digits: number;
  specialCharacters: number;
  repeatingCharacters: number;
  consecutiveCharacters: number;
  duration: {
    expiration: number;
    expirationWarning: number;
  };
  lockout: {
    failedAttempts: number;
    reset: number;
    locked: number;
  };
  lockTimeout: number;
}

export interface PasswordPolicyDto {
  id: string;
  tenantId: string;
  config: PasswordPolicyModel;
}
export interface IValidatePwdByPolicyParams {
  email: string;
  name: string;
  surname: string;
  password: string;
}
export interface IGWMVersionRes {
  gwm: string;
}
