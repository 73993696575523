import { LocaleFile } from "../..";

const alarm: LocaleFile = {
  clear: "清除",
  allAlarmTitle: "所有告警 ({{count}})",
  actions: {
    SYNC: "同步",
    ROUTE: "转发",
    EXPORT: "导出",
    ACK: "确认",
    ACTION: "操作",
    UNACK: "取消确认",
    CLEAR: "清除",
    UNCLEAR: "取消清除",
    FULL_SCREEN: "全屏",
    PRINT: "打印",
    SETTINGS: "设置",
    VIEW_DETAIL: "查看详情",
    SOUNDS: "告警声音",
  },
  customColor: "自定义告警颜色",
  selectedColor: "选中的颜色",
  soundsFile: "声音文件",
  importSoundFile: "导入声音文件",
  settingsTitle: "设置",
  alarmSound: "告警声音",
  filters: "过滤",
  routeTitle: "告警转发",
  routeChannel: "转发通道",
  routeChannels: {
    mails: "电子邮件",
    sms: "短信",
  },
  backToOverview: "告警列表",
  attribute: "属性",
  basicInfo: "基本信息",
  eventTime: "时间",
  deviceGroup: "设备组",
  moreFilters: "添加更多过滤器",
  alarmDescription: "告警名称",
  specificProblem: "告警描述",
  deviceName: "设备",
  deviceType: "设备类型",
  severity: "告警等级",
  ipAddress: "IP地址",
  alarmObject: "告警对象",
  alarmIdHolder: "输入告警ID",
  alarmId: "告警ID",
  alarmType: "告警类型",
  alarmTime: "告警时间",
  devices: "设备",
  ceaseTime: "停止时间",
  alarmLevels: {
    CRITICAL: "严重",
    MAJOR: "主要",
    MINOR: "次要",
    WARNING: "警告",
    CLEARED: "已清除",
  },
  alarmScroll: "告警滚动",
  searchAlarmTip: "告警名称,告警ID...",
  filtersTitle: "过滤",
  alarmRouterRules: "告警转发规则",
  alarmRuleSetting: "告警规则设置",
  rulesManagement: "告警规则管理",
  createAlarmRules: "创建告警规则",
  editAlarmRules: "修改告警规则",
  ruleName: "规则名称",
  description: "描述",
  alarmRule: "告警规则",
  routeRule: "转发规则",
  mails: "邮箱",
  sms: "短信",
  manual: "手动",
  confirmed: "已确认",
  unconfirmed: "未确认",
  cleared: "已清除",
  resetColors: "重置颜色",
  clearAlarms: "清除告警",
  clearAlarmsContent: "如果已确认报警，执行清除操作将从报警概览中删除报警。这些报警将仅在报警历史记录中可见。你确定要继续吗？",
  alarmTypes: {
    communicationAlarm: "通信告警",
    equipmentAlarm: "设备告警",
    processingFailure: "处理错误告警",
    environmentalAlarm: "环境告警",
    qualityOfServiceAlarm: "服务质量告警",
  },
  alarmRulePlaceholder: "选择告警规则",
  addAlarmRule: "新增告警规则",
  everyday: "每天",
  everyweek: "每周",
  byTime: "按时间",
  monday: "周一",
  tuesday: "周二",
  wednesday: "周三",
  thursday: "周四",
  friday: "周五",
  saturday: "周六",
  sunday: "周天",
  rules: "规则",
  deleteAlarmRuleTitle: "删除告警转发规则",
  deleteAlarmRuleContent: "该操作不能撤销，确定要删除当前选中的告警转发规则吗？",
  clearAlarmErrorContent: "存在未确认的告警，请确认后重试！",
  multipleAlarmOperationError: "请选择告警后执行此操作！",
  timeRangeError: "时间范围错误",
  exportAllAlarms: "导出全部告警",
  exportSelectedAlarms: "导出已选告警",
  routingForm: {
    ruleInfo: "规则信息",
    alarmRulesSetting: "告警规则设置",
    routeRule: "转发规则",
    ruleName: "规则名称",
    description: "描述",
    alarmId: "告警ID ",
    alarmCode: "告警代码",
    devices: "设备",
    alarmTime: "告警时间",
    mails: "邮箱",
    email: "邮箱",
    sms: "电话号码",
    alarmNamePlaceholder: "请输入规则名称",
    alarmIdPlaceholder: "选择告警代码",
    alarmRulePlaceholder: "选择告警规则",
    alarmIdequired: "请选择告警代码",
    alarmRuleRequired: "请选择告警规则",
    alarmRuleEmptyRequired: "请填写告警规则",
    alarmDevicesRequired: "请选择设备",
    alarmEmailsRequired: "请选择邮箱",
    addAlarmRule: "新增告警规则",
    clearAll: "清除所有",
    byTime: "按时间",
    userName: "用户名",
    createRuleSuccuessed: "创建规则成功",
    editRuleSuccuessed: "修改规则成功",
    deviceOffline: "设备不在线",
    geofenceViolation: "超出地理围栏",
    licenseIsAboutToExpire: "授权即将过期",
    licenseExpired: "授权已过期",
    deviceOfflineWithIndex: "告警代码 01: 设备不在线",
    geofenceViolationWithIndex: "告警代码 02: 超出地理围栏",
    licenseIsAboutToExpireWithIndex: "告警代码 03: 授权即将过期",
    licenseExpiredWithIndex: "告警代码 04: 授权已过期",
    ruleNameBeUsed: "规则名称已经存在",
    ruleNameRequired: "规则名称必填",
  },
  alarmReporting: "上报告警",
  reportSuccuessed: "上报成功",
  reportFailed: "上报失败",
  maxAlarmStatusLegends: "最多选择{{count}}项",
  limitSavedSearchTip: "最多可以保存10个结果。添加之前请删除已保存的结果",
};

export default alarm;
