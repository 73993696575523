const user = {
  surname: "姓",
  sms: "手机号",
  description: "描述",
  changePwd: "更改密码",
  deleteUserTitle: "确定删除选中的这个用户吗？",
  deleteUserGroupTitle: "确定删除选中的这个用户组吗？",
  password: "密码",
  currentPassword: "当前密码",
  newPassword: "新密码",
  passwordConfirm: "确认密码",
  pwdMin8: "密码长度不能低于8位",
  pwdNotSame: "两次输入的密码不一致",
  pwdRuleSetting: "密码规则设置",
  pwdLabels: {
    lowercaseLettersRequired: "密码必须包含小写字母",
    lowercaseLetters: "小写字母长度 (1-32)",
    uppercaseLettersRequired: "密码必须包含大写字母",
    uppercaseLetters: "大写字母长度 (1-32)",
    digitsRequired: "密码必须包含数字",
    digits: "数字长度 (1-32)",
    specialCharactersRequired: "密码必须包含特殊字符",
    specialCharacters: "特殊字符长度 (1-32)",
    repeatingCharactersRequired: "限制重复字符的数量",
    repeatingCharacters: "最大重复字符数 (1-32)",
    consecutiveCharactersRequired: "限制连续重复字符的数量",
    consecutiveCharacters: "最大连续重复字符数 (1-32)",
    associated: "新密码不得包含与其关联的用户名、姓名和姓氏",
    dictionary: "密码不能包含任何字典单词",
    minimumLengthRequired: "强制最小密码长度",
    maximumLengthRequired: "强制最大密码长度",
    minimumLength: "字符数 (8-32)",
    maximumLength: "字符数 (8-32)",
    expiration: "密码有效的天数",
    expirationTip: "设置密码有效的最大天数 (1-180)",
    expirationWarning: "密码过期警告的天数",
    expirationWarningTip: "设置密码过期前显示过期警告的天数 (0-14)",
    accountLockout: "帐户锁定",
    failedAttempts: "失败登录尝试的最大次数",
    failedAttemptsTip: "帐户被锁定前失败登录尝试的最大次数 (最多10次)",
    isReset: "失败尝试计数将在之后自动重置",
    resetTip: "失败尝试次数总是在下次成功登录后重置。可选地，它也可以在指定的时间段（从1到60分钟）后自动重置",
    isLocked: "帐户将暂时锁定",
    lockedTip: "管理员可以随时解除锁定帐户的锁定。只能临时锁定（1到60分钟）",
    accountTimeout: "超时时间",
    timeoutTip: "帐户将在设定的时间后自动锁屏。",
  },
  editPolicySuccessed: "修改成功,请重新登录",
  status: "账号启用状态",
  profile: "个人设置",
  logout: "退出登录",
  basicInfo: "基础信息",
  accountSecurity: "账户安全",
  basicOrgInfoErrors: {
    name: "请输入组织名称",
    phone: "请输入联系方式",
    city: "请输入城市",
    address: "请输入地址",
    invalidEmail: "请输入正确的电子邮箱",
    email: "请输入电子邮箱",
    zip: "请输入邮编",
  },
  loginTitle: "登录您的账户",
  rememberPwd: "记住密码",
  forgotPwd: "忘记密码",
  portalHolder: "您的门户",
  fillInfo: "填写信息",
  setPwd: "设置密码",
  registerTitle: "注册您的账户",
  signupErrors: {
    domain: "请填写门户信息",
    invalidEmail: "邮件地址不正确",
    email: "请填写电子邮件地址",
    firstName: "请填写名",
    lastName: "请填写姓",
    name: "请填写组织名称",
    password: "请填写密码",
    mobile: "请填写电话号码",
    passwordMoreThan6: "密码长度必须大于6",
    passwordConfirm: "请确认您的密码",
    passwordNotSame: "两次输入的密码不一致",
  },
  firstName: "名",
  lastName: "姓",
  loginTipText: "已有账户？",
  loginTipButton: "立即登录",
  registSuccess: "注册成功，请登录",
  registerPageDesc: "注册BlueSphere MDM以控制移动设备的配置、系统状态和任务关键型任务。",
  register: "注册",
  registerTipText: "还没有账户?",
  registerTipButton: "立即注册",
  orgInfoKeys: {
    name: "组织名称",
    phone: "联系方式",
    country: "国家",
    address: "组织地址",
    email: "电子邮箱",
    code: "组织短代码",
    city: "城市",
    zip: "邮编",
  },
  login: "登录",
  loginToUnlock: "登录以解锁",
  loginPageDesc: "",
  resetPassword: "重置密码",
  passwordNotSame: "两次输入的密码不一致",
  resetPwdSuccessedTip: "密码重置成功，请登录",
  inputAccountEmail: "请输入账号的电子邮箱地址",
  checkEmailNotExist: "该邮箱不存在于BlueSphere，请检查后重新输入",
  sendResetEmailSuccessed: "重置密码邮件已经发送，请查看您的电子邮箱",
  email: "邮箱",
  phoneNumber: "手机号",
  reset: "重置",
  createAdminErrors: {
    email: "请正确输入邮箱",
    password: "请输入密码",
    emailNotValid: "请输入正确的邮箱",
    passwordMin8: "密码最少8位数",
    passwordConfirm: "请确认密码",
  },
  lastLogin: "最近登录",
  loginSuccessed: "登录成功",
  loginFailed: "登录失败",
  lock: "锁定",
  unlockSuccessed: "解锁成功",
  unlockFailed: "密码错误",
  name: "名",
  noPermissionForThisPage: "您没有权限访问该页面",
  pwdRuleNumberMin0: "最小值为0",
  welcomeBack: "欢迎回来",
  switchAccount: "切换账号",
  passwordComplexity: "密码复杂性",
  accountLockout: "帐户锁定",
  accountTimeout: "帐户超时",
  lengthError: "长度不合法",
  language: "语言",
  about: "关于",
  productInfo: "产品信息",
  GWMVersion: "GWM版本",
  userGroupSearchPlaceholder: "搜索用户组",
  Feedback: "反馈",
  feedback: {
    security: "安全",
    interactive: "交互",
    functionality: "功能",
    dataRelated: "数据相关",
    uploadPictures: "上传图片",
    descriptionPlaceholder: "请输入您的反馈内容，至多{{data}}字符",
    uploadTo: "至多上传{{data}}张图片",
    sendSuccess: "反馈发送成功！",
    sendError: "反馈发送失败！",
    moreOptions: "更多选项",
    recentAlarms: "最近10条告警",
    operationLogs: "操作日志",
    recentDeviceReports: "最近的设备报告",
  },
  checkTenantPwdFalse: "用户名和密码不匹配, 请重试!",
  devices: "设备",
  groups: "设备组",
  passwordExpirationTitle: "密码过期",
  passwordExpirationContent: "密码有效期仅剩 {{days}}，请及时更改密码",
  changePasswordSuccess: "修改密码成功，请重新登录！",
};
export default user;
