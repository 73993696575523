const security = {
  userManage: "用户管理",
  userGroupManager: "用户组管理",
  addUser: "添加用户",
  modifyUser: "修改用户",
  addUserGroup: "添加用户组",
  modifyUserGroup: "修改用户组",
  deleteUser: "删除用户",
  deleteUserGroup: "删除用户组",
  user: "用户",
  userGroup: "用户组",
  passwordPolicy: "密码策略",
  menu: "目录",
  assigned: "已分配",
  name: "名称",
  surname: "姓",
  password: "密码",
  newPassword: "新密码",
  passwordConfirm: "确认密码",
  role: "角色",
  email: "邮箱",
  status: "状态",
  userStatus: "用户状态",
  permission: "权限",
  permissions: "权限",
  userGroups: "用户组",
  lastLogin: "最近登录",
  lastLoginIp: "最近登录IP",
  forcePasswordChange: "下次登录强制改密码",
  passwordDuration: "密码有效期",
  changePassword: "修改密码",
  device: "设备",
  devices: "设备",
  deviceGroups: "设备组",
  deviceGroup: "设备组",
  createdTime: "创建时间",
  sessionLabel: "会话状态",
  creator: "创建人",
  phoneNumber: "手机号",
  forever: "永远",
  userGroupName: "用户组名称",
  userInfo: "用户信息",
  groupInfo: "用户组信息",
  selectPermission: "选择权限",
  selectDevices: "选择设备",
  selectDeviceGroups: "选择设备组",
  selectUserGroups: "选择用户组",
  selectUser: "选择用户",
  online: "在线",
  offline: "离线",
  details: "详情",
  useSystem: "使用系统设置",
  customizeAgeing: "自定义密码代理",
  enablePwdAgeing: "启用密码代理",
  authentication: "权限",
  label: {
    pwdAgeingLabel: "密码有效天数",
    expirationWarningLabel: "密码过期警告天数",
    emailInvalid: "邮箱不合法",
  },
  auth: {
    monitoring: "监控",
    provisioning: "预配置",
    ota: "OTA", // 一级目录和二级目录同名
    system: "系统管理",
    // 二级目录
    // // 监控
    dashboard: "仪表盘",
    alarmOverview: "告警概览",
    alarmRouting: "告警转发",
    topology: "拓扑管理",
    mqttTracing: "实时消息追踪",
    deviceReport: "设备报告",
    PLCDataWidgets: "PLC数据部件",
    // // 预配置
    configuration: "配置管理",
    batchCommand: "命令群发",
    edgeComputing: "边缘计算",
    softwareManagement: "软件管理",
    deviceManagement: "设备管理",
    // // 安全管理
    security: "安全管理",
    addUser: "添加用户",
    passwordPolicy: "密码策略",
    userManagement: "用户管理",
    // // 系统管理
    log: "日志",
    helpCenter: "帮助中心",
    // 二级目录下的子路由或者非侧边栏的路由菜单
    alarmDetail: "告警详情",
    userGroupManagement: "用户组管理",
    addOrModifyUser: "添加/修改用户",
    addUserGroupPage: "添加/修改用户组",
    taskManagement: "任务管理",
    addTrackTask: "创建追踪任务",
    taskDetails: "任务详情",
    profile: "用户设置",
    notice: "通知中心",
    // 按钮权限级别的翻译（用于选择权限树的渲染）
    informationDisplay: "信息展示",
    alarm_setting: "告警设置",
    export_alarms: "导出告警",
    clear_alarms: "清除告警",
    ack_alarms: "确认告警",
    alarm_filter: "告警筛选",
    unack_alarms: "取消确认告警",
    route_alarms: "转发告警",
    alarm_route_module: "告警转发",
    add_alarm_route_rule: "添加告警规则",
    modify_alarm_route_rule: "修改告警规则",
    delete_alarm_route_rule: "删除告警规则",
    device_management_export_devices: "导出设备",
    device_management_modify_device: "修改设备",
    device_management_restart_device: "重启设备",
    device_management_delete_device: "删除设备",
    add_device_module: "创建设备",
    add_device_report_task: "创建任务",
    modify_device_report_task: "修改任务",
    delete_device_report_task: "删除任务",
    delete_device_report: "删除设备报告",
    route_device_report: "转发设备报告",
    device_report_download: "导出设备报告",
    configuration_save_sys: "修改系统配置",
    configuration_save_plc: "修改PLC配置",
    configuration_sync: "同步配置",
    configuration_export_data: "导出配置",
    configuration_remote: "远程控制",
    add_device_group: "添加设备组",
    modify_device_group: "修改设备组",
    delete_device_group: "删除设备组",
    change_users_in_device_group: "修改设备组中用户",
    change_devices_in_device_group: "修改设备组中设备",
    distribute_to_device: "下发至设备并激活",
    activate_task: "激活任务",
    deactivate_task: "去激活任务",
    delete_task: "删除任务",
    distribute_script_to_device: "脚本下发",
    addOtaTask: "创建OTA任务",
    modifyOtaTask: "修改OTA任务",
    deleteOtaTask: "删除OTA任务",
    exportOtaTask: "导出OTA任务",
    addHealthCheck: "创建健康检查",
    modifyHealthCheck: "修改健康检查",
    createComparisonReport: "生成对比报告",
    deleteHealthCheck: "删除健康检查",
    exportHealthCheck: "导出健康检查",
    addLicense: "添加许可证",
    modifyLicense: "修改许可证",
    deleteLicense: "删除许可证",
    exportLicense: "导出许可证",
    add_user: "添加用户",
    modify_user: "修改用户",
    delete_user: "删除用户",
    usergroup_management: "用户组管理",
    modify_usergroup: "修改用户组",
    add_usergroup: "添加用户组",
    delete_usergroup: "删除用户组",
    modify_password_policy: "修改密码策略",
    export_operation_secure_logs: "导出操作和安全日志",
    create_trace_task: "创建追踪任务",
    modify_trace_task: "修改追踪任务状态",
    delete_trace_task: "删除追踪任务",
    export_trace_task_log: "导出追踪任务日志",
    set_device_location: "拖拽设备至地图",
    device_management_diagnostics: "设备诊断",
  },
};
export default security;
