import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { CommomConfirmConfigData, SnackBarConfig } from "../models/material.models";
export type TSnackBarMessage = SnackBarConfig & { id: number };
// 工具模块
export interface ToolsState {
  snackBarMessage: TSnackBarMessage;
  confirmConfig: CommomConfirmConfigData;
  crumbDetailTitle: string;
}

const initialState: ToolsState = {
  snackBarMessage: {} as TSnackBarMessage,
  confirmConfig: {} as CommomConfirmConfigData,
  crumbDetailTitle: "",
};

export const toolSlice = createSlice({
  name: "tool",
  initialState,
  reducers: {
    openConfirmAction(state, action: PayloadAction<CommomConfirmConfigData>) {
      state.confirmConfig = { ...state.confirmConfig, ...action.payload };
    },
    closeConfirmAction(state) {
      state.confirmConfig = { ...state.confirmConfig, ...{ open: false } };
    },
    addSnackBarMsg(state, action: PayloadAction<SnackBarConfig>) {
      state.snackBarMessage = { ...action.payload, id: Date.now() };
    },
    removeSnackBarMsg(state, action: PayloadAction<number>) {
      state.snackBarMessage = {} as TSnackBarMessage;
    },
    setCrumbDetailTitle: (state, action: PayloadAction<string>) => {
      state.crumbDetailTitle = action.payload;
    },
  },
});

export const { openConfirmAction, closeConfirmAction, addSnackBarMsg, removeSnackBarMsg, setCrumbDetailTitle } = toolSlice.actions;

export const selectConfirmConfig = (state: RootState) => state.tool.confirmConfig;

export const selectSnackBarMessage = (state: RootState) => state.tool.snackBarMessage;

export default toolSlice.reducer;
