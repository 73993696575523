/*
 * Copyright © 2018-2021 Chengdu Vantron Technology Co., Ltd. All rights reserved.
 */
import {
  CreateUserParams,
  CreateUserParamsFactory,
  IAddUserGroup,
  IGWMVersionRes,
  ISelectDeviceTreeResItem,
  IUserGroup,
  IUserInfoContact,
  IValidatePwdByPolicyParams,
  UpdateUserBasicInfoParams,
  UpdateUserParams,
  UserInfo,
} from "../models/user.model";
import { IResponseMessageData, PageLinkInterface, ResponseData } from "../models/request.model";
import HttpController from "./http.controller";
import { IDeviceGroup } from "../models/device.model";
import { SignupParams } from "../models/login.model";

const mockUserList = [...Array.from({ length: 50 }).map((v, i) => ({ email: "USER" + i + "@vantron.com", username: "USER " + i, id: "user" + i }))];

export default class UsersController extends HttpController {
  // 查询系统版本号
  getGWMVersion = () => this.get<IGWMVersionRes>("/gwm/version");

  getUsers = (data: PageLinkInterface) => this.post<ResponseData<UserInfo>>("/gwm/user/page", data);

  // 告警转发模块查询用户列表
  getUsersContact = (data: PageLinkInterface) => this.post<ResponseData<IUserInfoContact>>("/gwm/user/contact", data);

  login = (data: { username: string; password: string }) => super.post<{ accessToken: string; expiredNotice: boolean; expiredDays: number }>("/gwm/login", data);

  logout = () => super.post("/gwm/auth/logout");

  signup = (params: SignupParams) => super.post("/gwm/tenant/register", params, null, false);

  unlock = (data: { username: string; password: string }) => super.post<{ accessToken: string }>("/gwm/user/unlock", data);

  lock = () => super.post("/gwm/user/lock");

  getCurrentUserInfo = () => super.get<UserInfo>("/gwm/user/profile"); // 查询当前登录用户信息

  updateUserInProfile = (data: UpdateUserBasicInfoParams) => super.put<boolean>("/gwm/user/profile", data);

  getUserById = (id: string) => super.get<CreateUserParamsFactory>(`/gwm/user/detail`, { params: { id } });

  getUserGroupById = (id: string) => super.get<IAddUserGroup>(`/gwm/usergroup/detail/${id}`);

  addUser = (data: CreateUserParams) => this.post<UserInfo>("/gwm/user", data);

  addUserGroup = (data: IAddUserGroup) => this.post<UserInfo>("/gwm/usergroup", data);

  updateUsers = (data: UpdateUserParams) => super.put("/gwm/user", data);

  updateUserGroups = (data: IAddUserGroup) => super.put("/gwm/usergroup", data);

  deleteUser = (userId: string) => super.delete("/gwm/user/" + userId);

  deleteUserGroup = (userGroupId: string) => super.delete("/gwm/usergroup/" + userGroupId);

  deleteUsers = (userIds: string[]) => super.all(userIds.map((v) => this.deleteUser(v)));

  deleteUserGroups = (userGroupIds: string[]) => super.all(userGroupIds.map((v) => this.deleteUserGroup(v)));

  changeUserStatus = (ids: string[], status: 0 | 1) => super.put("/gwm/user/status", { ids, status });

  changeUserGroupStatus = (ids: string[], status: 0 | 1) => super.put("/gwm/usergroup/status/reset", { ids, status });

  changePwd = (data: { userId: string; newPassword: string; oldPassword?: string; isForceChangePwd: boolean }) => super.put("/gwm/user/password", data);

  forceChangePwdStatus = (data: { userId: string; status: boolean }) => super.put(`/gwm/user/force_modify_pwd/status`, data);

  getUserGroups = (data: PageLinkInterface) => super.post<ResponseData<any>>(`/gwm/usergroup/page`, data);

  // 新增/修改用户/用户组界面下的查询接口
  getUserGroupsList = (keyword: string) => super.get<IUserGroup[]>("/gwm/usergroup/tenant/app/list", { params: { userGroupName: keyword } }); // 用户组

  getDevicesList = () => super.get<ISelectDeviceTreeResItem[]>("/gwm/device/v2/user/by_device_type"); // 设备树

  getDeviceGroupsList = () => super.get<IDeviceGroup[]>("/gwm/devicegroup/v2/tenant/list"); // 设备组列表

  validatePwdByPolicy = (data: IValidatePwdByPolicyParams) => super.post<IResponseMessageData>(`/gwm/user/pwd/validate_by_policy`, data);

  sendResetEmail = (data: { email: string }) => super.get("/gwm/user/reset_pwd/sending_email", { params: data }, undefined);

  resetPassword = (data: { password: string }, token: string) => super.put("/gwm/user/reset_pwd", data, { headers: { authorization: token } }, false);

  updateUserDescription = (data: { id: string; description: string }) => super.put(`/gwm/user/description`, data);

  updateUserGroupDescription = (data: { id: string; description: string }) => super.put(`/gwm/usergroup/description`, data);

  feedback = (data: FormData, config) => super.post("/gwm/user_feedback", data, config);

  // 获取当前用户所属的租户管理员列表
  getTenantListByUser = () => super.get<string[]>("/gwm/user/admin/list");

  // 检查用户名和密码是否匹配（无敏感响应信息）
  checkUserPwd = (data: { username: string; password: string }) => super.post<boolean>("/gwm/user/check_pwd", data);

  getCalendarStatistics = () => super.get<Array<{
    dateStr: string;
    alarmNum: number;
    deviceReportNum: number;
    otaTaskNum: number;
  }>>(`/gwm/statistics/dateStatistics`);
}

export const userController = new UsersController();
