import { LocaleFile } from "../..";

const alarm: LocaleFile = {
  clear: "Clear",
  allAlarmTitle: "All Alarms({{count}})",
  actions: {
    SYNC: "Sync",
    ROUTE: "Route",
    EXPORT: "Export",
    ACK: "Ack",
    ACTION: "Action",
    UNACK: "Unack",
    CLEAR: "Clear",
    UNCLEAR: "Unclear",
    FULL_SCREEN: "Fullscreen",
    PRINT: "Print",
    SETTINGS: "Settings",
    VIEW_DETAIL: "View Detail",
    SOUNDS: "Alarm Sound",
  },
  customColor: "Custom Alarm Colors",
  selectedColor: "Selected Color",
  soundsFile: "Sounds File",
  importSoundFile: "Import Sound File",
  settingsTitle: "Settings",
  alarmSound: "Alarm Sound",
  filters: "Filters",
  routeTitle: "Alarm Route",
  routeChannel: "Route Channels",
  routeChannels: {
    mails: "Mails",
    sms: "Phone Number",
  },
  backToOverview: "Back To Alarm View",
  attribute: "Attribute",
  basicInfo: "Basic Information",
  eventTime: "Event Time",
  deviceGroup: "Device Group",
  moreFilters: "Add More Filters",
  alarmDescription: "Alarm Description",
  specificProblem: "Specific Problem",
  deviceName: "Device Name",
  deviceType: "Model",
  severity: "Severity",
  ipAddress: "IP address",
  alarmObject: "Alarm Object",
  alarmIdHolder: "Input Alarm ID",
  alarmId: "Alarm ID",
  alarmType: "Alarm Type",
  alarmTime: "Alarm Time",
  devices: "Devices",
  ceaseTime: "Cease Time",
  alarmLevels: {
    CRITICAL: "Critical",
    MAJOR: "Major",
    MINOR: "Minor",
    WARNING: "Warning",
    CLEARED: "Cleared",
  },
  alarmScroll: "Alarm Scroll",
  searchAlarmTip: "Alarm Name, Alarm ID…",
  filtersTitle: "Filters",
  alarmRouterRules: "Alarm Router Rules",
  alarmRuleSetting: "Alarm Rules Setting",
  rulesManagement: "Rules Management",
  createAlarmRules: "Create Alarm Rules",
  editAlarmRules: "Edit Alarm Rules",
  ruleName: "Rule Name",
  description: "Description",
  alarmRule: "Alarm Rule",
  routeRule: "Route Rule",
  mails: "Mails",
  sms: "SMS",
  manual: "Manual",
  confirmed: "Acknowledged",
  unconfirmed: "Unacknowledged",
  cleared: "Cleared",
  resetColors: "Reset colors",
  clearAlarms: "Clear Alarms",
  clearAlarmsContent:
    "If an Alarm is already acknowledged, performing the Clear operation will remove the Alarm from the Alarm Overview. These Alarms will only be visible in Alarm History. Are you sure you want to proceed?",
  alarmTypes: {
    communicationAlarm: "Communication Alarm",
    equipmentAlarm: "Equipment Alarm",
    processingFailure: "Processing Failure",
    environmentalAlarm: "Environmental Alarm",
    qualityOfServiceAlarm: "Quality Of Service Alarm",
  },
  alarmRulePlaceholder: "Select alarm rule",
  addAlarmRule: "Add alarm rule",
  everyday: "Everyday",
  everyweek: "Every week",
  byTime: "By time",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  rules: "Rules",
  deleteAlarmRuleTitle: "Delete Alarm Routing Rule",
  deleteAlarmRuleContent: "This operation cannot be undone. Are you sure you want to delete the selected alert routing rule?",
  clearAlarmErrorContent: "There are unconfirmed alarms, please confirm and try again!",
  multipleAlarmOperationError: "Please perform this operation after selecting an alarm",
  timeRangeError: "Time range error",
  exportAllAlarms: "Export All Alarms",
  exportSelectedAlarms: "Export Selected Alarms",
  routingForm: {
    ruleInfo: "Rule Info",
    alarmRulesSetting: "Alarm Rules Setting",
    routeRule: "Route Rule",
    ruleName: "Rule Name",
    description: "Description",
    alarmId: "Alarm ID",
    devices: "Devices",
    alarmTime: "Alarm Time",
    mails: "Mails",
    email: "Email",
    sms: "SMS",
    alarmNamePlaceholder: "Please enter the rule name",
    alarmIdPlaceholder: "Select alarm ID",
    alarmRulePlaceholder: "Select alarm rule",
    alarmIdequired: "Please select alarm ID",
    alarmRuleRequired: "Please select alarm rule",
    alarmRuleEmptyRequired: "Please complete alarm rule",
    alarmDevicesRequired: "Please select devices",
    alarmEmailsRequired: "Please select emails",
    addAlarmRule: "Add Alarm Rule",
    clearAll: "Clear All",
    byTime: "By time",
    userName: "Username",
    createRuleSuccuessed: "Create rule succuessed",
    editRuleSuccuessed: "Edit rule succuessed",

    deviceOffline: "Device Offline",
    geofenceViolation: "Geofence Violation",
    licenseIsAboutToExpire: "License Is About To Expire",
    licenseExpired: "License Expired",
    deviceOfflineWithIndex: "Alarm ID 01: Device Offline",
    geofenceViolationWithIndex: "Alarm ID 02: Geofence Violation",
    licenseIsAboutToExpireWithIndex: "Alarm ID 03: License Is About To Expire",
    licenseExpiredWithIndex: "Alarm ID 04: License Expired",
    ruleNameBeUsed: "The name is already be used",
    ruleNameRequired: "Rule Name is required",
  },
  alarmReporting: "Alarm Reporting",
  reportSuccuessed: "Report succuessed",
  reportFailed: "Report failed",
  maxAlarmStatusLegends: "Up to {{count}} items",
  limitSavedSearchTip: 'Up to {{num}} results can be saved. Please delete the saved results before adding them',
};

export default alarm;
