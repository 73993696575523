import { Box, Theme, Typography, Button, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import MatPassword from "../components/common/mui/MatPassword";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { LoginParams } from "../models/login.model";
import { $confirm, $message } from "../utils";
import logo from "../assets/imgs/gwm_logo.svg";
import bluesphere_logo from "../assets/imgs/bluesphere_logo.svg";
import { t } from "i18next";
import MatInput from "../components/common/mui/MatInput";
import MatCheckbox from "../components/common/mui/MatCheckbox";
import CustomLink from "../components/common/tools/CustomLink";
import MatDialog from "../components/common/mui/MatDialog";
import { useState } from "react";
import { KeyboardEvent } from "react";
import { useCallback } from "react";
import { userController } from "../controllers/user.controller";
import { Pathnames } from "../router/route.config";
import login_bg from "../assets/imgs/login_bg.png";
import ChangeUserPwdDialog from "../components/users/ChangeUserPwdDialog";
import {logout} from "../store/mainSlice";
import {useDispatch} from "react-redux";

const currentYear = new Date().getFullYear();
const version = process.env.REACT_APP_VERSION;
const useStyles = makeStyles((theme: Theme) => {
  return {
    loginContainer: {
      backgroundColor: "transparent",
      padding: "0 40px",
      width: 480,
      borderRadius: 8,
      margin: 0,
    },
    loginTitle: {
      color: theme.palette.primary.main,
      letterSpacing: 1.5,
    },
    infoContainer: {
      width: 640,
      marginBottom: 120,
    },
    translucenceImg: {
      right: -64,
      width: 480,
      bottom: -120,
      position: "absolute",
    },
  };
});
export default function Login() {
  const initRemember = !!window.localStorage.getItem("remember");
  const initUsername = window.localStorage.getItem("username") || "";
  const initPassword = window.localStorage.getItem("password") || "";
  const classes = useStyles();
  const navigate = useNavigate();
  const [resetPwdDialogOpen, setResetPwdDialogOpen] = useState(false);
  const [changePwdOpen, setChangePwdOpen] = useState(false);
  const [loginResult, setLoginResult] = useState(null);
  const dispatch = useDispatch();

  const navigateToRegist = () => {
    navigate("/signup");
  };

  const onPwdExpired = useCallback((expiredDays: number) => {
    return new Promise<void>((resolve, reject) => {
      $confirm({
        title: "user.passwordExpirationTitle",
        content: t("user.passwordExpirationContent", { days: `${expiredDays} ${expiredDays > 1 ? t('common.days') : t('common.day')}` }),
        okText: "common.modifyNow",
        cancelText: "common.skip",
        onOk: () => {
          reject();
          setChangePwdOpen(true);
        },
        onCancel: () => resolve(),
        showCancelButton: expiredDays > 0,
        showClose: false,
      });
    })
  }, []);

  const formik = useFormik({
    initialValues: {
      username: initUsername,
      password: initPassword,
      remember: initRemember,
    } as LoginParams,
    // valid
    validationSchema: Yup.object({
      username: Yup.string().email(t("user.createAdminErrors.emailNotValid")).max(255).required(t("user.createAdminErrors.email")),
      password: Yup.string().max(255).min(5, t("user.createAdminErrors.passwordMin8")).required(t("user.createAdminErrors.password")),
    }),
    onSubmit: (values) => {
      setLoginResult(null);
      const result = new Promise<void>((resolve, reject) => {
        const { username, password, remember } = values;
        userController
          .login({ password: password, username })
          .then((res) => {
            function loginSuccessedCb() {
              sessionStorage.setItem("jwt_token", res.accessToken);
              localStorage.setItem("remember", remember.toString());
              if (remember) {
                window.localStorage.setItem("username", username);
                window.localStorage.setItem("password", password);
              } else {
                window.localStorage.removeItem("username");
                window.localStorage.removeItem("password");
              }
            }
            if (res.accessToken) {
              setLoginResult(res);
              if (res.expiredNotice) {
                loginSuccessedCb();
                onPwdExpired(res.expiredDays).then(resolve, reject);
              } else {
                loginSuccessedCb();
                resolve();
              }
            } else {
              reject();
            }
          })
          .catch(reject);
      });
      result
        .then(navigateIndex)
        .catch();
      return result;
    }
  });

  const navigateIndex = useCallback(() => {
    navigate(Pathnames.MainLayout, { state: { from: Pathnames.Login } });
  }, []);

  const resetPwdFormik = useFormik({
    initialValues: { email: "" },
    validateOnMount: true,
    validationSchema: Yup.object({
      email: Yup.string().email("user.basicOrgInfoErrors.invalidEmail").required("user.basicOrgInfoErrors.invalidEmail"),
    }),
    onSubmit() {},
  });
  // 重置密码
  const onResetOk = () => {
    resetPwdFormik.handleSubmit();
    return new Promise<void>((resolve, reject) => {
      resetPwdFormik.validateForm().then((errors) => {
        if (errors.email) {
          $message.error(errors.email);
          reject();
        } else {
          const param = { email: resetPwdFormik.values.email };
          // userController.checkEmail(param).then((res) => {
          //   resetPwdFormik.handleSubmit();
          //   if (res.isExist) {
          userController
            .sendResetEmail(param)
            .then((res) => {
              // navigate(Pathnames.ResetPassword + '?resetToken=' + res)
              resetPwdFormik.handleSubmit();
              $message.success("user.sendResetEmailSuccessed");
              resolve();
            })
            .catch(() => reject());
          //   } else {
          //     $message.error("user.checkEmailNotExist");
          //     reject();
          //   }
          // });
        }
      });
    });
  };
  // 点击登录
  const login: () => Promise<any> | void = useCallback(() => {
    formik.handleSubmit();
  }, [formik]);
  // 关闭重置密码面板
  const closeResetPwdDialog = () => {
    resetPwdFormik.resetForm();
    setResetPwdDialogOpen(false);
  };
  const getFieldProps = useCallback(
    (key: string) => {
      return {
        ...formik.getFieldProps(key),
        error: Boolean(formik.touched[key] && formik.errors[key]),
        helperText: formik.touched[key] && formik.errors[key],
        onKeyPress(e: KeyboardEvent) {
          if (e.key === "Enter") {
            login();
          }
        },
      };
    },
    [formik, login]
  );

  const handleChangePwdOk = async (values) => {
    await userController.changePwd({
      userId: loginResult.userId,
      newPassword: values.newPassword,
      isForceChangePwd: true,
    });
    $message.success("user.changePasswordSuccess");
    setChangePwdOpen(false);
    setLoginResult(null);
    dispatch(logout());
    formik.setFieldValue('password', '');
    // 阻断执行onClose
    throw new Error('password changed');
  }

  const handleCancelChangePwd = () => {
    if (loginResult?.expiredDays > 0) {
      setChangePwdOpen(false);
      setLoginResult(null);
      navigateIndex();
    }
  }

  return (
    <Box className="flex" sx={{ height: 1 / 1, flexDirection: "column" }}>
      <Box className="flex-start border-box" sx={{ px: 10, width: 1 / 1, height: 64, bgcolor: "#fff", boxShadow: "0px 1px 4px 2px #0C185A0F" }}>
        <img height={48} src={bluesphere_logo} alt="" />
      </Box>
      <Box
        className="flex-row"
        sx={{
          width: 1 / 1,
          flex: 1,
          position: "relative",
        }}
      >
        <Box component={"img"} src={login_bg} alt="" sx={{ width: "calc(100% - 480px)", height: "calc(100vh - 112px)" }}></Box>
        <Box
          sx={{
            zIndex: 1,
          }}
          className={classes.loginContainer + " border-box flex-col flex-v-center"}
        >
          <Box component={"img"} sx={{ mt: 15, mb: 5 }} height={80} src={logo} alt="" />
          <fieldset
            style={{
              width: "100%",
              overflow: "hidden",
              margin: "0",
              padding: 0,
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "none",
              borderTop: `1px solid #D9D9D9`,
              fontSize: 14,
              cursor: "default",
            }}
          >
            <legend style={{ margin: "auto", paddingLeft: "20px", paddingRight: "20px" }}>
              <Typography variant={"body1"}>{t("user.loginTitle")}</Typography>
            </legend>
          </fieldset>
          <Stack sx={{ mt: 5 }} spacing={3} width={1} alignItems={"flex-start"}>
            <Box className={"w-full"}>
              <Typography display={"inline-block"} variant="label">
                {t("user.email")}
              </Typography>
              <MatInput sx={{ mt: 0.5 }} matstyle="true" width={400} {...getFieldProps("username")} />
            </Box>
            <Box className={"w-full"}>
              <Typography variant="label">{t("user.password")}</Typography>
              <MatPassword sx={{ mt: 0.25 }} matstyle="true" width={400} {...getFieldProps("password")} />
            </Box>
            <Box className="flex-btw w-full">
              <MatCheckbox
                sx={{
                  ml: 0.5,
                  "& .MuiButtonBase-root": { py: 1 },
                }}
                {...formik.getFieldProps("remember")}
                label="user.rememberPwd"
              ></MatCheckbox>
              <Button onClick={() => setResetPwdDialogOpen(true)} size="small">
                <Typography color={"primary.main"} variant="body1">
                  {t("user.forgotPwd")}
                </Typography>
              </Button>
            </Box>
            <LoadingButton sx={{ height: 36 }} loading={formik.isSubmitting} fullWidth onClick={login} variant="contained">
              <span>{t("user.login")}</span>
            </LoadingButton>
            <Stack sx={{ width: 1 }} direction={"row"} justifyContent={"center"}>
              <Typography sx={{ mr: 1 }} variant="body1">
                {t("user.registerTipText")}
              </Typography>
              <CustomLink variant={"body1"} onClick={navigateToRegist} underlineOnHover={false}>
                {t("user.registerTipButton")}
              </CustomLink>
            </Stack>
          </Stack>
        </Box>
        {Boolean(version) && (
          <Box className={"absolute"} sx={{ right: 10, bottom: 4 }}>
            <Typography variant={"title3"}>
              {t("common.currentVersion")}: {version}
            </Typography>
          </Box>
        )}
      </Box>
      {/* footer */}
      <Box sx={{ width: 1 / 1, minHeight: 48, bgcolor: "#fff" }} className="flex flex-wrap">
        <Typography sx={{ mr: 3 }} variant="subtitle1">
          {`© ${currentYear} Vantron. All rights reserved.`}
        </Typography>
        {/* <CustomLink showUnderline>Privacy statement</CustomLink>
        <Divider orientation="vertical" sx={{ height: 24, mx: 1 }}></Divider>
        <CustomLink showUnderline>Terms of use</CustomLink>
        <Divider orientation="vertical" sx={{ height: 24, mx: 1 }}></Divider>
        <CustomLink showUnderline>All polices and guidelines</CustomLink> */}
      </Box>
      <MatDialog size="xs" title={t("user.resetPassword")} open={resetPwdDialogOpen} onClose={closeResetPwdDialog} onOk={onResetOk}>
        <Box sx={{ px: 1 }}>
          <Typography sx={{ mt: -1.5, mb: 1 }}>{t("user.inputAccountEmail")}</Typography>
          <form action="">
            <MatInput matstyle="true" sx={{ mb: 1.5 }} {...resetPwdFormik.getFieldProps("email")} error={Boolean(resetPwdFormik.touched.email && resetPwdFormik.errors.email)}></MatInput>
          </form>
        </Box>
      </MatDialog>
      <ChangeUserPwdDialog
        open={changePwdOpen}
        title="security.changePassword"
        forceChangePwd={loginResult?.expiredDays <= 0}
        onOk={handleChangePwdOk}
        onClose={handleCancelChangePwd}
      />
    </Box>
  );
}
