import { isUndefined } from "lodash";
import { Message, MessageType, Ws, defaultWsServerAddr } from "./websocket";

export interface WsCallbacks {
  onclose?: ((this: WebSocket, ev: CloseEvent) => any) | null;
  onerror?: ((this: WebSocket, ev: Event) => any) | null;
  onmessage?: ((this: WebSocket, ev: MessageEvent) => any) | null;
  onopen?: ((this: WebSocket, ev: Event) => any) | null;
}

export const noticeRoom = "/gwm/notice";

// WebSocket Service
export class WsNoticeService {
  public socket: WebSocket;

  constructor(public server: string, public room: string, public userId: string) {
    this.socket = new WebSocket(server + room + `/${userId}`);
  }
  public registEvents(cbs: WsCallbacks) {
    const { onclose, onerror, onopen, onmessage } = cbs;
    this.socket.onclose = onclose;
    this.socket.onerror = onerror;
    this.socket.onopen = onopen;
    this.socket.onmessage = onmessage;
  }
}

export class WsWithNotice extends Ws {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(server: string, room: string) {
    super(server, room);
  }
  connect(): void;
  connect(userId: string): void; // 重载
  public connect(userId?: string) {
    if (isUndefined(userId)) {
      super.connect();
      return;
    }
    const wsNoticeService = new WsNoticeService(this.server, this.room, userId);
    const { socket } = wsNoticeService;
    this.socket = socket;

    // Ws对象实例
    const _this = this;

    wsNoticeService.registEvents({
      onopen(ev) {
        // 建立连接
        console.log(ev);
      },
      onclose(ev) {
        // 连接关闭
        console.log(ev);
        // _this.reconnect();
      },
      onerror(ev) {
        // 连接异常（网络信号不好、服务器异常等）
        console.error(ev);
        _this.reconnect(userId);
      },
      onmessage(ev) {
        // 不同消息类型予以不同处理事件
        const data = JSON.parse(ev.data);
        _this.emit("getNotice", data);
      },
    });

    this.on("getNotice", "getNotice", (data: Message) => {
      // data包含消息的类型和消息的内容
      switch (data.type) {
        case MessageType.NOTICE:
          // 提示
          this.emit("noticeMessage", data.data);
          break;
        case MessageType.ALARM:
          // emit告警消息的事件
          this.emit("alarmMessage", data.data);
          break;
        default:
          break;
      }
    });

    // 告警消息
    this.on("alarmMessage", "alarmMessage", (data) => {});
  }

  reconnect(): void;
  reconnect(userId: string): void;
  public reconnect(userId?: string) {
    if (isUndefined(userId)) {
      super.reconnect();
      return;
    }
    this.connect(userId);
  }
}

export const wsWithNotice = new WsWithNotice(defaultWsServerAddr, noticeRoom);
