// import ActionType from "../store/action.type";

import { SxProps } from "@mui/material";
import React, { ChangeEvent, KeyboardEvent, ReactElement, ReactNode } from "react";
import { MatSelectOption } from "../components/common/mui/MatSelect";
import enLocale from "date-fns/locale/en-US";
import zhLocale from "date-fns/locale/zh-CN";
import { Locale } from "date-fns";
import { TDeviceListItem } from "./device.model";
import type { useFormik } from "formik";

// 语言
export enum Languages {
  zh = "zh_CN",
  tw = "zh_TW",
  en = "en_US",
}

export const localeMap = new Map<Languages, Locale>([
  [Languages.zh, zhLocale],
  [Languages.en, enLocale],
  [undefined, enLocale],
]);
export enum IntBool {
  true = 1,
  false = 0,
}

export interface BaseData {
  id: string;
}

export interface BaseOtherData<T = any> {
  [key: string]: T;
  // [key: string]?: any;
}

export interface BaseAnyData {
  [key: string]: any;
}

export interface BaseNameData extends BaseData {
  name: string;
}
// ----- //

export class OperationMenu<T = any> {
  public customStartComponent?: ReactNode;
  constructor(public action: T, public title: string, public icon?: any, public showDivider?: boolean, public disabled?: boolean, public authId?: string, public toolTip?: string) {}
}
// 自定义操作按钮选项
export class CustomOperationMenu<T = any> extends OperationMenu<T> {
  constructor(public action: T, public title: string, public customStartComponent?: ReactNode, public icon?: any, public showDivider?: boolean, public disabled?: boolean) {
    super(action, title, icon, showDivider, disabled);
  }
}
// 步骤条item类型
export class StepsItem<T> {
  constructor(public label: string, public path: string, public key: T, public component?: React.ReactNode) {}
}
// form表单的类型
export interface MatFormItemProps<T = string, L = ReactNode | string, C = ChangeEvent<HTMLInputElement> | any> {
  value: T;
  name?: string;
  label?: L;
  // options: MatSelectOption[];
  required?: boolean;
  onBlur?: (e: any) => void;
  onChange?: (e: C) => void;
  onKeyPress?: (e: KeyboardEvent) => void;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  width?: number;
  disabled?: boolean;
  sx?: SxProps;
  matstyle?: "true" | "false";
  options?: MatSelectOption[];
  [propsName: string]: any;
}
// radio option

export interface MatRadioOption<T> {
  label: string;
  value: T;
  tip?: string;
  customRender?: ReactElement;
}
// Mui开关的props
export interface MatSwitchItem<T = string> {
  key: string;
  label: string;
  tip?: T;
  defaultValue?: boolean;
}

export class MatSwitchItemFactory<T = string> implements MatSwitchItem<T> {
  label: string | undefined;
  constructor(public key: string, label?: string, public tip?: T) {
    if (!label) {
      this.label = key;
    }
  }
}

export enum TreeDataType {
  GROUP,
  ITEM,
  FORM,
}

export interface TreeData extends BaseData, BaseNameData {
  parentId?: string;
  path?: string;
  root_node?: boolean;
  children?: TreeData[];
  treeDataType: TreeDataType;
  info?: TDeviceListItem; // 设备详细信息
  [propName: string]: any;
}
export interface TreeDataNotype extends BaseData, BaseNameData {
  parentId?: string;
  path?: string; // udmp后端定义的path，前端暂时一般不作使用
  webIdentifier?: string; // 权限标识符
  children?: TreeDataNotype[];
  // udmp的parentId和children字段
  pid?: string;
  child?: TreeDataNotype[];
  [propsName: string]: any;
}

export function treeDataToList(data: TreeData[]) {
  const dataList: BaseNameData[] = [{ name: "ALL", id: "ALL" }];

  function flatTree(tree: TreeData) {
    const { name, id, children } = tree;
    dataList.push({ name, id });
    children?.map((child) => flatTree(child));
  }
  data.forEach((item) => flatTree(item));
  return dataList;
}

export type SortOrder = "Ascending" | "Descending" | "";

export enum GroupType {
  DEVICE = "DEVICE",
  PIG = "PIG",
}

export function calcTreeLevel(data: TreeData): number {
  // if (data.root_node) {
  //   return 0;
  // }
  if (!data?.path) {
    return 0;
  }
  return data.path.split(",").length - 2;
}

export enum EWeekDays {
  monday = "monday",
  tuesday = "tuesday",
  wednesday = "wednesday",
  thursday = "thursday",
  friday = "friday",
  saturday = "saturday",
  sunday = "sunday",
}

export enum CommonTaskExecutePeriod {
  EVERYDAY,
  EVERYWEEK,
}

export interface ITab {
  label: string;
  value: string;
}
export interface ITimeParams {
  startTime?: number;
  endTime?: number;
}

export enum CommonStatus {
  OFF,
  ON,
}

export type Formik = ReturnType<typeof useFormik<any>>;
