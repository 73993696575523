import { LocaleFile } from "../..";

const dashboard: LocaleFile = {
  deviceOnline: "在线",
  deviceOffline: "离线",
  deviceConfiguring: "配置中",
  OTAUpgrade: "OTA升级",
  deviceList: "设备列表",
  viewDetail: "查看详情",
  admin: "管理员",
  deviceStatus: "设备状态",
  deviceStatusSubtitle: "状态采集于每日{{time}}",
  deviceLocation: "设备位置",
  alarmStatus: "告警状态",
  PLCStatus: "PLC状态",
  device: "设备",
  city: "城市",
  calendar: "日历",
  prevMonth: "上一个月",
  nextMonth: "下一个月",
  deviceNum: "设备数量",
  deviceModel: "设备型号",
  androidVersion: "安卓版本",
  activityCalendar: "活动日历",
  noCityTip: "若一直获取不到位置信息，请检查设备是否具有且开启GPS定位功能",
  noTaskTip: "没有任务提要",
  taskActionTip: "请前往设备管理页面进行操作",
  guideTitle: "欢迎使用BlueSphere移动设备管理云平台",
  guideSubTitle: "您可以通过平台进行移动设备管理，让你了解和控制关键业务移动设备的配置、全方位的系统状态和安全风险。",
  registerByQrCode: "通过二维码注册设备",
  registByQrCodeSubtitle: "您可以通过使用移动设备扫描二维码的方式来注册您的设备",
  registerByTempCode: "通过模板代码注册设备",
  registByTempCodeSubtitle: "您可以通过使用移动设备输入模板代码的方式来注册您的设备",
  viewRegistMethod: "查看注册方法",
  createRegTemp: "创建注册模板",
  letsStart: "让我们开始吧！",
  guideDialogTitles: {
    FACTORY_RESET: "恢复出厂设置，在欢迎屏幕的任意位置点按 6 次",
    CONNECT_WIFI: "将设备连接到蜂窝网络或 Wi-Fi。单击下一步",
    SCAN_QR_CODE: "选择注册模板，查看二维码。在设备上根据配置步骤，扫描从门户生成的二维码。",
    INPUT_TEMPLATE_COD: "选择注册模板，查看模板代码。在设备上根据配置步骤，输入从门户生成的模板代码。",
    INSTALL_AGENT: "要完成BlueSphere MDM设备管理应用程序的安装，请单击接受并继续",
    ALLOW_PERMISSION: "授予 BlueSphere 设备管理应用程序修改系统设置的权限，单击“开启权限”继续配置",
    SETTINGS: "要授予权限，请进入修改系统设置> BlueSphere 设备管理，然后切换到允许修改系统设置。返回主屏幕。",
  },
  guideDialogSubTitles: {
    FACTORY_RESET: "注意：如果您在设备上没有看到此欢迎屏幕，请尝试使用 Provisioner Tool 进行配置或联系我们",
    CONNECT_WIFI: "注意：此步骤不适用于 android 9 及更高版本。",
    SCAN_QR_CODE: "注意：某些设备可能会多次出现扫描屏幕，请重新扫描并继续。",
    INPUT_TEMPLATE_COD: "",
    INSTALL_AGENT: "",
    ALLOW_PERMISSION: "",
    SETTINGS: "",
  },
  onlinePointers: "在线点位",
  offlinePointers: "离线点位",
  offlineDevices: "离线设备",
  onlineDevices: "在线设备",
};

export default dashboard;
