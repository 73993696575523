import { LocaleFile } from "../..";

const edgeComputing: LocaleFile = {
  scriptLoad: "加载脚本",
  taskManagement: "任务管理",
  selectDevices: "选择设备",
  uploadFile: "上传文件",
  analysisScript: "脚本解析",
  uploadTooltip: "仅支持 .bin 格式文件",
  selectedDevices: "已选择设备列表",
  verifying: "校验中...",
  verifyFaild: "校验失败，请重新上传",
  verifySuccess: "校验成功",
  executor: "执行器",
  distribute: "下发至设备",
  distributeAndActivate: "下发并激活",
  taskList: "任务列表",
  variable: "变量",
  activate: "激活",
  deactivate: "去激活",
  delete: "删除",
  searchForTask: "搜索任务",
  channelNameExist: "名称已存在",
  variables: {
    scriptName: "脚本名称",
    Executor: "执行器",
    editVariable: "编辑变量",
    editInputVariable: "编辑输入变量",
    variableName: "变量名",
    execution: "执行对象",
    variableValue: "变量值",
    editOutputVariable: "编辑输出变量",
    calcResultName: "计算结果名称",
    title: "标题",
    scriptVariable: "脚本变量",
    dataType: "数据类型",
    resultValue: "结果值",
    outputPoint: "输出到点位",
    outputVariable: "输出到变量",
  },
  table: {
    name: "脚本名称",
    size: "文件大小(Byte)",
    type: "脚本类型",
    device: "设备",
    distributeTime: "下发时间",
    progress: "进度",
    status: "状态",
    createdTime: "创建时间",
  },
  status: {
    awaitingActivate: "待激活",
    activating: "激活中",
    activated: "已激活",
    deactivated: "未激活",
    failed: "失败",
  },
};

export default edgeComputing;
