import { Box, SxProps, Typography, alpha } from "@mui/material";
import { useTranslation } from "react-i18next";
import no_template from "../../../assets/imgs/no_data_bg/no_template.svg";
import no_geofence from "../../../assets/imgs/no_data_bg/no_geofence.svg";
import no_device from "../../../assets/imgs/no_data_bg/no_device.svg";
import no_application from "../../../assets/imgs/no_data_bg/no_application.svg";
import no_file from "../../../assets/imgs/no_data_bg/no_file.svg";
import no_data from "../../../assets/imgs/no_data_bg/no_data.svg";
import img_empty from "../../../assets/imgs/no_data_bg/img_empty.svg";
import not_support from "../../../assets/imgs/no_data_bg/img_notsupport.svg";
import not_selected from "../../../assets/imgs/no_data_bg/img_selectdevice.svg";

interface EmptyDataProps {
  emptyTitle?: string;
  type?: EmptydataType;
  height?: number;
  pt?: number;
  sx?: SxProps;
  imgWidth?: number;
  imgHeight?: number;
}

const typeToImgMap = new Map([
  ["device", no_device],
  ["template", no_template],
  ["geofence", no_geofence],
  ["application", no_application],
  ["file", no_file],
  ["nodata", no_data],
  ["notSupport", not_support],
  ["notSelected", not_selected],
  ["img_empty", img_empty],
] as const);

type EmptydataType = Parameters<typeof typeToImgMap["has"]>[0];

export default function EmptyData(props: EmptyDataProps) {
  const { height, type, emptyTitle = "common.noDataFound", pt = 10, imgWidth = 120, imgHeight = 120, sx = {} } = props;

  const { t } = useTranslation();
  return (
    <Box sx={{ height, flexDirection: "column", justifyContent: "flex-start", pt, ...sx }} className="flex border-box">
      {type && <img src={typeToImgMap.get(type || "img_empty")} width={imgWidth} height={imgHeight} alt="" />}
      <Typography sx={{ mt: 2, mb: pt, whiteSpace: "nowrap", color: (theme) => alpha(theme.custom.fontBaseColor, 0.6) }} variant="body1">
        {t(emptyTitle)}
      </Typography>
    </Box>
  );
}
