const reports = {
  taskManagement: "任务管理",
  customizedReport: "自定义报告",
  groups: "设备组",
  devices: "设备",
  device: "设备",
  createTask: "创建任务",
  deleteTask: "删除任务",
  deleteReport: "删除报告",
  taskDetail: "任务详情",
  deviceReport: "设备报告",
  deviceGroup: "设备组",
  fields: "字段",
  execute: "执行",
  routeReport: "转发报告",
  deleteTaskContent: "此操作无法撤销，您确定要删除所选的任务吗？",
  deleteReportContent: "此操作无法撤销，您确定要删除所选的报告吗？",
  routeMax10: "报告转发最多支持10条报告",
  table: {
    reportName: "报告名称",
    taskName: "任务名称",
    reportType: "报告类型",
    createdTime: "创建时间",
    deviceNumber: "设备数量",
    deviceAssociated: "关联设备数量",
    creator: "创建者",
    friendlyName: "标识名称",
    model: "型号",
    status: "状态",
    androidVersion: "Android版本",
    batteryLevel: "电池电量",
    authorizationStatus: "授权状态",
    serialNumber: "序列号",
    license: "许可证",
    enrolledTime: "注册时间",
    buildVersion: "构建版本",
    agentVersion: "Agent版本",
    groupName: "设备组名称",
    lastOnlineTime: "上次在线时间",
    storage: "存储",
    licenseExpireDay: "授权到期日",
    whitelist: "白名单",
    appList: "已安装应用",
    appEvent: "APP事件",
    SSID: "Wi-Fi SSID",
    ADB: "ADB",
    bluetooth: "蓝牙",
    geofence: "地理围栏",
    offline: "离线",
    online: "在线",
    installed: "已安装",
    updated: "已更新",
    uninstalled: "已卸载",
    apps: "应用",
    routeNum: "转发人员数量",
  },
  createForm: {
    taskInfo: "任务信息",
    selectDevices: "选择设备",
    selectedDevices: "已选择设备",
    executionCycle: "执行周期",
    selectFields: "选择字段",
    selectedFields: "已选择字段",
    routeSetting: "转发设置",
    daily: "每日报告",
    weekly: "每周报告",
    customized: "自定义报告",
    taskNameTip: "使用当前日期和时间作为默认名称",
    executePeriod: "执行周期",
    field: "字段",
    selectDeviceError: "请选择至少一个设备或设备组",
    selectFieldError: "请选择至少一个字段",
    selectUserError: "请选择至少一位租户",
  },
  detail: {
    reportName: "报告名称",
    reportType: "报告类型",
    deviceNumber: "设备",
    sn: "Sn号",
    deviceSn: "设备Sn号",
    createdTime: "创建时间",
    friendlyName: "标识名称",
    model: "型号",
    status: "状态",
    authorizationStatus: "授权状态",
    serialNumber: "序列号",
    license: "许可证",
    enrollmentTime: "注册时间",
    buildVersion: "构建版本",
    agentVersion: "代理版本",
    plcVersion: "PLC 版本",
    osVersion: "系统版本",
    groupName: "设备组名称",
    lastOnlineTime: "上次在线时间",
    storage: "存储",
    licenseExpireDay: "授权到期日",
    whitelist: "白名单",
    WifiSSID: "Wi-Fi SSID",
    geofence: "地理围栏",
    offline: "离线",
    online: "在线",
    buildTime: "构建时间",
    currentSim: "当前Sim号",
    cpuType: "CPU 类型",
    connectionType: "连接方式",
    active: "已授权",
    unactive: "未授权",
    depletion: "授权已过期",
    ipAddress: "IP地址",
    ipAddress$: "IP地址{{index}}",
    macAddress: "MAC地址",
    macAddress$: "MAC地址{{index}}",
    cellularSignalStrength: "蜂窝信号强度(RSRP)",
    apDevList: "AP设备列表",
    location: "位置",
  },
};

export default reports;
