const device = {
  device: "Device",
  devices: "Devices",
  allDevices: "All Devices",
  addDeviceGroup: "Add Device Group",
  modifyDeviceGroup: "Modify Device Group",
  deviceGroup: "Device Group",
  deviceGroups: "Device Groups",
  searchPlaceholder: "Search for Devices",
  sync: "Sync",
  Detype: "Device Type",
  DefirmwareVersion: "Device Sys Version",
  DesoftwareVersion: "Device Software Version",
  Desn: "SN",
  Deposition: "Longitude & Latitude",
  Deackstate: "State",
  DeackTime: "Ack Time",
  DeackType: "Ack Type",
  deleteDeviceContent: "The current operation requires double authorization, please contact the administrator and enter the administrator password to continue.",
  addDevice: "Add Device",
  moreOptions: "More Options",
  deviceInfo: "Device Information",
  linkToDeviceGroup: "Link to Device Group",
  selectTargetGroup: "Select Target Group",
  createTargetGroup: "Create target group",
  name: "Device Name",
  type: "Device Type",
  administrator: "Administrator",
  password: "Password",
  sn: "SN",
  protocol: "Communication Protocol",
  encryption: "Encryption",
  osVersion: "System Version",
  version: "Sys Version",
  timeZone: "Time zone",
  location: "Location",
  description: "Description",
  displayAsSn: "Display as SN",
  displayAsName: "Display as Name",
  connectionType: {
    wifi: "Wi-Fi",
    cellular: "Cellular",
    wan: "WAN",
    lan: "LAN",
    Ethernet: "Ethernet",
  },
  NO_TARGET_GROUP: "Do Not Link",
  TO_AN_EXISTING_GROUP: "Connect to an existing group",
  TO_A_NEW_GROUP: "Connect to new group",
  //
  addGroup: "Add Device Group",
  root: "Root",
  addDevices: "Add Device",
  addUsers: "Add User",
  modifyDevices: "Modify Devices",
  modifyUsers: "Modify Users",
  currentDeviceList: "Current Device",
  systemDeviceList: "Unassigned Device List",
  currentUserList: "Current User",
  systemUserList: "System Users",
  groupName: "Group Name",
  groupNameRequired: "Please enter a group name",
  deleteGroupTitle: 'Are you sure to delete the device group "{{name}}"? ',
  remoteAccess: "Remote Access",
  url: "URL",
  port: "Port",
  accessFailure: "Access failure",
  startRemoteAccess: "Start Remote Access",
  closeRemoteAccess: "Close Remote Access",
  remoteWeb: "Remote Desktop",
  remoteTerminal: "Remote Terminal",
  connectionStatus: "Connection Status",
  formatError: "Format error",
  example: "Example",
  timeout: "Timeout",
  timeoutNote: "Please note that unless you disable the remote access, otherwise it will always be connected!",
  deviceList: "Device List",
  userList: "User List",
  restart: "Restart Device",
  savedSearch: "Saved Search",
  restartDeviceContent: "Are you sure you want to restart the device?",
  deviceGroupNameMaxTip: "The device group name exceeds the maximum limit",
  addDeviceFormTokenTip: "Only valid for non-Vantron devices",
  addDeviceRegistLoading: "Obtaining device registration...",
  addDeviceRegistSuccess: "Obtaining device registration successfully!",
  addDeviceRegistFailed: "Obtaining device registration failed!",
  addDeviceVersiontLoading: "Obtaining system version...",
  addDeviceVersionSuccess: "Obtaining system version successfully!",
  addDeviceVersionFailed: "Obtaining system version failed!",
  addDeviceVersionSuccessTips: "The system version is ",
  constants: {
    VIEW_DETAIL: "View Details",
    RENAME: "Rename Group",
    MOVE: "Move Group",
    DELETE: "Delete Group",
    RESTART_DEVICES: "Restart Device",
    DELETE_DEVICES: "Delete Devices",
    ONLINE: "Online",
    OFFLINE: "Offline",
  },
  ungrouped: "Ungrouped",
  multipleGroups: "{{data}} Groups",
  modifyDevice: "Modify Device",
  restartDevice: "Restart",
  pingDevice: "Ping",
  moveTo: "Move to",
  switchSIM: "SIM Switch",
  sim: "SIM",
  deleteDevice: "Delete Device",
  batchSwitchSIMTip: "Switching SIM in batch may result in unexpected failure, are you sure to continue?",
  addDeviceGroupSuccessed: "Add device group successed",
  deleteDeviceGroupSuccessed: "Delete device group successed",
  renameDeviceGroupSuccessed: "Rename device group successed",
  // save search result
  saveSearchResult: "Save Search Result",
  clearFilters: "Clear Filters",
  searchName: "Name",
  saveSearchResultSuccessed: "Save search result successed",
  saveSearchResultFailed: "Save search result failed",
  searchResultLimitTip: "Saved up to ten, you have exceeded the limit",
  deleteSavedSearchTitle: "Delete Saved Search",
  deleteSavedSearchContent: "This operation cannot be undone. Are you sure you want to delete the selected saved search?",
  deleteSavedSearchSuccessed: "Delete saved search successed",
  token: "Token",
  // custom table columns
  customColumnTitle: "Header of columns, please drag or select to reorder",
  unSelectedColumn: "Click to add to show on table",
  customColumnsSuccessed: "Custom display items saved successfully",
  table: {
    name: "Device Name",
    type: "Model",
    isActive: "Device Status",
    licenseName: "License",
    lastLogin: "Last Online Time",
    ipAddress: "IP Address",
    sn: "SN",
    groupName: "Group Name",
    protocol: "Protocol",
    macAddress: "MAC",
    fwVersion: "OS Version",
    currentSim: "Current SIM",
    cellularSignalStrength: "Cellular Signal Strength(RSRP)",
    cellularSignalPercentage: "Cellular Signal Strength(%)",
    connectionType: "Connection Type",
    plcVersion: "PLC Version",
    lng: "Longtitude",
    lat: "Latitude",
    criticalAlarms: "Critical Alarms",
    majorAlarms: "Major Alarms",
    minorAlarms: "Minor Alarms",
    warningAlarms: "Warning Alarms",
    location: "Navigation",
    alarms: "Alarms",
    status: "Authorization Status",
    createTime: "Enrollment Time",
    storage: "Storage",
    clientSsid: "Wi-Fi SSID",
    cpuType: "CPU Type",
    buildTime: "Build Time",
    agentVersion: "Agent Version",
    ipAddress1: "IP Address 1",
    ipAddress2: "IP Address 2",
    ipAddress3: "IP Address 3",
    ipAddress4: "IP Address 4",
    ipAddress5: "IP Address 5",
    macAddress1: "MAC Address 1",
    macAddress2: "MAC Address 2",
    macAddress3: "MAC Address 3",
    macAddress4: "MAC Address 4",
    macAddress5: "MAC Address 5",
    lora: "Lora",
    loraGatewayId: "Lora Gateway ID",
    loraState: "Lora State",
  },
  license: {
    onUsing: "Activated",
    expired: "Expired",
    ineffective: "License Expired",
    noLienseBind: "No License",
  },
  diagnostics: "Diagnostics",
  domainName: "Domain Name",
  diagnose: "Diagnose",
  result: "Result",
  invalidDomain: "Invalid domain name or IP address",
  loraDevEui: "DevEUI",
  loraDeviceProfileId: "Profile",
  otaaKey: "OTAA key",
  loraDeviceClassEnabled: "Class Enabled",
  loraDevAddr: "Device Addr",
  information: "Information",
  data: "Data",
  method: "Method",
  deletePlcDeviceTip: "Are you sure to delete this device?",
  lastSeen: "Last Seen",
  neverSeen: "Never Seen",
};

export default device;
