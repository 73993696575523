import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { matchPath, useLocation, useNavigate, useRoutes } from "react-router-dom";
import { allRoutes, RouteWithArgs } from "./MainRoute";
import CommonLoading from "../components/common/tools/CommonLoading";
import Login from "../pages/Login";
import SignUpPage from "../pages/SignUpPage";
import ResetPassword from "../components/common/tools/ResetPassword";
import {
  setPermissionTree,
  setUserFetchStatus,
  setUserInfo,
  selectPermissionsAggregation,
  setPermissionFetchStatus,
  selectPermissionTree,
  selectCurrentUser,
  selectLockTimeout,
  lockUser,
  selectIsLocked,
  selectRole,
  setRole,
} from "../store/mainSlice";
import { RootState } from "../store";
import { userController } from "../controllers/user.controller";
import { Pathnames } from "./route.config";
import { ws } from "../components/layouts/util/websocket";
import NotFound from "../components/common/tools/NotFound";
import { allNavItems, notFunctionPaths, notNavPaths, routesWithoutPermission } from "./nav";
import { NavItems } from "../models/nav.model";
import { debounce, isEmpty, isNull, isUndefined } from "lodash";
import { useTranslation } from "react-i18next";
import useLanguage from "../hooks/useLanguage";
import { Languages } from "../models/base.model";
import NoPermission from "../components/common/tools/NoPermission";
import { Role, UserInfo } from "../models/user.model";
import { wsWithNotice } from "../components/layouts/util/wsWithNotice";
import { securityController } from "../controllers/security.controller";
import ChangeUserPwdDialog from "../components/users/ChangeUserPwdDialog";

/**
 *
 * @param allNavItems 侧边栏功能模块
 * @param targetPath 当前的路由
 * @returns
 */
const match = (allNavItems: NavItems[], targetPath: Pathnames) => {
  for (const nav of allNavItems) {
    if (
      nav.pathMatch.some((v) => {
        return matchPath(v, targetPath);
      }) ||
      notNavPaths.includes(targetPath)
    ) {
      return true;
    }
    if (nav.children) {
      if (match(nav.children, targetPath)) {
        return true;
      }
    }
  }
  return false;
};

let activeTimer;
const Routes = () => {
  const token = window.sessionStorage.getItem("jwt_token");

  const isLogin = !isNull(token);

  const hash = window.location.hash;

  const navigate = useNavigate();

  const [routes, setRoutes] = useState<RouteWithArgs[]>([]);

  const locationData = useLocation();

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const {
    i18n: { language: curLang },
  } = useTranslation();

  const { setLang } = useLanguage();

  const matchRoutes = useMemo(() => {
    return match(allNavItems, locationData.pathname as Pathnames);
  }, [locationData.pathname]);

  const getUserPermission = useCallback(async () => {
    try {
      const res = await securityController.getUserPermission();
      setRoutes(allRoutes);
      // 将权限树存储到全局状态中
      dispatch(setPermissionTree(res));
      // 获取完成
      dispatch(setPermissionFetchStatus(true));
    } catch (error) {
      setRoutes([]);
    }
  }, [dispatch]);
  const checkUserInfo = useCallback(
    (cb?: (res: UserInfo) => void) => {
      userController
        .getCurrentUserInfo()
        .then((res) => {
          getUserPermission();
          cb && cb(res);
          dispatch(setUserInfo(res));
          dispatch(setUserFetchStatus(true));
        })
        .catch((err) => {
          navigate(Pathnames.Login);
          dispatch(setUserFetchStatus(false));
        });
    },
    [dispatch, getUserPermission, navigate]
  );

  const permissionTree = useSelector(selectPermissionTree);
  const { secondPermissions } = useSelector(selectPermissionsAggregation);

  const secondPermissionsItems = useMemo(() => {
    const res: NavItems[] = [];
    for (const fNav of allNavItems) {
      const items = fNav.children.filter((sNav) => secondPermissions.includes(sNav.authId));
      res.push(...items);
    }
    // 和不涉及权限的路由合并
    return [...res, ...routesWithoutPermission];
  }, [secondPermissions]);

  const userInfo = useSelector(selectCurrentUser);

  const auth = useSelector(selectRole);

  const getUserInfoFinish = useSelector((state: RootState) => state.main.getUserInfoFinish);

  const getPermissionFinish = useSelector((state: RootState) => state.main.getPermissionFinish);

  const RouteComponent = useRoutes(routes);

  const forcePasswordChange = useMemo(() => {
    return userInfo?.forcePasswordChange ?? false;
  }, [userInfo]);

  const handleChangePwd = useCallback(
    (values) => {
      const { newPassword } = values;
      // 修改密码
      userController
        .changePwd({ userId: userInfo.id, newPassword, isForceChangePwd: true })
        .then((res) => {
          checkUserInfo();
        })
        .catch(() => {});
    },
    [checkUserInfo, userInfo?.id]
  );

  useEffect(() => {
    setOpen(forcePasswordChange);
  }, [forcePasswordChange, userInfo]);

  const changeUserLanguage = useCallback(
    async (lang: Languages) => {
      try {
        const additionalInfo = JSON.parse(isEmpty(userInfo?.additionalInfo) ? "{}" : userInfo?.additionalInfo);
        const data = { ...userInfo, additionalInfo: JSON.stringify({ ...additionalInfo, language: lang }) };
        await userController.updateUserInProfile(data);
      } catch (error: any) {
        console.error(error.stack);
      }
    },
    [userInfo]
  );

  // 监听用户鼠标与键盘活动（超时自动锁定）
  const locked = useSelector(selectIsLocked);
  const lockTimeout = useSelector(selectLockTimeout);
  const handleLock = useCallback(() => {
    if (locked || (lockTimeout ?? 0) === 0) return;
    userController
      .lock()
      .then(() => {
        dispatch(lockUser());
        sessionStorage.removeItem("gwm_lock_timer");
      })
      .catch();
  }, [dispatch, lockTimeout, locked]);
  const handleActivity = useCallback(
    debounce((e) => {
      // 监听到用户活动，则刷新延时器
      activeTimer && clearTimeout(activeTimer);
      if ((lockTimeout ?? 0) > 0 && !locked) {
        activeTimer = setTimeout(() => {
          // 锁定
          handleLock();
        }, lockTimeout * 60 * 1000);
        sessionStorage.setItem("gwm_lock_timer", String(activeTimer));
      }
    }, 800),
    [handleLock]
  );

  useEffect(() => {
    if (locationData.pathname === Pathnames.Login) return;
    if (isEmpty(userInfo)) return;
    if (auth !== Role.PLANTFORM_ADMIN) {
      if (!ws.socket) {
        ws.connect();
      }
      if (!wsWithNotice.socket && !isEmpty(userInfo)) {
        wsWithNotice.connect(userInfo.id);
      }
    }
    try {
      const language = JSON.parse(isEmpty(userInfo?.additionalInfo) ? "{}" : userInfo?.additionalInfo).language;
      if (isUndefined(language)) {
        // 用户语言缺省->调用接口修改用户的语言为当前环境下的默认语言
        changeUserLanguage(curLang as Languages);
      } else {
        setLang(language);
      }
    } catch (error: any) {
      console.error(error.stack);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curLang, locationData.pathname, userInfo]);
  useEffect(() => {
    // 登录后的路由hash变化
    if (isLogin && hash.length > 0 && locationData.pathname !== Pathnames.Login) {
      if (locationData.state?.from && locationData.state?.from === Pathnames.Login) {
        // 登入系统后查询用户信息初始化延时锁定器
        const cb = (res: UserInfo) => {
          const lockTimeoutRes = res.lockTimeout;
          if ((lockTimeoutRes ?? 0) > 0) {
            activeTimer && clearTimeout(activeTimer);
            activeTimer = setTimeout(() => {
              // 锁定
              handleLock();
            }, lockTimeoutRes * 60 * 1000);
            sessionStorage.setItem("gwm_lock_timer", String(activeTimer));
          }
          // 清除login传的state
          const newState = { ...locationData.state };
          delete newState.from;
          window.history.replaceState(newState, "");
        };
        checkUserInfo(cb);
      } else {
        // 锁屏状态或者访问非功能页面无需校验token
        if (locked || notFunctionPaths.includes(locationData.pathname as Pathnames)) return;
        checkUserInfo();
      }
    } else {
      activeTimer && clearTimeout(activeTimer);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("mousedown", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("blur", handleActivity);
    }
  }, [checkUserInfo, handleActivity, handleLock, hash.length, isLogin, locationData.pathname, locationData.state, locked]);
  useEffect(() => {
    // 路由hash为空字符串或访问系统根目录时的重定向
    if (hash.length === 0 || locationData.pathname === Pathnames.MainLayout) {
      if (isLogin) {
        if (auth !== null) {
          if (auth === Role.PLANTFORM_ADMIN) {
            navigate(Pathnames.PackageManagement);
          } else {
            navigate(Pathnames.Dashboard);
          }
        }
      } else {
        navigate(Pathnames.Login);
      }
    }
  }, [hash, isLogin, locationData.pathname, auth]);
  useEffect(() => {
    if (locationData.pathname === Pathnames.Login) {
      dispatch(setRole(null));
      dispatch(setUserFetchStatus(false));
      dispatch(setPermissionFetchStatus(false));
    }
  }, [locationData.pathname]);
  useEffect(() => {
    // 未登录访问功能页->调用profile，重定向到login，并提示
    if (!isLogin && !notFunctionPaths.includes(locationData.pathname as Pathnames)) {
      checkUserInfo();
    }
  }, [checkUserInfo, isLogin, locationData.pathname]);

  useEffect(() => {
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("mousedown", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("blur", handleActivity);
    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("mousedown", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("blur", handleActivity);
    };
  }, [handleActivity]);

  if (matchRoutes) {
    // 直接访问登录页
    if (locationData.pathname === Pathnames.Login) {
      return <Login></Login>;
    } else if (locationData.pathname === Pathnames.SignUpPage) {
      return <SignUpPage></SignUpPage>;
    } else if (locationData.pathname === Pathnames.ResetPassword) {
      return <ResetPassword></ResetPassword>;
    } else {
      // 获取用户信息和权限信息完成
      if (getUserInfoFinish && getPermissionFinish) {
        if (auth === Role.PLANTFORM_ADMIN) {
          return RouteComponent;
        } else {
          if (secondPermissionsItems.length > 0 && permissionTree.length > 0) {
            // 检测权限完成，验证当前路由是否有权限访问
            if (
              secondPermissionsItems.some((item) => {
                return item.pathMatch.some((v) => {
                  return matchPath(v, locationData.pathname);
                });
              })
            ) {
              if (forcePasswordChange) {
                return <ChangeUserPwdDialog title="user.changePwd" open={open} forceChangePwd={forcePasswordChange} onOk={handleChangePwd}></ChangeUserPwdDialog>;
              }
              return RouteComponent;
            } else {
              return <NoPermission></NoPermission>;
            }
          }
        }
      }
      // 获取用户信息和权限信息中
      else {
        return <CommonLoading title={null}></CommonLoading>;
      }
    }
  } else {
    // 不匹配系统的任意路由
    return <NotFound></NotFound>;
  }
};

export default Routes;
