import { LocaleFile } from "../..";

const configuration: LocaleFile = {
  FW: "VantronOS Config",
  PLC: "PLC Config",
  total: "Total Devices",
  notStarted: "Not Started",
  inProgress: "In Progress",
  success: "Success",
  failed: "Failed",
  operationStatus: "Operation Status",
  devices: "Devices",
  results: "Results",
  distributeSuccessfully: "Successfully issued synchronization task",
  distributeFailed: "Failed to issue synchronization task",
  syncSuccessfully: "Sync successfully",
  syncFailed: "Sync failed",
  actions: {
    batchCommand: "Batch Command",
    currentDevice: "Current Device",
    allDevices: "All Devices",
  },
  tree: {},
  detail: {
    editAttributes: "Edit Attributes",
  },
  batchCommand: {
    taskManagement: "Task Management",
    command: "Command",
    needTemplate: "Need A Template",
    commandFile: "Command File",
    uploadText: "Please drag and drop the file here or",
    uploadFiles: "Upload Files",
    reUpload: "Re-Upload",
    carryOut: "Carry Out",
    rightNow: "right Now",
    schedule: "Schedule",
    commandProgress: "Command Progress",
    log: "Log",
    scheduleTitle: "Schedule",
    scheduleTime: "Schedule Time",
    taskList: "Task List",
    taskTable: {
      fileName: "File Name",
      fileSize: "File Size",
      runtime: "Runtime",
      status: "Status",
      createTime: "Create Time",
    },
  },
  defaultGroup: "Default Group",
  addChannel: "Add Channel",
  editChannel: "Edit Channel",
  deleteChannel: "Delete Channel",
  deleteChannelContent: "Are you sure delete this channel?",
  addDevice: "Add Device",
  editDevice: "Edit Device",
  deleteDevice: "Delete Device",
  deleteDeviceContent: "Are you sure delete this device?",
  copyDevice: "Copy Device",
  deleteVariable: "Delete Variable",
  deleteVariableContent: "Are you sure delete this variable?",
  channel_name: "Channel Name",
  description: "Description",
  driver: "protocol Type",
  enabled: "whether To Enable",
  communication_type: "Communication Method",
  copyVariableOrNot: "Whether to Copy Variables",
  addVariable: "Add Variable",
  copyVariable: "Copy Variable",
  editVariable: "Edit Variable",
  nameRepeat: "The name already exists, please re-enter",
  varibleNodeIdExist: "Node ID already exists, please re-enter",
  syncCompleted: "Sync Completed",
  lastDataUpdateTime: "Last Data Update Time",
  synchronizing: "Synchronizing...",
  pleaseAddChannel: "Please add channel",
  pleaseAddDevice: "Please add device",
  notSelectChannel: "No channel selected!",
  notSupportDevice: "Current Channel does not support adding devices",
  portAlreadyExists: "Serial port is used by {{channelName}}",
  deviceOffline: "Device offline, please check the device status.",
  syncDataTip: "Please sync the configuration again.",
  leaveTabTitle: "Leaving the page",
  leaveTabContent: "The changes made to the current page have not been saved yet. Editing content after leaving the page will not be automatically saved. Do you want to leave this page?",
  leave: "Leave",
  channel: "Channel",
  notSelected: "Please select the device to view the configuration details.",
  notSupport: "Oops… not applicable to selected device, please use Remote Access.",
  noSelectedChannel: "Please select channel first",
  dataDistribution: "Distribution data",
  advanceConfig: "Advance config",
  scale: "Proportion conversion",
  zoom: "Zoom",
  maxValue: "Max value",
  minValue: "Min value",
  maxScale: "Max scale",
  minScale: "Min scale",
  offset: "Offset",
  gain: "Gain",
  scaleTip: "Note: Display value = (max proportion - min proportion) / (max value - min value) * (PLC reading value - min value) + min proportion",
  zoomTip1: "Note: value=(PLC value * gain)+offset",
  zoomTip2: "PLC value=(value-offset)/gain",
  maxLessThanMin: "Max items should be larger than min items",
  gainNotZero: 'The value of gain cannot be "zero"',
  configTree: {
    system: "System",
    network: "Network",
    linkTracking: "Link Tracking",
    deviceManager: "Device Manager",
    networkSecurity: "Network Security",
  },
  loadWebIframeTimeout: "Connection time out, please try again.",
  remoteIsUsingTip: "Device terminal is using by others.",
};
export default configuration;
