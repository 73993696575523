import * as React from "react";
import Button from "@mui/material/Button";
import { styled, SxProps } from "@mui/material/styles";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import { MuiButtonProps } from "../../../models/material.models";
import { t } from "i18next";
import { isUndefined } from "lodash";
import { MouseEventHandler } from "react";

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  "& .MuiDialogTitle-root+.MuiDialogContent-root": {
    paddingTop: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
  },
}));

export interface DialogTitleProps {
  children?: React.ReactNode;
  transparentBg?: boolean;
  showClose?: boolean;
  onClose?: MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps;
}

export const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, showClose = true, transparentBg, sx = {}, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        px: 3,
        bgcolor: transparentBg ? "#fff" : (theme) => theme.palette.primary.main,
        color: transparentBg ? (theme) => theme.palette.text.primary : "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 1,
        fontSize: 16,
        ...sx,
      }}
      {...other}
    >
      {children}
      {showClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            ml: 3,
            p: 0,
            color: (theme) => (transparentBg ? theme.palette.text.secondary : theme.palette.background.default),
          }}
        >
          <CloseIcon sx={{ fontSize: 24 }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

// ok按钮数据类型
export interface OkBtnProps extends MuiButtonProps {
  loading?: boolean;
}

// 自定义Dialog的props数据类型
export interface MatDialogProps extends DialogProps {
  showClose?: boolean;
  onOk?: (...rest: any) => void | Promise<void | any>;
  title?: string;
  children?: React.ReactNode;
  okBtnProps?: OkBtnProps;
  cancelBtnProps?: MuiButtonProps;
  size?: DialogProps["maxWidth"];
  transparentTitleBg?: boolean;
  showDivider?: boolean;
  contentPadding?: number;
  maxHeight?: number;
  footerPadding?: number;
  footer?: React.ReactNode;
  hideFooter?: boolean;
  sx?: SxProps;
  fullWidth?: boolean;
  paperSx?: object;
  dialogSx?: SxProps;
  className?: string;
}

export default function MatDialog(props: MatDialogProps) {
  const {
    open,
    showClose,
    onClose = () => {},
    onOk = () => {},
    title = "Dialog Title",
    children,
    okBtnProps = {},
    cancelBtnProps = {},
    transparentTitleBg = true,
    size = "sm",
    contentPadding = 5,
    sx = {},
    fullWidth = true,
    paperSx = {},
    dialogSx = {},
    PaperProps,
    className = "",
  } = props;

  const [loading, setLoading] = React.useState<boolean>(Boolean(okBtnProps?.loading));
  React.useEffect(() => {
    !isUndefined(okBtnProps?.loading) && setLoading(okBtnProps.loading);
  }, [okBtnProps?.loading]);

  const onDialogOk = (e) => {
    const result = onOk();
    if (result instanceof Promise) {
      setLoading(true);
      result
        .then(() => {
          onClose(e, undefined);
          setLoading(false);
        })
        .catch(() => {
          // props.onClose();
          setLoading(false);
        });
    }
  };
  const getFooter = () => {
    if (props.hideFooter) {
      return null;
    }
    if (props.footer) {
      return props.footer;
    }
    return (
      <DialogActions sx={{ pr: props.footerPadding || 3 }}>
        {cancelBtnProps && (
          <Button autoFocus variant="text" {...cancelBtnProps} sx={{ mr: 2, height: 36 }} onClick={onClose as MouseEventHandler<HTMLButtonElement>}>
            {t(cancelBtnProps.text || "common.cancel")}
          </Button>
        )}
        {okBtnProps && (
          <LoadingButton variant="contained" autoFocus {...okBtnProps} sx={{ ml: "0 !important", height: 36 }} loading={loading} onClick={onDialogOk}>
            <span>{t(okBtnProps.text || "common.confirm")}</span>
          </LoadingButton>
        )}
      </DialogActions>
    );
  };
  return (
    <div>
      <BootstrapDialog
        className={className}
        PaperProps={PaperProps}
        maxWidth={size}
        sx={{ ...dialogSx, "& .MuiDialog-paper": { ...paperSx, borderRadius: 2 } }}
        // onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={fullWidth}
      >
        <BootstrapDialogTitle onClose={onClose as MouseEventHandler<HTMLButtonElement>} showClose={showClose} transparentBg={transparentTitleBg}>
          {t(title)}
        </BootstrapDialogTitle>
        <DialogContent dividers={props.showDivider} sx={{ px: contentPadding, py: "16px !important", maxHeight: props.maxHeight || null, ...sx }}>
          {/* Dialog Content Start*/}
          {children}
          {/* Dialog Content End*/}
        </DialogContent>
        {getFooter()}
      </BootstrapDialog>
    </div>
  );
}
