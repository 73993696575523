import { LocaleFile } from "../..";

const log: LocaleFile = {
  operationLog: "操作日志",
  securityLog: "安全日志",
  mqttLog: "MQTT日志",
  filter: "筛选",
  startTime: "开始时间",
  endTime: "结束时间",
  exportSelectedLogs: "导出选中日志",
  exportAllLogs: "导出全部日志",
  clearConditions: "清空条件",
  logout: "登出",
  create: "创建",
  update: "修改",
  operationType: "操作类型",
  moduleName: "模块名",
  view: "查看",
  ack: "确认",
  nack: "否定应答",
  issue: "下发",
  export: "导出",
  import: "导入",
  time: "时间",
  messages: "信息",
  user: "用户",
  expandDetails: "展开详情",
  foldDetails: "收起详情",
  userIp: "登录源IP",
  operationContent: "操作内容",
  eventLevel: "事件级别",
  logLevel: "日志级别",
  searchTask: "搜索任务",
  addTrackTask: "创建追踪任务",
  start: "启动",
  stop: "停止",
  taskName: "任务名称",
  selectDevice: "选择设备",
  taskConfig: "追踪任务配置",
  keyword: "关键字",
  eventType: "事件类型",
  warning: "警告",
  info: "信息",
  debug: "调试",
  error: "错误",
  timedTask: "定时任务",
  createTask: "创建任务",
  taskId: "任务ID",
  associatedDevices: "关联设备",
  isScheduledTask: "是否为定时任务",
  yes: "是",
  no: "否",
  noDevice: "未选择设备",
  creator: "创建人",
  running: "运行中",
  stopped: "已停止",
  units: "台",
  operationSuccess: "操作成功",
  refreshTime: "刷新时间",
  never: "从不",
  seconds: "秒",
  minute: "分钟",
  minutes: "分钟",
  pageLoading: "页面刷新中...",
  all: "全部",
  activatedTasks: "当前已激活任务数:",
  activatedTasksTip: "激活任务总数为租户下所有已激活任务，有可能与你所看到的数量不一致。",
  keywordTip: "请使用关键字来过滤你所需要跟踪的日志",
  httpPayload: "请求体",
  timeError: "时间错误",
  file: "文件",
  eventTypeTip: "请先选择事件级别",
};
export default log;
