import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { TreeDataNotype } from "../models/base.model";
import { Role, mockUsers, TenantInfo, UserInfo } from "../models/user.model";
import { navsWithoutPermission } from "../router/nav";

export interface SetUserTenantArg {
  userInfo: UserInfo;
  tenantInfo: TenantInfo;
}

export interface MainState {
  userInfo: UserInfo;
  collapsed: boolean;
  innerWidth: number;
  innerHeight: number;
  tenantInfo: TenantInfo;
  type: Role; // 用户的角色，等价于role，由于后端原因，改名为type
  // 获取用户信息是否完毕
  getUserInfoFinish: boolean;
  // 获取用户权限是否完毕
  getPermissionFinish: boolean;
  feedbackOpen: boolean;
  permissionTree: TreeDataNotype[];
  PermissionsAggregation: {
    firstPermissions: string[];
    secondPermissions: string[];
    btnPermissions: string[];
  };
}

const initialState: MainState = {
  userInfo: null,
  tenantInfo: null,
  type: null,
  collapsed: true,
  innerHeight: 0,
  innerWidth: 0,
  feedbackOpen: false,
  // 获取用户信息是否完毕
  getUserInfoFinish: false,
  getPermissionFinish: false,
  permissionTree: [],
  PermissionsAggregation: {
    firstPermissions: [],
    secondPermissions: [],
    btnPermissions: [],
  },
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    logout(state) {
      state.userInfo = null;
      state.permissionTree = [];
      state.PermissionsAggregation = {
        firstPermissions: [],
        secondPermissions: [],
        btnPermissions: [],
      };
      state.getUserInfoFinish = false;
      state.getPermissionFinish = false;
      state.feedbackOpen = false;
    },
    setRole(state, action: PayloadAction<Role | null>) {
      state.type = action.payload;
    },
    setUserInfo(state, action: PayloadAction<UserInfo>) {
      state.userInfo = { ...action.payload, additionalInfo: action.payload.additionalInfo ?? "{}" };
      state.type = action.payload?.type;
    },
    setPermissionTree(state, action: PayloadAction<TreeDataNotype[]>) {
      state.permissionTree = action.payload;
      const tree = action.payload;
      // 收集一级权限
      const firstPermissions: string[] = tree.map((f) => f.webIdentifier ?? "").filter((f) => f !== "");
      const secondPermissions: string[] = [];
      const btnPermissions: string[] = [];
      for (const first of tree) {
        const seconds = first.child;
        // 收集二级菜单权限
        secondPermissions.push(...seconds.map((s) => s.webIdentifier ?? "").filter((s) => s !== ""));
        for (const second of seconds) {
          const thirds = second.child;
          // 收集按钮权限
          btnPermissions.push(...thirds.map((t) => t.webIdentifier ?? "").filter((t) => t !== ""));
        }
      }
      state.PermissionsAggregation = {
        firstPermissions,
        secondPermissions: [...secondPermissions, ...navsWithoutPermission],
        btnPermissions,
      };
    },
    lockUser(state) {
      state.userInfo && (state.userInfo.locked = true);
    },
    unlockUser(state) {
      state.userInfo && (state.userInfo.locked = false);
    },
    setAdditionalInfo(state, action: PayloadAction<string>) {
      state.userInfo && (state.userInfo.additionalInfo = action.payload);
    },
    setWindowSize(state, action: PayloadAction<{ innerWidth?: number; innerHeight?: number }>) {
      Object.assign(state, action.payload);
    },
    setUserFetchStatus(state, action: PayloadAction<boolean>) {
      state.getUserInfoFinish = action.payload;
    },
    setPermissionFetchStatus(state, action: PayloadAction<boolean>) {
      state.getPermissionFinish = action.payload;
    },
    setFeedbackOpen(state, action: PayloadAction<boolean>) {
      state.feedbackOpen = action.payload;
    },
    changeCollapsed(state) {
      state.collapsed = !state.collapsed;
    },
  },
});

export const { setPermissionTree, logout, setRole, setUserInfo, setWindowSize, setUserFetchStatus, setPermissionFetchStatus, setAdditionalInfo, changeCollapsed, lockUser, unlockUser, setFeedbackOpen } =
  mainSlice.actions;

export const selectPermissionTree = (state: RootState) => state.main.permissionTree;

// 根据权限树生成的三级权限集合
// 侧边栏菜单中渲染：需要做不涉及权限处理的路由在这里处理
export const selectPermissionsAggregation = (state: RootState) => state.main.PermissionsAggregation;

export const selectCurrentUser = (state: RootState) => state.main.userInfo;

export const selectCollapsed = (state: RootState) => state.main.collapsed;

export const selectRole = (state: RootState) => state.main.type;

export const selectWindowSize = (state: RootState) => {
  const { innerHeight, innerWidth } = state.main;
  return { innerHeight, innerWidth };
};

export const isMobile = (state: RootState) => state.main.innerWidth < 980;

export const isXMobile = (state: RootState) => state.main.innerWidth < 500;

export const selectUserLoginStatus = (state: RootState) => !!state.main.userInfo || true;

export const selectIsLocked = (state: RootState) => state.main.userInfo?.locked === true;

export const selectLanguage = (state: RootState) => JSON.parse(state.main.userInfo?.additionalInfo ?? "{}").language || navigator.language;

export const selectLockTimeout = (state: RootState) => state.main.userInfo?.lockTimeout ?? null;

export const selectNavMenuWidth = (state: RootState) => {
  return state.main.collapsed ? 224 : 72;
};

export const selectFeedbackOpen = (state: RootState) => state.main.feedbackOpen;

export default mainSlice.reducer;
