import { Alert, AlertProps, Box, Snackbar, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { TSnackBarMessage, removeSnackBarMsg, selectSnackBarMessage } from "../../../store/toolSlice";
import Iconfont from "../tools/Iconfont";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep } from "lodash";

export interface MatSnackBarProps {
  duration: number;
  type: AlertProps["severity"];
}
export default function MatSnackBar() {
  const dispatch = useDispatch();
  const message = useSelector(selectSnackBarMessage);
  const { palette } = useTheme();
  const [offsets, setOffsets] = useState([]);
  const refs = useRef([]);
  const [messagesSet, setMessagesSet] = useState<TSnackBarMessage[]>([]);
  useEffect(() => {
    // 计算每个Snackbar的高度，并累加设置偏移量
    const tempOffsets = [24]; // 假设第一个Snackbar距离底部为24px
    setMessagesSet((pre) => {
      const clone = cloneDeep(pre);
      if (clone.findIndex((c) => c.content === message.content && c.type === message.type) > -1) {
        return pre;
      }
      clone.push(message);
      clone.forEach((msg, index) => {
        if (index > 0) {
          // 获取当前Snackbar的DOM元素
          const el = refs.current[index - 1];
          // 计算当前Snackbar的偏移量，并添加到临时数组中
          tempOffsets.push(tempOffsets[index - 1] + 4 + el?.offsetHeight ?? 0); // 每条msg间隔4px
        }
      });
      setOffsets(tempOffsets);
      return clone;
    });
  }, [message]);

  const iconMapping = useMemo(() => {
    return {
      error: <Iconfont icon="ic_defeat" mr={0} color={palette.error.main} />,
      warning: <Iconfont icon="ic_alert" mr={0} color={palette.warning.main} />,
      info: <Iconfont icon="ic_alert" mr={0} color={palette.info.main} />,
      success: <Iconfont icon="ic_success" mr={0} color={palette.success.main} />,
    };
  }, [palette.error.main, palette.info.main, palette.success.main, palette.warning.main]);

  const onSnackbarClose = useCallback((id: number) => {
    setMessagesSet((pre) => {
      const clone = cloneDeep(pre);
      const idx = clone.findIndex((c) => c.id === id);
      clone.splice(idx, 1);
      return clone;
    });
  }, []);

  return (
    <Box sx={{ position: "fixed", bottom: 0, right: 24, zIndex: 9999998 }}>
      {messagesSet?.map((msg, index) => {
        return (
          <Snackbar
            key={msg.id}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={true}
            onClose={() => onSnackbarClose(msg.id)}
            autoHideDuration={msg.duration * 1000}
            className="border-box"
            sx={{ position: "absolute", bottom: `${offsets[index]}px !important` }}
            ref={(el) => (refs.current[index] = el)}
          >
            <Alert severity={msg.type} iconMapping={iconMapping} sx={{ bgcolor: "#fff", width: 360, boxShadow: "0px 4px 10px 0px #000A111A" }}>
              <Typography sx={{ lineHeight: "22px" }}>{t(msg.content) || msg.content}</Typography>
            </Alert>
          </Snackbar>
        );
      })}
    </Box>
  );
}
