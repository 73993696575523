import { configureStore } from "@reduxjs/toolkit";
import configurationReducer from "./configurationSlice";
import counterReducer from "./counterSlice";
import mainReducer from "./mainSlice";
import toolReducer from "./toolSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    main: mainReducer,
    tool: toolReducer,
    configuration: configurationReducer,
  },
  middleware(getDefault) {
    return getDefault({ serializableCheck: false });
  },
});
// export const selectDtlsTitle = (state: Store) => state.config.detailsTitle;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
