const user = {
  email: "Email",
  name: "Name",
  surname: "Surname",
  sms: "SMS",
  description: "Description",
  phoneNumber: "Phone Number",
  lastLogin: "Last Login",
  changePwd: "Change Password",
  deleteUserTitle: "Are you sure to delete the user? ",
  deleteUserGroupTitle: "Are you sure to delete the user group? ",
  password: "Password",
  currentPassword: "Current Password",
  newPassword: "New Password",
  passwordConfirm: "Confirm Password",
  pwdMin8: "The password length cannot be less than 8 characters",
  pwdNotSame: "The passwords entered twice are inconsistent",
  pwdRuleSetting: "Password Rule Setting",
  pwdLabels: {
    lowercaseLettersRequired: "Password must contain lowercase letters",
    lowercaseLetters: "Number of lowercase letters (1-32)",
    uppercaseLettersRequired: "Password must contain uppercase letters",
    uppercaseLetters: "Number of uppercase letters (1-32)",
    digitsRequired: "Password must contain digits",
    digits: "Number of digitals (1-32)",
    specialCharactersRequired: "Password must contain special characters",
    specialCharacters: "Number of special characters (1-32)",
    repeatingCharactersRequired: "Restrict the number of repeating characters",
    repeatingCharacters: "Maximum number of repeating characters (1-32)",
    consecutiveCharactersRequired: "Restrict the number of consecutive repeating characters",
    consecutiveCharacters: "Maximum number of consecutive repeating characters (1-32)",
    associated: "New password must not contain the Username, Name, Surname with which they are associated",
    dictionary: "Password must not contain any dictionary words",
    minimumLengthRequired: "Enforce minimum password length",
    maximumLengthRequired: "Enforce maximum password length",
    minimumLength: "Number of characters (8-32)",
    maximumLength: "Number of characters (8-32)",
    expiration: "Number of days password is valid",
    expirationTip: "Sets the maximum number of days that a password is valid (1-180)",
    expirationWarning: "Number of days to password expiration warning",
    expirationWarningTip: "Sets number of days to display the expiration warning before password expiration (0-14)",
    accountLockout: "Account Lockout",
    failedAttempts: "Maximum failed login attempts",
    failedAttemptsTip: "Maximum number of failed login attempts (up to 10) before an account gets locked out",
    isReset: "Failed attempts count will be reset automatically after",
    resetTip: "Failed attempts count is always reset after the next successful login. Optionally it can also be reset automatically after a specified period of time (from 1 to 60 minutes)",
    isLocked: "Account will be locked out temporarily for",
    lockedTip: "A locked account can always be unlocked by the administrator at any time. Optionally it can be locked out only temporarily (from 1 to 60 minutes)",
    accountTimeout: "Timeout",
    timeoutTip: "Account will be automatically lock screen after a set period of time.",
  },
  editPolicySuccessed: "Modification successful, please log in again",
  status: "User enable status",
  profile: "Personal Settings",
  logout: "Logout",
  basicInfo: "Basic Information",
  accountSecurity: "Account Security",
  basicOrgInfoErrors: {
    name: "Please enter an organization name",
    phone: "Please enter your contact information",
    city: "Please enter a city",
    address: "Please enter an address",
    invalidEmail: "Please enter a valid email",
    email: "Please enter your email",
    zip: "Please enter zip code",
  },
  loginTitle: "Please Login",
  rememberPwd: "Remember Password",
  forgotPwd: "Forgot Password",
  loginTipText: "Already have an account?",
  loginTipButton: "Log in",
  registSuccess: "Registration is successful, please log in with your account",
  registerPageDesc: "Signing up for BlueSphere MDM to control the configuration, system status and mission-critical tasks of mobile devices.",
  fillInfo: "Fill information",
  setPwd: "Set password",
  registerTitle: "Register your account",
  portalHolder: "your portal",
  signupErrors: {
    domain: "Domain name is required",
    invalidEmail: "Email is required",
    email: "email is required",
    firstName: "First name is required",
    lastName: "Last name is required",
    name: "Organization name is required",
    password: "Password is required",
    mobile: "Mobile is required",
    passwordMoreThan6: "Password's length must be more than 6",
    passwordConfirm: "Please confirm your password",
    passwordNotSame: "The two entered passwords do not match",
  },
  firstName: "First Name",
  lastName: "Last Name",
  register: "Register",
  registerTipText: "No account yet?",
  registerTipButton: "Register now",
  orgInfoKeys: {
    name: "Organization name",
    phone: "Contact information",
    country: "Country",
    address: "Organization address",
    email: "E-mail",
    code: "Organize short codes",
    city: "City",
    zip: "Zip code",
  },
  login: "Login",
  loginToUnlock: "Login to unlock",
  loginPageDesc: "",
  reset: "Reset",
  resetPassword: "Reset Password",
  inputAccountEmail: "Please enter the account email address",
  checkEmailNotExist: "This email does not exist in BlueSphere, please check and re-enter",
  sendResetEmailSuccessed: "The reset password email has been sent, please check your email",
  passwordNotSame: "The new password entered twice is inconsistent",
  resetPwdSuccessedTip: "Password reset successfully, please log in",
  createAdminErrors: {
    email: "Please enter your email address correctly",
    password: "Please enter a password",
    emailNotValid: "Email is not invalid",
    passwordMin8: "Password length muster be larger than 8",
    passwordConfirm: "Please confirm the password",
  },
  loginSuccessed: "Login Successfully",
  loginFailed: "Login failed",
  lock: "Lock",
  unlockSuccessed: "Unlock Successfully",
  unlockFailed: "Password error",
  noPermissionForThisPage: "You don't have permission for this page",
  pwdRuleNumberMin0: "Must be larger than 0",
  welcomeBack: "Welcome Back",
  switchAccount: "Switch Account",
  passwordComplexity: "Password Complexity",
  accountLockout: "Account Lockout",
  accountTimeout: "Account Timeout",
  lengthError: "Illegal length",
  language: "Language",
  about: "About",
  productInfo: "Product Information",
  GWMVersion: "GWM Version",
  userGroupSearchPlaceholder: "Search user groups",
  Feedback: "Feedback",
  feedback: {
    security: "Security",
    interactive: "Interactive",
    functionality: "Functionality",
    dataRelated: "Data-related",
    uploadPictures: "Upload Pictures",
    descriptionPlaceholder: "Please enter the your feedback, up to {{data}} characters",
    uploadTo: "Upload up to {{data}} pictures",
    sendSuccess: "Feedback sent successfully!",
    sendError: "Feedback sent failed!",
    moreOptions: "More Options",
    recentAlarms: "Recent 10 Alarms",
    operationLogs: "Operation logs",
    recentDeviceReports: "Recent Device Reports",
  },
  checkTenantPwdFalse: "The administrator and password do not match, please check your administrator and password and try again!",
  devices: "Devices",
  groups: "Groups",
  passwordExpirationTitle: "Password Expiration",
  passwordExpirationContent: "The password is only valid for {{days}}, please change the password in time",
  changePasswordSuccess: "change password successfully, please login again!",
};

export default user;
