import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Box, Divider, PopoverOrigin, SxProps, Typography } from "@mui/material";
import { OperationMenu } from "../../../models/base.model";
import Iconfont from "../tools/Iconfont";
import { StyledMenu } from "../styled/StyledMenu";
import TextButton from "../styled/TextButton";
import EmptyData from "../table/EmptyData";
import { useTranslation } from "react-i18next";
import { isUndefined } from "lodash";
import HtmlTooltip from "./HtmlTooltip";

export const anchorOriginDefault: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right",
};
export const transformOriginDefault: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
/**
 *
 * @interface MatDropdownProps
 * @template T menu的Action的类型
 */
export interface MatDropdownProps<T> {
  menus?: OperationMenu<T>[];
  selected?: T;
  onMenuClick?: (action: T) => void;
  // dropDown组件的触发组件  可以自定义 如果不自定义就是默认的TextButton（在tsx里面可以看到）
  trigger?: React.ReactNode;
  dividerKeys?: T[];
  sx?: SxProps;
  disabled?: boolean;
  open?: boolean;
  unsetWidth?: boolean;
  triggerIcon?: string;
  onTrigger?: (...rest: T[]) => void | Promise<void | any>;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  showMenus?: boolean;
  menuSx?: SxProps;
  menuItemSx?: SxProps;
}

/**
 *
 * @export
 * @template T menu的Action的类型
 * @param {MatDropdownProps<T>} props
 * @returns
 */
export default function MatDropdown<T>(props: React.PropsWithChildren<MatDropdownProps<T>>) {
  const {
    menus,
    open = true,
    showMenus = true,
    unsetWidth = false,
    triggerIcon = "ic_more",
    onTrigger = () => {},
    anchorOrigin = anchorOriginDefault,
    transformOrigin = transformOriginDefault,
    menuSx,
    menuItemSx = {},
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const computedOpen = Boolean(anchorEl) && open;
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!props.disabled) {
      const result = onTrigger();
      if (result instanceof Promise) {
        const currentTarget = event.currentTarget;
        result.then(() => {
          setAnchorEl(currentTarget);
        });
      } else {
        setAnchorEl(event.currentTarget);
      }
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMenuClick = (e) => {
    if (props.onMenuClick) {
      props.onMenuClick(e);
    }
    handleClose();
  };

  return (
    <Box sx={{ ...props.sx }}>
      {props.trigger ? (
        <Box onClick={handleClick}>{props.trigger}</Box>
      ) : (
        // 如果没有trigger这个props就用默认的TextButton
        <TextButton size="small" disabled={props.disabled} onClick={handleClick} sx={{ width: 32, height: 32, minWidth: "auto", borderRadius: 2, px: 1 }}>
          {/* <MoreVertIcon sx={{ transform: "rotate(90deg)", color: (theme) => theme.palette.action.active }} /> */}
          <Iconfont icon={triggerIcon} mr={0} />
          {/* <Iconfont icon="ic_more" mr={0}></Iconfont> */}
        </TextButton>
      )}
      {!props.disabled && (
        <StyledMenu
          sx={{ maxHeight: 450, "& .MuiPaper-root": { minWidth: unsetWidth ? "unset" : undefined }, ...menuSx }}
          anchorEl={anchorEl}
          open={computedOpen}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          {props.children}
          {showMenus &&
            menus?.length > 0 &&
            menus?.map((menu, index) => (
              <Box key={index}>
                <MenuItem disabled={menu.disabled} sx={{ p: 1, ...menuItemSx }} onClick={() => onMenuClick(menu.action)} selected={props.selected === menu.action} disableRipple>
                  {menu.customStartComponent}
                  {menu.icon && <Iconfont fontSize={24} icon={menu.icon} />}
                  <Typography sx={{ mx: 1 }}>{t(menu.title)}</Typography>
                  {menu.toolTip && (
                    <HtmlTooltip
                      title={
                        <Typography fontSize={12} lineHeight="20px" color="#fff">
                          {t(menu.toolTip)}
                        </Typography>
                      }
                      placement="top"
                    >
                      <Box>
                        <Iconfont fontSize={14} icon="ic_info" mr={0} />
                      </Box>
                    </HtmlTooltip>
                  )}
                </MenuItem>
                {menu.showDivider && <Divider sx={{ m: "0 !important" }} />}
              </Box>
            ))}
          {showMenus && menus?.length === 0 && <EmptyData pt={0} />}
        </StyledMenu>
      )}
    </Box>
  );
}
