import { LocaleFile } from "../..";

const topology: LocaleFile = {
  MAP_VIEW: "Map View",
  LINK_VIEW: "Link View",
  LIST_VIEW: "List View",
  headers: {
    placeholder: "Device name, SN",
    custom: "CUSTOM",
    auto: "AUTO",
  },
  switchSIM: {
    success: "SIM card changed successfully!",
    tip: "Whether to change the current SIM card settings?",
  },
  map: {
    deviceInfo: "Device Info",
    deviceList: "Device List",
    mapViewedAndTotal: "Map viewed/Total",
  },
  dragDeviceTip: "Drag this icon onto the map to set the device's latitude and longitude.",
  linkRootNode: "Gateways",
  removeDeviceLocationTitle: "Remove Device",
  removeDeviceLocationContent: "This operation will remove the device from the map, Are you sure to continue?",
  removeSuccessed: "Remove successed!",
};
export default topology;
