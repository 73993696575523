import { Box } from "@mui/material";
import { useFormik } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { $message, Yup } from "../../utils";
import MatDialog, { MatDialogProps } from "../common/mui/MatDialog";
import MatPassword from "../common/mui/MatPassword";

const dialogSx = {
  // 隐藏关闭按钮
  "& h2 .MuiButtonBase-root": {
    display: "none",
    pointerEvents: "none",
  },
  // 隐藏取消按钮
  "& .MuiDialogActions-root .MuiButton-text": {
    display: "none",
    pointerEvents: "none",
  },
};

interface ChangeUserPwdProps extends MatDialogProps {
  forceChangePwd?: boolean;
}

const initialValues = {
  password: "",
  newPassword: "",
  passwordConfirm: "",
};

export type PasswordConfigParams = typeof initialValues;

export default function ChangeUserPwdDialog(props: ChangeUserPwdProps) {
  const { t } = useTranslation();
  const { onOk, onClose, forceChangePwd = false } = props;

  const formik = useFormik({
    initialValues: { ...initialValues },
    validateOnMount: true,
    validationSchema: Yup.object({
      newPassword: Yup.string().required(t("common.requiredFields")).min(8, t("user.pwdMin8")),
      passwordConfirm: Yup.string()
        .required(t("common.requiredFields"))
        .min(8, t("user.pwdMin8"))
        .when("newPassword", {
          is: (value: string) => {
            return value?.length >= 8;
          },
          then: (schema) => schema.equals([formik.values.newPassword], t("user.pwdNotSame")),
          otherwise: (schema) => schema.optional(),
        }),
    }),
    onSubmit(values, formikHelpers) {},
  });

  const getFieldProps = useCallback(
    (key: keyof typeof initialValues) => {
      return {
        ...formik.getFieldProps(key),
        label: "user." + key,
        error: formik.errors[key] && formik.touched[key],
        helperText: formik.touched[key] && formik.errors[key],
      };
    },
    [formik]
  );

  const handleOk = useCallback(() => {
    formik.handleSubmit();
    if (formik.isValid && formik.dirty) {
      return onOk(formik.values);
    }
  }, [formik, onOk]);

  const handleClose = useCallback(() => {
    if (forceChangePwd) return;
    formik.resetForm();
    // @ts-ignore
    onClose && onClose();
  }, [forceChangePwd, formik, onClose]);
  if (forceChangePwd) {
    return (
      <MatDialog
        size="xs"
        {...props}
        onOk={handleOk}
        onClose={() => {}}
        dialogSx={dialogSx}
        okBtnProps={{
          text: t("common.apply"),
        }}
      >
        <Box sx={{ px: 1 }}>
          <MatPassword sx={{ mb: 2 }} {...getFieldProps("newPassword")}></MatPassword>
          <MatPassword sx={{ mb: 2 }} {...getFieldProps("passwordConfirm")}></MatPassword>
        </Box>
      </MatDialog>
    );
  }
  return (
    <MatDialog size="xs" {...props} onOk={handleOk} onClose={handleClose}>
      <Box sx={{ px: 1 }}>
        <MatPassword sx={{ mb: 2 }} {...getFieldProps("newPassword")}></MatPassword>
        <MatPassword sx={{ mb: 2 }} {...getFieldProps("passwordConfirm")}></MatPassword>
      </Box>
    </MatDialog>
  );
}
