import { alpha, Box, Stack, Step, StepButton, Stepper, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MatPassword from "../components/common/mui/MatPassword";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { SignupParams } from "../models/login.model";
import bluesphere_logo from "../assets/imgs/bluesphere_logo.svg";
import MatInput from "../components/common/mui/MatInput";
import CustomLink from "../components/common/tools/CustomLink";
import { Fragment, useCallback, useState } from "react";
import PhoneNumberInput from "../components/common/mui/PhoneNumberInput";
// import MatInputWithExtraParam from "../components/common/mui/MatInputWithExtraParam";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { $message } from "../utils";
import { userController } from "../controllers/user.controller";
import login_bg from "../assets/imgs/login_bg.png";

const currentYear = new Date().getFullYear();

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      height: "calc(100% - 112px)",
      // backgroundColor: alpha(theme.palette.primary.main, 0.08),
    },
    registerContainer: {
      height: "100%",
      zIndex: 1,
    },
    registerContent: {
      borderRadius: 8,
      margin: 0,
      padding: "0 40px",
      width: 480,
      maxWidth: "100%",
      backgroundColor: theme.custom?.commonBg,
    },
    registerTitle: {
      color: theme.palette.primary.main,
      letterSpacing: 1.5,
    },
    infoContainer: {
      width: "100%",
      height: "100%",
      position: "relative",
      backgroundColor: theme.palette.primary.main,
      padding: "48px",
      boxSizing: "border-box",
      "& .sign-up-ng": {
        position: "absolute",
      },
    },
    translucenceImg: {
      right: -68,
      width: 480,
      bottom: -120,
      position: "absolute",
    },
  };
});

enum RegistSteps {
  FILL_INFO = 0,
  SET_PWD = 1,
}
const steps = [
  { label: "user.fillInfo", key: RegistSteps.FILL_INFO },
  { label: "user.setPwd", key: RegistSteps.SET_PWD },
];

const initialValues: SignupParams = {
  // domainName: "",
  companyName: "",
  phone: "",
  email: "",
  password: "",
  country: "+1",
  userName: "",
  surname: "",
};

export default function SignUpPage() {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(RegistSteps.FILL_INFO);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate("/login");
  };

  const generateRequiredText = useCallback(
    (key: string) => {
      return t("user.signupErrors." + key);
    },
    [t]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      // domainName: Yup.string().max(24, "user.portal24").required(generateRequiredText("domain")),
      email: Yup.string().email(generateRequiredText("invalidEmail")).max(255).required(generateRequiredText("email")),
      userName: Yup.string().required(generateRequiredText("firstName")),
      surname: Yup.string().required(generateRequiredText("lastName")),
      companyName: Yup.string().required(generateRequiredText("name")),
      phone: Yup.string().required(generateRequiredText("mobile")),
      // password: Yup.string().max(255).min(5, "At least 5 letters required").required(generateRequiredText("password")),
    }),
    validateOnMount: true,
    onSubmit: (values) => {
      const result = new Promise<void>((resolve, reject) => {
        resolve();
      });
      return result;
    },
  });

  const passworfForm = useFormik({
    initialValues: { password: "", passwordConfirm: "" },
    validationSchema: Yup.object({
      password: Yup.string().max(255).min(6, generateRequiredText("passwordMoreThan6")).required(generateRequiredText("password")),
      passwordConfirm: Yup.string().max(255).min(6, generateRequiredText("passwordMoreThan6")).required(generateRequiredText("passwordConfirm")),
    }),
    validateOnMount: true,
    onSubmit: (values) => {
      return new Promise<void>((resolve, reject) => {
        if (values.password !== values.passwordConfirm) {
          $message.error("user.signupErrors.passwordNotSame");
          reject();
        } else {
          const param = formik.values;
          param.password = values.password;
          const { country, ...submitParam } = param;
          userController
            .signup({
              ...submitParam,
              phone: country + " " + submitParam.phone,
            })
            .then(() => {
              $message.success("user.registSuccess");
              navigateToLogin();
              resolve();
            })
            .catch((err) => {
              if (err?.data?.message) {
                $message.error(err.data.message);
              }
              reject();
            });
        }
      });
    },
  });

  const onCountryChange = (country: string) => {
    formik.setFieldValue("country", country);
  };

  const setPassword = () => {
    formik.handleSubmit();
    formik.validateForm().then(() => {
      if (formik.isValid) {
        setCurrentStep(RegistSteps.SET_PWD);
      }
    });
  };

  const doSignup = () => {
    passworfForm.handleSubmit();
  };

  const handleStep = (key: RegistSteps) => () => {
    if (key === currentStep) {
      return;
    }
    if (key === RegistSteps.SET_PWD) {
      setPassword();
      return;
    }
    setCurrentStep(key);
  };

  return (
    <Box className="flex" sx={{ height: 1 / 1, flexDirection: "column" }}>
      <Box className="flex-start border-box" sx={{ px: 10, width: 1 / 1, height: 64, bgcolor: "#fff" }}>
        <img height={48} src={bluesphere_logo} alt="" />
      </Box>
      <Box className={classes.container + " flex"} sx={{ width: 1 / 1, position: "relative" }}>
        <Box component={"img"} src={login_bg} alt="" sx={{ width: "calc(100% - 480px)", height: "calc(100vh - 112px)" }}></Box>
        <Box className={classes.registerContainer + " border-box flex-column flex-v-center"}>
          {/* 步骤条 */}
          <Stepper sx={{ width: 360, my: { xs: 2, md: 4, lg: 8 }, maxWidth: "100%" }} nonLinear activeStep={currentStep}>
            {steps.map(({ label, key }) => (
              <Step key={label} completed={currentStep >= key}>
                <StepButton color="inherit" onClick={handleStep(key)}>
                  {t(label)}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <Box className={classes.registerContent + " border-box"}>
            <Stack spacing={3} width={1} alignItems={"stretch"}>
              <Typography className={classes.registerTitle} variant="h5">
                {t("user.registerTitle")}
              </Typography>
              {currentStep === RegistSteps.FILL_INFO && (
                <Fragment>
                  <Stack direction={"row"} spacing={3}>
                    <Stack alignItems={"flex-start"}>
                      {/* 名 */}
                      <Typography variant="label">{t("user.firstName")}</Typography>
                      <MatInput
                        sx={{ mt: 0.5 }}
                        matstyle="true"
                        {...formik.getFieldProps("userName")}
                        error={Boolean(formik.touched.userName && formik.errors.userName)}
                        helperText={formik.touched.userName && formik.errors.userName}
                      />
                    </Stack>
                    <Stack alignItems={"flex-start"}>
                      {/* 姓 */}
                      <Typography variant="label">{t("user.lastName")}</Typography>
                      <MatInput
                        sx={{ mt: 0.5 }}
                        matstyle="true"
                        {...formik.getFieldProps("surname")}
                        error={Boolean(formik.touched.surname && formik.errors.surname)}
                        helperText={formik.touched.surname && formik.errors.surname}
                      />
                    </Stack>
                  </Stack>
                  <Box>
                    {/* 组织名称 */}
                    <Typography variant="label">{t("user.orgInfoKeys.name")}</Typography>
                    <MatInput
                      sx={{ mt: 0.5 }}
                      width={400}
                      matstyle="true"
                      {...formik.getFieldProps("companyName")}
                      error={Boolean(formik.touched.companyName && formik.errors.companyName)}
                      helperText={formik.touched.companyName && formik.errors.companyName}
                    />
                  </Box>
                  <Box>
                    {/* 邮箱 */}
                    <Typography variant="label">{t("user.email")}</Typography>
                    <MatInput
                      sx={{ mt: 0.5 }}
                      width={400}
                      matstyle="true"
                      {...formik.getFieldProps("email")}
                      error={Boolean(formik.touched.email && formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Box>
                  <Box>
                    {/* 电话 */}
                    <Typography variant="label">{t("user.phoneNumber")}</Typography>
                    <PhoneNumberInput
                      sx={{ mt: 0.5 }}
                      inputProps={{
                        ...formik.getFieldProps("phone"),
                        error: Boolean(formik.touched.phone && formik.errors.phone),
                        helperText: formik.touched.phone && formik.errors.phone,
                      }}
                      onCountryChange={onCountryChange}
                    ></PhoneNumberInput>
                  </Box>
                  {/* 下一步 */}
                  <LoadingButton sx={{ height: 36 }} loading={formik.isSubmitting} fullWidth onClick={setPassword} variant="contained">
                    <span>{t("common.nextStep")}</span>
                  </LoadingButton>
                </Fragment>
              )}
              {currentStep === RegistSteps.SET_PWD && (
                <Fragment>
                  <Box>
                    {/* 邮箱 */}
                    <Typography display={"inline-block"} variant="label">
                      {t("user.password")}
                    </Typography>
                    <MatPassword
                      sx={{ mt: 0.25 }}
                      width={400}
                      {...passworfForm.getFieldProps("password")}
                      error={Boolean(passworfForm.touched.password && passworfForm.errors.password)}
                      helperText={passworfForm.touched.password && passworfForm.errors.password}
                      // label="user.password"
                    ></MatPassword>
                  </Box>
                  <Box>
                    {/* 邮箱 */}
                    <Typography variant="label">{t("user.passwordConfirm")}</Typography>
                    <MatPassword
                      sx={{ mt: 0.25 }}
                      width={400}
                      {...passworfForm.getFieldProps("passwordConfirm")}
                      error={Boolean(passworfForm.touched.passwordConfirm && passworfForm.errors.passwordConfirm)}
                      helperText={passworfForm.touched.passwordConfirm && passworfForm.errors.passwordConfirm}
                      // label="user.passwordConfirm"
                    ></MatPassword>
                  </Box>
                  <LoadingButton sx={{ height: 36 }} loading={passworfForm.isSubmitting} fullWidth onClick={doSignup} variant="contained">
                    <span>{t("user.register")}</span>
                  </LoadingButton>
                </Fragment>
              )}
              <Box sx={{ mt: 2, width: 1 }} className="flex">
                <Typography sx={{ mr: 1 }} variant="body1">
                  {t("user.loginTipText")}
                </Typography>
                <CustomLink variant={"body1"} underlineOnHover={false} onClick={navigateToLogin}>
                  {t("user.loginTipButton")}
                </CustomLink>
              </Box>
            </Stack>
          </Box>
        </Box>
        {/* 右侧信息 */}
        {/* <Box className={classes.infoContainer + " sign-up-info-container"} sx={{ display: { xs: "none", lg: "block" } }}>
          <Box sx={{ position: "absolute", top: 210, px: 8, zIndex: 1 }}>
            <img width={320} src={whiteLogo} alt="" />
            <Typography lineHeight={1.5} fontSize={16} color="#fff" sx={{ mt: 4.5 }}>
              {t("user.registerPageDesc")}
            </Typography>
          </Box>
          <img className="sign-up-ng" src={signupBg} style={{ top: "-18%" }} width={"50%"} height={"50%"} alt="" />
          <img className="sign-up-ng" src={signupBg} style={{ top: "10%", left: "60%" }} width={"50%"} height={"50%"} alt="" />
          <img className="sign-up-ng" src={signupBg} style={{ top: "35%" }} width={"50%"} height={"50%"} alt="" />
          <img className="sign-up-ng" src={signupBg} style={{ top: "65%", left: "60%" }} width={"50%"} height={"50%"} alt="" />
        </Box> */}
      </Box>
      {/* footer */}
      <Box sx={{ width: 1 / 1, height: 48, bgcolor: "#fff" }} className="flex flex-wrap">
        <Typography sx={{ mr: 3 }} variant="subtitle1">
          {`© ${currentYear} Vantron. All rights reserved.`}
        </Typography>
      </Box>
    </Box>
  );
}
