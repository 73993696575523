import {
  DeviceConfigurationInfo,
  VPTDriverDireaction,
  VPTDriverType,
  VPTDriversConfigDescription,
  PlcChannelPointStatusModel
} from "../models/configuration.model";

export const mockConfiguration: DeviceConfigurationInfo = {
  protocol: {
    output_groups: { "123": { title: "", description: "13" }, test: { title: "", description: "1" } },
    plc_daemon: {
      "?log_level": "-1:NONE, 0: FATAL, 1:ERROR, 2:WARNING, 3: INFO, 4:DEBUG, 5:TRACE",
      log_level: 3,
      file_log_level: 1,
      file_log_size: 1000000,
      file_log_max_count: 10,
      web_log_level: 3,
      show_duplicated_log: true,
      login_required: true,
      language: "en_US",
      "?language": "en_US, zh_CN",
      edge_compute: {
        JerryScript: {
          heap_size: 1024,
          "?heap_size": "heap size in kB, upper limit 16M",
        },
        MicroPython: {
          heap_size: 1024,
          "?heap_size": "heap size in kB, upper limit 16M",
        },
      },
      file_log_size_k: 976.5625,
    },
    function_blocks: {
      channel_ab: {
        description: "ab",
        callup_interval: 100,
        driver_type: VPTDriverType.south,
        driver: "ablogix_s",
        enabled: true,
        driver_settings: {
          communication: {
            timeout_ms: 5000,
            serial: {},
            tcp: {},
            communication_type: "ablogix_s",
            service_type: "master",
          },
          inputs: {},
          outputs: {
            "ab-plc": {
              point_table: [
                ["test-int", "ii", "description", "int16", "ro", 1, "default", "1", "", "0", null, "default_group", null, "m³"],
                ["test-bool", "bb", "description", "bool", "ro", 1, "default", "11", "", "0", null, "default_group"],
              ],
              use_json: true,
              inputs: { write_data: { enabled: false, source: "" } },
              device_name: "ab-plc",
              enabled: true,
              interval_ms: 1000,
              address: "192.168.11.1",
              port: 44818,
              path: "1,0",
              plc: "ControlLogix",
            },
          },
          output_groups: {},
        },
        config_version: 1,
        channel_name: "channel_ab",
        driver_direction: VPTDriverDireaction.south,
      },
      compute_c: {
        driver_type: VPTDriverType.compute,
        driver: "compute_c",
        enabled: true,
        channel_name: "compute_c",
        driver_settings: {
          communication: {},
          outputs: {
            ee: {
              inputs: {
                aaa: { source: "modbus_protocol/pump_room_data/23050144_LH01" },
                bbb: { source: "modbus_protocol/pump_room_data/23050144_LH00" },
              },
              point_table: [["12345678_123", "12345678_123", null, "float", 0, null, null, "", null, null, null, null]],
              execute: {
                engine: "javascript",
                code: "// ECMAScript 5.1\n// https://262.ecma-international.org/5.1/\n\nconsole.log(Global);\n12345678_123 = aaa + bbb;",
                reuse_engine: true,
                max_reuse_count: 100,
                cache_raw_data: false,
                direct_output_point: "",
              },
              use_json: true,
              enabled: true,
              execute_strategy: "timed_execution",
              interval_ms: 1000,
            },
          },
        },
        config_version: 2,
        executeDetails: {},
      },
      modbus_protocol: {
        description: "Modbus通道采集",
        callup_interval: 100,
        driver_type: VPTDriverType.south,
        driver: "modbus_s",
        enabled: true,
        driver_settings: {
          communication: {
            timeout_ms: 5000,
            serial: {
              ascii_mode: 0,
              port: "COM4",
              mode: "rs485",
              baudrate: 9600,
              data_bits: 8,
              parity: "N",
              stop_bits: 1,
              rts: 0,
            },
            tcp: {},
            communication_type: "serial",
            service_type: "master",
          },
          inputs: {},
          outputs: {
            pump_room_data: {
              point_table: [
                ["23050144_LH00", "Infrared alarm", "reg1", "uint16", "rw", 1, "holding", 1, "hl", "0", null, "default_group", null, "℃"],
                ["23050144_LH01", "摄像报警", "reg1", "uint16", "ro", 1, "holding", 2, "hl", "0", null, "default_group", null, "V"],
                ["23050144_LH02", "门磁反馈", "reg1", "uint16", "ro", 1, "holding", 3, "hl", "0", null, "default_group", null, "Kg"],
                ["23050144_LH03", "模块反馈", "reg1", "uint16", "ro", 1, "holding", 4, "hl", "0", null, "default_group", null, "t/h"],
                ["23050144_LH04", "布防开关", "reg1", "uint16", "ro", 1, "holding", 5, "hl", "0", null, "default_group", null, "℃"],
                ["23050144_LH05", "烟感报警", "reg1", "uint16", "ro", 1, "holding", 6, "hl", "0", null, "default_group", null, "%RH"],
                ["23050144_LH06", "水淹报警", "reg1", "uint16", "ro", 1, "holding", 7, "hl", "0", null, "test", null, "V"],
                ["23050144_LH07", "蜂鸣器", "reg1", "uint16", "ro", 1, "holding", 9, "hl", "0", null, "default_group", null, "℉"],
                ["23050144_LH08", "布防灯", "reg1", "uint16", "ro", 1, "holding", 10, "hl", "0", null, "default_group", null, "m³/s"],
                ["23050144_LH09", "泵房照明", "reg1", "uint16", "ro", 1, "holding", 11, "hl", "0", null, "default_group", null, "%"],
                ["23050144_LH10", "摄像联动", "reg1", "uint16", "ro", 1, "holding", 12, "hl", "0", null, "default_group", null, "℃"],
                ["23050144_VS38", "1#阀门打开", "reg1", "bool", "rw", 1, "holding", 51, "", "0", null, "default_group"],
                ["23050144_VS40", "1#阀门关闭", "reg1", "bool", "rw", 1, "holding", 51, "", "1", null, "default_group"],
                ["23050144_VS39", "2#阀门打开", "reg1", "bool", "rw", 1, "holding", 51, "", "2", null, "default_group"],
                ["23050144_VS41", "2#阀门关闭", "reg1", "bool", "rw", 1, "holding", 51, "", "3", null, "default_group"],
                ["23050144_VS48", "3#阀门打开", "reg1", "bool", "rw", 1, "holding", 51, "", "4", null, "default_group"],
                ["23050144_VS49", "3#阀门关闭", "reg1", "bool", "rw", 1, "holding", 51, "", "5", null, "default_group"],
                ["23050144_VS50", "4#阀门打开", "reg1", "bool", "rw", 1, "holding", 51, "", "6", null, "default_group"],
                ["23050144_VS51", "4#阀门关闭", "reg1", "bool", "rw", 1, "holding", 51, "", "7", null, "default_group"],
              ],
              use_json: true,
              inputs: { write_data: { enabled: false, source: "" } },
              device_name: "pump_room_data",
              slave_address: 6,
              enabled: true,
              interval_ms: 20000,
              addrStart: 1,
              channel_name: "modbus_protocol",
              deviceName: "pump_room_data",
              driver: "modbus_s",
            },
            flowmeter_1_communication: {
              point_table: [
                ["23050144_MF00", "Instantaneous flow rate 1", "reg1", "float", "ro", 1, "holding", 1, "1234", "0", null, "test", 2],
                ["23050144_MF01", "Accumulated traffic 1", "reg1", "uint32", "ro", 1, "holding", 4, "1234", "0", null, "default_group", null, null, 65536],
              ],
              use_json: true,
              inputs: { write_data: { enabled: false, source: "" } },
              device_name: "flowmeter_1_communication",
              slave_address: 2,
              enabled: true,
              interval_ms: 1000,
              addrStart: 1,
              channel_name: "modbus_protocol",
              deviceName: "flowmeter_1_communication",
              driver: "modbus_s",
              status: {
                code: 200,
                result: {
                  lastConnectTime: 0,
                  point_table: {
                    "23050144_MF00": {
                      lastReadTime: 1686733446,
                      status: 0,
                    },
                    "23050144_MF01": { lastReadTime: 1686733446, status: 0 },
                  },
                  status: 0,
                },
              },
            },
            flowmeter_2_communication: {
              point_table: [
                ["23050144_MF02", "Instantaneous flow rate2", "reg1", "float", "ro", 1, "holding", 1, "1234", "0", null, "default_group", 2],
                ["23050144_MF10", "Cumulative flow2", "reg1", "uint32", "ro", 1, "holding", 1, "1234", "0", null, "test", null, null, 65536],
              ],
              use_json: true,
              inputs: { write_data: { enabled: false, source: "" } },
              device_name: "flowmeter_2_communication",
              slave_address: 3,
              enabled: true,
              interval_ms: 1000,
              addrStart: 1,
              channel_name: "modbus_protocol",
              deviceName: "flowmeter_2_communication",
              driver: "modbus_s",
              status: {
                code: 200,
                result: {
                  lastConnectTime: 0,
                  point_table: {
                    "23050144_MF02": {
                      lastReadTime: 1686733445,
                      status: 0,
                    },
                    "23050144_MF10": { lastReadTime: 1686733445, status: 0 },
                  },
                  status: 0,
                },
              },
            },
            flowmeter_3_communication: {
              point_table: [
                ["23050144_MF03", "Instantaneous flow rate3", "reg1", "float", "ro", 1, "holding", 1, "1234", "0", null, "default_group", 2],
                ["23050144_MF11", "Cumulative flow3", "reg1", "uint32", "ro", 1, "holding", 4, "1234", "0", null, "default_group", null, null, 65536],
              ],
              use_json: true,
              inputs: { write_data: { enabled: false, source: "" } },
              device_name: "flowmeter_3_communication",
              slave_address: 4,
              enabled: true,
              interval_ms: 1000,
              addrStart: 1,
              channel_name: "modbus_protocol",
              deviceName: "flowmeter_3_communication",
              driver: "modbus_s",
              status: {
                code: 200,
                result: {
                  lastConnectTime: 0,
                  point_table: {
                    "23050144_MF03": {
                      lastReadTime: 1686733445,
                      status: 0,
                    },
                    "23050144_MF11": { lastReadTime: 1686733445, status: 0 },
                  },
                  status: 0,
                },
              },
            },
            temperature_and_humidity_communication: {
              point_table: [
                ["23050144_RS10", "泵房温度", "reg1", "int16", "ro", 1, "holding", 1, "hl", "0", { zoom: { offset: 0, gain: 0.1 }, selected: "zoom" }, "default_group"],
                ["23050144_RS11", "泵房湿度", "reg1", "int16", "ro", 1, "holding", 2, "hl", "0", { zoom: { offset: 0, gain: 0.1 }, selected: "zoom" }, "test"],
              ],
              use_json: true,
              inputs: { write_data: { enabled: false, source: "" } },
              device_name: "temperature_and_humidity_communication",
              slave_address: 1,
              enabled: true,
              interval_ms: 1000,
              addrStart: 1,
              channel_name: "modbus_protocol",
              deviceName: "temperature_and_humidity_communication",
              driver: "modbus_s",
              status: {
                code: 200,
                result: {
                  lastConnectTime: 0,
                  point_table: {
                    "23050144_RS10": {
                      lastReadTime: 1686733445,
                      status: 0,
                    },
                    "23050144_RS11": { lastReadTime: 1686733445, status: 0 },
                  },
                  status: 0,
                },
              },
            },
            energy_meter_communication: {
              point_table: [
                ["23050144_RS00", "A相电压", "reg1", "uint16", "ro", 1, "holding", 0, "lh", "0", { zoom: { offset: 0, gain: 0.1 }, selected: "zoom" }, "default_group"],
                ["23050144_RS01", "B相电压", "reg1", "uint16", "ro", 1, "holding", 1, "lh", "0", { zoom: { offset: 0, gain: 0.1 }, selected: "zoom" }, "default_group"],
                ["23050144_RS02", "C相电压", "reg1", "uint16", "ro", 1, "holding", 2, "lh", "0", { zoom: { offset: 0, gain: 0.1 }, selected: "zoom" }, "default_group"],
                [
                  "23050144_RS03",
                  "A相电流",
                  "reg1",
                  "uint16",
                  "ro",
                  1,
                  "holding",
                  3,
                  "lh",
                  "0",
                  {
                    zoom: { offset: 0, gain: 0.001 },
                    selected: "zoom",
                  },
                  "default_group",
                ],
                [
                  "23050144_RS04",
                  "B相电流",
                  "reg1",
                  "uint16",
                  "ro",
                  1,
                  "holding",
                  4,
                  "lh",
                  "0",
                  {
                    zoom: { offset: 0, gain: 0.001 },
                    selected: "zoom",
                  },
                  "default_group",
                ],
                [
                  "23050144_RS05",
                  "C相电流",
                  "reg1",
                  "uint16",
                  "ro",
                  1,
                  "holding",
                  5,
                  "lh",
                  "0",
                  {
                    zoom: { offset: 0, gain: 0.001 },
                    selected: "zoom",
                  },
                  "default_group",
                ],
                ["23050144_EE00", "累计功耗", "reg1", "uint32", "ro", 1, "holding", 29, "1234", "0", { zoom: { offset: 0, gain: 0.01 }, selected: "zoom" }, "default_group", null, null, 65536],
              ],
              use_json: true,
              inputs: { write_data: { enabled: false, source: "" } },
              device_name: "energy_meter_communication",
              slave_address: 11,
              enabled: true,
              interval_ms: 1000,
              addrStart: 0,
              channel_name: "modbus_protocol",
              deviceName: "energy_meter_communication",
              driver: "modbus_s",
              status: {
                code: 200,
                result: {
                  lastConnectTime: 0,
                  point_table: {
                    "23050144_EE00": {
                      lastReadTime: 1686733446,
                      status: 0,
                    },
                    "23050144_RS00": {
                      lastReadTime: 1686733445,
                      status: 0,
                    },
                    "23050144_RS01": {
                      lastReadTime: 1686733445,
                      status: 0,
                    },
                    "23050144_RS02": {
                      lastReadTime: 1686733445,
                      status: 0,
                    },
                    "23050144_RS03": {
                      lastReadTime: 1686733445,
                      status: 0,
                    },
                    "23050144_RS04": {
                      lastReadTime: 1686733445,
                      status: 0,
                    },
                    "23050144_RS05": { lastReadTime: 1686733445, status: 0 },
                  },
                  status: 0,
                },
              },
            },
            water_quality_communication: {
              point_table: [
                ["23050144_WQ00", "浊度", "reg1", "float", "ro", 1, "holding", 61, "4321", "0", null, "default_group", 2],
                ["23050144_WQ03", "余氯", "reg1", "float", "ro", 1, "holding", 55, "4321", "0", null, "default_group", 2],
                ["23050144_WQ04", "PH", "reg1", "float", "ro", 1, "holding", 57, "4321", "0", null, "default_group", 2],
              ],
              use_json: true,
              inputs: { write_data: { enabled: false, source: "" } },
              device_name: "water_quality_communication",
              slave_address: 5,
              enabled: true,
              interval_ms: 10000,
              addrStart: 1,
              channel_name: "modbus_protocol",
              deviceName: "water_quality_communication",
              driver: "modbus_s",
              status: {
                code: 200,
                result: {
                  lastConnectTime: 0,
                  point_table: {
                    "23050144_WQ00": {
                      lastReadTime: 1686733439,
                      status: 0,
                    },
                    "23050144_WQ03": {
                      lastReadTime: 1686733439,
                      status: 0,
                    },
                    "23050144_WQ04": { lastReadTime: 1686733439, status: 0 },
                  },
                  status: 0,
                },
              },
            },
          },
          output_groups: {},
        },
        config_version: 1,
        channel_name: "modbus_protocol",
        driver_direction: VPTDriverDireaction.south,
      },
    },
    encapsulation: {
      With_Device_Info_3: {
        code: 'var cached_sn;\n\nif (!cached_sn) {\n    cached_sn = readFileSync("/tmp/sysinfo/SN");\n    if (cached_sn) {\n        cached_sn = cached_sn.trim();\n    }\n}\n\nvar lastChannel = getLastChannel();\nvar lastDevice = getLastDevice();\nvar deviceInfo = getDeviceInfo(lastChannel, lastDevice);\nvar selfInfo = getChannelInfo(getSelfName());\n\noutputs = {\n    sn: cached_sn,\n    channel: lastChannel,\n    device: lastDevice,\n    slave_address: deviceInfo ? deviceInfo.slave_address : null,\n    client_id: selfInfo && selfInfo.client_id ? selfInfo.client_id : null,\n    data: {}\n};\n\nfunction parseData() {\n    var data_string = getLastData();\n    if (!data_string) {\n        return;\n    }\n    outputs.data = JSON.parse(data_string);\n}\n\nparseData();\ncloud_output = JSON.stringify(outputs);\n',
        desc: '{\n    "sn": "V201912091-059",\n    "channel": "modbus",\n    "device": "sensor1",\n    "slave_address": 1,\n    "client_id": "the mqtt client id",\n    "data": {\n        "temperature": 21.30,\n        "humidity": 60\n    }\n}\n',
        engine: "javascript",
        is_build_in: false,
        name: "With Device Info 3",
      },
    },
    profinet: {},
  },
};
export const mockVPTDrivers: VPTDriversConfigDescription = {
  ablogix_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        ablogix_s: {
          channel_config: {},
          device_config: {
            address: {
              check: "^((25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))\\.){3}(25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))$",
              required: true,
              title: { en: "Ip Address", zh: "设备地址" },
              type: "string",
            },
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  address: { index: 7, required: true, show_on_table: true, table_width: 200, title: { en: "Register Addr", zh: "点名称" }, type: "string" },
                  address_type: {
                    enum: [
                      { title: "01", value: "coil" },
                      { title: "02", value: "digital" },
                      { title: "03", value: "holding" },
                      { title: "04", value: "analogue" },
                    ],
                    index: 6,
                    required: true,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Register Addr Type", zh: "寄存器类型" },
                    type: "string",
                  },
                  bit_bias: {
                    default: "0",
                    enum: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"],
                    index: 9,
                    required: false,
                    show: "(data.address_type === 'holding' || data.address_type === 'analogue') && data.data_type === 'bool'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Bit Bias", zh: "偏移" },
                    type: "string",
                  },
                  byte_order: {
                    default: " ",
                    enum: [
                      { title: { en: "Default", zh: "默认方式" }, value: " " },
                      { title: { en: "h", zh: "取高字节" }, value: "h" },
                      { title: { en: "l", zh: "取低字节" }, value: "l" },
                      { title: { en: "hl", zh: "先高后低" }, value: "hl" },
                      { title: { en: "lh", zh: "先低后高" }, value: "lh" },
                      { title: "1234", value: "1234" },
                      { title: "4321", value: "4321" },
                      { title: "2143", value: "2143" },
                      { title: "3412", value: "3412" },
                      { title: "12345678", value: "12345678" },
                      { title: "78563412", value: "78563412" },
                    ],
                    index: 8,
                    required: true,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Byte Order", zh: "解析方式" },
                    type: "string",
                  },
                  data_type: {
                    enum: [
                      { title: "BOOL(bit)", value: "bool" },
                      { title: "BYTE(uint8)", value: "byte" },
                      { title: "SINT(int8)", value: "char" },
                      { title: "INT(int16)", value: "int16" },
                      { title: "WORD(uint16)", value: "uint16" },
                      { title: "DINT(int32)", value: "int" },
                      { title: "DWORD(uint32)", value: "uint" },
                      { title: "FLOAT(32bit)", value: "float" },
                      { title: "INT64 (int64)", value: "long" },
                      { title: "WORD64(uint64)", value: "ulong" },
                      { title: "DOUBLE(64bit)", value: "double" },
                    ],
                    index: 3,
                    required: true,
                    show_on_table: true,
                    table_width: 300,
                    title: { en: "Date Type", required: true, zh: "数据类型" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: 0,
                    enum: [
                      { title: { en: "default", zh: "默认不转换" }, value: 0 },
                      { title: { en: "Convert and retain 1 decimal place", zh: "转换并保留1位小数" }, value: 1 },
                      { title: { en: "Convert and retain 2 decimal place", zh: "转换并保留2位小数" }, value: 2 },
                      { title: { en: "Convert and retain 3 decimal place", zh: "转换并保留3位小数" }, value: 3 },
                      { title: { en: "Convert and retain 4 decimal place", zh: "转换并保留4位小数" }, value: 4 },
                      { title: { en: "Convert and retain 5 decimal place", zh: "转换并保留5位小数" }, value: 5 },
                      { title: { en: "Convert and retain 6 decimal place", zh: "转换并保留6位小数" }, value: 6 },
                    ],
                    index: 12,
                    required: false,
                    show: "data.data_type === 'float' || data.data_type === 'double'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Convert String", zh: "转字符串" },
                    type: "int",
                  },
                  group: { default: "default_group", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: {
                    default: 1,
                    index: 5,
                    max: 255,
                    min: 1,
                    required: true,
                    show: "data.data_type === 'hex' || data.data_type === 'string'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Length", zh: "变量长度" },
                    type: "int",
                  },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Read Or Write", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "title", "description", "int16", "ro", 1, "default", "TEST_INT", "", "0", null, "default_group"],
                required: ["name", "title", "group", "data_type", "format", "address", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            path: { default: "1,0", required: true, title: { en: "Path", zh: "Path" }, type: "string" },
            plc: { default: "ControlLogix", enum: ["ControlLogix", "plc5", "lgx", "slc", "omron-njnx", "Micro800"], required: true, title: { en: "PLC", zh: "PLC" }, type: "string" },
            port: { default: 44818, max: 65535, min: 1, required: true, title: { en: "Port", zh: "端口号" }, type: "int" },
            protocol: { default: "ab-eip", enum: ["ab-eip", "ab_eip"], required: true, title: { en: "protocol", zh: "protocol" }, type: "string" },
            required: ["device_name", "enabled", "interval_ms", "address", "port", "path", "plc"],
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          title: { en: "AB EtherNet/IP VPT", zh: "AB EtherNet/IP协议" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "ablogix_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "ablogix",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "AB EtherNet/IP VPT", zh: "AB EtherNet/IP协议" },
    version: "1.0.1",
  },
  alarm_n: {
    attribute: VPTDriverDireaction.north,
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "alarm_n",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "alarm",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "Alarm Plugin", zh: "告警插件" },
    version: "1.0.0",
  },
  azure_n: {
    attribute: VPTDriverDireaction.north,
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "azure_n",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "azure",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "Azure upload (customized)", zh: "Azure上传插件(定制)" },
    version: "1.1.0",
  },
  bacnet_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        udp: {
          channel_config: {
            data_link: { default: "BIP", enum: ["BIP"], required: true, title: { en: "Data Link", zh: "数据链路" }, type: "string" },
            iface: {
              enum: ["eth1", "any", "eth0", "br-lan", "can0", "gre0", "gretap0", "erspan0", "nflog", "nfqueue"],
              required: true,
              title: { en: "Network Interface", zh: "网卡" },
              type: "string",
            },
          },
          device_config: {
            address: {
              check: "^((25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))\\.){3}(25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))$",
              required: false,
              title: { en: "Ip Address", zh: "IP地址" },
              type: "string",
            },
            device_id: { max: 4194303, min: 0, required: true, title: { en: "Device ID", zh: "设备ID" }, type: "int" },
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: ["", 0],
                    enum: [],
                    index: 12,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Units &amp; decimal places", zh: "单位&小数点位数" },
                    type: "string",
                  },
                  group: { default: "暂不分组", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  object_id: { index: 7, max: 4194303, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "Object ID", zh: "对象ID" }, type: "int" },
                  object_type: {
                    enum: [
                      { title: "analog-input", value: 0 },
                      { title: "analog-output", value: 1 },
                      { title: "analog-value", value: 2 },
                      { title: "binary-input", value: 3 },
                      { title: "binary-output", value: 4 },
                      { title: "binary-value", value: 5 },
                      { title: "calendar", value: 6 },
                      { title: "command", value: 7 },
                      { title: "device", value: 8 },
                      { title: "event-enrollment", value: 9 },
                      { title: "file", value: 10 },
                      { title: "group", value: 11 },
                      { title: "loop", value: 12 },
                      { title: "multi-state-input", value: 13 },
                      { title: "multi-state-output", value: 14 },
                      { title: "notification-class", value: 15 },
                      { title: "program", value: 16 },
                      { title: "schedule", value: 17 },
                      { title: "averaging", value: 18 },
                      { title: "multi-state-value", value: 19 },
                      { title: "trend-log", value: 20 },
                      { title: "life-safety-point", value: 21 },
                      { title: "life-safety-zone", value: 22 },
                      { title: "accumulator", value: 23 },
                      { title: "pulse-converter", value: 24 },
                      { title: "event-log", value: 25 },
                      { title: "global-group", value: 26 },
                      { title: "trend-log-multiple", value: 27 },
                      { title: "load-control", value: 28 },
                      { title: "structured-view", value: 29 },
                      { title: "access-door", value: 30 },
                      { title: "Reserved for Use by ASHRAE", value: 31 },
                      { title: "access-credential", value: 32 },
                      { title: "access-point", value: 33 },
                      { title: "access-rights", value: 34 },
                      { title: "access-user", value: 35 },
                      { title: "access-zone", value: 36 },
                      { title: "credential-data-input", value: 37 },
                      { title: "network-security", value: 38 },
                      { title: "bitstring-value", value: 39 },
                      { title: "characterstring-value", value: 40 },
                      { title: "date-pattern-value", value: 41 },
                      { title: "date-value", value: 42 },
                      { title: "datetime-pattern-value", value: 43 },
                      { title: "datetime-value", value: 44 },
                      { title: "integer-value", value: 45 },
                      { title: "large-analog-value", value: 46 },
                      { title: "octetstring-value", value: 47 },
                      { title: "positive-integer-value", value: 48 },
                      { title: "time-pattern-value", value: 49 },
                      { title: "time-value", value: 50 },
                      { title: "notification-forwarder", value: 51 },
                      { title: "alert-enrollment", value: 52 },
                      { title: "channel", value: 53 },
                      { title: "lighting-output", value: 54 },
                      { title: "binary-lighting-output", value: 55 },
                      { title: "network-port", value: 56 },
                      { title: "elevator-group", value: 57 },
                      { title: "escalator", value: 58 },
                      { title: "lift", value: 59 },
                      { title: "staging", value: 60 },
                    ],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Object Type", zh: "对象类型" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Permission", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "reg1", "reg1", "int16", "ro", 1, "", 0, "", "0", { scale: {}, selected: "", zoom: {} }, "default_group"],
                required: ["name", "title", "group", "object_type", "object_id", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            port: { default: 47808, max: 65535, min: 0, required: false, title: { en: "Port", zh: "端口号" }, type: "int" },
            required: ["device_name", "device_id", "enabled", "interval_ms"],
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          required: ["data_link", "iface"],
          title: { en: "BACnet", zh: "BACnet" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: true,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["udp"],
    config_version: 2,
    description: { en: "", zh: "" },
    driver_name: "bacnet_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "bacnet",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "BACnet VPT", zh: "BACnet采集协议" },
    version: "1.0.1",
  },
  buffer_c: {
    attribute: VPTDriverDireaction.compute,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        disk: {
          channel_config: {
            buffer_name: { max: 256, min: 1, required: true, title: { en: "Buffer Name", zh: "缓存保存名字" }, type: "string" },
            max_delayed_pop_cnt: { default: 0, min: 0, title: { en: "Max Delayed Delete Count", zh: "最大延迟删除行数" }, type: "int" },
            max_delayed_push_cnt: { default: 10, min: 0, title: { en: "Max Delayed Save Count", zh: "最大延迟存盘行数" }, type: "int" },
            max_delayed_push_size: { default: 10, max: 1048576, min: 0, title: { en: "Max Delayed Save Size", zh: "最大延迟存盘数据大小" }, type: "int" },
            max_disk_cnt: { default: 1000, min: 1, required: true, title: { en: "Max Data Count", zh: "最大缓存行数" }, type: "int" },
            max_disk_size: { default: 10485760, max: 1073741824, min: 1024, required: true, title: { en: "Max Disk Usage", zh: "最大磁盘使用量" }, type: "int" },
            max_keep_time: { default: 3600, min: 0, title: { en: "Max Keep Time", zh: "最长保存时间" }, type: "int" },
          },
          title: { en: "Disk Buffer", zh: "磁盘缓存" },
        },
        memory: {
          channel_config: {
            max_keep_time: { default: 3600, min: 0, title: { en: "Max Keep Time", zh: "最长保存时间" }, type: "int" },
            max_memory_cnt: { default: 1000, min: 1, required: true, title: { en: "Max Data Count", zh: "最大缓存行数" }, type: "int" },
            max_memory_size: { default: 1048576, max: 10485760, min: 1024, required: true, title: { en: "Max Memory Usage", zh: "最大内存使用量" }, type: "int" },
          },
          title: { en: "Memory Buffer", zh: "内存缓存" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: true,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["memory", "disk"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "buffer_c",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "Data Buffer", zh: "数据缓存插件" },
    version: "1.0.0",
  },
  cdt91_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        serial: {
          channel_config: {
            baudrate: { default: 9600, enum: [1200, 2400, 4800, 9600, 19200, 38400, 115200], required: true, title: { en: "Baudrate", zh: "波特率" }, type: "int" },
            data_bits: { default: 8, enum: [5, 6, 7, 8], required: true, title: { en: "Data Bits", zh: "数据位" }, type: "int" },
            enable_crc: {
              default: 0,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: false,
              title: { en: "Check CRC", zh: "CRC校验" },
              type: "bool",
            },
            mode: {
              default: "rs485",
              enum: [
                { disabled: "!(data.port === 'COM1' || data.port === 'COM2' || data.port === 'COM3' || data.port === 'COM4')", title: "RS485", value: "rs485" },
                { disabled: "!(data.port === 'COM1')", title: "RS232", value: "rs232" },
              ],
              required: true,
              title: { en: "Mode", zh: "模式" },
              type: "string",
            },
            parity: { default: "N", enum: ["N", "E", "O"], required: true, title: { en: "Parity", zh: "校验位" }, type: "string" },
            port: {
              enum: [
                { title: "COM1", value: "COM1" },
                { title: "COM2", value: "COM2" },
                { title: "COM3", value: "COM3" },
                { title: "COM4", value: "COM4" },
              ],
              required: true,
              title: { en: "Serial Port", zh: "串口" },
              type: "string",
            },
            stop_bits: { default: "1", enum: ["1", "1.5", "2"], required: true, title: { en: "Stop Bits", zh: "停止位" }, type: "string" },
            use_json_value: {
              default: 0,
              enum: [
                { title: { en: "Json Value", zh: "Json数据" }, value: 1 },
                { title: { en: "Simplified Value", zh: "简化数据" }, value: 0 },
              ],
              required: false,
              title: { en: "Data Format", zh: "数据格式" },
              type: "bool",
            },
          },
          device_config: {
            dest_address: { default: 1, max: 255, min: 0, required: true, title: { en: "Destination Address", zh: "目的站地址" }, type: "int" },
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  address: { default: 0, index: 7, max: 512, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "Point Number", zh: "点号" }, type: "int" },
                  address_type: {
                    default: "yc",
                    enum: [
                      { title: { en: "Remote signaling", zh: "遥测" }, value: "yc" },
                      { title: { en: "Telemetering", zh: "遥信" }, value: "yx" },
                    ],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Variable Type", zh: "变量类型" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: ["", 0],
                    enum: [],
                    index: 12,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Units &amp; decimal places", zh: "单位&小数点位数" },
                    type: "string",
                  },
                  group: { default: "暂不分组", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Permission", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "reg1", "reg1", "int16", "ro", 1, "", 0, "", "0", { scale: {}, selected: "", zoom: {} }, "default_group"],
                required: ["name", "title", "group", "address_type", "address", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            required: ["device_name", "enabled", "source_address", "dest_address", "timeout", "interval_ms"],
            source_address: { default: 1, max: 255, min: 0, required: true, title: { en: "Source Address", zh: "源站地址" }, type: "int" },
            timeout: { default: 0, max: 35791394, min: 0, required: false, title: { en: "Offline Timeout (minute)", zh: "离线超时时间(分)" }, type: "int" },
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          required: ["port", "mode", "baudrate", "data_bits", "parity", "stop_bits", "enable_crc", "use_json_value"],
          title: { en: "CDT91", zh: "CDT91" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: true,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["serial"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "cdt91_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "cdt91",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "CDT91 VPT", zh: "CDT91采集插件" },
    version: "1.0.1",
  },
  coap_n: {
    attribute: VPTDriverDireaction.north,
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "coap_n",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "coap",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "COAP Server VPT", zh: "COAP服务端协议" },
    version: "1.1.0",
  },
  coap_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        tcp: {
          channel_config: {},
          device_config: {
            address: {
              check: "^((25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))\\.){3}(25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))$",
              required: true,
              title: { en: "Ip Address", zh: "设备地址" },
              type: "string",
            },
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  address: { default: "/", index: 7, required: true, show_on_table: true, table_width: 300, title: { en: "uri", zh: "uri" }, type: "string" },
                  address_type: { index: 6, required: true, show_on_table: true, table_width: 200, title: { en: "uri", zh: "uri" }, type: "string" },
                  bit_bias: { default: 0, index: 9, required: true, show_on_table: true, table_width: 200, title: { en: "Bit Bias", zh: "偏移" }, type: "int" },
                  byte_order: { default: " ", enum: [], index: 8, required: true, show_on_table: false, table_width: 200, title: { en: "Byte Order", zh: "解析方式" }, type: "string" },
                  data_type: { index: 3, required: true, show_on_table: true, table_width: 300, title: { en: "Date Type", zh: "数据类型" }, type: "string" },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: 2,
                    enum: [1, 2, 3, 4, 5, 6, 7, 8],
                    index: 12,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Decimal places", zh: "小数点位数" },
                    type: "int",
                  },
                  group: { default: "default_group", enum: [], index: 11, required: true, show_on_table: true, table_width: 100, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: {
                    default: 1,
                    index: 5,
                    max: 255,
                    min: 1,
                    required: true,
                    show: "data.data_type === 'hex' || data.data_type === 'string'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Length", zh: "数据长度" },
                    type: "int",
                  },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 300,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Permission", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "", "", "string", "ro", 1, "path", 0, "", 0, null, "default_group"],
                required: ["name", "title", "group", "address", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            port: { default: 5683, max: 65535, min: 1, required: true, title: { en: "Port", zh: "端口号" }, type: "int" },
            required: ["device_name", "enabled", "interval_ms", "address", "port"],
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          title: { en: "CoAP VPT", zh: "CoAP协议" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: false,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "coap_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "coap",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "CoAP VPT", zh: "CoAP协议" },
    version: "1.0.1",
  },
  collaboration_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        http: {
          channel_config: {
            ip: { required: true, title: { en: "IP", zh: "IP" }, type: "string" },
            password: { default: "admin", required: true, title: { en: "Password", zh: "密码" }, type: "string" },
            port: { default: 8081, required: true, title: { en: "Port", zh: "端口" }, type: "int" },
            remote_channel: { required: true, title: { en: "Remote Channel", zh: "远端通道名" }, type: "string" },
            username: { default: "admin", required: true, title: { en: "Username", zh: "用户名" }, type: "string" },
          },
          device_config: {
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  address: { index: 7, max: 65535, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "Register Addr", zh: "寄存器地址" }, type: "int" },
                  data_type: {
                    enum: [
                      { title: "BOOL(bit)", value: "bool" },
                      { title: "SINT(int8)", value: "char" },
                      { title: "INT(int16)", value: "int16" },
                      { title: "DINT(int32)", value: "int" },
                      { title: "INT64(int64)", value: "long" },
                      { title: "BYTE(uint8)", value: "byte" },
                      { title: "WORD(uint16)", value: "uint16" },
                      { title: "DWORD(uint32)", value: "uint" },
                      { title: "WORD64(uint64)", value: "ulong" },
                      { title: "FLOAT(32bit)", value: "float" },
                      { title: "DOUBLE(64bit)", value: "double" },
                      { title: "STRING(string)", value: "string" },
                      { title: "HEX(hex)", value: "hex" },
                    ],
                    index: 3,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Date Type", required: true, zh: "变量类型" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: ["", 0],
                    enum: [],
                    index: 12,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Units &amp; decimal places", zh: "单位&小数点位数" },
                    type: "string",
                  },
                  group: { default: "暂不分组", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: {
                    default: 1,
                    index: 5,
                    max: 255,
                    min: 1,
                    required: false,
                    show: "data.data_type === 'hex' || data.data_type === 'string'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Length", zh: "变量长度" },
                    type: "int",
                  },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Permission", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "reg1", "reg1", "int16", "ro", 1, "", 0, "", "0", { scale: {}, selected: "", zoom: {} }, "default_group"],
                required: ["name", "title", "group", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Synchronization period (MS) ", zh: "同步周期(毫秒)" }, type: "int" },
            required: ["device_name", "enabled", "interval_ms"],
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          required: ["ip", "port", "username", "password", "remote_channel"],
          title: { en: "Collaboration VPT", zh: "协同协议" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: true,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["http"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "collaboration_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "collaboration",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "Gateway Collaboration", zh: "网关协同" },
    version: "1.0.0",
  },
  compute_c: {
    attribute: VPTDriverDireaction.compute,
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["javascript", "lua", "python"],
    config_version: 2,
    description: { en: "", zh: "" },
    driver_name: "compute_c",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "compute",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "Edge Compute", zh: "边缘计算" },
    version: "1.3.2",
  },
  disa_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        serial: {
          channel_config: {
            baudrate: { default: 9600, enum: [1200, 2400, 4800, 9600, 19200, 38400, 115200], required: true, title: { en: "Baudrate", zh: "波特率" }, type: "int" },
            data_bits: { default: 8, enum: [5, 6, 7, 8], required: true, title: { en: "Data Bits", zh: "数据位" }, type: "int" },
            enable_crc: {
              default: 0,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: false,
              title: { en: "Check CRC", zh: "CRC校验" },
              type: "bool",
            },
            mode: {
              default: "rs232",
              enum: [
                { disabled: "!(data.port === 'COM1' || data.port === 'COM2' || data.port === 'COM3' || data.port === 'COM4')", title: "RS485", value: "rs485" },
                { disabled: "!(data.port === 'COM1')", title: "RS232", value: "rs232" },
              ],
              required: true,
              title: { en: "Mode", zh: "模式" },
              type: "string",
            },
            parity: { default: "N", enum: ["N", "E", "O"], required: true, title: { en: "Parity", zh: "校验位" }, type: "string" },
            port: {
              enum: [
                { title: "COM1", value: "COM1" },
                { title: "COM2", value: "COM2" },
                { title: "COM3", value: "COM3" },
                { title: "COM4", value: "COM4" },
              ],
              required: true,
              title: { en: "Serial Port", zh: "串口" },
              type: "string",
            },
            stop_bits: { default: "1", enum: ["1", "1.5", "2"], required: true, title: { en: "Stop Bits", zh: "停止位" }, type: "string" },
            use_json_value: {
              default: 0,
              enum: [
                { title: { en: "Json Value", zh: "Json数据" }, value: 1 },
                { title: { en: "Simplified Value", zh: "简化数据" }, value: 0 },
              ],
              required: false,
              title: { en: "Data Format", zh: "数据格式" },
              type: "bool",
            },
          },
          device_config: {
            dest_address: { default: 1, max: 255, min: 0, required: true, title: { en: "Destination Address", zh: "目的站地址" }, type: "int" },
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  address: { default: 0, index: 7, max: 8191, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "Point Number", zh: "点号" }, type: "int" },
                  address_type: {
                    default: "yc",
                    enum: [
                      { title: { en: "Remote signaling", zh: "遥测" }, value: "yc" },
                      { title: { en: "Telemetering Status", zh: "遥信状态" }, value: "yx-s" },
                      { title: { en: "Displacement Telemetering", zh: "变位遥信" }, value: "yx" },
                    ],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Variable Type", zh: "变量类型" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: ["", 0],
                    enum: [],
                    index: 12,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Units &amp; decimal places", zh: "单位&小数点位数" },
                    type: "string",
                  },
                  group: { default: "暂不分组", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Permission", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "reg1", "reg1", "int16", "ro", 1, "", 0, "", "0", { scale: {}, selected: "", zoom: {} }, "default_group"],
                required: ["name", "title", "group", "address_type", "address", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            required: ["device_name", "enabled", "source_address", "dest_address", "timeout", "interval_ms"],
            source_address: { default: 1, max: 255, min: 0, required: true, title: { en: "Source Address", zh: "源站地址" }, type: "int" },
            timeout: { default: 0, max: 35791394, min: 0, required: false, title: { en: "Offline Timeout (minute)", zh: "离线超时时间(分)" }, type: "int" },
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          required: ["port", "mode", "baudrate", "data_bits", "parity", "stop_bits", "enable_crc", "use_json_value"],
          title: { en: "DISA", zh: "DISA" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: true,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["serial"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "disa_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "disa",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "DISA VPT", zh: "DISA采集插件" },
    version: "1.0.1",
  },
  dlt645_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        serial: {
          channel_config: {
            baudrate: { default: 2400, enum: [1200, 2400, 4800, 9600, 19200, 38400, 115200], required: true, title: { en: "Baudrate", zh: "波特率" }, type: "int" },
            data_bits: { default: 8, enum: [5, 6, 7, 8], required: true, title: { en: "Data Bits", zh: "数据位" }, type: "int" },
            mode: {
              default: "rs485",
              enum: [{ disabled: "!(data.port === 'COM1' || data.port === 'COM2' || data.port === 'COM3' || data.port === 'COM4')", title: "RS485", value: "rs485" }],
              required: true,
              title: { en: "Mode", zh: "模式" },
              type: "string",
            },
            parity: { default: "E", enum: ["N", "E", "O"], required: true, title: { en: "Parity", zh: "校验位" }, type: "string" },
            port: {
              enum: [
                { title: "COM1", value: "COM1" },
                { title: "COM2", value: "COM2" },
                { title: "COM3", value: "COM3" },
                { title: "COM4", value: "COM4" },
              ],
              required: true,
              title: { en: "Serial Port", zh: "串口" },
              type: "string",
            },
            stop_bits: { default: "1", enum: ["1", "1.5", "2"], required: true, title: { en: "Stop Bits", zh: "停止位" }, type: "string" },
          },
          device_config: {
            address: { check: "^[0-9]{12,12}$", required: true, title: { en: "Device ID", zh: "通信地址" }, type: "string" },
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "DLT645-1997": {
                point_schema: {
                  address: {
                    customize: true,
                    enum: [
                      { title: { en: "Total Current Positive Active Power", zh: "当前正向有功总电量" }, value: "9010" },
                      { title: { en: "Current Positive Active Power", zh: "当前正向有功费率1电量" }, value: "9011" },
                      { title: { en: "Current Positive Active Peak Energy", zh: "当前正向有功费率2电量" }, value: "9012" },
                      { title: { en: "Current Positive Active Energy", zh: "当前正向有功费率3电量" }, value: "9013" },
                      { title: { en: "Current Positive Active Valley Energy", zh: "当前正向有功费率4电量" }, value: "9014" },
                      { title: { en: "Phase A Voltage", zh: "A相电压" }, value: "B611" },
                      { title: { en: "Phase B Voltage", zh: "B相电压" }, value: "B612" },
                      { title: { en: "Phase C Voltage", zh: "C相电压" }, value: "B613" },
                      { title: { en: "Phase A Current", zh: "A相电流" }, value: "B621" },
                      { title: { en: "Phase B Current", zh: "B相电流" }, value: "B622" },
                      { title: { en: "Phase C Current", zh: "C相电流" }, value: "B623" },
                      { title: { en: "Total Power Factor", zh: "总功率因数" }, value: "B650" },
                      { title: { en: "Instantaneous Active Power", zh: "瞬时有功功率" }, value: "B630" },
                      { title: { en: "Phase A Active Power", zh: "A相有功功率" }, value: "B631" },
                      { title: { en: "Phase B Active Power", zh: "B相有功功率" }, value: "B632" },
                      { title: { en: "Phase C Active Power", zh: "C相有功功率" }, value: "B633" },
                      { title: { en: "Instantaneous Reactive Power", zh: "瞬时无功功率" }, value: "B640" },
                      { title: { en: "Phase A Reactive Power", zh: "A相无功功率" }, value: "B641" },
                      { title: { en: "Phase B Reactive Power", zh: "B相无功功率" }, value: "B642" },
                      { title: { en: "Phase C Reactive Power", zh: "C相无功功率" }, value: "B643" },
                    ],
                    index: 7,
                    placeholder: { en: "Select or enter data DI1DI0", zh: "选择或输入数据标识DI1DI0" },
                    required: true,
                    show_on_table: true,
                    table_width: 300,
                    title: { en: "Data Addr", zh: "采集点位" },
                    type: "string",
                  },
                  address_type: {
                    enum: [
                      { title: "01", value: "coil" },
                      { title: "02", value: "digital" },
                      { title: "03", value: "holding" },
                      { title: "04", value: "analogue" },
                    ],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Register Addr Type", zh: "寄存器类型" },
                    type: "string",
                  },
                  bit_bias: { default: "0", enum: [], index: 9, required: false, show: "", show_on_table: false, table_width: 200, title: { en: "Bit Bias", zh: "偏移" }, type: "string" },
                  byte_order: {
                    default: "XXXXXX.XX",
                    enum: ["XXXXXX.XX", "XXX.X", "XX.XX", "X.XXX", "XX.XXXX-YYMMDDhhmm", "XXX.XXX", "XX.XXXX", "XXXXXXXX", "YYMMDDWW", "XX", "XXXX", "YYMMDDhhmm", "YYMMDDhhmmss"],
                    index: 8,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Byte Order", zh: "数据格式" },
                    type: "string",
                  },
                  data_type: { enum: [], index: 3, required: true, show_on_table: false, table_width: 300, title: { en: "Date Type", zh: "数据类型" }, type: "string" },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    enum: [1, 2, 3, 4],
                    index: 12,
                    placeholder: { en: "Decimal places correspond to the data format", zh: "小数位与数据格式对应" },
                    required: true,
                    show: "data.byte_order === 'XXXXXX.XX' || data.byte_order === 'XXX.X' || data.byte_order === 'XX.XX' || data.byte_order === 'X.XXX' || data.byte_order === 'XXX.XXX' || data.byte_order === 'XX.XXXX' || data.byte_order === 'N.NNN' || data.byte_order === 'XXXX.XXXX' || data.byte_order === 'NN.NNNN'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Decimal Places", zh: "小数点位数" },
                    type: "int",
                  },
                  group: { default: "default_group", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: {
                    default: 1,
                    index: 5,
                    max: 255,
                    min: 1,
                    required: true,
                    show: "data.data_type === 'hex' || data.data_type === 'string'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Length", zh: "变量长度" },
                    type: "int",
                  },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Read Or Write", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "kWh", "kvarh", "kVah", "%RH", "kW", "kvar", "A", "kVa", "V", "Hz", "分", "%", "℃", "Kw.h", "次.分", "Ah", "次", "个", "秒", "位", "毫秒"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "Ua", "Ua", "int16", "ro", 1, "default", "02010100", "XXX.X", "0", null, "default_group"],
                required: ["name", "title", "group", "read_write", "address", "byte_order", "format", "unit"],
              },
              "DLT645-2007": {
                point_schema: {
                  address: {
                    customize: true,
                    enum: [
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Total Current Positive Active Power", zh: "当前正向有功总电量" },
                        value: "00010000",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Current Positive Active Power Consumption", zh: "当前正向有功费率1电量" },
                        value: "00010100",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Current Positive Active Peak Rnergy", zh: "当前正向有功费率2电量" },
                        value: "00010200",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Current Positive Active Rnergy Level", zh: "当前正向有功费率3电量" },
                        value: "00010300",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Current Positive Active Valley Rnergy", zh: "当前正向有功费率4电量" },
                        value: "00010400",
                      },
                      { disabled: "!(data.address_type === '11')", form_ctrl: { byte_order: "XXX.X", format: 1 }, title: { en: "Phase A Voltage", zh: "A相电压" }, value: "02010100" },
                      { disabled: "!(data.address_type === '11')", form_ctrl: { byte_order: "XXX.X", format: 1 }, title: { en: "Phase B Voltage", zh: "B相电压" }, value: "02010200" },
                      { disabled: "!(data.address_type === '11')", form_ctrl: { byte_order: "XXX.X", format: 1 }, title: { en: "Phase C Voltage", zh: "C相电压" }, value: "02010300" },
                      { disabled: "!(data.address_type === '11')", form_ctrl: { byte_order: "XXX.XXX", format: 1 }, title: { en: "Phase A Current", zh: "A相电流" }, value: "02020100" },
                      { disabled: "!(data.address_type === '11')", form_ctrl: { byte_order: "XXX.XXX", format: 1 }, title: { en: "Phase B Current", zh: "B相电流" }, value: "02020200" },
                      { disabled: "!(data.address_type === '11')", form_ctrl: { byte_order: "XXX.XXX", format: 1 }, title: { en: "Phase C Current", zh: "C相电流" }, value: "02020300" },
                      { disabled: "!(data.address_type === '11')", form_ctrl: { byte_order: "N.NNN", format: 3 }, title: { en: "Total Power Factor", zh: "总功率因数" }, value: "02060000" },
                      { disabled: "!(data.address_type === '11')", form_ctrl: { byte_order: "N.NNN", format: 3 }, title: { en: "Total Power Factor", zh: "A相率因数" }, value: "02060100" },
                      { disabled: "!(data.address_type === '11')", form_ctrl: { byte_order: "N.NNN", format: 3 }, title: { en: "Total Power Factor", zh: "B相率因数" }, value: "02060200" },
                      { disabled: "!(data.address_type === '11')", form_ctrl: { byte_order: "N.NNN", format: 3 }, title: { en: "Total Power Factor", zh: "C相率因数" }, value: "02060300" },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "NN.NNNN", format: 3 },
                        title: { en: "Instantaneous Total Active Power", zh: "瞬时总有功功率" },
                        value: "02030000",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "NN.NNNN", format: 3 },
                        title: { en: "Instantaneous Phase A Active Power", zh: "瞬时A相有功功率" },
                        value: "02030100",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "NN.NNNN", format: 3 },
                        title: { en: "Instantaneous Phase B Active Power", zh: "瞬时B相有功功率" },
                        value: "02030200",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "NN.NNNN", format: 3 },
                        title: { en: "Instantaneous Phase C Active Power", zh: "瞬时C相有功功率" },
                        value: "02030300",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "NN.NNNN", format: 3 },
                        title: { en: "Instantaneous Total Reactive Power", zh: "瞬时总无功功率" },
                        value: "02040000",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "NN.NNNN", format: 3 },
                        title: { en: "Instantaneous Phase A Reactive Power", zh: "瞬时A相无功功率" },
                        value: "02040100",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "NN.NNNN", format: 3 },
                        title: { en: "Instantaneous Phase B Reactive Power", zh: "瞬时B相无功功率" },
                        value: "02040200",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "NN.NNNN", format: 3 },
                        title: { en: "Instantaneous Phase C Reactive Power", zh: "瞬时C相无功功率" },
                        value: "02040300",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "NN.NNNN", format: 3 },
                        title: { en: "Instantaneous Total Apparent Power", zh: "瞬时总视在功率" },
                        value: "02050000",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "NN.NNNN", format: 3 },
                        title: { en: "Instantaneous Phase A Apparent Power", zh: "瞬时A相视在功率" },
                        value: "02050100",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "NN.NNNN", format: 3 },
                        title: { en: "Instantaneous Phase B Apparent Power", zh: "瞬时B相视在功率" },
                        value: "02050200",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "NN.NNNN", format: 3 },
                        title: { en: "Instantaneous Phase C Apparent Power", zh: "瞬时C相视在功率" },
                        value: "02050300",
                      },
                      { disabled: "!(data.address_type === '11')", form_ctrl: { byte_order: "XXXXXX.XX", format: 2 }, title: { en: "Current Remaining Power", zh: "当前剩余电量" }, value: "00900100" },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Current Overdraft Capacity", zh: "当前透支电量" },
                        value: "00900101",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Current Remaining Amount", zh: "当前剩余金额" },
                        value: "00900200",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Current Overdraft Amount", zh: "当前透支金额" },
                        value: "00900201",
                      },
                      { disabled: "!(data.address_type === '11')", form_ctrl: { byte_order: "XXXX.XXXX", format: 4 }, title: { en: "Current Step Tariff", zh: "当前阶梯电价" }, value: "0208000B" },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Total current reverse active power", zh: "当前反向有功总电量" },
                        value: "00020000",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Current reverse active rate 1 electric quantity", zh: "当前反向有功费率1电量" },
                        value: "00020100",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Current reverse active rate 2 electric quantity", zh: "当前反向有功费率2电量" },
                        value: "00020200",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Current reverse active rate 3 electric quantity", zh: "当前反向有功费率3电量" },
                        value: "00020300",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Current reverse active rate 4 electric quantity", zh: "当前反向有功费率4电量" },
                        value: "00020400",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Total current combined active power", zh: "当前组合有功总电量" },
                        value: "00000000",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Current combined active power rate 1 electricity", zh: "当前组合有功费率1电量" },
                        value: "00000100",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Current combined active power rate 2 electricity", zh: "当前组合有功费率2电量" },
                        value: "00000200",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Current combined active power rate 3 electricity", zh: "当前组合有功费率3电量" },
                        value: "00000300",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Current combined active power rate 4 electricity", zh: "当前组合有功费率4电量" },
                        value: "00000400",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Total current combined reactive power", zh: "当前组合无功总电量" },
                        value: "00030000",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Current combined reactive power rate 1 electricity", zh: "当前组合无功费率1电量" },
                        value: "00030100",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Current combined reactive power rate 2 electricity", zh: "当前组合无功费率2电量" },
                        value: "00030200",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Current combined reactive power rate 3 electricity", zh: "当前组合无功费率3电量" },
                        value: "00030300",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Current combined reactive power rate 4 electricity", zh: "当前组合无功费率4电量" },
                        value: "00030400",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Total positive apparent power", zh: "正向视在总电量" },
                        value: "00090000",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Positive apparent rate 1 electricity", zh: "正向视在费率1电量" },
                        value: "00090100",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Positive apparent rate 2 electricity", zh: "正向视在费率2电量" },
                        value: "00090200",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Positive apparent rate 3 electricity", zh: "正向视在费率3电量" },
                        value: "00090300",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Positive apparent rate 4 electricity", zh: "正向视在费率4电量" },
                        value: "00090400",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Reverse apparent total power", zh: "反向视在总电量" },
                        value: "000A0000",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Reverse apparent rate 1 electricity", zh: "反向视在费率1电量" },
                        value: "000A0100",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Reverse apparent rate 2 electricity", zh: "反向视在费率2电量" },
                        value: "000A0200",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Reverse apparent rate 3 electricity", zh: "反向视在费率3电量" },
                        value: "000A0300",
                      },
                      {
                        disabled: "!(data.address_type === '11')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Reverse apparent rate 4 electricity", zh: "反向视在费率4电量" },
                        value: "000A0400",
                      },
                      { disabled: "!(data.address_type === '1C')", title: { en: "Switch Off", zh: "拉闸" }, value: "1A" },
                      { disabled: "!(data.address_type === '1C')", title: { en: "Switch ON Permission", zh: "合闸允许" }, value: "1B" },
                      { disabled: "!(data.address_type === '1C')", title: { en: "Switch ON", zh: "合闸" }, value: "1C" },
                      { disabled: "!(data.address_type === '1C')", title: { en: "Warning", zh: "报警" }, value: "2A" },
                      { disabled: "!(data.address_type === '1C')", title: { en: "Warning Release", zh: "报警解除" }, value: "2B" },
                      { disabled: "!(data.address_type === '1C')", title: { en: "Power Protection", zh: "保电" }, value: "3A" },
                      { disabled: "!(data.address_type === '1C')", title: { en: "Power Protection Release", zh: "保电解除" }, value: "3B" },
                      { disabled: "!(data.address_type === '08')", title: { en: "Broadcast Timing", zh: "广播校时" }, value: "08" },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "YYMMDDWW" },
                        title: { en: "Date And Week", zh: "日期及星期" },
                        value: "04000101",
                      },
                      { disabled: "!(data.address_type === '11' || data.address_type === '14')", form_ctrl: { byte_order: "hhmmss" }, title: { en: "Time", zh: "时间" }, value: "04000102" },
                      { disabled: "!(data.address_type === '11' || data.address_type === '14')", form_ctrl: { byte_order: "NN" }, title: { en: "Max Time", zh: "最大需量时间" }, value: "04000103" },
                      { disabled: "!(data.address_type === '11' || data.address_type === '14')", form_ctrl: { byte_order: "NN" }, title: { en: "Slip time", zh: "滑差时间" }, value: "04000104" },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "YYMMDDhhmm" },
                        title: { en: "Switching time of two sets of time zone tables", zh: "两套时区表切换时间" },
                        value: "04000106",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "YYMMDDhhmm" },
                        title: { en: "Switching time of two sets of daily time table", zh: "两套日时段表切换时间" },
                        value: "04000107",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Number of time zones per year p≤14", zh: "年时区数 p≤14" },
                        value: "04000201",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Number of daily period tables q≤8", zh: "日时段表数 q≤8" },
                        value: "04000202",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Number of daily periods (number of daily switches) m≤14", zh: "日时段数(每日切换数) m≤14" },
                        value: "04000203",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Rate number k≤63", zh: "费率数 k≤63" },
                        value: "04000204",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NNNN" },
                        title: { en: "Number of public holidays n≤254", zh: "公共假日数 n≤254" },
                        value: "04000205",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Harmonic analysis times", zh: "谐波分析次数" },
                        value: "04000206",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Number of automatic cycle display screens", zh: "自动循环显示屏数" },
                        value: "04000301",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Display time per screen", zh: "每屏显示时间" },
                        value: "04000302",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Display decimal places of electric energy", zh: "显示电能小数位数" },
                        value: "04000303",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Display power (maximum demand) decimal places", zh: "显示功率(最大需量)小数位数" },
                        value: "04000304",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Number of key cycle displays", zh: "按键循环显示屏数" },
                        value: "04000305",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NNNNNNNNNNNN" },
                        title: { en: "Communication Address", zh: "通信地址" },
                        value: "04000401",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NNNNNNNNNNNN" },
                        title: { en: "Meter Number", zh: "表号" },
                        value: "04000402",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN...NN(ASCII)32" },
                        title: { en: "Asset management code(ASCII)", zh: "资产管理编码(ASCII 码)" },
                        value: "04000403",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Characteristic word of active power combination mode", zh: "有功组合方式特征字" },
                        value: "04000601",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Characteristic word of reactive power combination mode 1", zh: "无功组合方式 1 特征字" },
                        value: "04000602",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Characteristic word of reactive power combination mode 2", zh: "无功组合方式 2 特征字" },
                        value: "04000603",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Modulation type infrared optical port communication speed characteristic word", zh: "调制型红外光口通信速率特征字" },
                        value: "04000701",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Contact infrared optical port communication speed character", zh: "接触式红外光口通信速率特征字" },
                        value: "04000702",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Communication rate characteristic word of communication port 1", zh: "通信口1通信速率特征字" },
                        value: "04000703",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Communication rate characteristic word of communication port 2", zh: "通信口2通信速率特征字" },
                        value: "04000704",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Communication rate characteristic word of communication port 3", zh: "通信口3通信速率特征字" },
                        value: "04000705",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Weekly rest day character", zh: "周休日特征字" },
                        value: "04000801",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Table no. of daily period adopted on weekly rest days", zh: "周休日采用的日时段表号" },
                        value: "04000802",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN" },
                        title: { en: "Load recording mode word", zh: "负荷记录模式字" },
                        value: "04000901",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "MMDDhhmm" },
                        title: { en: "Load record start time", zh: "负荷记录起始时间" },
                        value: "04000A01",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NNNN" },
                        title: { en: "Recording interval of type 1 load", zh: "第1类负荷记录间隔时间" },
                        value: "04000A02",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NNNN" },
                        title: { en: "Recording interval of type 2 load", zh: "第2类负荷记录间隔时间" },
                        value: "04000A03",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NNNN" },
                        title: { en: "Recording interval of type 3 load", zh: "第3类负荷记录间隔时间" },
                        value: "04000A04",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NNNN" },
                        title: { en: "Recording interval of type 4 load", zh: "第4类负荷记录间隔时间" },
                        value: "04000A05",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NNNN" },
                        title: { en: "Recording interval of type 5 load", zh: "第5类负荷记录间隔时间" },
                        value: "04000A06",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NNNN" },
                        title: { en: "Recording interval of type 6 load", zh: "第6类负荷记录间隔时间" },
                        value: "04000A07",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "DDhh" },
                        title: { en: "The first settlement day of each month", zh: "每月第1结算日" },
                        value: "04000B01",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "DDhh" },
                        title: { en: "The second settlement day of each month", zh: "每月第2结算日" },
                        value: "04000B02",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "DDhh" },
                        title: { en: "The third settlement day of each month", zh: "每月第3结算日" },
                        value: "04000B03",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "N.NNN", format: 3 },
                        title: { en: "Phase A conductivity", zh: "A 相电导系数" },
                        value: "04000D01",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "N.NNN", format: 3 },
                        title: { en: "Susceptance coefficient of phase A", zh: "A 相电纳系数" },
                        value: "04000D02",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "N.NNN", format: 3 },
                        title: { en: "Resistance coefficient of phase A", zh: "A 相电阻系数" },
                        value: "04000D03",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "N.NNN", format: 3 },
                        title: { en: "Phase A reactance coefficient", zh: "A 相电抗系数" },
                        value: "04000D04",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "N.NNN", format: 3 },
                        title: { en: "Phase B conductivity", zh: "B 相电导系数" },
                        value: "04000D05",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "N.NNN", format: 3 },
                        title: { en: "Susceptance coefficient of phase B", zh: "B 相电纳系数" },
                        value: "04000D06",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "N.NNN", format: 3 },
                        title: { en: "Resistance coefficient of phase B", zh: "B 相电阻系数" },
                        value: "04000D07",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "N.NNN", format: 3 },
                        title: { en: "Phase B reactance coefficient", zh: "B 相电抗系数" },
                        value: "04000D08",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "N.NNN", format: 3 },
                        title: { en: "Phase C conductivity", zh: "C 相电导系数" },
                        value: "04000D09",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "N.NNN", format: 3 },
                        title: { en: "Susceptance coefficient of phase C", zh: "C 相电纳系数" },
                        value: "04000D0A",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "N.NNN", format: 3 },
                        title: { en: "Resistance coefficient of phase  C", zh: "C 相电阻系数" },
                        value: "04000D0B",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "N.NNN", format: 3 },
                        title: { en: "Phase C reactance coefficient", zh: "C 相电抗系数" },
                        value: "04000D0C",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN.NNNN", format: 3 },
                        title: { en: "Upper limit value of positive active power", zh: "正向有功功率上限值" },
                        value: "04000E01",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NN.NNNN", format: 3 },
                        title: { en: "Upper limit value of reverse active power", zh: "反向有功功率上限值" },
                        value: "04000E02",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NNN.N", format: 1 },
                        title: { en: "Upper voltage limit", zh: "电压上限值" },
                        value: "04000E03",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "NNN.N", format: 1 },
                        title: { en: "Lower voltage limit", zh: "电压下限值" },
                        value: "04000E04",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "XXXX.XX", format: 2 },
                        title: { en: "Alarm power 1 limit", zh: "报警电量1限值" },
                        value: "04000F01",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "XXXX.XX", format: 2 },
                        title: { en: "Alarm power 2 limit", zh: "报警电量2限值" },
                        value: "04000F02",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "XXXX.XX", format: 2 },
                        title: { en: "Overdraft power limit", zh: "透支电量限值" },
                        value: "04000F04",
                      },
                      {
                        disabled: "!(data.address_type === '11' || data.address_type === '14')",
                        form_ctrl: { byte_order: "XXXXXX.XX", format: 2 },
                        title: { en: "Limit of stored electricity", zh: "囤积电量限值" },
                        value: "04000F03",
                      },
                    ],
                    index: 7,
                    placeholder: { en: "Select or enter data ID DI3~DI0", zh: "选择或输入数据标识DI3~DI0" },
                    required: true,
                    show_on_table: true,
                    table_width: 300,
                    title: { en: "Data Addr", zh: "采集点位" },
                    type: "string",
                  },
                  address_type: {
                    default: "11",
                    enum: [
                      { disabled: "data.read_write === 'wo' || data.read_write === 'co'", title: { en: "Read Data", zh: "读数据" }, value: "11" },
                      { disabled: "data.read_write === 'ro' || data.read_write === 'co'", title: { en: "Write Data", zh: "写数据" }, value: "14" },
                      { disabled: "!(data.read_write === 'co')", title: { en: "Tripping And Closing Alarm And Power Protection", zh: "跳合闸、报警、保电" }, value: "1C" },
                      { disabled: "!(data.read_write === 'co')", title: { en: "Broadcast Timing", zh: "广播校时" }, value: "08" },
                    ],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Control Code", zh: "控制码" },
                    type: "string",
                  },
                  bit_bias: { default: "0", enum: [], index: 9, required: false, show: "", show_on_table: false, table_width: 200, title: { en: "Bit Bias", zh: "偏移" }, type: "string" },
                  byte_order: {
                    default: "XXXXXX.XX",
                    enum: [
                      { title: "XXXXXX.XX", value: "XXXXXX.XX" },
                      { title: "XXX.X", value: "XXX.X" },
                      { title: "XX.XX", value: "XX.XX" },
                      { title: "X.XXX", value: "X.XXX" },
                      { title: "XX.XXXX-YYMMDDhhmm", value: "XX.XXXX-YYMMDDhhmm" },
                      { title: "XXX.XXX", value: "XXX.XXX" },
                      { title: "XX.XXXX", value: "XX.XXXX" },
                      { title: "XXXXXXXX", value: "XXXXXXXX" },
                      { title: "XX", value: "XX" },
                      { title: "XXXX", value: "XXXX" },
                      { title: "YYMMDDWW", value: "YYMMDDWW" },
                      { title: "YYMMDDhhmm", value: "YYMMDDhhmm" },
                      { title: "YYMMDDhhmmss", value: "YYMMDDhhmmss" },
                      { title: "YYMMDDhhmmNN", value: "YYMMDDhhmmNN" },
                      { title: "NNNNNNNNNNNN", value: "NNNNNNNNNNNN" },
                      { title: "XXXXXXXXXXXX(ASCII)", value: "XXXXXXXXXXXX(ASCII)" },
                      { title: "N.NNN", value: "N.NNN" },
                      { title: "hhmm", value: "hhmm" },
                      { title: "hhmmss", value: "hhmmss" },
                      { title: "NNNN", value: "NNNN" },
                      { title: "NNN.N", value: "NNN.N" },
                      { title: "NN", value: "NN" },
                      { title: "XXXXXX", value: "XXXXXX" },
                      { title: "hhmmNN", value: "hhmmNN" },
                      { title: "MMDDNN", value: "MMDDNN" },
                      { title: "C0C1C2C3", value: "C0C1C2C3" },
                      { title: "DDhh", value: "DDhh" },
                      { title: "NN.NNNN", value: "NN.NNNN" },
                      { title: "MMDDhhmm", value: "MMDDhhmm" },
                      { title: "XXXX.XXXX", value: "XXXX.XXXX" },
                      { title: "XXXXXX,XXXXXX", value: "XXXXXX,XXXXXX" },
                      { title: "NN...NN(ASCII)32", value: "NN...NN(ASCII)32" },
                      { title: "XXXXXXXX(ASCII)", value: "XXXXXXXX(ASCII)" },
                      { title: "XX...XX(ASCII)10", value: "XX...XX(ASCII)10" },
                      { title: "XX...XX(ASCII)16", value: "XX...XX(ASCII)16" },
                      { title: "NNNNNNNN", value: "NNNNNNNN" },
                      { title: "YYMMDDNN", value: "YYMMDDNN" },
                      { title: "NNNNNNNN,NN", value: "NNNNNNNN,NN" },
                      { title: "01", value: "01" },
                      { title: "XXXX.XX", value: "XXXX.XX" },
                    ],
                    index: 8,
                    required: true,
                    show: "data.read_write === 'ro' || data.read_write === 'rw' || data.read_write === 'wo'",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Byte Order", zh: "数据格式" },
                    type: "string",
                  },
                  data_type: { enum: [], index: 3, required: true, show_on_table: false, table_width: 300, title: { en: "Date Type", zh: "数据类型" }, type: "string" },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    enum: [1, 2, 3, 4],
                    index: 12,
                    placeholder: { en: "Decimal places correspond to the data format", zh: "小数位与数据格式对应" },
                    required: true,
                    show: "data.byte_order === 'XXXX.XX' || data.byte_order === 'XXXXXX.XX' || data.byte_order === 'NNN.N' || data.byte_order === 'XXX.X' || data.byte_order === 'XX.XX' || data.byte_order === 'X.XXX' || data.byte_order === 'XXX.XXX' || data.byte_order === 'XX.XXXX' || data.byte_order === 'N.NNN' || data.byte_order === 'XXXX.XXXX' || data.byte_order === 'NN.NNNN'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Decimal Places", zh: "小数点位数" },
                    type: "int",
                  },
                  group: { default: "default_group", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: {
                    default: 1,
                    index: 5,
                    max: 255,
                    min: 1,
                    required: true,
                    show: "data.data_type === 'hex' || data.data_type === 'string'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Length", zh: "变量长度" },
                    type: "int",
                  },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    default: "ro",
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                      { title: { en: "Control", zh: "控制" }, value: "co" },
                    ],
                    index: 4,
                    required: true,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Read Or Write", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "kWh", "kvarh", "kVah", "%RH", "kW", "kvar", "A", "kVa", "V", "Hz", "分", "%", "℃", "Kw.h", "次.分", "Ah", "次", "个", "秒", "位", "毫秒"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "Ua", "Ua", "int16", "ro", 1, "default", "02010100", "XXX.X", "0", null, "default_group"],
                required: ["name", "title", "group", "read_write", "address_type", "address", "byte_order", "format", "unit"],
              },
              title: { en: "VPT Type", zh: "协议版本" },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            operator_code: { check: "^[0-9A-F]{8,8}$", default: "12345678", required: true, show: "write === 1", title: { en: "Operator Code", zh: "操作者代码" }, type: "string" },
            password: { check: "^[0-9A-F]{8,8}$", default: "00000002", required: true, show: "write === 1", title: { en: "Password", zh: "密码" }, type: "password" },
            required: ["device_type", "device_name", "address", "enabled", "interval_ms", "write", "password", "operator_code", "subscribe"],
            subscribe: { default: "", required: false, title: { en: "Write Device", zh: "数据下发" }, type: "northOutput" },
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
            write: {
              default: 0,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled Write Data", zh: "是否下发数据" },
              type: "bool",
            },
          },
          required: ["port", "mode", "baudrate", "data_bits", "parity", "stop_bits"],
          title: { en: "DLT645 VPT", zh: "DLT645通讯" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: true,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["serial"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "dlt645_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "dlt645",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "DTL645 VPT", zh: "DLT645采集协议" },
    version: "1.0.0",
  },
  dnp3_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        tcp: {
          channel_config: {},
          device_config: {
            address: {
              check: "^((25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))\\.){3}(25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))$",
              required: true,
              title: { en: "Ip Address", zh: "IP地址" },
              type: "string",
            },
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  address: { default: 1, index: 7, max: 5000, min: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Number of Points", zh: "点数" }, type: "int" },
                  address_type: {
                    enum: [
                      { title: { en: "binary input", zh: "二进制输入" }, value: 1 },
                      { title: { en: "double input", zh: "双点输入" }, value: 2 },
                      { title: { en: "binary output", zh: "二进制输出" }, value: 3 },
                      { title: { en: "counter input", zh: "计数器输入" }, value: 4 },
                      { title: { en: "analog input", zh: "模拟输入" }, value: 5 },
                      { title: { en: "analog outputs", zh: "模拟输出" }, value: 6 },
                      { title: { en: "octect string", zh: "八进制字符串" }, value: 7 },
                    ],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "DNP3 Group ID", zh: "数据类型" },
                    type: "string",
                  },
                  bit_bias: { default: 0, index: 9, required: true, show_on_table: true, table_width: 200, title: { en: "Bit Bias", zh: "偏移" }, type: "int" },
                  byte_order: { default: " ", enum: [], index: 8, required: true, show_on_table: false, table_width: 200, title: { en: "Class", zh: "类别" }, type: "string" },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: ["", 0],
                    enum: [],
                    index: 12,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Units &amp; decimal places", zh: "单位&小数点位数" },
                    type: "string",
                  },
                  group: { default: "暂不分组", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Permission", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "kWh", "kvarh", "kVah", "%RH", "kW", "kvar", "A", "kVa", "V", "Hz", "分", "%", "℃", "Kw.h", "次.分", "Ah", "次", "个", "秒", "位", "毫秒"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "reg1", "reg1", "int16", "ro", 1, "", 0, "", "0", { scale: {}, selected: "", zoom: {} }, "default_group"],
                required: ["name", "title", "group", "address_type", "address", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            master_addr: { default: 2, max: 65519, min: 1, required: true, title: { en: "Master Address", zh: "主站地址" }, type: "int" },
            port: { default: 20000, max: 65535, min: 1, required: true, title: { en: "Port", zh: "端口号" }, type: "int" },
            required: ["device_name", "address", "port", "master_addr", "slave_addr", "enabled", "interval_ms"],
            slave_addr: { default: 1, max: 65519, min: 1, required: true, title: { en: "Slave Address", zh: "从站地址" }, type: "int" },
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          title: { en: "DNP3 VPT", zh: "DNP3协议" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: false,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp"],
    config_version: 2,
    description: { en: "", zh: "" },
    driver_name: "dnp3_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "dnp3",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "DNP3 VPT", zh: "DNP3协议" },
    version: "1.0.0",
  },
  fins_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        fins: {
          channel_config: {},
          device_config: {
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  address: { index: 7, required: true, show_on_table: true, table_width: 200, title: { en: "Register Address", zh: "寄存器地址" }, type: "string" },
                  area_code: {
                    default: "*",
                    enum: [
                      { title: { en: "Auto", zh: "自动" }, value: "*" },
                      { title: "A", value: "A" },
                      { title: "CCY", value: "CCY" },
                      { title: "CEQ", value: "CEQ" },
                      { title: "CER", value: "CER" },
                      { title: "CGE", value: "CGE" },
                      { title: "CGT", value: "CGT" },
                      { title: "CIO", value: "CIO" },
                      { title: "CLE", value: "CLE" },
                      { title: "CLT", value: "CLT" },
                      { title: "CNE", value: "CNE" },
                      { title: "CNT", value: "CNT" },
                      { title: "COF", value: "COF" },
                      { title: "CUF", value: "CUF" },
                      { title: "DM", value: "DM" },
                      { title: "DR", value: "DR" },
                      { title: "E", value: "E" },
                      { title: "EA_", value: "EA_" },
                      { title: "EB_", value: "EB_" },
                      { title: "EC_", value: "EC_" },
                      { title: "EM", value: "EM" },
                      { title: "H", value: "H" },
                      { title: "IR", value: "IR" },
                      { title: "NEG", value: "NEG" },
                      { title: "OFF", value: "OFF" },
                      { title: "ON", value: "ON" },
                      { title: "TIM", value: "TIM" },
                      { title: "TK", value: "TK" },
                      { title: "TKS", value: "TKS" },
                      { title: "W", value: "W" },
                    ],
                    index: 14,
                    required: false,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Memory Area", zh: "存储区" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  fins_data_type: {
                    enum: [
                      { title: "INT16", value: 1 },
                      { title: "INT32", value: 2 },
                      { title: "UINT16", value: 3 },
                      { title: "UINT32", value: 4 },
                      { title: "BCD16", value: 5 },
                      { title: "BCD32", value: 6 },
                      { title: "SBCD16_0", value: 7 },
                      { title: "SBCD16_1", value: 8 },
                      { title: "SBCD16_2", value: 9 },
                      { title: "SBCD16_3", value: 10 },
                      { title: "SBCD32_0", value: 11 },
                      { title: "SBCD32_1", value: 12 },
                      { title: "SBCD32_2", value: 13 },
                      { title: "SBCD32_3", value: 14 },
                      { disabled: "true", title: "FLOAT", value: 15 },
                      { disabled: "true", title: "DOUBLE", value: 16 },
                      { title: "BIT", value: 17 },
                      { disabled: "true", title: "BIT FORCED", value: 18 },
                      { disabled: "true", title: "WORD FORCED", value: 19 },
                    ],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Date Type", zh: "变量类型" },
                    type: "int",
                  },
                  format: {
                    default: ["", 0],
                    enum: [],
                    index: 12,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Units &amp; decimal places", zh: "单位&小数点位数" },
                    type: "string",
                  },
                  group: { default: "暂不分组", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: {
                    default: 1,
                    index: 5,
                    max: 255,
                    min: 1,
                    required: false,
                    show: "data.data_type === 'hex' || data.data_type === 'string'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Length", zh: "变量长度" },
                    type: "int",
                  },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Permission", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 17)",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "reg1", "reg1", "int16", "ro", 1, "", 0, "", "0", { scale: {}, selected: "", zoom: {} }, "default_group"],
                required: ["name", "title", "group", "fins_data_type", "area_code", "address", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            ip: {
              check: "^((25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))\\.){3}(25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))$",
              required: true,
              title: { en: "Ip Address", zh: "IP地址" },
              type: "string",
            },
            local_net: { default: 0, max: 127, min: 0, required: true, title: { en: "Source network address", zh: "源网络地址" }, type: "int" },
            local_node: { default: 0, max: 255, min: 0, required: true, title: { en: "Source node number", zh: "源节点编号" }, type: "int" },
            local_unit: { default: 0, max: 255, min: 0, required: true, title: { en: "Source unit address", zh: "源单元地址" }, type: "int" },
            plc_mode: {
              default: 1,
              enum: [
                { title: "CV PLC", value: 1 },
                { title: "CS/CJ PLC", value: 2 },
                { title: "AUTO", value: 0 },
              ],
              required: false,
              title: { en: "PLC Mode", zh: "PLC模式" },
              type: "int",
            },
            port: { default: 9600, max: 65535, min: 0, required: true, title: { en: "Port", zh: "端口号" }, type: "int" },
            protocol: { default: "tcp", enum: ["TCP", "UDP"], required: true, title: { en: "Transport VPT", zh: "传输协议" }, type: "string" },
            remote_net: { default: 0, max: 127, min: 0, required: true, title: { en: "Destination network address", zh: "目的网络地址" }, type: "int" },
            remote_node: { default: 0, max: 255, min: 0, required: true, title: { en: "Destination node number", zh: "目的点编号" }, type: "int" },
            remote_unit: { default: 0, max: 255, min: 0, required: true, title: { en: "Destination unit address", zh: "目的元地址" }, type: "int" },
            required: ["device_name", "enabled", "interval_ms", "protocol", "ip", "port", "plc_mode", "remote_net", "remote_node", "remote_unit", "local_net", "local_node", "local_unit"],
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          title: { en: "fins", zh: "fins" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: false,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["fins"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "fins_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "fins",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "Omron Fins", zh: "欧姆龙Fins协议采集" },
    version: "1.1.0",
  },
  fuzz_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      show_communication_type: false,
      communication_type: {
        fuzz: {
          channel_config: {},
          device_config: {
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  address: {
                    default: 1,
                    index: 7,
                    required: true,
                    show: "data.address_type != 'random'",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Value/Step", zh: "定值/步长" },
                    type: "int",
                  },
                  address_type: {
                    default: "monotonic_increase",
                    enum: [
                      { title: { en: "Static Value", zh: "固定值" }, value: "static_value" },
                      { title: { en: "Monotonic Increase", zh: "单调递增" }, value: "monotonic_increase" },
                      { title: { en: "Random Number", zh: "随机数" }, value: "random" },
                    ],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Function Code", zh: "功能码" },
                    type: "string",
                  },
                  data_type: {
                    default: "int16",
                    enum: [
                      { title: "BOOL(bit)", value: "bool" },
                      { title: "SINT(int8)", value: "char" },
                      { title: "INT(int16)", value: "int16" },
                      { title: "DINT(int32)", value: "int" },
                      { title: "INT64(int64)", value: "long" },
                      { title: "BYTE(uint8)", value: "byte" },
                      { title: "WORD(uint16)", value: "uint16" },
                      { title: "DWORD(uint32)", value: "uint" },
                      { title: "WORD64(uint64)", value: "ulong" },
                      { title: "FLOAT(32bit)", value: "float" },
                      { title: "DOUBLE(64bit)", value: "double" },
                      { title: "STRING(string)", value: "string" },
                      { title: "HEX(hex)", value: "hex" },
                    ],
                    index: 3,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Date Type", required: true, zh: "变量类型" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: ["", 0],
                    enum: [],
                    index: 12,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Units &amp; decimal places", zh: "单位&小数点位数" },
                    type: "string",
                  },
                  group: { default: "暂不分组", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: {
                    default: 1,
                    index: 5,
                    max: 255,
                    min: 1,
                    required: false,
                    show: "data.data_type === 'hex' || data.data_type === 'string'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Length", zh: "变量长度" },
                    type: "int",
                  },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    default: "rw",
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Permission", zh: "权限" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                },
                point_template: ["name", "reg1", "reg1", "int16", "ro", 1, "", 0, "", "0", { scale: {}, selected: "", zoom: {} }, "default_group"],
                required: ["name", "title", "group", "read_write", "data_type", "length", "address_type", "address"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            random_connect: {
              default: 0,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Random Connect", zh: "随机连接" },
              type: "bool",
            },
            required: ["device_name", "enabled", "interval_ms", "random_connect"],
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          title: { en: "fuzz", zh: "fuzz" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["fuzz"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "fuzz_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "fuzz",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "Data Generator", zh: "数据生成器" },
    version: "1.0.0",
  },
  hd_c: {
    attribute: VPTDriverDireaction.compute,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        disk: {
          channel_config: {
            hd_name: { max: 256, min: 1, required: true, title: { en: "Hd Name", zh: "缓存保存名字" }, type: "string" },
            max_delayed_pop_cnt: { default: 0, min: 0, title: { en: "Max Delayed Delete Count", zh: "最大延迟删除行数" }, type: "int" },
            max_delayed_push_cnt: { default: 10, min: 0, title: { en: "Max Delayed Save Count", zh: "最大延迟存盘行数" }, type: "int" },
            max_delayed_push_size: { default: 10, max: 1048576, min: 0, title: { en: "Max Delayed Save Size", zh: "最大延迟存盘数据大小" }, type: "int" },
            max_disk_cnt: { default: 1000, min: 1, required: true, title: { en: "Max Data Count", zh: "最大缓存行数" }, type: "int" },
            max_disk_size: { default: 10485760, max: 1073741824, min: 1024, required: true, title: { en: "Max Disk Usage", zh: "最大磁盘使用量" }, type: "int" },
            max_keep_time: { default: 3600, min: 0, title: { en: "Max Keep Time", zh: "最长保存时间" }, type: "int" },
          },
          title: { en: "Disk Hd", zh: "磁盘缓存" },
        },
        memory: {
          channel_config: {
            max_keep_time: { default: 3600, min: 0, title: { en: "Max Keep Time", zh: "最长保存时间" }, type: "int" },
            max_memory_cnt: { default: 1000, min: 1, required: true, title: { en: "Max Data Count", zh: "最大缓存行数" }, type: "int" },
            max_memory_size: { default: 1048576, max: 10485760, min: 1024, required: true, title: { en: "Max Memory Usage", zh: "最大内存使用量" }, type: "int" },
          },
          title: { en: "Memory Hd", zh: "内存缓存" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: true,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["memory", "disk"],
    config_version: 1,
    data_version: 1,
    description: { en: "", zh: "" },
    driver_name: "hd_c",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "History Data", zh: "历史数据插件" },
    version: "1.1.0",
  },
  hj212_n: {
    attribute: VPTDriverDireaction.north,
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "hj212_n",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "hj212",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "HJ212 VPT", zh: "HJ212协议" },
    version: "1.0.0",
  },
  hostlink_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        serial: {
          channel_config: {
            baudrate: { default: 9600, enum: [1200, 2400, 4800, 9600, 19200, 38400, 115200], required: true, title: { en: "Baudrate", zh: "波特率" }, type: "int" },
            data_bits: { default: 8, enum: [5, 6, 7, 8], required: true, title: { en: "Data Bits", zh: "数据位" }, type: "int" },
            mode: {
              default: "rs485",
              enum: [
                { disabled: "!(data.port === 'COM1' || data.port === 'COM2' || data.port === 'COM3' || data.port === 'COM4')", title: "RS485", value: "rs485" },
                { disabled: "!(data.port === 'COM1')", title: "RS232", value: "rs232" },
              ],
              required: true,
              title: { en: "Mode", zh: "模式" },
              type: "string",
            },
            parity: { default: "N", enum: ["N", "E", "O"], required: true, title: { en: "Parity", zh: "校验位" }, type: "string" },
            port: {
              enum: [
                { title: "COM1", value: "COM1" },
                { title: "COM2", value: "COM2" },
                { title: "COM3", value: "COM3" },
                { title: "COM4", value: "COM4" },
              ],
              required: true,
              title: { en: "Serial Port", zh: "串口" },
              type: "string",
            },
            stop_bits: { default: "1", enum: ["1", "1.5", "2"], required: true, title: { en: "Stop Bits", zh: "停止位" }, type: "string" },
          },
          device_config: {
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  address: {
                    index: 7,
                    max: 9999,
                    min: 0,
                    required: true,
                    show: "data.address_type != 'MS' && data.address_type != 'MF' && data.address_type != 'MM'",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Register Addr", zh: "寄存器地址" },
                    type: "int",
                  },
                  address_type: {
                    enum: [
                      { title: "CIO Area", value: "RR" },
                      { title: "LR Area", value: "RL" },
                      { title: "HR Area", value: "RH" },
                      { title: "Timer/Counter PV", value: "RC" },
                      { title: "Timer/Counter Status", value: "RG" },
                      { title: "DM Area", value: "RD" },
                      { title: "AR Area", value: "RJ" },
                      { title: "EM Area", value: "RE" },
                      { title: "Status Read", value: "MS" },
                      { title: "Error Read", value: "MF" },
                      { title: "PLC Mode", value: "MM" },
                    ],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Header Code", zh: "功能码" },
                    type: "string",
                  },
                  bit_bias: {
                    default: "0",
                    enum: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"],
                    index: 9,
                    required: false,
                    show: "(data.address_type != 'MS' && data.address_type != 'MF' && data.address_type != 'MM') && data.data_type === 'bool'",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Bit Bias", zh: "偏移" },
                    type: "string",
                  },
                  data_type: {
                    enum: [
                      { title: "BOOL(bit)", value: "bool" },
                      { title: "SINT(int8)", value: "char" },
                      { title: "INT(int16)", value: "int16" },
                      { title: "DINT(int32)", value: "int" },
                      { title: "INT64(int64)", value: "long" },
                      { title: "BYTE(uint8)", value: "byte" },
                      { title: "WORD(uint16)", value: "uint16" },
                      { title: "DWORD(uint32)", value: "uint" },
                      { title: "WORD64(uint64)", value: "ulong" },
                      { title: "FLOAT(32bit)", value: "float" },
                      { title: "DOUBLE(64bit)", value: "double" },
                      { title: "STRING(string)", value: "string" },
                      { title: "HEX(hex)", value: "hex" },
                    ],
                    index: 3,
                    required: true,
                    show: "data.address_type != 'MS' && data.address_type != 'MF' && data.address_type != 'MM'",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Date Type", required: true, zh: "变量类型" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: ["", 0],
                    enum: [],
                    index: 12,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Units &amp; decimal places", zh: "单位&小数点位数" },
                    type: "string",
                  },
                  group: { default: "暂不分组", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Permission", zh: "读写" },
                    type: "string",
                  },
                  str_length: {
                    default: 1,
                    index: 5,
                    max: 60,
                    min: 1,
                    required: false,
                    show: "data.data_type === 'hex' || data.data_type === 'string'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Length", zh: "变量长度" },
                    type: "int",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 17)",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "reg1", "reg1", "int16", "ro", 1, "", 0, "", "0", { scale: {}, selected: "", zoom: {} }, "default_group"],
                required: ["name", "title", "group", "address_type", "address", "data_type", "bit_bias", "str_length", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            format: { default: "1:N", enum: ["1:N", "1:1"], required: true, title: { en: "Format", zh: "格式" }, type: "string" },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            required: ["device_name", "enabled", "format", "unit_number", "interval_ms"],
            unit_number: { max: 31, min: 0, required: false, title: { en: "Unit Number", zh: "单元码" }, type: "int" },
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          required: ["port", "mode", "baudrate", "data_bits", "parity", "stop_bits"],
          title: { en: "C-mode", zh: "C-mode" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: true,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["serial"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "hostlink_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "hostlink",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "HostLink VPT", zh: "HostLink采集插件" },
    version: "1.0.0",
  },
  http_n: {
    attribute: VPTDriverDireaction.north,
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "http_n",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "http",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "HTTP Post Plugin", zh: "HTTP上传插件" },
    version: "1.0.1",
  },
  iec101_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        serial: {
          channel_config: {
            balanced_mode: {
              default: 0,
              enum: [
                { title: { en: "Balanced", zh: "平衡模式" }, value: 1 },
                { title: { en: "UnBalanced", zh: "非平衡模式" }, value: 0 },
              ],
              required: true,
              title: { en: "Mode", zh: "模式" },
              type: "bool",
            },
            baudrate: { default: 9600, enum: [1200, 2400, 4800, 9600, 19200, 38400, 115200], required: true, title: { en: "Baudrate", zh: "波特率" }, type: "int" },
            data_bits: { default: 8, enum: [7, 8], required: true, title: { en: "Data Bits", zh: "数据位" }, type: "int" },
            mode: {
              default: "rs485",
              enum: [{ disabled: "!(data.port === 'COM1' || data.port === 'COM2' || data.port === 'COM3' || data.port === 'COM4')", title: "RS485", value: "rs485" }],
              required: true,
              title: { en: "Serial Mode", zh: "串口模式" },
              type: "string",
            },
            originator_address_len: { default: 1, enum: [1, 2], required: true, title: { en: "link layer address length", zh: "链路地址长度" }, type: "int" },
            parity: { default: "E", enum: ["N", "E", "O"], required: true, title: { en: "Parity", zh: "校验位" }, type: "string" },
            port: {
              enum: [
                { title: "COM1", value: "COM1" },
                { title: "COM2", value: "COM2" },
                { title: "COM3", value: "COM3" },
                { title: "COM4", value: "COM4" },
              ],
              required: true,
              title: { en: "Serial Port", zh: "串口" },
              type: "string",
            },
            sizeOfCA: { default: 1, enum: [1, 2], required: true, title: { en: "common address length", zh: "公共地址长度" }, type: "int" },
            sizeOfCOT: { default: 1, enum: [1, 2], required: true, title: { en: "Transmission reason lengt", zh: "传送原因长度" }, type: "int" },
            sizeOfIOA: { default: 2, enum: [1, 2, 3], required: true, title: { en: "Message body address length", zh: "信息体地址长度" }, type: "int" },
            stop_bits: { default: "1", enum: ["1", "1.5", "2"], required: true, title: { en: "Stop Bits", zh: "停止位" }, type: "string" },
          },
          device_config: {
            common_address: { default: 1, max: 65535, min: 0, required: true, title: { en: "Common Address", zh: "公共地址" }, type: "int" },
            counter_interrogation: { default: 0, max: 65535, min: 0, required: true, title: { en: "Counter Interrogation(min)", zh: "电召周期(分钟)" }, type: "int" },
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: ["", 0],
                    enum: [],
                    index: 12,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Units &amp; decimal places", zh: "单位&小数点位数" },
                    type: "string",
                  },
                  group: { default: "暂不分组", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  ioa: { index: 7, max: 16777215, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "Information Address", zh: "信息对象地址" }, type: "int" },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Permission", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  type_id: {
                    enum: [
                      { title: { en: "Single point information(M_SP_NA_1)", zh: "单点信息(遥信)" }, value: 1 },
                      { title: { en: "Single point information with time tag", zh: "单点带时间标志(M_SP_TA_1)" }, value: 2 },
                      { title: { en: "Double point information(M_DP_NA_1)", zh: "双点信息(遥信)" }, value: 3 },
                      { title: { en: "Double point information with time tag", zh: "双点带时间标志(M_DP_TA_1)" }, value: 4 },
                      { title: { en: "Step position information", zh: "步进位置信息(M_ST_NA_1)" }, value: 5 },
                      { title: { en: "Step position information with time tag", zh: "步进位置信息带时间标志(M_ST_TA_1)" }, value: 6 },
                      { title: { en: "Bit string of 32 bit", zh: "32位的位字符串(M_BO_NA_1)" }, value: 7 },
                      { title: { en: "Bit string of 32 bit with time tag", zh: "32位的位字符串带时间标志(M_BO_TA_1)" }, value: 8 },
                      { title: { en: "Measured/normalized value(M_ME_NA_1)", zh: "测量值,标准化值(遥测)" }, value: 9 },
                      { title: { en: "Measured/normalized value with time tag(M_ME_TA_1)", zh: "测量值,标准化值带时间标志(遥测)" }, value: 10 },
                      { title: { en: "Measured/scaled value(M_ME_NB_1)", zh: "测量值,标度值(遥测)" }, value: 11 },
                      { title: { en: "Measured/scaled value with time tag(M_ME_TB_1)", zh: "测量值,标度值带时间标志(遥测)" }, value: 12 },
                      { title: { en: "Measured/short floating point value(M_ME_NC_1)", zh: "测量值,短浮点值(遥测)" }, value: 13 },
                      { title: { en: "Measured/short floating point value with time tag(M_ME_TC_1)", zh: "测量值,短浮点值带时间标志(遥测)" }, value: 14 },
                      { title: { en: "Integrated totals", zh: "综合总数(M_IT_NA_1)" }, value: 15 },
                      { title: { en: "Integrated totals with time tag", zh: "综合总数带时间标志(M_IT_TA_1)" }, value: 16 },
                      { title: { en: "Packed single point information with status change detection(M_PS_NA_1)", zh: "带状态变化检测的压缩单点信息(遥信)" }, value: 20 },
                      { title: { en: "Measured value, normalized value without quality descriptor(M_ME_ND_1)", zh: "测量值,无质量描述符的标准化值(遥测)" }, value: 21 },
                      { title: { en: "Single point information with time tag", zh: "带有时间标签的单点信息(M_SP_TB_1)" }, value: 30 },
                      { title: { en: "Double point information with time tag", zh: "带有时间标签的两点信息(M_DP_TB_1)" }, value: 31 },
                      { title: { en: "Step position information with time tag", zh: "带有时间标签的步进位置信息(M_ST_TB_1)" }, value: 32 },
                      { title: { en: "Bit string of 32 bit with time tag", zh: "带有时间标签的32位的字符串(M_BO_TB_1)" }, value: 33 },
                      { title: { en: "Measured value, normalized value with time tag", zh: "带有时间标签的测量值,标准化值(M_ME_TD_1)" }, value: 34 },
                      { title: { en: "Measured value, scaled value with time tag", zh: "带有时间标签的测量值,标度值(M_ME_TE_1)" }, value: 35 },
                      { title: { en: "Measured value, short floating point value with time tag", zh: "带有时间标签的测量值,短浮点值(M_ME_TF_1)" }, value: 36 },
                      { title: { en: "Integrated totals with time tag", zh: "带有时间标签的综合总数(M_IT_TB_1)" }, value: 37 },
                      { title: { en: "Event or protection equipment with time tag", zh: "带有时间标签的事件或保护设备(M_EP_TD_1)" }, value: 38 },
                      { title: { en: "Packed start events of protection equipment with time tag", zh: "带有时间标签的保护设备的打包启动事(M_EP_TE_1)" }, value: 39 },
                      { title: { en: "Packed output circuit information of protection equipment with time tag", zh: "带有时间标签的保护设备的打包输出电路信息(M_EP_TF_1)" }, value: 40 },
                      { title: { en: "End if initialization", zh: "(M_EI_NA_1)" }, value: 70 },
                    ],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 400,
                    title: { en: "Type ID", zh: "类型ID" },
                    type: "string",
                  },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "reg1", "reg1", "int16", "ro", 1, "", 0, "", "0", { scale: {}, selected: "", zoom: {} }, "default_group"],
                required: ["name", "title", "group", "type_id", "ioa", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            enabled_timing: {
              default: 0,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Timing selection", zh: "校时选择" },
              type: "bool",
            },
            interrogation_interval: { default: 15, max: 65535, min: 0, required: true, title: { en: "Interrogation Interval(min)", zh: "总召周期(分钟)" }, type: "int" },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            originator_address: { default: 0, max: 255, min: 0, required: true, title: { en: "Originator Address", zh: "链路地址" }, type: "int" },
            periodic_timing: { default: 0, max: 65535, min: 0, required: true, show: "enabled_timing === 1", title: { en: "periodic timing(min)", zh: "周期校时(分钟)" }, type: "int" },
            required: ["device_name", "originator_address", "common_address", "enabled_timing", "periodic_timing", "interrogation_interval", "enabled", "interval_ms"],
            second_data_call_cycle: { default: 30, max: 65535, min: 0, required: true, title: { en: "Secondary data call cycle", zh: "二级数据召唤周期(分钟)" }, type: "int" },
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          required: ["port", "mode", "baudrate", "data_bits", "parity", "stop_bits", "balanced_mode", "sizeOfCA", "sizeOfIOA", "sizeOfCOT", "originator_address_len"],
          title: { en: "IEC101", zh: "IEC101" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: true,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp"],
    config_version: 2,
    description: { en: "", zh: "" },
    driver_name: "iec101_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "iec101",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "Iec101 VPT", zh: "Iec101采集协议" },
    version: "1.0.1",
  },
  iec103_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        serial: {
          channel_config: {
            baudrate: { default: 9600, enum: [1200, 2400, 4800, 9600, 19200, 38400, 115200], required: true, title: { en: "Baudrate", zh: "波特率" }, type: "int" },
            data_bits: { default: 8, enum: [7, 8], required: true, title: { en: "Data Bits", zh: "数据位" }, type: "int" },
            mode: {
              default: "rs485",
              enum: [{ disabled: "!(data.port === 'COM1' || data.port === 'COM2' || data.port === 'COM3' || data.port === 'COM4')", title: "RS485", value: "rs485" }],
              required: true,
              title: { en: "Serial Mode", zh: "串口模式" },
              type: "string",
            },
            parity: { default: "E", enum: ["N", "E", "O"], required: true, title: { en: "Parity", zh: "校验位" }, type: "string" },
            port: {
              enum: [
                { title: "COM1", value: "COM1" },
                { title: "COM2", value: "COM2" },
                { title: "COM3", value: "COM3" },
                { title: "COM4", value: "COM4" },
              ],
              required: true,
              title: { en: "Serial Port", zh: "串口" },
              type: "string",
            },
            stop_bits: { default: "1", enum: ["1", "1.5", "2"], required: true, title: { en: "Stop Bits", zh: "停止位" }, type: "string" },
          },
          device_config: {
            common_address: { default: 1, max: 65535, min: 0, required: true, title: { en: "Common Address", zh: "保护地址" }, type: "int" },
            counter_interrogation: { default: 0, max: 65535, min: 0, required: true, title: { en: "Counter Interrogation(min)", zh: "电召周期(分钟)" }, type: "int" },
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  bit_bias: { default: 0, index: 9, max: 65535, min: 0, required: false, show_on_table: true, table_width: 200, title: { en: "", zh: "条目号" }, type: "int" },
                  byte_order: { default: 0, index: 8, max: 65535, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "Group Number", zh: "组号" }, type: "int" },
                  data_type: {
                    enum: [
                      { title: { en: "Remote signaling", zh: "遥测" }, value: 1 },
                      { title: { en: "Telemetering", zh: "遥信" }, value: 2 },
                    ],
                    index: 3,
                    required: true,
                    show_on_table: true,
                    table_width: 300,
                    title: { en: "Information", required: true, zh: "类型" },
                    type: "int",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: ["", 0],
                    enum: [],
                    index: 12,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Units &amp; decimal places", zh: "单位&小数点位数" },
                    type: "string",
                  },
                  group: { default: "暂不分组", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  ioa: { default: 0, index: 7, max: 65535, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "INF", zh: "信息序号(INF)" }, type: "int" },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  type_id: { default: 0, index: 6, max: 65535, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "FUN", zh: "功能类型(FUN)" }, type: "int" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "reg1", "reg1", "int16", "ro", 1, "", 0, "", "0", { scale: {}, selected: "", zoom: {} }, "default_group"],
                required: ["name", "title", "group", "data_type", "type_id", "ioa", "byte_order", "bit_bias", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interrogation_interval: { default: 15, max: 65535, min: 0, required: true, title: { en: "Interrogation Interval(min)", zh: "总召周期(分钟)" }, type: "int" },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            link_address: { default: 0, max: 255, min: 0, required: true, title: { en: "Link Player Address", zh: "链路地址" }, type: "int" },
            periodic_timing: { default: 0, max: 65535, min: 0, required: true, title: { en: "periodic timing(min)", zh: "周期校时(分钟)" }, type: "int" },
            required: ["device_name", "link_address", "interrogation_interval", "enabled", "interval_ms"],
            second_data_call_cycle: { default: 30, max: 65535, min: 0, required: true, title: { en: "Secondary data call cycle", zh: "二级数据召唤周期(分钟)" }, type: "int" },
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          required: ["port", "mode", "baudrate", "data_bits", "parity", "stop_bits"],
          title: { en: "IEC103 Serial", zh: "IEC103串口模式" },
        },
        tcp: {
          channel_config: {},
          device_config: {
            address: {
              check: "^((25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))\\.){3}(25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))$",
              required: true,
              title: { en: "Ip Address", zh: "设备地址" },
              type: "string",
            },
            common_address: { default: 1, max: 65535, min: 0, required: true, title: { en: "Common Address", zh: "保护地址" }, type: "int" },
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  bit_bias: { default: 0, index: 9, max: 65535, min: 0, required: false, show_on_table: true, table_width: 200, title: { en: "", zh: "条目号" }, type: "int" },
                  byte_order: { default: 0, index: 8, max: 65535, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "Group Number", zh: "组号" }, type: "int" },
                  data_type: {
                    enum: [
                      { title: { en: "Remote signaling", zh: "遥测" }, value: 1 },
                      { title: { en: "Telemetering", zh: "遥信" }, value: 2 },
                    ],
                    index: 3,
                    required: true,
                    show_on_table: true,
                    table_width: 300,
                    title: { en: "Information", required: true, zh: "类型" },
                    type: "int",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: ["", 0],
                    enum: [],
                    index: 12,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Units &amp; decimal places", zh: "单位&小数点位数" },
                    type: "string",
                  },
                  group: { default: "暂不分组", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  ioa: { default: 0, index: 7, max: 65535, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "INF", zh: "信息序号(INF)" }, type: "int" },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  type_id: { default: 0, index: 6, max: 65535, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "FUN", zh: "功能类型(FUN)" }, type: "int" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "reg1", "reg1", "int16", "ro", 1, "", 0, "", "0", { scale: {}, selected: "", zoom: {} }, "default_group"],
                required: ["name", "title", "group", "data_type", "type_id", "ioa", "byte_order", "bit_bias", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interrogation_interval: { default: 15, max: 65535, min: 0, required: true, title: { en: "Interrogation Interval(min)", zh: "总召周期(分钟)" }, type: "int" },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            link_address: { default: 0, max: 255, min: 0, required: true, title: { en: "Link Player Address", zh: "链路地址" }, type: "int" },
            port: { default: 1048, max: 65535, min: 1, required: true, title: { en: "Port", zh: "端口号" }, type: "int" },
            required: ["device_name", "address", "port", "link_address", "interrogation_interval", "enabled", "interval_ms"],
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          title: { en: "IEC103 UDP", zh: "IEC103 UDP" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: true,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp", "serial"],
    config_version: 2,
    description: { en: "", zh: "" },
    driver_name: "iec103_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "iec103",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "Iec103 VPT", zh: "Iec103采集协议" },
    version: "1.0.1",
  },
  iec104_n: {
    attribute: VPTDriverDireaction.north,
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "iec104_n",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "iec104",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "IEC104 Slave", zh: "IEC104 从站" },
    version: "1.0.0",
  },
  iec104_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        tcp: {
          channel_config: {
            sizeOfCA: { default: 2, enum: [1, 2], required: false, title: { en: "Size of Common Address", zh: "公共地址长度" }, type: "int" },
            sizeOfCOT: { default: 2, enum: [1, 2], required: false, title: { en: "Size of COT", zh: "传输原因长度" }, type: "int" },
            sizeOfIOA: { default: 3, enum: [2, 3], required: false, title: { en: "Size of IOA", zh: "信息体地址长度" }, type: "int" },
            useJsonValue: {
              default: 0,
              enum: [
                { title: { en: "Json Value", zh: "Json数据" }, value: 1 },
                { title: { en: "Simplified Value", zh: "简化数据" }, value: 0 },
              ],
              required: false,
              title: { en: "Data Format", zh: "数据格式" },
              type: "bool",
            },
          },
          device_config: {
            address: {
              check: "^((25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))\\.){3}(25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))$",
              required: true,
              title: { en: "Ip Address", zh: "IP地址" },
              type: "string",
            },
            ca_cert: { disabled: "!data.enable_ssl", required: false, title: { en: "CA Certificate", zh: "CA证书" }, type: "string" },
            client_cert: { required: false, title: { en: "Client Certificate", zh: "客户端证书" }, type: "string" },
            client_key: { required: false, title: { en: "Client Key", zh: "客户端私钥" }, type: "string" },
            client_key_password: { required: false, title: { en: "Client Key Password", zh: "客户端私钥密码" }, type: "string" },
            common_address: { default: 1, max: 65535, min: 0, required: true, title: { en: "Common Address", zh: "公共地址" }, type: "int" },
            counter_interrogation: { default: 15, max: 65535, min: 0, required: true, title: { en: "Counter Interrogation(min)", zh: "电度召唤周期(分钟)" }, type: "int" },
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  address_type: {
                    default: "0",
                    enum: [
                      { title: { en: "IOA", zh: "信息体地址" }, value: "0" },
                      { title: { en: "Offset 1H", zh: "遥信 1H" }, value: "1H" },
                      { title: { en: "Offset 4001H", zh: "遥测 (02版) 4001H" }, value: "4001H" },
                      { disabled: true, title: { en: "Offset 6001H", zh: "遥控 (02版) 6001H" }, value: "6001H" },
                      { disabled: true, title: { en: "Offset 6201H", zh: "设点 (02版) 6201H" }, value: "6201H" },
                      { title: { en: "Offset 6401H", zh: "遥脉 (02版) 6401H" }, value: "6401H" },
                      { title: { en: "Offset 701H", zh: "遥测 (97版) 701H" }, value: "701H" },
                      { disabled: true, title: { en: "Offset B01H", zh: "遥控 (97版) B01H" }, value: "B01H" },
                      { disabled: true, title: { en: "Offset B81H", zh: "设点 (97版) B81H" }, value: "B81H" },
                      { title: { en: "Offset C01H", zh: "遥脉 (97版) C01H" }, value: "C01H" },
                    ],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Type", zh: "类型" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: ["", 0],
                    enum: [],
                    index: 12,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Units &amp; decimal places", zh: "单位&小数点位数" },
                    type: "string",
                  },
                  group: { default: "暂不分组", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  ioa: {
                    index: 7,
                    max: 16777215,
                    min: 1,
                    required: true,
                    show: "(data.address_type === '0')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "IOA", zh: "信息体地址" },
                    type: "int",
                  },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  point_number: {
                    index: 14,
                    max: 16777215,
                    min: 1,
                    required: true,
                    show: "!(data.address_type === '0')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Point Number", zh: "点号" },
                    type: "int",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Permission", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "reg1", "reg1", "int16", "ro", 1, "", 0, "", "0", { scale: {}, selected: "", zoom: {} }, "default_group"],
                required: ["name", "title", "group", "address_type", "ioa", "point_number", "unit"],
              },
            },
            enable_ssl: {
              default: 0,
              enum: [
                { title: { en: "Yes", zh: "是" }, value: 1 },
                { title: { en: "No", zh: "否" }, value: 0 },
              ],
              required: false,
              title: { en: "Enable SSL", zh: "启用SSL" },
              type: "bool",
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interrogation_interval: { default: 15, max: 65535, min: 0, required: true, title: { en: "Interrogation Interval(min)", zh: "总召周期(分钟)" }, type: "int" },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            k: { default: 12, max: 65535, min: 0, required: true, title: { en: "k", zh: "k" }, type: "int" },
            originator_address: { default: 0, max: 255, min: 0, required: false, title: { en: "Originator Address", zh: "来源地址" }, type: "int" },
            port: { default: 2404, max: 65535, min: 0, required: true, title: { en: "Port", zh: "端口号" }, type: "int" },
            required: [
              "device_name",
              "address",
              "port",
              "originator_address",
              "common_address",
              "t1",
              "t2",
              "t3",
              "k",
              "w",
              "interrogation_interval",
              "counter_interrogation",
              "enabled",
              "interval_ms",
            ],
            t0: { default: 10, max: 65535, min: 0, required: false, title: { en: "t0 (seconds)", zh: "t0 (秒)" }, type: "int" },
            t1: { default: 15, max: 65535, min: 0, required: true, title: { en: "t1 (seconds)", zh: "t1 (秒)" }, type: "int" },
            t2: { default: 10, max: 65535, min: 0, required: true, title: { en: "t2 (seconds)", zh: "t2 (秒)" }, type: "int" },
            t3: { default: 20, max: 65535, min: 0, required: true, title: { en: "t3 (seconds)", zh: "t3 (秒)" }, type: "int" },
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
            w: { default: 8, max: 65535, min: 0, required: true, title: { en: "w", zh: "w" }, type: "int" },
          },
          required: ["sizeOfCOT", "sizeOfCA", "sizeOfIOA", "useJsonValue"],
          title: { en: "Iec104", zh: "Iec104" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: true,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp"],
    config_version: 2,
    description: { en: "", zh: "" },
    driver_name: "iec104_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "iec104",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "Iec104 VPT", zh: "Iec104采集协议" },
    version: "1.0.2",
  },
  iec61850_n: {
    attribute: VPTDriverDireaction.north,
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "iec61850_n",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "iec61850",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "IEC61850 Server", zh: "IEC61850服务" },
    version: "1.0.0",
  },
  iec61850_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        tcp: {
          channel_config: {
            address: {
              check: "^((25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))\\.){3}(25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))$",
              required: true,
              title: { en: "Ip Address", zh: "IP地址" },
              type: "string",
            },
            port: { default: 102, max: 65535, min: 0, required: true, title: { en: "Port", zh: "端口号" }, type: "int" },
          },
          device_config: {
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  address: { index: 7, required: true, show_on_table: true, table_width: 200, title: { en: "Data Attribute", zh: "数据/属性" }, type: "string" },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  fc: {
                    enum: [
                      { title: "ST", value: "ST" },
                      { title: "MX", value: "MX" },
                      { title: "SP", value: "SP" },
                      { title: "SV", value: "SV" },
                      { title: "CF", value: "CF" },
                      { title: "DC", value: "DC" },
                      { title: "SG", value: "SG" },
                      { title: "SE", value: "SE" },
                      { title: "SR", value: "SR" },
                      { title: "OR", value: "OR" },
                      { title: "BL", value: "BL" },
                      { title: "EX", value: "EX" },
                      { title: "CO", value: "CO" },
                      { title: "US", value: "US" },
                      { title: "MS", value: "MS" },
                      { title: "RP", value: "RP" },
                      { title: "BR", value: "BR" },
                      { title: "LG", value: "LG" },
                      { title: "GO", value: "GO" },
                    ],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Functional Constraint", zh: "FC" },
                    type: "string",
                  },
                  format: {
                    default: ["", 0],
                    enum: [],
                    index: 12,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Units &amp; decimal places", zh: "单位&小数点位数" },
                    type: "string",
                  },
                  group: { default: "暂不分组", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  ln_name: { index: 8, required: true, show_on_table: true, table_width: 200, title: { en: "Logical Node", zh: "逻辑节点" }, type: "string" },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Permission", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "reg1", "reg1", "int16", "ro", 1, "", 0, "", "0", { scale: {}, selected: "", zoom: {} }, "default_group"],
                required: ["name", "title", "group", "fc", "ln_name", "address", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            ld_name: { required: true, title: { en: "Logical Device", zh: "逻辑设备" }, type: "string" },
            required: ["device_name", "ld_name", "enabled", "interval_ms"],
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          required: ["address", "port"],
          title: { en: "Iec61850", zh: "Iec61850" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: true,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "iec61850_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "iec61850",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "Iec61850 VPT", zh: "Iec61850采集协议" },
    version: "1.0.0",
  },
  influxdb_n: {
    attribute: VPTDriverDireaction.north,
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "influxdb_n",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "influxdb",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "InfluxDB Post Plugin", zh: "InfluxDB上传插件" },
    version: "1.0.0",
  },
  lscnet_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        serial: {
          channel_config: {
            baudrate: { default: 9600, enum: [1200, 2400, 4800, 9600, 19200, 38400, 115200], required: true, title: { en: "Baudrate", zh: "波特率" }, type: "int" },
            data_bits: { default: 8, enum: [7, 8], required: true, title: { en: "Data Bits", zh: "数据位" }, type: "int" },
            mode: {
              default: "rs485",
              enum: [
                { disabled: "!(data.port === 'COM1' || data.port === 'COM2' || data.port === 'COM3' || data.port === 'COM4')", title: "RS485", value: "rs485" },
                { disabled: "!(data.port === 'COM1')", title: "RS232", value: "rs232" },
              ],
              required: true,
              title: { en: "Serial Mode", zh: "串口模式" },
              type: "string",
            },
            parity: { default: "E", enum: ["N", "E", "O"], required: true, title: { en: "Parity", zh: "校验位" }, type: "string" },
            port: {
              enum: [
                { title: "COM1", value: "COM1" },
                { title: "COM2", value: "COM2" },
                { title: "COM3", value: "COM3" },
                { title: "COM4", value: "COM4" },
              ],
              required: true,
              title: { en: "Serial Port", zh: "串口" },
              type: "string",
            },
            stop_bits: { default: "1", enum: ["1", "1.5", "2"], required: true, title: { en: "Stop Bits", zh: "停止位" }, type: "string" },
          },
          device_config: {
            address: { max: 255, min: 1, required: true, title: { en: "Station Number", zh: "站号" }, type: "int" },
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  address: { index: 7, max: 10239, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "Register Addr", zh: "寄存器地址" }, type: "int" },
                  address_type: {
                    enum: ["P", "K", "M", "F", "T", "C", "L", "D"],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Register Addr Type", zh: "寄存器类型" },
                    type: "string",
                  },
                  bit_bias: { default: 0, index: 9, required: true, show_on_table: true, table_width: 200, title: { en: "Bit Bias", zh: "偏移" }, type: "int" },
                  byte_order: { default: " ", enum: [], index: 8, required: true, show_on_table: false, table_width: 200, title: { en: "Byte Order", zh: "解析方式" }, type: "string" },
                  data_type: {
                    enum: [
                      { disabled: "data.address_type === 'D'", title: "BOOL(bit)", value: "bool" },
                      { disabled: "data.address_type === 'D'", title: "BYTE(uint8)", value: "byte" },
                      { title: "WORD(uint16)", value: "uint16" },
                      { title: "DWORD(uint32)", value: "uint32" },
                      { title: "LWORD(uint64)", value: "uint64" },
                    ],
                    index: 3,
                    required: true,
                    show_on_table: true,
                    table_width: 300,
                    title: { en: "Date Type", required: true, zh: "数据类型" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: 2,
                    enum: [1, 2, 3, 4, 5, 6, 7, 8],
                    index: 12,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Decimal places", zh: "小数点位数" },
                    type: "int",
                  },
                  group: { default: "default_group", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: { default: 1, index: 5, max: 255, min: 1, required: true, show_on_table: false, table_width: 200, title: { en: "Length", zh: "数据长度" }, type: "int" },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Permission", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "MW100", "MW100", "uint16", "ro", 1, "M", 0, "", 0, null, "default_group"],
                required: ["name", "title", "group", "address_type", "data_type", "address", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            required: ["device_name", "address", "enabled", "interval_ms"],
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          required: ["port", "mode", "baudrate", "data_bits", "parity", "stop_bits"],
          title: { en: "LS Cnet VPT", zh: "LS Cnet协议" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: true,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["serial"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "lscnet_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "lscnet",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "LS Cnet", zh: "LS Cnet协议" },
    version: "1.0.0",
  },
  lwm2m_n: {
    attribute: VPTDriverDireaction.north,
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "lwm2m_n",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "LWM2M",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "LWM2M Client VPT", zh: "LWM2M客户端协议" },
    version: "1.0.0",
  },
  lwm2m_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        tcp: {
          channel_config: {
            inet: {
              default: 23,
              enum: [
                { title: "IPV4", value: 2 },
                { title: "IPV6", value: 23 },
              ],
              required: true,
              title: { en: "IP Version", zh: "IP版本" },
              type: "int",
            },
            port: { default: "5683", max: 65535, min: 1, required: true, title: { en: "Port", zh: "端口号" }, type: "string" },
          },
          device_config: {
            address: { required: true, title: { en: "Client Number", zh: "客户端编号" }, type: "string" },
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  address: { default: "/", index: 7, required: true, show_on_table: true, table_width: 300, title: { en: "uri", zh: "uri" }, type: "string" },
                  address_type: { index: 6, required: true, show_on_table: true, table_width: 200, title: { en: "uri", zh: "uri" }, type: "string" },
                  bit_bias: { default: 0, index: 9, required: true, show_on_table: true, table_width: 200, title: { en: "Bit Bias", zh: "偏移" }, type: "int" },
                  byte_order: { default: " ", enum: [], index: 8, required: true, show_on_table: false, table_width: 200, title: { en: "Byte Order", zh: "解析方式" }, type: "string" },
                  data_type: { index: 3, required: true, show_on_table: true, table_width: 300, title: { en: "Date Type", zh: "数据类型" }, type: "string" },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: 2,
                    enum: [1, 2, 3, 4, 5, 6, 7, 8],
                    index: 12,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Decimal places", zh: "小数点位数" },
                    type: "int",
                  },
                  group: { default: "default_group", enum: [], index: 11, required: true, show_on_table: true, table_width: 100, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: {
                    default: 1,
                    index: 5,
                    max: 255,
                    min: 1,
                    required: true,
                    show: "data.data_type === 'hex' || data.data_type === 'string'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Length", zh: "数据长度" },
                    type: "int",
                  },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 300,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Permission", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "", "", "string", "ro", 1, "path", 0, "", 0, null, "default_group"],
                required: ["name", "title", "group", "address", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            required: ["device_name", "enabled", "interval_ms", "address"],
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          required: ["port", "inet"],
          title: { en: "LWM2M VPT", zh: "LWM2M协议" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: true,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "lwm2m_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "lwm2m",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "LWM2M VPT", zh: "LWM2M协议" },
    version: "1.0.0",
  },
  mitsubishiFx_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        serial: {
          channel_config: {
            baudrate: { default: 9600, enum: [1200, 2400, 4800, 9600, 19200, 38400, 115200], required: true, title: { en: "Baudrate", zh: "波特率" }, type: "int" },
            data_bits: { default: 7, enum: [5, 6, 7, 8], required: true, title: { en: "Data Bits", zh: "数据位" }, type: "int" },
            mode: {
              default: "rs232",
              enum: [
                { disabled: "!(data.port === 'COM1' || data.port === 'COM2' || data.port === 'COM3' || data.port === 'COM4')", title: "RS485", value: "rs485" },
                { disabled: "!(data.port === 'COM1')", title: "RS232", value: "rs232" },
              ],
              required: true,
              title: { en: "Mode", zh: "模式" },
              type: "string",
            },
            parity: { default: "E", enum: ["N", "E", "O"], required: true, title: { en: "Parity", zh: "校验位" }, type: "string" },
            port: {
              enum: [
                { title: "COM1", value: "COM1" },
                { title: "COM2", value: "COM2" },
                { title: "COM3", value: "COM3" },
                { title: "COM4", value: "COM4" },
              ],
              required: true,
              title: { en: "Serial Port", zh: "串口" },
              type: "string",
            },
            stop_bits: { default: 1, enum: [1, 1.5, 2], required: true, title: { en: "Stop Bits", zh: "停止位" }, type: "int" },
          },
          device_config: {
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  address: {
                    index: 7,
                    max: 65535,
                    min: 0,
                    placeholder: { en: "X Y only supports octal numbers", zh: "X、Y只支持8进制数" },
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Register Addr", zh: "寄存器地址" },
                    type: "int",
                  },
                  address_type: {
                    enum: [
                      { title: { en: "X Switching Value Input", zh: "X 开关量输入" }, value: "X" },
                      { title: { en: "Y Switching Value Output", zh: "Y 开关量输出" }, value: "Y" },
                      { title: { en: "M Auxiliary Relay", zh: "M 辅助继电器" }, value: "M" },
                      { title: { en: "D Data Register", zh: "D 数据寄存器" }, value: "D" },
                      { title: { en: "S Status Relay", zh: "S 状态继电器" }, value: "S" },
                      { title: { en: "CS Counter [Contact]", zh: "CS 计数器【接点】" }, value: "CS" },
                      { title: { en: "CN Counter [Current Value]", zh: "CN 计数器【当前值】" }, value: "CN" },
                      { title: { en: "TS Timer [Contact]", zh: "TS 定时器【接点】" }, value: "TS" },
                      { title: { en: "TN Timer [Current Value]", zh: "TN 定时器【当前值】" }, value: "TN" },
                      { title: { en: "DT Special Data Register", zh: "D 特殊数据寄存器" }, value: "DT" },
                    ],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Register Addr Type", zh: "寄存器类型" },
                    type: "string",
                  },
                  bit_bias: {
                    default: 0,
                    enum: [0, 1, 2, 3, 4, 5, 6, 7],
                    index: 9,
                    required: true,
                    show: "data.data_type === 'bool' && !(data.address_type === 'X' || data.address_type === 'Y' || data.address_type === 'S' || data.address_type === 'M')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Bit Bias", zh: "偏移" },
                    type: "int",
                  },
                  byte_order: {
                    default: "false",
                    enum: [
                      { title: { en: "NO", zh: "否" }, value: "false" },
                      { title: { en: "YES", zh: "是" }, value: "BCD" },
                    ],
                    index: 8,
                    required: true,
                    show: "data.data_type === 'int16' || data.data_type === 'int32'|| data.data_type === 'float'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "BCD Mode", zh: "BCD模式" },
                    type: "string",
                  },
                  data_type: {
                    enum: [
                      { disabled: "data.address_type === 'D' || data.address_type === 'DT' || data.address_type === 'TN' || data.address_type === 'CN'", title: "BIT(bit)", value: "bool" },
                      { disabled: "!(data.address_type === 'D' || data.address_type === 'DT' || data.address_type === 'TN' || data.address_type === 'CN')", title: "INT(int16)", value: "int16" },
                      { disabled: "!(data.address_type === 'D' || data.address_type === 'DT' || data.address_type === 'TN' || data.address_type === 'CN')", title: "DINT(int32)", value: "int32" },
                      { disabled: "!(data.address_type === 'D' || data.address_type === 'DT')", title: "FLOAT(32bit)", value: "float" },
                      { disabled: "!(data.address_type === 'D' || data.address_type === 'DT')", title: "STRING(string)", value: "string" },
                    ],
                    index: 3,
                    required: true,
                    show_on_table: true,
                    table_width: 300,
                    title: { en: "Date Type", zh: "数据类型" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: 0,
                    enum: [
                      { title: { en: "default", zh: "默认不转换" }, value: 0 },
                      { title: { en: "Convert and retain 1 decimal place", zh: "转换并保留1位小数" }, value: 1 },
                      { title: { en: "Convert and retain 2 decimal place", zh: "转换并保留2位小数" }, value: 2 },
                      { title: { en: "Convert and retain 3 decimal place", zh: "转换并保留3位小数" }, value: 3 },
                      { title: { en: "Convert and retain 4 decimal place", zh: "转换并保留4位小数" }, value: 4 },
                      { title: { en: "Convert and retain 5 decimal place", zh: "转换并保留5位小数" }, value: 5 },
                      { title: { en: "Convert and retain 6 decimal place", zh: "转换并保留6位小数" }, value: 6 },
                    ],
                    index: 12,
                    required: false,
                    show: "data.data_type === 'float'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Convert String", zh: "转字符串" },
                    type: "int",
                  },
                  group: { default: "default_group", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: {
                    default: 1,
                    index: 5,
                    max: 255,
                    min: 1,
                    required: true,
                    show: "data.data_type === 'hex' || data.data_type === 'string'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Length", zh: "变量长度" },
                    type: "int",
                  },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: 0 },
                      { title: { en: "Write Only", zh: "只写" }, value: 1 },
                      { title: { en: "Read Write", zh: "可读可写" }, value: 2 },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Permission", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "x1", "x1", "bool", "ro", 1, "X", 0, "false", 0, null, "default_group"],
                required: ["name", "title", "group", "address_type", "data_type", "format", "length", "address", "byte_order", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            required: ["device_name", "enabled", "interval_ms"],
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          required: ["port", "mode", "baudrate", "data_bits", "parity", "stop_bits"],
          title: { en: "Mitsubishi Fx VPT", zh: "三菱Fx系列编程口协议" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: true,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["serial"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "mitsubishiFx_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "mitsubishiFx",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "Mitsubishi Fx", zh: "三菱Fx系列编程口采集协议" },
    version: "1.0.0",
  },
  mitsubishiMc_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        tcp: {
          channel_config: {},
          device_config: {
            address: {
              check: "^((25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))\\.){3}(25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))$",
              required: true,
              title: { en: "Ip Address", zh: "IP地址" },
              type: "string",
            },
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  address: { index: 7, max: 65535, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "Register Addr", zh: "寄存器地址" }, type: "int" },
                  address_type: {
                    enum: [
                      { title: { en: "X Input Relay(bit)", zh: "X 输入继电器（位）" }, value: "X" },
                      { title: { en: "Y Output Relay(bit)", zh: "Y 输出继电器（位）" }, value: "Y" },
                      { title: { en: "M Input Relay(bit)", zh: "M 辅助继电器（位）" }, value: "M" },
                      { title: { en: "D Data Register(byte)", zh: "D 数据寄存器（字）" }, value: "D" },
                      { title: { en: "B Link Relay(bit)", zh: "B 链接继电器（位）" }, value: "B" },
                      { title: { en: "F Alarm Relay(bit)", zh: "F 报警继电器（位）" }, value: "F" },
                      { title: { en: "W Link Register(byte)", zh: "W 链接寄存器（字）" }, value: "W" },
                      { title: { en: "L Latch Relay(bit)", zh: "L 锁存继电器（位）" }, value: "L" },
                      { title: { en: "TS Timer Contact Register(bit)", zh: "TS 定时器触点（位）" }, value: "TS" },
                      { title: { en: "TC Timer Coil(bit)", zh: "TC 定时器线圈（位）" }, value: "TC" },
                      { title: { en: "TN Timer Current Value(byte)", zh: "TN 定时器当前值（字）" }, value: "TN" },
                      { title: { en: "SS Cumulative Timer Contact(bit)", zh: "SS 累计定时器触点（位）" }, value: "SS" },
                      { title: { en: "SC Cumulative Timer Coil(bit)", zh: "SC 累计定时器线圈（位）" }, value: "SC" },
                      { title: { en: "SN Current Value Of Cumulative Timer(byte)", zh: "SN 累计定时器当前值（字）" }, value: "SN" },
                      { title: { en: "CS Counter Contact(bit)", zh: "CS 计数器触点（位）" }, value: "CS" },
                      { title: { en: "CC Counter Coil(bit)", zh: "CC 计数器线圈（位）" }, value: "CC" },
                      { title: { en: "CN Counter Current Value(byte)", zh: "CN 计数器当前值（字）" }, value: "CN" },
                    ],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Register Addr Type", zh: "寄存器类型" },
                    type: "string",
                  },
                  bit_bias: {
                    default: 0,
                    enum: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
                    index: 9,
                    required: true,
                    show: "data.data_type === 'bool'",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Bit Bias", zh: "位偏移" },
                    type: "int",
                  },
                  byte_order: { default: "false", enum: [], index: 8, required: false, show_on_table: false, table_width: 200, title: { en: "BCD Mode", zh: "BCD模式" }, type: "string" },
                  data_type: {
                    enum: [
                      {
                        disabled: "(data.address_type === 'D' || data.address_type === 'W' || data.address_type === 'TN' || data.address_type === 'SN' || data.address_type === 'CC')",
                        title: "BIT(bit)",
                        value: "bool",
                      },
                      {
                        disabled: "!(data.address_type === 'D' || data.address_type === 'W' || data.address_type === 'TN' || data.address_type === 'SN' || data.address_type === 'CC')",
                        title: "BYTE(uint8)",
                        value: "byte",
                      },
                      {
                        disabled: "!(data.address_type === 'D' || data.address_type === 'W' || data.address_type === 'TN' || data.address_type === 'SN' || data.address_type === 'CC')",
                        title: "SINT(int8)",
                        value: "char",
                      },
                      {
                        disabled: "!(data.address_type === 'D' || data.address_type === 'W' || data.address_type === 'TN' || data.address_type === 'SN' || data.address_type === 'CC')",
                        title: "INT(int16)",
                        value: "int16",
                      },
                      {
                        disabled: "!(data.address_type === 'D' || data.address_type === 'W' || data.address_type === 'TN' || data.address_type === 'SN' || data.address_type === 'CC')",
                        title: "WORD(uint16)",
                        value: "uint16",
                      },
                      {
                        disabled: "!(data.address_type === 'D' || data.address_type === 'W' || data.address_type === 'TN' || data.address_type === 'SN' || data.address_type === 'CC')",
                        title: "DINT(int32)",
                        value: "int32",
                      },
                      {
                        disabled: "!(data.address_type === 'D' || data.address_type === 'W' || data.address_type === 'TN' || data.address_type === 'SN' || data.address_type === 'CC')",
                        title: "DWORD(uint32)",
                        value: "uint32",
                      },
                      {
                        disabled: "!(data.address_type === 'D' || data.address_type === 'W' || data.address_type === 'TN' || data.address_type === 'SN' || data.address_type === 'CC')",
                        title: "FLOAT(32bit)",
                        value: "float",
                      },
                      {
                        disabled: "!(data.address_type === 'D' || data.address_type === 'W' || data.address_type === 'TN' || data.address_type === 'SN' || data.address_type === 'CC')",
                        title: "DOUBLE(64bit)",
                        value: "double",
                      },
                    ],
                    index: 3,
                    required: true,
                    show_on_table: true,
                    table_width: 300,
                    title: { en: "Date Type", zh: "数据类型" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: 0,
                    enum: [
                      { title: { en: "default", zh: "默认不转换" }, value: 0 },
                      { title: { en: "Convert and retain 1 decimal place", zh: "转换并保留1位小数" }, value: 1 },
                      { title: { en: "Convert and retain 2 decimal place", zh: "转换并保留2位小数" }, value: 2 },
                      { title: { en: "Convert and retain 3 decimal place", zh: "转换并保留3位小数" }, value: 3 },
                      { title: { en: "Convert and retain 4 decimal place", zh: "转换并保留4位小数" }, value: 4 },
                      { title: { en: "Convert and retain 5 decimal place", zh: "转换并保留5位小数" }, value: 5 },
                      { title: { en: "Convert and retain 6 decimal place", zh: "转换并保留6位小数" }, value: 6 },
                    ],
                    index: 12,
                    required: false,
                    show: "data.data_type === 'float' || data.data_type === 'double'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Convert String", zh: "转字符串" },
                    type: "int",
                  },
                  group: { default: "default_group", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: {
                    default: 1,
                    index: 5,
                    max: 255,
                    min: 1,
                    required: true,
                    show: "data.data_type === 'hex' || data.data_type === 'string'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Length", zh: "变量长度" },
                    type: "int",
                  },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Permission", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "reg1", "reg1", "int16", "ro", 1, "", 0, "", 0, { scale: {}, selected: "", zoom: {} }, "default_group"],
                required: ["name", "title", "group", "address_type", "address", "data_type", "format", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            frame_format: { default: "3E", enum: ["3E"], required: true, title: { en: "Frame Format", zh: "帧格式" }, type: "string" },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期（毫秒）" }, type: "int" },
            port: { max: 65535, min: 1, required: true, title: { en: "Port", zh: "端口号" }, type: "int" },
            protocol_type: { default: "BINARY", enum: ["BINARY", "ASCII"], required: true, title: { en: "VPT Type", zh: "协议类型" }, type: "string" },
            required: ["device_name", "address", "port", "enabled", "interval_ms", "protocol_type", "frame_format"],
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          title: { en: "Mitsubishi MC(Ethernet)", zh: "三菱Mc协议（以太网）" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: true,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "mitsubishiMc_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "mitsubishiMc",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "MITSUBISHI-Q MC", zh: "三菱Q系列Mc协议" },
    version: "1.0.0",
  },
  modbusSerial_n: {
    attribute: VPTDriverDireaction.north,
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["serial"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "modbusSerial_n",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    platform_type: [{ title: { en: "Modbus Serial Slave VPT", zh: "Modbus Serial Slave 协议" }, value: "Modbus Serial Slave" }],
    protocol: "modbus",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "Modbus Serial Slave VPT", zh: "Modbus Serial Slave 协议" },
    version: "2.0.0",
  },
  modbus_n: {
    attribute: VPTDriverDireaction.north,
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "modbus_n",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    platform_type: [{ title: { en: "Modbus TCP Server", zh: "Modbus TCP服务端" }, value: "Modbus TCP Server" }],
    protocol: "modbus",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "Modbus TCP Server VPT", zh: "Modbus TCP服务端协议" },
    version: "2.0.0",
  },
  modbus_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        serial: {
          channel_config: {
            ascii_mode: {
              default: 0,
              enum: [
                { title: "Modbus RTU", value: 0 },
                { title: "Modbus ASCII", value: 1 },
              ],
              required: true,
              title: { en: "VPT Mode", zh: "协议模式" },
              type: "bool",
            },
            baudrate: { default: 9600, enum: [1200, 2400, 4800, 9600, 19200, 38400, 115200], required: true, title: { en: "Baudrate", zh: "波特率" }, type: "int" },
            data_bits: { default: 8, enum: [5, 6, 7, 8], required: true, title: { en: "Data Bits", zh: "数据位" }, type: "int" },
            mode: {
              default: "rs485",
              enum: [
                { disabled: "!(data.port === 'COM1' || data.port === 'COM2' || data.port === 'COM3' || data.port === 'COM4')", title: "RS485", value: "rs485" },
                { disabled: "!(data.port === 'COM1')", title: "RS232", value: "rs232" },
              ],
              required: true,
              title: { en: "Serial Mode", zh: "串口模式" },
              type: "string",
            },
            parity: { default: "N", enum: ["N", "E", "O"], required: true, title: { en: "Parity", zh: "校验位" }, type: "string" },
            port: {
              enum: [
                { title: "COM1", value: "COM1" },
                { title: "COM2", value: "COM2" },
                { title: "COM3", value: "COM3" },
                { title: "COM4", value: "COM4" },
              ],
              required: true,
              title: { en: "Serial Port", zh: "串口" },
              type: "string",
            },
            rts: {
              default: 0,
              enum: [
                { title: { en: "NONE", zh: "无" }, value: 0 },
                { title: { en: "UP", zh: "开" }, value: 1 },
                { title: { en: "DOWN", zh: "关" }, value: 2 },
              ],
              required: true,
              title: { en: "RTS", zh: "RTS" },
              type: "int",
            },
            stop_bits: { default: 1, enum: [1, 1.5, 2], required: true, title: { en: "Stop Bits", zh: "停止位" }, type: "int" },
          },
          device_config: {
            addrStart: { default: 0, enum: [0, 1], required: true, title: { en: "Register Start Bit", zh: "寄存器起始位" }, type: "int" },
            device_name: {
              placeholder: { en: "Only one device can be created in one channel of RS232 communication", zh: "RS232通讯一个通道只能创建一个设备" },
              required: true,
              title: { en: "Device Name", zh: "设备名称" },
              type: "string",
            },
            device_type: {
              "*": {
                point_schema: {
                  address: { index: 7, max: 65535, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "Register Addr", zh: "寄存器地址" }, type: "int" },
                  address_type: {
                    enum: [
                      { disabled: "data.read_write === 'wo'", title: "01", value: "coil" },
                      { disabled: "data.read_write === 'wo' || data.read_write === 'rw'", title: "02", value: "digital" },
                      { disabled: "data.read_write === 'wo'", title: "03", value: "holding" },
                      { disabled: "data.read_write === 'wo' || data.read_write === 'rw'", title: "04", value: "analogue" },
                      { disabled: "data.read_write === 'ro' || data.read_write === 'rw'", title: "05", value: "single_coil" },
                      { disabled: "data.read_write === 'ro' || data.read_write === 'rw'", title: "06", value: "single_register" },
                      { disabled: "data.read_write === 'ro' || data.read_write === 'rw'", title: "15", value: "multiple_coils" },
                      { disabled: "data.read_write === 'ro' || data.read_write === 'rw'", title: "16", value: "multiple_registers" },
                    ],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Function Code", zh: "功能码" },
                    type: "string",
                  },
                  bit_bias: {
                    default: "0",
                    enum: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"],
                    index: 9,
                    required: true,
                    show: "(data.address_type === 'holding' || data.address_type === 'analogue') && data.data_type === 'bool'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Bit Bias", zh: "偏移" },
                    type: "string",
                  },
                  byte_order: {
                    enum: [
                      {
                        disabled:
                          "!(((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type === 'byte') || data.data_type === 'byte' || data.data_type === 'char')",
                        title: { en: "h", zh: "取高字节" },
                        value: "h",
                      },
                      {
                        disabled:
                          "!(((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type === 'byte') || data.data_type === 'byte' || data.data_type === 'char')",
                        title: { en: "l", zh: "取低字节" },
                        value: "l",
                      },
                      {
                        disabled:
                          "!(((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type === 'uint16') || data.data_type === 'int16' || data.data_type === 'uint16')",
                        title: { en: "hl", zh: "先高后低" },
                        value: "hl",
                      },
                      {
                        disabled:
                          "!(((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type === 'uint16') || data.data_type === 'int16' || data.data_type === 'uint16')",
                        title: { en: "lh", zh: "先低后高" },
                        value: "lh",
                      },
                      {
                        disabled:
                          "!(((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type === 'uint32') || data.data_type === 'int32' || data.data_type === 'uint32' || data.data_type === 'float')",
                        title: "1234",
                        value: "1234",
                      },
                      {
                        disabled:
                          "!(((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type === 'uint32') || data.data_type === 'int32' || data.data_type === 'uint32' || data.data_type === 'float')",
                        title: "4321",
                        value: "4321",
                      },
                      {
                        disabled:
                          "!(((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type === 'uint32') || data.data_type === 'int32' || data.data_type === 'uint32' || data.data_type === 'float')",
                        title: "2143",
                        value: "2143",
                      },
                      {
                        disabled:
                          "!(((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type === 'uint32') || data.data_type === 'int32' || data.data_type === 'uint32' || data.data_type === 'float')",
                        title: "3412",
                        value: "3412",
                      },
                      { disabled: "!(data.data_type === 'double' || data.data_type === 'int64' || data.data_type === 'uint64')", title: "12345678", value: "12345678" },
                      { disabled: "!(data.data_type === 'double' || data.data_type === 'int64' || data.data_type === 'uint64')", title: "78563412", value: "78563412" },
                      { disabled: "!(data.data_type === 'double' || data.data_type === 'int64' || data.data_type === 'uint64')", title: "21436587", value: "21436587" },
                      { disabled: "!(data.data_type === 'double' || data.data_type === 'int64' || data.data_type === 'uint64')", title: "87654321", value: "87654321" },
                    ],
                    index: 8,
                    required: true,
                    show: "!(data.data_type === 'string' || data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Byte Order", zh: "解析方式" },
                    type: "string",
                  },
                  data_type: {
                    enum: [
                      { title: "BOOL(bit)", value: "bool" },
                      {
                        disabled:
                          "!((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') || data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "BYTE(uint8)",
                        value: "byte",
                      },
                      {
                        disabled: "!(data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "SINT(int8)",
                        value: "char",
                      },
                      {
                        disabled: "!(data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "INT(int16)",
                        value: "int16",
                      },
                      {
                        disabled:
                          "!((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') || data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "WORD(uint16)",
                        value: "uint16",
                      },
                      {
                        disabled: "!(data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "DINT(int32)",
                        value: "int32",
                      },
                      {
                        disabled:
                          "!((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') || data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "DWORD(uint32)",
                        value: "uint32",
                      },
                      {
                        disabled: "!(data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "FLOAT(32bit)",
                        value: "float",
                      },
                      {
                        disabled: "!(data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "INT64 (int64)",
                        value: "int64",
                      },
                      {
                        disabled: "!(data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "WORD64(uint64)",
                        value: "uint64",
                      },
                      {
                        disabled: "!(data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "DOUBLE(64bit)",
                        value: "double",
                      },
                      {
                        disabled: "!(data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "STRING(string)",
                        value: "string",
                      },
                    ],
                    index: 3,
                    required: true,
                    show_on_table: true,
                    table_width: 300,
                    title: { en: "Data Type", zh: "数据类型" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: 0,
                    enum: [
                      { title: { en: "default", zh: "默认不转换" }, value: 0 },
                      { title: { en: "Convert and retain 1 decimal place", zh: "转换并保留1位小数" }, value: 1 },
                      { title: { en: "Convert and retain 2 decimal place", zh: "转换并保留2位小数" }, value: 2 },
                      { title: { en: "Convert and retain 3 decimal place", zh: "转换并保留3位小数" }, value: 3 },
                      { title: { en: "Convert and retain 4 decimal place", zh: "转换并保留4位小数" }, value: 4 },
                      { title: { en: "Convert and retain 5 decimal place", zh: "转换并保留5位小数" }, value: 5 },
                      { title: { en: "Convert and retain 6 decimal place", zh: "转换并保留6位小数" }, value: 6 },
                    ],
                    index: 12,
                    required: false,
                    show: "data.data_type === 'float' || data.data_type === 'double'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Convert String", zh: "转字符串" },
                    type: "int",
                  },
                  group: { default: "default_group", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: {
                    default: 1,
                    index: 5,
                    max: 250,
                    min: 1,
                    required: true,
                    show: "((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type !== 'bool') || data.data_type === 'string'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Length", zh: "变量长度" },
                    type: "int",
                  },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    default: "ro",
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Permission", zh: "权限" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                  word_base: {
                    default: 65536,
                    index: 14,
                    max: 65536,
                    min: 0,
                    required: false,
                    show: "!(data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type === 'uint32'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Word base", zh: "Word进制" },
                    type: "int",
                  },
                },
                point_template: ["name", "reg1", "reg1", "int16", "ro", 1, "holding", 0, "", "0", null, "default_group"],
                required: ["name", "title", "group", "read_write", "address_type", "data_type", "format", "length", "address", "byte_order", "bit_bias", "unit", "word_base"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            required: ["device_name", "slave_address", "enabled", "interval_ms", "addrStart", "subscribe"],
            slave_address: { max: 255, min: 0, placeholder: { en: "Slave address 0 ~ 255", zh: "从站地址0~255" }, required: true, title: { en: "Slave", zh: "从站地址" }, type: "int" },
            subscribe: { default: "", required: false, title: { en: "Write Device", zh: "数据下发" }, type: "northOutput" },
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          required: ["ascii_mode", "port", "mode", "baudrate", "data_bits", "parity", "stop_bits", "rts"],
          title: { en: "modbus serial", zh: "Modbus 串口通讯" },
        },
        tcp: {
          channel_config: {},
          device_config: {
            addrStart: { default: 0, enum: [0, 1], required: true, title: { en: "Register Start Bit", zh: "寄存器起始位" }, type: "int" },
            address: {
              check: "^((25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))\\.){3}(25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))$",
              required: true,
              title: { en: "Ip Address", zh: "设备地址" },
              type: "string",
            },
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  address: { index: 7, max: 65535, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "Register Addr", zh: "寄存器地址" }, type: "int" },
                  address_type: {
                    enum: [
                      { disabled: "data.read_write === 'wo'", title: "01", value: "coil" },
                      { disabled: "data.read_write === 'wo' || data.read_write === 'rw'", title: "02", value: "digital" },
                      { disabled: "data.read_write === 'wo'", title: "03", value: "holding" },
                      { disabled: "data.read_write === 'wo' || data.read_write === 'rw'", title: "04", value: "analogue" },
                      { disabled: "data.read_write === 'ro' || data.read_write === 'rw'", title: "05", value: "single_coil" },
                      { disabled: "data.read_write === 'ro' || data.read_write === 'rw'", title: "06", value: "single_register" },
                      { disabled: "data.read_write === 'ro' || data.read_write === 'rw'", title: "15", value: "multiple_coils" },
                      { disabled: "data.read_write === 'ro' || data.read_write === 'rw'", title: "16", value: "multiple_registers" },
                    ],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Function Code", zh: "功能码" },
                    type: "string",
                  },
                  bit_bias: {
                    default: "0",
                    enum: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"],
                    index: 9,
                    required: false,
                    show: "(data.address_type === 'holding' || data.address_type === 'analogue') && data.data_type === 'bool'",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Bit Bias", zh: "偏移" },
                    type: "string",
                  },
                  byte_order: {
                    enum: [
                      {
                        disabled:
                          "!(((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type === 'byte') || data.data_type === 'byte' || data.data_type === 'char')",
                        title: { en: "h", zh: "取高字节" },
                        value: "h",
                      },
                      {
                        disabled:
                          "!(((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type === 'byte') || data.data_type === 'byte' || data.data_type === 'char')",
                        title: { en: "l", zh: "取低字节" },
                        value: "l",
                      },
                      {
                        disabled:
                          "!(((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type === 'uint16') || data.data_type === 'int16' || data.data_type === 'uint16')",
                        title: { en: "hl", zh: "先高后低" },
                        value: "hl",
                      },
                      {
                        disabled:
                          "!(((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type === 'uint16') || data.data_type === 'int16' || data.data_type === 'uint16')",
                        title: { en: "lh", zh: "先低后高" },
                        value: "lh",
                      },
                      {
                        disabled:
                          "!(((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type === 'uint32') || data.data_type === 'int32' || data.data_type === 'uint32' || data.data_type === 'float')",
                        title: "1234",
                        value: "1234",
                      },
                      {
                        disabled:
                          "!(((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type === 'uint32') || data.data_type === 'int32' || data.data_type === 'uint32' || data.data_type === 'float')",
                        title: "4321",
                        value: "4321",
                      },
                      {
                        disabled:
                          "!(((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type === 'uint32') || data.data_type === 'int32' || data.data_type === 'uint32' || data.data_type === 'float')",
                        title: "2143",
                        value: "2143",
                      },
                      {
                        disabled:
                          "!(((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type === 'uint32') || data.data_type === 'int32' || data.data_type === 'uint32' || data.data_type === 'float')",
                        title: "3412",
                        value: "3412",
                      },
                      { disabled: "!(data.data_type === 'double' || data.data_type === 'int64' || data.data_type === 'uint64')", title: "12345678", value: "12345678" },
                      { disabled: "!(data.data_type === 'double' || data.data_type === 'int64' || data.data_type === 'uint64')", title: "78563412", value: "78563412" },
                      { disabled: "!(data.data_type === 'double' || data.data_type === 'int64' || data.data_type === 'uint64')", title: "21436587", value: "21436587" },
                      { disabled: "!(data.data_type === 'double' || data.data_type === 'int64' || data.data_type === 'uint64')", title: "87654321", value: "87654321" },
                    ],
                    index: 8,
                    required: true,
                    show: "!(data.data_type === 'string' || data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Byte Order", zh: "解析方式" },
                    type: "string",
                  },
                  data_type: {
                    enum: [
                      { title: "BOOL(bit)", value: "bool" },
                      {
                        disabled:
                          "!((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') || data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "BYTE(uint8)",
                        value: "byte",
                      },
                      {
                        disabled: "!(data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "SINT(int8)",
                        value: "char",
                      },
                      {
                        disabled: "!(data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "INT(int16)",
                        value: "int16",
                      },
                      {
                        disabled:
                          "!((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') || data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "WORD(uint16)",
                        value: "uint16",
                      },
                      {
                        disabled: "!(data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "DINT(int32)",
                        value: "int32",
                      },
                      {
                        disabled:
                          "!((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') || data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "DWORD(uint32)",
                        value: "uint32",
                      },
                      {
                        disabled: "!(data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "FLOAT(32bit)",
                        value: "float",
                      },
                      {
                        disabled: "!(data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "INT64 (int64)",
                        value: "int64",
                      },
                      {
                        disabled: "!(data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "WORD64(uint64)",
                        value: "uint64",
                      },
                      {
                        disabled: "!(data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "DOUBLE(64bit)",
                        value: "double",
                      },
                      {
                        disabled: "!(data.address_type === 'holding' || data.address_type == 'analogue' || data.address_type === 'single_register' || data.address_type == 'multiple_registers')",
                        title: "STRING(string)",
                        value: "string",
                      },
                    ],
                    index: 3,
                    required: true,
                    show_on_table: true,
                    table_width: 300,
                    title: { en: "Data Type", required: true, zh: "数据类型" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: 0,
                    enum: [
                      { title: { en: "default", zh: "默认不转换" }, value: 0 },
                      { title: { en: "Convert and retain 1 decimal place", zh: "转换并保留1位小数" }, value: 1 },
                      { title: { en: "Convert and retain 2 decimal place", zh: "转换并保留2位小数" }, value: 2 },
                      { title: { en: "Convert and retain 3 decimal place", zh: "转换并保留3位小数" }, value: 3 },
                      { title: { en: "Convert and retain 4 decimal place", zh: "转换并保留4位小数" }, value: 4 },
                      { title: { en: "Convert and retain 5 decimal place", zh: "转换并保留5位小数" }, value: 5 },
                      { title: { en: "Convert and retain 6 decimal place", zh: "转换并保留6位小数" }, value: 6 },
                    ],
                    index: 12,
                    required: false,
                    show: "data.data_type === 'float' || data.data_type === 'double'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Convert String", zh: "转字符串" },
                    type: "int",
                  },
                  group: { default: "default_group", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: {
                    default: 1,
                    index: 5,
                    max: 250,
                    min: 1,
                    required: false,
                    show: "((data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type !== 'bool') || data.data_type === 'string'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Length", zh: "变量长度" },
                    type: "int",
                  },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    default: "ro",
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Permission", zh: "权限" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                  word_base: {
                    default: 65536,
                    index: 14,
                    max: 65536,
                    min: 0,
                    required: false,
                    show: "!(data.address_type === 'coil' || data.address_type === 'single_coil' || data.address_type === 'multiple_coils') && data.data_type === 'uint32'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Word base", zh: "Word进制" },
                    type: "int",
                  },
                },
                point_template: ["name", "reg1", "reg1", "int16", "ro", 1, "holding", 0, " ", "0", null, "default_group"],
                required: ["name", "title", "group", "read_write", "address_type", "data_type", "format", "length", "address", "byte_order", "bit_bias", "unit", "word_base"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            port: { default: 502, max: 65535, min: 1, required: true, title: { en: "Port", zh: "端口号" }, type: "int" },
            required: ["device_name", "slave_address", "enabled", "interval_ms", "address", "port", "addrStart", "subscribe"],
            slave_address: { max: 255, min: 0, placeholder: { en: "Slave address 0 ~ 255", zh: "从站地址0~255" }, required: true, title: { en: "Slave", zh: "从站地址" }, type: "int" },
            subscribe: { default: "", required: false, title: { en: "Write Device", zh: "数据下发" }, type: "northOutput" },
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          title: { en: "modbus TCP", zh: "Modbus TCP通讯" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: true,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp", "serial"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "modbus_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "modbus",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "Modbus VPT", zh: "Modbus采集协议" },
    version: "1.5.3",
  },
  mqtt_n: {
    attribute: VPTDriverDireaction.north,
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "mqtt_n",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    need_property: true,
    platform: {
      wpg: {
        payload_encrypt_method: [
          { title: "GM CMS", value: "cms" },
          { title: "SM2", value: "sm2" },
        ],
      },
    },
    platform_type: [
      { title: { en: "blueSphere", zh: "blueSphere" }, value: "blueSphere" },
      { title: { en: "MQTT Client", zh: "MQTT客户端" }, value: "standardMqtt" },
      { title: { en: "MQTT Client With Composite Data", zh: "MQTT客户端-聚合上传" }, value: "compositeMqtt" },
      { title: { en: "MQTT Client edge computing packaging", zh: "MQTT客户端-边缘计算封装报文" }, value: "computeToCustomPlatform" },
      { title: { en: "MQTT Client thingsboard", zh: "MQTT客户端-thingsboard平台" }, value: "thingsboard" },
      { title: { en: "MQTT Client ECP", zh: "MQTT客户端-ECP平台" }, value: "ecp" },
      { title: { en: "MQTT Client WPG", zh: "MQTT客户端-WPG智慧水务" }, value: "wpg" },
    ],
    protocol: "mqtt",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "MQTT VPT", zh: "MQTT协议" },
    version: "2.0.0",
  },
  mysql_n: {
    attribute: VPTDriverDireaction.north,
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "mysql_n",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "mysql",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "MYSQL Plugin", zh: "MYSQL协议插件" },
    version: "1.0.1",
  },
  opcua_n: {
    attribute: VPTDriverDireaction.north,
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "opcua_n",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "opcua",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "OPCUA Server VPT", zh: "OPCUA服务端协议" },
    version: "1.0.1",
  },
  opcua_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        binary: {
          channel_config: {},
          device_config: {
            address: { default: "opc.tcp://", required: true, title: { en: "URL", zh: "服务器地址" }, type: "string" },
            communication_type: { default: "binary", enum: ["binary"], required: false, title: "communication type", type: "string" },
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  address: { index: 7, required: true, show_on_table: true, table_width: 200, title: { en: "Node ID", zh: "节点地址" }, type: "string" },
                  address_type: {
                    enum: [
                      { title: "01", value: "coil" },
                      { title: "02", value: "digital" },
                      { title: "03", value: "holding" },
                      { title: "04", value: "analogue" },
                    ],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Register Addr Type", zh: "寄存器类型" },
                    type: "string",
                  },
                  bit_bias: {
                    default: "0",
                    enum: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"],
                    index: 9,
                    required: false,
                    show: "(data.address_type === 'holding' || data.address_type === 'analogue') && data.data_type === 'bool'",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Bit Bias", zh: "偏移" },
                    type: "string",
                  },
                  byte_order: {
                    default: " ",
                    enum: [
                      { title: { en: "Default", zh: "默认方式" }, value: " " },
                      { title: { en: "h", zh: "取高字节" }, value: "h" },
                      { title: { en: "l", zh: "取低字节" }, value: "l" },
                      { title: { en: "hl", zh: "先高后低" }, value: "hl" },
                      { title: { en: "lh", zh: "先低后高" }, value: "lh" },
                      { title: "1234", value: "1234" },
                      { title: "4321", value: "4321" },
                      { title: "2143", value: "2143" },
                      { title: "3412", value: "3412" },
                      { title: "12345678", value: "12345678" },
                      { title: "78563412", value: "78563412" },
                    ],
                    index: 8,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Byte Order", zh: "解析方式" },
                    type: "string",
                  },
                  data_type: {
                    enum: [
                      { title: "BOOL(bit)", value: "bool" },
                      { title: "BYTE(uint8)", value: "byte" },
                      { title: "SINT(int8)", value: "char" },
                      { title: "INT(int16)", value: "int16" },
                      { title: "WORD(uint16)", value: "uint16" },
                      { title: "DINT(int32)", value: "int32" },
                      { title: "DWORD(uint32)", value: "uint32" },
                      { title: "FLOAT(32bit)", value: "float" },
                      { title: "INT64 (int64)", value: "int64" },
                      { title: "WORD64(uint64)", value: "uint64" },
                      { title: "DOUBLE(64bit)", value: "double" },
                      { title: "HEX(hex)", value: "hex" },
                      { title: "STRING(string)", value: "string" },
                    ],
                    index: 3,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Date Type", required: true, zh: "数据类型" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: 2,
                    enum: [],
                    index: 12,
                    required: false,
                    show: "data.data_type === 'float' || data.data_type === 'double'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Decimal places", zh: "小数点位数" },
                    type: "int",
                  },
                  group: { default: "default_group", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: { default: 1, index: 5, max: 255, min: 1, required: true, show_on_table: false, table_width: 200, title: { en: "Length", zh: "变量长度" }, type: "int" },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 300,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Permission", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 300, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "node1", "node1", "int16", "ro", 1, "node", "nodeID", "default", "0", null, "default_group"],
                required: ["name", "title", "group", "address", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            login_method: {
              cerdential_key: { cerdential: { title: "cerdential", type: "string" }, key: { title: "key", type: "string" } },
              default: {},
              user_name: { password: { title: "password", type: "string" }, username: { title: "Usr Name", type: "string" } },
            },
            required: ["device_name", "enabled", "interval_ms", "address", "login_method"],
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          title: { en: "OPCUA", zh: "OPCUA通讯" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "opcua_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "opcua",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "OPC-UA VPT", zh: "OPC UA采集协议" },
    version: "1.0.0",
  },
  opentsdb_n: {
    attribute: VPTDriverDireaction.north,
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "opentsdb_n",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "opentsdb",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "OpenTSDB Post Plugin", zh: "OpenTSDB上传插件" },
    version: "1.0.0",
  },
  ppi_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        serial: {
          channel_config: {
            baudrate: { default: 9600, enum: [1200, 2400, 4800, 9600, 19200, 38400, 115200], required: true, title: { en: "Baudrate", zh: "波特率" }, type: "int" },
            data_bits: { default: 8, enum: [7, 8], required: true, title: { en: "Data Bits", zh: "数据位" }, type: "int" },
            mode: {
              default: "rs485",
              enum: [{ disabled: "!(data.port === 'COM1' || data.port === 'COM2' || data.port === 'COM3' || data.port === 'COM4')", title: "RS485", value: "rs485" }],
              required: true,
              title: { en: "Serial Mode", zh: "串口模式" },
              type: "string",
            },
            parity: { default: "E", enum: ["N", "E", "O"], required: true, title: { en: "Parity", zh: "校验位" }, type: "string" },
            port: {
              enum: [
                { title: "COM1", value: "COM1" },
                { title: "COM2", value: "COM2" },
                { title: "COM3", value: "COM3" },
                { title: "COM4", value: "COM4" },
              ],
              required: true,
              title: { en: "Serial Port", zh: "串口" },
              type: "string",
            },
            stop_bits: { default: "1", enum: ["1", "1.5", "2"], required: true, title: { en: "Stop Bits", zh: "停止位" }, type: "string" },
          },
          device_config: {
            address: { max: 255, min: 1, required: true, title: { en: "Slave Address", zh: "从站地址" }, type: "int" },
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "s7_200 smart": {
                point_schema: {
                  address: { index: 7, max: 65535, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "Register Addr", zh: "寄存器地址" }, type: "int" },
                  address_type: {
                    enum: ["I", "Q", "M", "V", "S", "SM"],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Register Addr Type", zh: "寄存器类型" },
                    type: "string",
                  },
                  bit_bias: {
                    default: 0,
                    enum: [0, 1, 2, 3, 4, 5, 6, 7],
                    index: 9,
                    required: true,
                    show: "(data.address_type === 'V' || data.address_type === 'M' || data.address_type === 'I' || data.address_type === 'S' || data.address_type === 'SM' || data.address_type === 'Q') && data.data_type === 'bool'",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Bit Bias", zh: "偏移" },
                    type: "int",
                  },
                  byte_order: { default: " ", enum: [], index: 8, required: true, show_on_table: false, table_width: 200, title: { en: "Byte Order", zh: "解析方式" }, type: "string" },
                  data_type: {
                    enum: [
                      { disabled: "data.address_type === 'C' || data.address_type === 'T'", title: "BOOL(bit)", value: "bool" },
                      { title: "BYTE(uint8)", value: "byte" },
                      { title: "INT(int16)", value: "int16" },
                      { title: "WORD(uint16)", value: "uint16" },
                      { title: "DINT(int32)", value: "int32" },
                      { title: "DWORD(uint32)", value: "uint32" },
                      { title: "FLOAT(32bit)", value: "float" },
                    ],
                    index: 3,
                    required: true,
                    show_on_table: true,
                    table_width: 300,
                    title: { en: "Date Type", required: true, zh: "数据类型" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: 0,
                    enum: [
                      { title: { en: "default", zh: "默认不转换" }, value: 0 },
                      { title: { en: "Convert and retain 1 decimal place", zh: "转换并保留1位小数" }, value: 1 },
                      { title: { en: "Convert and retain 2 decimal place", zh: "转换并保留2位小数" }, value: 2 },
                      { title: { en: "Convert and retain 3 decimal place", zh: "转换并保留3位小数" }, value: 3 },
                      { title: { en: "Convert and retain 4 decimal place", zh: "转换并保留4位小数" }, value: 4 },
                      { title: { en: "Convert and retain 5 decimal place", zh: "转换并保留5位小数" }, value: 5 },
                      { title: { en: "Convert and retain 6 decimal place", zh: "转换并保留6位小数" }, value: 6 },
                    ],
                    index: 12,
                    required: false,
                    show: "data.data_type === 'float'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Convert String", zh: "转字符串" },
                    type: "int",
                  },
                  group: { default: "default_group", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: {
                    default: 1,
                    index: 5,
                    max: 255,
                    min: 1,
                    required: true,
                    show: "data.data_type === 'hex' || data.data_type === 'string'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Length", zh: "数据长度" },
                    type: "int",
                  },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Permission", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "I0.0", "I0.0", "bool", "ro", 1, "I", 0, "", 0, null, "default_group"],
                required: ["name", "title", "group", "address_type", "address", "data_type", "format", "bit_bias", "unit"],
              },
              title: { en: "Device Type", zh: "设备型号" },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            required: ["device_name", "address", "enabled", "interval_ms", "device_type"],
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          required: ["port", "mode", "baudrate", "data_bits", "parity", "stop_bits"],
          title: { en: "SIEMENS PPI VPT", zh: "西门子PPI协议" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: true,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["serial"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "ppi_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "ppi",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "SIEMENS PPI", zh: "西门子PPI协议" },
    version: "1.0.0",
  },
  profinet_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        pn_rt: {
          channel_config: {
            iface: {
              enum: ["eth1", "any", "eth0", "br-lan", "can0", "gre0", "gretap0", "erspan0", "nflog", "nfqueue"],
              required: true,
              title: { en: "Network Interface", zh: "网卡" },
              type: "string",
            },
          },
          device_config: {
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  byte_offset: { default: 0, index: 9, required: false, show_on_table: false, table_width: 200, title: { en: "Data Offset", zh: "字段偏移" }, type: "int" },
                  byte_order: {
                    enum: [
                      { disabled: "!(data.data_type === 'byte' || data.data_type === 'char')", title: { en: "h", zh: "取高字节" }, value: "h" },
                      { disabled: "!(data.data_type === 'byte' || data.data_type === 'char')", title: { en: "l", zh: "取低字节" }, value: "l" },
                      { disabled: "!(data.data_type === 'int16' || data.data_type === 'uint16')", title: { en: "hl", zh: "先高后低" }, value: "hl" },
                      { disabled: "!(data.data_type === 'int16' || data.data_type === 'uint16')", title: { en: "lh", zh: "先低后高" }, value: "lh" },
                      { disabled: "!(data.data_type === 'int32' || data.data_type === 'uint32' || data.data_type === 'float')", title: "1234", value: "1234" },
                      { disabled: "!(data.data_type === 'int32' || data.data_type === 'uint32' || data.data_type === 'float')", title: "4321", value: "4321" },
                      { disabled: "!(data.data_type === 'int32' || data.data_type === 'uint32' || data.data_type === 'float')", title: "2143", value: "2143" },
                      { disabled: "!(data.data_type === 'int32' || data.data_type === 'uint32' || data.data_type === 'float')", title: "3412", value: "3412" },
                      { disabled: "!(data.data_type === 'double' || data.data_type === 'int64' || data.data_type === 'uint64')", title: "12345678", value: "12345678" },
                      { disabled: "!(data.data_type === 'double' || data.data_type === 'int64' || data.data_type === 'uint64')", title: "78563412", value: "78563412" },
                      { disabled: "!(data.data_type === 'double' || data.data_type === 'int64' || data.data_type === 'uint64')", title: "21436587", value: "21436587" },
                      { disabled: "!(data.data_type === 'double' || data.data_type === 'int64' || data.data_type === 'uint64')", title: "87654321", value: "87654321" },
                    ],
                    index: 8,
                    required: false,
                    show: "!(data.data_type === 'string' || data.data_type === 'hex' || data.data_type === 'bool' || data.data_type === 'any')",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Byte Order", zh: "解析方式" },
                    type: "string",
                  },
                  data_name: { index: 7, required: true, show_on_table: true, table_width: 200, title: { en: "DataItem", zh: "数据项" }, type: "string" },
                  data_type: {
                    default: "any",
                    enum: [
                      { title: "ANY(any)", value: "any" },
                      { title: "BOOL(bit)", value: "bool" },
                      { title: "SINT(int8)", value: "char" },
                      { title: "INT(int16)", value: "int16" },
                      { title: "DINT(int32)", value: "int" },
                      { title: "INT64(int64)", value: "long" },
                      { title: "BYTE(uint8)", value: "byte" },
                      { title: "WORD(uint16)", value: "uint16" },
                      { title: "DWORD(uint32)", value: "uint" },
                      { title: "WORD64(uint64)", value: "ulong" },
                      { title: "FLOAT(32bit)", value: "float" },
                      { title: "DOUBLE(64bit)", value: "double" },
                      { title: "STRING(string)", value: "string" },
                      { title: "HEX(hex)", value: "hex" },
                    ],
                    index: 3,
                    required: true,
                    show_on_table: false,
                    table_width: 300,
                    title: { en: "Date Type", required: true, zh: "变量类型" },
                    type: "string",
                  },
                  format: {
                    default: ["", 0],
                    enum: [],
                    index: 12,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Units &amp; decimal places", zh: "单位&小数点位数" },
                    type: "string",
                  },
                  group: { default: "default_group", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: {
                    default: 1,
                    index: 5,
                    max: 250,
                    min: 1,
                    required: false,
                    show: "data.data_type === 'hex' || data.data_type === 'string'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Length", zh: "变量长度" },
                    type: "int",
                  },
                  module: { index: 14, required: true, show_on_table: true, table_width: 200, title: { en: "Module", zh: "模块" }, type: "string" },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Permission", zh: "读写" },
                    type: "string",
                  },
                  submodule: { index: 6, required: true, show_on_table: true, table_width: 200, title: { en: "Submodule", zh: "子模块" }, type: "string" },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "reg1", "reg1", "int16", "ro", 1, "holding", 0, "", "0", null, "default_group"],
                required: ["name", "title", "group", "module", "submodule", "data_name", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            gsd: { enum: [], required: true, title: { en: "GSD", zh: "GSD" }, type: "string" },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            ip: {
              check: "^((25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))\\.){3}(25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))$",
              required: true,
              title: { en: "Ip Address", zh: "IP地址" },
              type: "string",
            },
            profinet_name: { required: true, title: { en: "Profinet Device Name", zh: "Profinet设备名" }, type: "string" },
            required: ["device_name", "gsd", "profinet_name", "enabled", "interval_ms"],
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          required: ["iface"],
          title: { en: "Profinet RT", zh: "Profinet RT" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: true,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["pn_rt"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "profinet_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "profinet",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "Profinet VPT", zh: "Profinet协议采集" },
    version: "1.0.0",
  },
  redis_n: {
    attribute: VPTDriverDireaction.north,
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "redis_n",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "redis",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "Redis Post Plugin", zh: "Redis上传插件" },
    version: "1.0.0",
  },
  s7_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        s7: {
          channel_config: {},
          device_config: {
            address: {
              check: "^((25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))\\.){3}(25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))$",
              required: true,
              title: { en: "Ip Address", zh: "设备地址" },
              type: "string",
            },
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              s7_1200: {
                point_schema: {
                  address: { index: 7, max: 65535, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "Register Addr", zh: "寄存器偏移" }, type: "int" },
                  address_type: { enum: ["I", "Q", "M", "DB"], index: 6, required: true, show_on_table: true, table_width: 200, title: { en: "Register Addr Type", zh: "寄存器类型" }, type: "string" },
                  bit_bias: {
                    default: 0,
                    enum: [0, 1, 2, 3, 4, 5, 6, 7],
                    index: 9,
                    required: true,
                    show: "(data.address_type === 'DB' || data.address_type === 'M' || data.address_type === 'I' || data.address_type === 'Q') && data.data_type === 'bool'",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Bit Offset", zh: "位偏移" },
                    type: "int",
                  },
                  data_type: {
                    enum: [
                      { disabled: "data.address_type === 'C' || data.address_type === 'T'", title: "BOOL(bit)", value: "bool" },
                      { title: "BYTE(uint8)", value: "byte" },
                      { title: "CHAR(int8)", value: "char" },
                      { title: "INT(int16)", value: "int16" },
                      { title: "WORD(uint16)", value: "uint16" },
                      { title: "DINT(int32)", value: "int32" },
                      { title: "DWORD(uint32)", value: "uint32" },
                      { title: "FLOAT(32bit)", value: "float" },
                    ],
                    index: 3,
                    required: true,
                    show_on_table: true,
                    table_width: 300,
                    title: { en: "Date Type", required: true, zh: "数据类型" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: 0,
                    enum: [
                      { title: { en: "default", zh: "默认不转换" }, value: 0 },
                      { title: { en: "Convert and retain 1 decimal place", zh: "转换并保留1位小数" }, value: 1 },
                      { title: { en: "Convert and retain 2 decimal place", zh: "转换并保留2位小数" }, value: 2 },
                      { title: { en: "Convert and retain 3 decimal place", zh: "转换并保留3位小数" }, value: 3 },
                      { title: { en: "Convert and retain 4 decimal place", zh: "转换并保留4位小数" }, value: 4 },
                      { title: { en: "Convert and retain 5 decimal place", zh: "转换并保留5位小数" }, value: 5 },
                      { title: { en: "Convert and retain 6 decimal place", zh: "转换并保留6位小数" }, value: 6 },
                    ],
                    index: 12,
                    required: false,
                    show: "data.data_type === 'float'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Convert String", zh: "转字符串" },
                    type: "int",
                  },
                  group: { default: "default_group", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: { index: 5, required: true, show: "data.address_type === 'DB'", show_on_table: false, table_width: 200, title: { en: "Block number", zh: "块号" }, type: "int" },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    default: "ro",
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Permission", zh: "权限" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "I0.0", "I0.0", "bool", "ro", 1, "I", 0, "", 0, null, "default_group"],
                required: ["name", "title", "group", "address_type", "length", "address", "data_type", "format", "bit_bias", "read_write", "unit"],
              },
              s7_1500: {
                point_schema: {
                  address: { index: 7, max: 65535, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "Register Addr", zh: "寄存器偏移" }, type: "int" },
                  address_type: { enum: ["I", "Q", "M", "DB"], index: 6, required: true, show_on_table: true, table_width: 200, title: { en: "Register Addr Type", zh: "寄存器类型" }, type: "string" },
                  bit_bias: {
                    default: 0,
                    enum: [0, 1, 2, 3, 4, 5, 6, 7],
                    index: 9,
                    required: true,
                    show: "(data.address_type === 'DB' || data.address_type === 'M' || data.address_type === 'I' || data.address_type === 'Q') && data.data_type === 'bool'",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Bit Bias", zh: "位偏移" },
                    type: "int",
                  },
                  data_type: {
                    enum: [
                      { disabled: "data.address_type === 'C' || data.address_type === 'T'", title: "BOOL(bit)", value: "bool" },
                      { title: "BYTE(uint8)", value: "byte" },
                      { title: "CHAR(int8)", value: "char" },
                      { title: "INT(int16)", value: "int16" },
                      { title: "WORD(uint16)", value: "uint16" },
                      { title: "DINT(int32)", value: "int32" },
                      { title: "DWORD(uint32)", value: "uint32" },
                      { title: "FLOAT(32bit)", value: "float" },
                    ],
                    index: 3,
                    required: true,
                    show_on_table: true,
                    table_width: 300,
                    title: { en: "Date Type", required: true, zh: "数据类型" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: 0,
                    enum: [
                      { title: { en: "default", zh: "默认不转换" }, value: 0 },
                      { title: { en: "Convert and retain 1 decimal place", zh: "转换并保留1位小数" }, value: 1 },
                      { title: { en: "Convert and retain 2 decimal place", zh: "转换并保留2位小数" }, value: 2 },
                      { title: { en: "Convert and retain 3 decimal place", zh: "转换并保留3位小数" }, value: 3 },
                      { title: { en: "Convert and retain 4 decimal place", zh: "转换并保留4位小数" }, value: 4 },
                      { title: { en: "Convert and retain 5 decimal place", zh: "转换并保留5位小数" }, value: 5 },
                      { title: { en: "Convert and retain 6 decimal place", zh: "转换并保留6位小数" }, value: 6 },
                    ],
                    index: 12,
                    required: false,
                    show: "data.data_type === 'float'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Convert String", zh: "转字符串" },
                    type: "int",
                  },
                  group: { default: "default_group", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: { index: 5, required: true, show: "data.address_type === 'DB'", show_on_table: false, table_width: 200, title: { en: "Block number", zh: "块号" }, type: "int" },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    default: "ro",
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Permission", zh: "权限" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "I0.0", "I0.0", "bool", "ro", 1, "I", 0, "", 0, null, "default_group"],
                required: ["name", "title", "group", "address_type", "length", "address", "data_type", "format", "bit_bias", "read_write", "unit"],
              },
              "s7_200 smart": {
                point_schema: {
                  address: { index: 7, max: 9999999999999, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "Register Addr", zh: "寄存器偏移" }, type: "int" },
                  address_type: {
                    enum: ["I", "Q", "M", "V", "T", "C", "S", "SM"],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Register Addr Type", zh: "寄存器类型" },
                    type: "string",
                  },
                  bit_bias: {
                    default: 0,
                    enum: [0, 1, 2, 3, 4, 5, 6, 7],
                    index: 9,
                    required: true,
                    show: "(data.address_type === 'V' || data.address_type === 'M' || data.address_type === 'I' || data.address_type === 'S' || data.address_type === 'SM' || data.address_type === 'Q') && data.data_type === 'bool'",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Bit Offset", zh: "位偏移" },
                    type: "int",
                  },
                  data_type: {
                    enum: [
                      { disabled: "data.address_type === 'C' || data.address_type === 'T'", title: "BOOL(bit)", value: "bool" },
                      { disabled: "data.address_type === 'C' || data.address_type === 'T'", title: "CHAR(int8)", value: "char" },
                      { disabled: "data.address_type === 'C' || data.address_type === 'T'", title: "BYTE(uint8)", value: "byte" },
                      { title: "INT(int16)", value: "int16" },
                      { title: "WORD(uint16)", value: "uint16" },
                      { disabled: "data.address_type === 'C' || data.address_type === 'T'", title: "DINT(int32)", value: "int32" },
                      { disabled: "data.address_type === 'C' || data.address_type === 'T'", title: "DWORD(uint32)", value: "uint32" },
                      { disabled: "data.address_type === 'C' || data.address_type === 'T'", title: "FLOAT(32bit)", value: "float" },
                    ],
                    index: 3,
                    required: true,
                    show_on_table: true,
                    table_width: 300,
                    title: { en: "Date Type", required: true, zh: "数据类型" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: 0,
                    enum: [
                      { title: { en: "default", zh: "默认不转换" }, value: 0 },
                      { title: { en: "Convert and retain 1 decimal place", zh: "转换并保留1位小数" }, value: 1 },
                      { title: { en: "Convert and retain 2 decimal place", zh: "转换并保留2位小数" }, value: 2 },
                      { title: { en: "Convert and retain 3 decimal place", zh: "转换并保留3位小数" }, value: 3 },
                      { title: { en: "Convert and retain 4 decimal place", zh: "转换并保留4位小数" }, value: 4 },
                      { title: { en: "Convert and retain 5 decimal place", zh: "转换并保留5位小数" }, value: 5 },
                      { title: { en: "Convert and retain 6 decimal place", zh: "转换并保留6位小数" }, value: 6 },
                    ],
                    index: 12,
                    required: false,
                    show: "data.data_type === 'float'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Convert String", zh: "转字符串" },
                    type: "int",
                  },
                  group: { default: "default_group", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: {
                    default: 1,
                    index: 5,
                    max: 255,
                    min: 1,
                    required: true,
                    show: "data.data_type === 'hex' || data.data_type === 'string'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Length", zh: "数据长度" },
                    type: "int",
                  },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    default: "ro",
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Permission", zh: "权限" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "I0.0", "I0.0", "bool", "ro", 1, "I", 0, "", 0, null, "default_group"],
                required: ["name", "title", "group", "address_type", "address", "data_type", "format", "bit_bias", "read_write", "unit"],
              },
              s7_300: {
                point_schema: {
                  address: { index: 7, max: 9999999999999, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "Register Addr", zh: "寄存器偏移" }, type: "int" },
                  address_type: { enum: ["I", "Q", "M", "DB"], index: 6, required: true, show_on_table: true, table_width: 200, title: { en: "Register Addr Type", zh: "寄存器类型" }, type: "string" },
                  bit_bias: {
                    default: 0,
                    enum: [0, 1, 2, 3, 4, 5, 6, 7],
                    index: 9,
                    required: true,
                    show: "(data.address_type === 'DB' || data.address_type === 'M' || data.address_type === 'I' || data.address_type === 'S' || data.address_type === 'SM' || data.address_type === 'Q') && data.data_type === 'bool'",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Bit Offset", zh: "位偏移" },
                    type: "int",
                  },
                  data_type: {
                    enum: [
                      { disabled: "data.address_type === 'C' || data.address_type === 'T'", title: "BOOL(bit)", value: "bool" },
                      { title: "CHAR(int8)", value: "char" },
                      { title: "BYTE(uint8)", value: "byte" },
                      { title: "INT(int16)", value: "int16" },
                      { title: "WORD(uint16)", value: "uint16" },
                      { title: "DINT(int32)", value: "int32" },
                      { title: "DWORD(uint32)", value: "uint32" },
                      { title: "FLOAT(32bit)", value: "float" },
                    ],
                    index: 3,
                    required: true,
                    show_on_table: true,
                    table_width: 300,
                    title: { en: "Date Type", required: true, zh: "数据类型" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: 0,
                    enum: [
                      { title: { en: "default", zh: "默认不转换" }, value: 0 },
                      { title: { en: "Convert and retain 1 decimal place", zh: "转换并保留1位小数" }, value: 1 },
                      { title: { en: "Convert and retain 2 decimal place", zh: "转换并保留2位小数" }, value: 2 },
                      { title: { en: "Convert and retain 3 decimal place", zh: "转换并保留3位小数" }, value: 3 },
                      { title: { en: "Convert and retain 4 decimal place", zh: "转换并保留4位小数" }, value: 4 },
                      { title: { en: "Convert and retain 5 decimal place", zh: "转换并保留5位小数" }, value: 5 },
                      { title: { en: "Convert and retain 6 decimal place", zh: "转换并保留6位小数" }, value: 6 },
                    ],
                    index: 12,
                    required: false,
                    show: "data.data_type === 'float'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Convert String", zh: "转字符串" },
                    type: "int",
                  },
                  group: { default: "default_group", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: { index: 5, required: true, show: "data.address_type === 'DB'", show_on_table: false, table_width: 200, title: { en: "Block number", zh: "块号" }, type: "int" },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    default: "ro",
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Permission", zh: "权限" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "I0.0", "I0.0", "bool", "ro", 1, "I", 0, "", 0, null, "default_group"],
                required: ["name", "title", "group", "address_type", "length", "address", "data_type", "format", "bit_bias", "read_write", "unit"],
              },
              title: { en: "Device Type", zh: "设备型号" },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            merge_address: {
              default: 0,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              show: "device_type=='s7_1500'",
              title: { en: "Merge address", zh: "读连续地址" },
              type: "bool",
            },
            port: { default: 102, max: 65535, min: 0, required: true, title: { en: "Port", zh: "端口号" }, type: "int" },
            rack: { default: 0, enum: [{ title: "0", value: 0 }], max: 255, min: 0, required: true, title: { en: "rack", zh: "机架号" }, type: "int" },
            required: ["device_type", "device_name", "enabled", "interval_ms", "address", "port", "rack", "slot", "merge_address", "subscribe"],
            slot: {
              enum: [
                { disabled: "data.device_type==='s7_300' || data.device_type==='s7_200 smart'", title: "0", value: 0 },
                { disabled: "data.device_type==='s7_300'", title: "1", value: 1 },
                { disabled: "data.device_type==='s7_200 smart' || data.device_type==='s7_1200' || data.device_type==='s7_1500'", title: "2", value: 2 },
              ],
              max: 255,
              min: 0,
              required: true,
              title: { en: "slot", zh: "槽号" },
              type: "int",
            },
            subscribe: { default: "", required: false, title: { en: "Write Device", zh: "数据下发" }, type: "northOutput" },
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          title: { en: "S7 VPT", zh: "西门子S7通讯" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "s7_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "s7",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "S7 VPT", zh: "西门子S7协议" },
    version: "1.0.3",
  },
  slmp_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        udp: {
          channel_config: {},
          device_config: {
            address: {
              check: "^((25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))\\.){3}(25[0-5]|2[0-4]\\d|((1\\d{2})|([1-9]?\\d)))$",
              required: true,
              title: { en: "Ip Address", zh: "IP地址" },
              type: "string",
            },
            device_family: { default: "Q/L", enum: ["Q/L", "iQ-R"], required: true, title: { en: "Device Family", zh: "产品系列" }, type: "string" },
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  address_backup: { index: 7, max: 16777215, min: 0, required: true, show_on_table: true, table_width: 200, title: { en: "Register Addr", zh: "寄存器地址" }, type: "int" },
                  address_dec: {
                    index: 14,
                    max: 16777215,
                    min: 0,
                    required: true,
                    show: "!(new Set(['X','Y','B','W','SB','SW','DX','DY','ZR']).has(data.address_type))",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Register Addr(decimal)", zh: "寄存器地址(10进制)" },
                    type: "int",
                  },
                  address_hex: {
                    check: "^(0x|0X)?[0-9A-Fa-f]{1,6}$",
                    index: 7,
                    required: true,
                    show: "new Set(['X','Y','B','W','SB','SW','DX','DY','ZR']).has(data.address_type)",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Register Addr(hex)", zh: "寄存器地址(16进制)" },
                    type: "string",
                  },
                  address_type: {
                    enum: [
                      { title: { en: "X Input Relay(bit)", zh: "X 输入继电器（位）" }, value: "X" },
                      { title: { en: "Y Output Relay(bit)", zh: "Y 输出继电器（位）" }, value: "Y" },
                      { title: { en: "M Input Relay(bit)", zh: "M 辅助继电器（位）" }, value: "M" },
                      { title: { en: "D Data Register(byte)", zh: "D 数据寄存器（字）" }, value: "D" },
                      { title: { en: "B Link Relay(bit)", zh: "B 链接继电器（位）" }, value: "B" },
                      { title: { en: "F Alarm Relay(bit)", zh: "F 报警继电器（位）" }, value: "F" },
                      { title: { en: "W Link Register(byte)", zh: "W 链接寄存器（字）" }, value: "W" },
                      { title: { en: "L Latch Relay(bit)", zh: "L 锁存继电器（位）" }, value: "L" },
                      { title: { en: "TS Timer Contact Register(bit)", zh: "TS 定时器触点（位）" }, value: "TS" },
                      { title: { en: "TC Timer Coil(bit)", zh: "TC 定时器线圈（位）" }, value: "TC" },
                      { title: { en: "TN Timer Current Value(byte)", zh: "TN 定时器当前值（字）" }, value: "TN" },
                      { title: { en: "SS Cumulative Timer Contact(bit)", zh: "SS 累计定时器触点（位）" }, value: "SS" },
                      { title: { en: "SC Cumulative Timer Coil(bit)", zh: "SC 累计定时器线圈（位）" }, value: "SC" },
                      { title: { en: "SN Current Value Of Cumulative Timer(byte)", zh: "SN 累计定时器当前值（字）" }, value: "SN" },
                      { title: { en: "CS Counter Contact(bit)", zh: "CS 计数器触点（位）" }, value: "CS" },
                      { title: { en: "CN Counter Coil(bit)", zh: "CN 计数器线圈（位）" }, value: "CN" },
                      { title: { en: "CC Counter Current Value(byte)", zh: "CC 计数器当前值（字）" }, value: "CC" },
                    ],
                    index: 6,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Register Addr Type", zh: "寄存器类型" },
                    type: "string",
                  },
                  bit_bias: {
                    default: 0,
                    enum: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
                    index: 9,
                    required: true,
                    show: "data.data_type === 'bool'",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Bit Bias", zh: "位偏移" },
                    type: "int",
                  },
                  byte_order: { default: "false", enum: [], index: 8, required: false, show_on_table: false, table_width: 200, title: { en: "BCD Mode", zh: "BCD模式" }, type: "string" },
                  data_type: {
                    enum: [
                      {
                        disabled: "(data.address_type === 'D' || data.address_type === 'W' || data.address_type === 'TN' || data.address_type === 'SN' || data.address_type === 'CC')",
                        title: "BIT(bit)",
                        value: "bool",
                      },
                      {
                        disabled: "!(data.address_type === 'D' || data.address_type === 'W' || data.address_type === 'TN' || data.address_type === 'SN' || data.address_type === 'CC')",
                        title: "BYTE(uint8)",
                        value: "byte",
                      },
                      {
                        disabled: "!(data.address_type === 'D' || data.address_type === 'W' || data.address_type === 'TN' || data.address_type === 'SN' || data.address_type === 'CC')",
                        title: "SINT(int8)",
                        value: "char",
                      },
                      {
                        disabled: "!(data.address_type === 'D' || data.address_type === 'W' || data.address_type === 'TN' || data.address_type === 'SN' || data.address_type === 'CC')",
                        title: "INT(int16)",
                        value: "int16",
                      },
                      {
                        disabled: "!(data.address_type === 'D' || data.address_type === 'W' || data.address_type === 'TN' || data.address_type === 'SN' || data.address_type === 'CC')",
                        title: "WORD(uint16)",
                        value: "uint16",
                      },
                      {
                        disabled: "!(data.address_type === 'D' || data.address_type === 'W' || data.address_type === 'TN' || data.address_type === 'SN' || data.address_type === 'CC')",
                        title: "DINT(int32)",
                        value: "int32",
                      },
                      {
                        disabled: "!(data.address_type === 'D' || data.address_type === 'W' || data.address_type === 'TN' || data.address_type === 'SN' || data.address_type === 'CC')",
                        title: "DWORD(uint32)",
                        value: "uint32",
                      },
                      {
                        disabled: "!(data.address_type === 'D' || data.address_type === 'W' || data.address_type === 'TN' || data.address_type === 'SN' || data.address_type === 'CC')",
                        title: "FLOAT(32bit)",
                        value: "float",
                      },
                      {
                        disabled: "!(data.address_type === 'D' || data.address_type === 'W' || data.address_type === 'TN' || data.address_type === 'SN' || data.address_type === 'CC')",
                        title: "DOUBLE(64bit)",
                        value: "double",
                      },
                    ],
                    index: 3,
                    required: true,
                    show_on_table: true,
                    table_width: 300,
                    title: { en: "Date Type", zh: "数据类型" },
                    type: "string",
                  },
                  description: { index: 2, required: true, show_on_table: false, table_width: 200, title: { en: "Description", zh: "描述" }, type: "string" },
                  format: {
                    default: 0,
                    enum: [
                      { title: { en: "default", zh: "默认不转换" }, value: 0 },
                      { title: { en: "Convert and retain 1 decimal place", zh: "转换并保留1位小数" }, value: 1 },
                      { title: { en: "Convert and retain 2 decimal place", zh: "转换并保留2位小数" }, value: 2 },
                      { title: { en: "Convert and retain 3 decimal place", zh: "转换并保留3位小数" }, value: 3 },
                      { title: { en: "Convert and retain 4 decimal place", zh: "转换并保留4位小数" }, value: 4 },
                      { title: { en: "Convert and retain 5 decimal place", zh: "转换并保留5位小数" }, value: 5 },
                      { title: { en: "Convert and retain 6 decimal place", zh: "转换并保留6位小数" }, value: 6 },
                    ],
                    index: 12,
                    required: false,
                    show: "data.data_type === 'float' || data.data_type === 'double'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Convert String", zh: "转字符串" },
                    type: "int",
                  },
                  group: { default: "default_group", enum: [], index: 11, required: true, show_on_table: true, table_width: 200, title: { en: "Group", zh: "所属分组" }, type: "string" },
                  length: {
                    default: 1,
                    index: 5,
                    max: 255,
                    min: 1,
                    required: true,
                    show: "data.data_type === 'hex' || data.data_type === 'string'",
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Length", zh: "变量长度" },
                    type: "int",
                  },
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  read_write: {
                    enum: [
                      { title: { en: "Read Only", zh: "只读" }, value: "ro" },
                      { title: { en: "Write Only", zh: "只写" }, value: "wo" },
                      { title: { en: "Read Write", zh: "可读可写" }, value: "rw" },
                    ],
                    index: 4,
                    required: false,
                    show_on_table: false,
                    table_width: 200,
                    title: { en: "Permission", zh: "读写" },
                    type: "string",
                  },
                  title: { index: 1, required: true, show_on_table: true, table_width: 200, title: { en: "Title", zh: "标题" }, type: "string" },
                  unit: {
                    customize: true,
                    default: "",
                    enum: ["", "℃", "m³/h", "m³", "%RH", "t/h", "V", "I", "Kg", "m3/h", "℉", "K", "mg/L", "%", "L", "Kw.h", "μg/L", "m³/s"],
                    index: 13,
                    placeholder: { en: "Select or enter data unit", zh: "选择或输入数据单位" },
                    required: false,
                    show: "!(data.data_type === 'bool')",
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Unit", zh: "单位" },
                    type: "string",
                  },
                },
                point_template: ["name", "reg1", "reg1", "int16", "ro", 1, "", 0, "", 0, { scale: {}, selected: "", zoom: {} }, "default_group"],
                required: ["name", "title", "group", "address_type", "address_hex", "address_dec", "data_type", "format", "unit"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            frame_format: { default: "4E", enum: ["3E", "4E"], required: true, title: { en: "Frame Format", zh: "帧格式" }, type: "string" },
            interval_ms: { default: 1000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期（毫秒）" }, type: "int" },
            port: { default: 61451, max: 65535, min: 1, required: true, title: { en: "Port", zh: "端口号" }, type: "int" },
            protocol_type: { default: "BINARY", enum: ["BINARY", "ASCII"], required: true, title: { en: "VPT Type", zh: "协议类型" }, type: "string" },
            required: ["device_name", "address", "port", "enabled", "interval_ms", "protocol_type", "frame_format", "device_family"],
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          title: { en: "CC-Link IE Field Basic", zh: "CC-Link IE Field Basic" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      show_communication_type: false,
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["udp"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "slmp_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "slmp",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "CC-Link IE Field Basic", zh: "CC-Link IE Field Basic" },
    version: "1.0.0",
  },
  status_s: {
    attribute: VPTDriverDireaction.south,
    channel_schema: {
      communication_name: { title: "Communication Name", type: "string" },
      communication_type: {
        tcp: {
          channel_config: {},
          device_config: {
            device_name: { required: true, title: { en: "Device Name", zh: "设备名称" }, type: "string" },
            device_type: {
              "*": {
                point_schema: {
                  name: {
                    index: 0,
                    placeholder: { en: "please input variable name", zh: "请输入变量名" },
                    required: true,
                    show_on_config: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Name", zh: "变量名" },
                    type: "string",
                  },
                  title: {
                    enum: [
                      { title: { en: "Device CPU Model", zh: "设备CPU型号" }, value: "CPU" },
                      { title: { en: "Device SN Number", zh: "设备SN号" }, value: "SN" },
                      { title: { en: "Device Memory", zh: "设备内存" }, value: "Memory" },
                      { title: { en: "Device Model", zh: "设备型号" }, value: "Model" },
                      { title: { en: "Device Version", zh: "设备系统版本" }, value: "Version" },
                      { title: { en: "Device MAC Address", zh: "设备MAC地址" }, value: "MAC" },
                      { title: { en: "Device 4G information", zh: "设备4G模块信息" }, value: "4GInfo" },
                      { title: { en: "Device IP Address", zh: "设备IP地址" }, value: "IP" },
                      { title: { en: "Device OS", zh: "设备操作系统" }, value: "OS" },
                      { title: { en: "Device Uptime", zh: "设备运行时间" }, value: "Uptime" },
                      { title: { en: "Device KernelVersion", zh: "设备内核版本" }, value: "KerVer" },
                      { title: { en: "Device Disk Use Rate", zh: "设备磁盘使用率" }, value: "DiskRate" },
                      { title: { en: "Device Memory Use Rate", zh: "设备内存使用率" }, value: "MemoryRate" },
                    ],
                    index: 1,
                    required: true,
                    show_on_table: true,
                    table_width: 200,
                    title: { en: "Device Information", zh: "设备信息" },
                    type: "string",
                  },
                },
                point_template: ["name", "CPU", "", "string", "ro", 1, "path", "CPU", "", 0, null, "default_group"],
                required: ["name", "title"],
              },
            },
            enabled: {
              default: 1,
              enum: [
                { title: { en: "YES", zh: "是" }, value: 1 },
                { title: { en: "NO", zh: "否" }, value: 0 },
              ],
              required: true,
              title: { en: "Enabled", zh: "是否启用" },
              type: "bool",
            },
            interval_ms: { default: 10000, min: 10, required: true, title: { en: "Interval_ms", zh: "采集周期(毫秒)" }, type: "int" },
            required: ["device_name", "enabled", "interval_ms"],
            use_json: { default: 1, enum: [0, 1], required: false, title: "Use Json", type: "bool" },
          },
          title: { en: "Device Status Information", zh: "设备状态信息" },
        },
      },
      enabled: {
        default: 1,
        enum: [
          { title: { en: "YES", zh: "是" }, value: 1 },
          { title: { en: "NO", zh: "否" }, value: 0 },
        ],
        title: "Enabled",
        type: "bool",
      },
      title: { title: "Title", type: "string" },
    },
    commit_id: "34bebeba371f7e96ce729951a668023ad6c45b1b",
    communication_schema: ["tcp"],
    config_version: 1,
    description: { en: "", zh: "" },
    driver_name: "status_s",
    interface_version: "@INTERFACE_VERSION@",
    minimum_daemon_version: "1.0",
    protocol: "status",
    short_target: "@SHORT_TARGET@",
    target: "@GCC_TARGET@",
    title: { en: "Device Status Information", zh: "设备状态信息" },
    version: "1.0.1",
  },
};

export const emptyMockConfiguration: DeviceConfigurationInfo = {
  protocol: {
    output_groups: { "123": { title: "", description: "13" }, test: { title: "", description: "1" } },
    plc_daemon: {
      "?log_level": "-1:NONE, 0: FATAL, 1:ERROR, 2:WARNING, 3: INFO, 4:DEBUG, 5:TRACE",
      log_level: 3,
      file_log_level: 1,
      file_log_size: 1000000,
      file_log_max_count: 10,
      web_log_level: 3,
      show_duplicated_log: true,
      login_required: true,
      language: "en_US",
      "?language": "en_US, zh_CN",
      edge_compute: {
        JerryScript: {
          heap_size: 1024,
          "?heap_size": "heap size in kB, upper limit 16M",
        },
        MicroPython: {
          heap_size: 1024,
          "?heap_size": "heap size in kB, upper limit 16M",
        },
      },
      file_log_size_k: 976.5625,
    },
    function_blocks: {},
    encapsulation: {
      With_Device_Info_3: {
        code: 'var cached_sn;\n\nif (!cached_sn) {\n    cached_sn = readFileSync("/tmp/sysinfo/SN");\n    if (cached_sn) {\n        cached_sn = cached_sn.trim();\n    }\n}\n\nvar lastChannel = getLastChannel();\nvar lastDevice = getLastDevice();\nvar deviceInfo = getDeviceInfo(lastChannel, lastDevice);\nvar selfInfo = getChannelInfo(getSelfName());\n\noutputs = {\n    sn: cached_sn,\n    channel: lastChannel,\n    device: lastDevice,\n    slave_address: deviceInfo ? deviceInfo.slave_address : null,\n    client_id: selfInfo && selfInfo.client_id ? selfInfo.client_id : null,\n    data: {}\n};\n\nfunction parseData() {\n    var data_string = getLastData();\n    if (!data_string) {\n        return;\n    }\n    outputs.data = JSON.parse(data_string);\n}\n\nparseData();\ncloud_output = JSON.stringify(outputs);\n',
        desc: '{\n    "sn": "V201912091-059",\n    "channel": "modbus",\n    "device": "sensor1",\n    "slave_address": 1,\n    "client_id": "the mqtt client id",\n    "data": {\n        "temperature": 21.30,\n        "humidity": 60\n    }\n}\n',
        engine: "javascript",
        is_build_in: false,
        name: "With Device Info 3",
      },
    },
    profinet: {},
  },
};
export const emptyMockVPTDrivers: VPTDriversConfigDescription = {};

export const emptyMockPlcPointStatus: Record<string, PlcChannelPointStatusModel> = {};
