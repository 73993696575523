import { LocaleFile } from "..";
import user from "./zh/user";
import alarm from "./zh/alarm";
import configuration from "./zh/configuration";
import topology from "./zh/topology";
import log from "./zh/log";
import edgeComputing from "./zh/edgeComputing";
import device from "./zh/device";
import noticeCenter from "./zh/noticeCenter";
import software from "./zh/software";
import dashboard from "./zh/dashboard";
import security from "./zh/security";
import reports from "./zh/reports";
import help from "./zh/help";

const zh_CN: LocaleFile = {
  dashboard,
  device,
  user,
  alarm,
  noticeCenter,
  configuration,
  topology,
  log,
  edgeComputing,
  software,
  security,
  reports,
  help,
  title: "中文",
  container: "中文容器",
  common: {
    all: "全部",
    help: "帮助",
    yes: "是",
    noChanges: "当前没有任何更改",
    no: "否",
    ok: "确定",
    success: "成功",
    failed: "失败",
    cancel: "取消",
    confirm: "确认",
    nextStep: "下一步",
    prevStep: "上一步",
    next: "下一步",
    back: "后退",
    name: "名称",
    filters: "筛选条件",
    details: "详情",
    reset: "重置",
    description: "描述",
    settings: "设置",
    resetToDefault: "重置为默认选项",
    mode: "模式",
    letterLessThan12: "最多输入12个字符",
    noDataFound: "暂无数据",
    delete: "删除",
    upload: "上传",
    enabled: "启用",
    disabled: "禁用",
    displayed: "展示",
    hidden: "隐藏",
    fillRequiredFields: "请正确填写所有必填项",
    requiredFields: "此项必填",
    select: "选择",
    createdTime: "创建时间",
    add: "添加",
    send: "发送",
    type: "类型",
    status: "状态",
    deleteContent: "删除后不可恢复，请谨慎操作！",
    save: "保存",
    enable: "启用",
    disable: "禁用",
    uploadTime: "上传时间",
    edit: "编辑",
    copy: "复制",
    editSuccessed: "修改成功",
    editFailed: "修改失败",
    deleteSuccessed: "删除成功",
    deleteFailed: "删除失败",
    action: "操作",
    updatedTime: "更新时间",
    user: "用户",
    users: "用户",
    admin: "管理员",
    emails: "邮箱",
    close: "关闭",
    remove: "移除",
    cardView: "卡片模式查看",
    listView: "列表模式查看",
    selectAll: "选择全部",
    backHome: "回到主页",
    notFoundPage: "页面不存在",
    selected: "已选中",
    addDesc: "添加描述",
    addDescSuccessed: "添加描述成功",
    addDescFailed: "添加描述失败",
    operation: "操作",
    none: "无",
    apply: "应用",
    refresh: "刷新",
    activate: "激活",
    skip: "此次忽略",
    modifyNow: "现在修改",
    changePasswordSuccess: "修改密码成功！",
    changePasswordFailed: "修改密码失败！",
    clear: "清除",
    clearAll: "清除全部",
    copySuccess: "复制成功",
    copyFailed: "复制失败",
    search: "搜索",
    rowsPerPage: "每页行数",
    days: "天",
    day: "天",
    return: "返回",
    leave: "离开",
    leavePageTitle: "离开页面",
    leavePageContent: "离开后系统将不会保存页面内容，是否要离开此页面？",
    loading: "加载中",
    noPermission: "您暂无权限访问该页面",
    loadError: "加载失败",
    tableReload: "重新加载",
    intotal: "总计",
    ascending: "升序",
    descending: "降序",
    currentVersion: "当前版本",
    maxChars: "最多输入{{count}}个字符",
    date: "日期",
    time: "时间",
    filterBy: "筛选",
    deployNow: "立即部署",
    monday: "周一",
    tuesday: "周二",
    wednesday: "周三",
    thursday: "周四",
    friday: "周五",
    saturday: "周六",
    sunday: "周天",
    everyday: "每天",
    everyweek: "每周",
    download: "下载",
    feedback: "反馈",
    loadMore: "加载更多",
    route: "转发",
  },
  content: {
    searchFile: "搜索文件",
    uploadFile: "上传文件",
  },
  nav: {
    mainLayout: "",
    // 一级目录
    favorites: "收藏夹",
    monitoring: "监控",
    provisioning: "预配置",
    ota: "OTA", // 一级目录和二级目录同名
    security: "安全管理",
    system: "系统管理",
    dashboard: "仪表盘",
    alarmOverview: "告警概览",
    alarmRouting: "告警转发",
    deviceReport: "设备报告",
    reportDetail: "报告详情",
    topology: "拓扑管理",
    mqttTracing: "实时消息追踪",
    configuration: "配置管理",
    batchCommand: "命令群发",
    deviceGroup: "设备组",
    edgeComputing: "边缘计算",
    softwareManagement: "软件管理",
    healthCheck: "健康检查",
    healthCheckDeviceDetail: "设备详情",
    comparisonReport: "对比报告",
    otaTaskDetail: "任务详情",
    LicenseDetail: "License详情",
    deviceManagement: "设备管理",
    deviceDetails: "设备详情",
    log: "日志",
    helpCenter: "帮助中心",
    alarmDetail: "告警详情",
    taskManagement: "任务管理",
    taskDetails: "任务详情",
    addTrackTask: "创建追踪任务",
    profile: "用户设置",
    notice: "通知中心",
    packageManagement: "包管理",
    helpCenterDtls: "详情",
    plcDataWidgets: "PLC Data Widgets",
  },
  tenants: {
    addUser: "添加租户用户",
  },
};
export default zh_CN;
