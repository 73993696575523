import { alpha, Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import noPermissionImg from "../../../assets/imgs/img_403.svg";
import { Pathnames } from "../../../router/route.config";

const NoPermission = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // 回到初始界面：dashboard或app launcher
  const navigateToHome = () => navigate(Pathnames.Dashboard);

  return (
    <Box className="flex" sx={{ height: 1 / 1, bgcolor: (theme) => alpha(theme.palette.primary.main, 0.06), flexDirection: "column" }}>
      <Box sx={{ maxWidth: 480 }}>
        <img style={{ width: "100%" }} src={noPermissionImg} alt="" />
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography textAlign="center" variant="h4">
          {t("common.noPermission")}
        </Typography>
        <Button sx={{ mt: 6 }} variant="contained" onClick={navigateToHome}>
          {t("common.backHome")}
        </Button>
      </Box>
    </Box>
  );
};

export default NoPermission;
