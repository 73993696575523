export enum Pathnames {
  Login = "/login",
  SignUpPage = "/signup",
  ResetPassword = "/login/resetPassword",
  MainLayout = "/",
  NotFound = "*",
  //
  Monitoring = "/monitoring",
  Dashboard = "/dashboard",
  AlarmOverview = "/alarms",
  AlarmDetail = "/alarms/:id",
  AlarmRouting = "/alarm_routing",
  DeviceReport = "/device-reports",
  DeviceReportDetail = "/device-report/:reportId",
  Topology = "/topology",
  MqttTracing = "/mqtt_tracing",
  PlcDataWidgets = "/plc_data_widgets",
  //
  Provisioning = "/Provisioning",
  DeviceManagement = "/device_management",
  LoraDeviceDetails = "/lora_device/overview/:deviceId",
  PlcDeviceDetails = "/plc_device/overview/:deviceId",
  Configuration = "/configuration",
  EdgeComputing = "/edge_computing",
  BatchCommand = "/batch_command",
  TaskList = "/configuration/task_list",
  //
  Security = "/security",
  System = "/system",
  Log = "/log",
  AddTrackTask = "/mqtt_tracing/task/add",
  TaskDetails = "/mqtt_tracing/task/details/:taskId",
  HelpCenter = "/help_center",
  HelpCenterDtls = "/help_center/:cardId",
  Profile = "/profile",
  //
  Favorites = "/",

  SoftwareManagement = "/software-management/:type",
  OtaTaskDetail = "/software-management/task-details/:taskId",
  LicenseDetail = "/software-management/license-details/:licenseId",
  HealthCheckDetail = "/software-management/health-check/:taskId",
  HealthCheckDeviceDetail = "/software-management/health-check/:taskId/device/:deviceId",
  ComparisonReport = "/software-management/health-check/:taskId/compare",
  PackageManagement = "/package-management",
}

export interface BreadcrumbData {
  title: string;
  path: Pathnames;
}

class BreadcrumbFactory implements BreadcrumbData {
  constructor(public title: string, public path: Pathnames) {}
}

const Login = new BreadcrumbFactory("login", Pathnames.Login);
const SignUpPage = new BreadcrumbFactory("SignUpPage", Pathnames.SignUpPage);
const ResetPassword = new BreadcrumbFactory("ResetPassword", Pathnames.ResetPassword);
const NotFound = new BreadcrumbFactory("notFound", Pathnames.NotFound);
const MainLayout = new BreadcrumbFactory("mainLayout", Pathnames.MainLayout);
const Dashboard = new BreadcrumbFactory("dashboard", Pathnames.Dashboard);
const AlarmOverview = new BreadcrumbFactory("alarmOverview", Pathnames.AlarmOverview);
const AlarmDetail = new BreadcrumbFactory("alarmDetail", Pathnames.AlarmDetail);
const AlarmRouting = new BreadcrumbFactory("alarmRouting", Pathnames.AlarmRouting);
const DeviceReport = new BreadcrumbFactory("deviceReport", Pathnames.DeviceReport);
const DeviceReportDetail = new BreadcrumbFactory("reportDetail", Pathnames.DeviceReportDetail);
const Topology = new BreadcrumbFactory("topology", Pathnames.Topology);
const MqttTracing = new BreadcrumbFactory("mqttTracing", Pathnames.MqttTracing);
const AddTrackTask = new BreadcrumbFactory("addTrackTask", Pathnames.AddTrackTask);
const TaskDetails = new BreadcrumbFactory("taskDetails", Pathnames.TaskDetails);
const PlcDataWidgets = new BreadcrumbFactory("plcDataWidgets", Pathnames.PlcDataWidgets);
const Log = new BreadcrumbFactory("log", Pathnames.Log);
const DeviceManagement = new BreadcrumbFactory("deviceManagement", Pathnames.DeviceManagement);
const Lora = new BreadcrumbFactory("deviceDetails", Pathnames.LoraDeviceDetails);
const Plc = new BreadcrumbFactory("deviceDetails", Pathnames.PlcDeviceDetails);
const Configuration = new BreadcrumbFactory("configuration", Pathnames.Configuration);
const BatchCommand = new BreadcrumbFactory("batchCommand", Pathnames.BatchCommand);
const TaskList = new BreadcrumbFactory("taskList", Pathnames.TaskList);
const EdgeComputing = new BreadcrumbFactory("edgeComputing", Pathnames.EdgeComputing);
const SoftwareManagement = new BreadcrumbFactory("softwareManagement", Pathnames.SoftwareManagement);
const OtaTaskDetail = new BreadcrumbFactory("otaTaskDetail", Pathnames.OtaTaskDetail);
const LicenseDetail = new BreadcrumbFactory("LicenseDetail", Pathnames.LicenseDetail);
const HealthCheckDetail = new BreadcrumbFactory("healthCheck", Pathnames.HealthCheckDetail);
const HealthCheckDeviceDetail = new BreadcrumbFactory("healthCheckDeviceDetail", Pathnames.HealthCheckDeviceDetail);
const ComparisonReport = new BreadcrumbFactory("comparisonReport", Pathnames.ComparisonReport);
const PackageManagement = new BreadcrumbFactory("packageManagement", Pathnames.PackageManagement);
const Profile = new BreadcrumbFactory("profile", Pathnames.Profile);
const Security = new BreadcrumbFactory("security", Pathnames.Security);
//
const HelpCenter = new BreadcrumbFactory("helpCenter", Pathnames.HelpCenter);
const HelpCenterDtls = new BreadcrumbFactory("helpCenterDtls", Pathnames.HelpCenterDtls);

// path映射面包屑数据
export const breadcrumbMaps = new Map<Pathnames, BreadcrumbData[]>([
  [Pathnames.Login, [Login]],
  [Pathnames.SignUpPage, [SignUpPage]],
  [Pathnames.ResetPassword, [ResetPassword]],
  [Pathnames.NotFound, [NotFound]],
  [Pathnames.MainLayout, [MainLayout]],
  [Pathnames.Dashboard, [Dashboard]],
  [Pathnames.AlarmOverview, [AlarmOverview]],
  [Pathnames.AlarmDetail, [AlarmOverview, AlarmDetail]],
  [Pathnames.AlarmRouting, [AlarmRouting]],
  [Pathnames.DeviceReport, [DeviceReport]],
  [Pathnames.DeviceReportDetail, [DeviceReport, DeviceReportDetail]],
  [Pathnames.Topology, [Topology]],
  [Pathnames.MqttTracing, [MqttTracing]],
  [Pathnames.TaskDetails, [MqttTracing, TaskDetails]],
  [Pathnames.AddTrackTask, [MqttTracing, AddTrackTask]],
  [Pathnames.PlcDataWidgets, [PlcDataWidgets]],
  [Pathnames.Log, [Log]],
  [Pathnames.Configuration, [Configuration]],
  [Pathnames.BatchCommand, [Configuration, BatchCommand]],
  [Pathnames.TaskList, [Configuration, BatchCommand, TaskList]],
  [Pathnames.EdgeComputing, [EdgeComputing]],
  [Pathnames.SoftwareManagement, [SoftwareManagement]],
  [Pathnames.OtaTaskDetail, [SoftwareManagement, OtaTaskDetail]],
  [Pathnames.LicenseDetail, [SoftwareManagement, LicenseDetail]],
  [Pathnames.HealthCheckDetail, [SoftwareManagement, HealthCheckDetail]],
  [Pathnames.HealthCheckDeviceDetail, [SoftwareManagement, HealthCheckDetail, HealthCheckDeviceDetail]],
  [Pathnames.ComparisonReport, [SoftwareManagement, HealthCheckDetail, ComparisonReport]],
  [Pathnames.PackageManagement, [PackageManagement]],
  [Pathnames.DeviceManagement, [DeviceManagement]],
  [Pathnames.LoraDeviceDetails, [DeviceManagement, Lora]],
  [Pathnames.PlcDeviceDetails, [DeviceManagement, Plc]],
  [Pathnames.Security, [Security]],
  [Pathnames.Profile, [Profile]],
  [Pathnames.HelpCenter, [HelpCenter]],
  [Pathnames.HelpCenterDtls, [HelpCenter, HelpCenterDtls]],
]);
