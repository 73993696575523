const help = {
  helpcard: {
    QuickStartGuidance: "Quick Start Guidance",
    NetworkCommunicationMatrix: "Network Communication Matrix",
    OTAUpgradeUserGuidence: "OTA Upgrade User Guidence",
    RemoteAccessUserGuidence: "Remote Access User Guidence",
    SupportedAlarmList: "Supported Alarm List",
    SupportedDeviceMatrix: "Supported Device Matrix",
    APIReference: "API Reference",
  },

  desc: {
    QuickStartGuidance: "New to BlueSphere GWM? Start here.",
    NetworkCommunicationMatrix: "For network connectivity troubleshooting.",
    OTAUpgradeUserGuidence: "Learn how to perform OTA upgrade to devices.",
    RemoteAccessUserGuidence: "Simple method to remotely configure devices.",
    SupportedAlarmList: "Supported alarm list for BlueSphere GWM.",
    SupportedDeviceMatrix: "Supported device list for BlueSphere GWM.",
    APIReference: "APIs for developers.",
  },

  isHelpfulToyou: "Is the content of the document helpful to you?",
  helpful: "Helpful",
  nohelp: "No Help",
  helpTips: "If you encounter product-related problems, please give ",
  feedback: "feedback",
  here: "here",
  thankComment: "Thanks for your comment !",

  networkTable: {
    appLayerPotocol: "Application Layer Protocol",
    transportLayerProtocol: "Transport Layer Protocol",
    port: "Port",
    address: "Address",
    purpose: "Purpose",
  },

  alarmTable: {
    order: "Order",
    speciality: "Speciality",
    manufactor: "Manufactor",
    alarmObjectType: "Alarm Object Type",
    way: "Way",
    mappingMethod: "Mapping Method",
    alarmTitle: "Alarm Title",
    alarmDescription: "Alarm Description",
    alarmID: "Alarm ID",
    alarmLocationInformation: "Alarm Location Information",
    alarmLevel: "Alarm Level",
    hardwareVersion: "Applicable Hardware Version",
    softwareVersion: "Applicable Software Version",
    GWMalarmID: "GWM AlarmID",
    alarmExplanation: "Alarm Explanation",
    alarmLogicClassification: "Alarm Logic Classification",
    alarmLogicSubclass: "Alarm Logic Subclass",
    impactOnDevice: "Impact On Device",
    impactOnBusiness: "Impact On Business",
  },

  deviceTable: {
    order: "Order",
    deviceModel: "Device Model",
    weblinkAddress: "Product Page",
    deviceOs: "Device OS",
    deviceConnect: "Device Connect",
    dashboard: "Dashboard",
    alarmOverview: "Alarm Overview",
    alarmRouting: "Alarm Routing",
    deviceReport: "Device Report",
    topogyViewerMap: "Topogy Viewer Map",
    topogyViewerLink: "Topogy Viewer Link",
    MQTTTracing: "MQTT Tracing",
    deviceManagement: "Device Management",
    pingRestart: "Ping/Restart",
    changeSIM: "Change SIM",
    deviceConfig: "Device Config",
    remoteTerminal: "Remote Terminal",
    remoteDesktop: "Remote Desktop",
    edgeComputing: "Edge Computing",
    heathCheck: "Heath Check",
    imageOTA: "Image OTA",
    agentOTA: "Agent OTA",
    appOTA: "App OTA",
    childDevice: "Child Device",
    lora: "Lora",
    PLCTelemetryData: "PLC Telemetry Data",
    PLCWidgetPlatform: "PLC Widget(Platform)",
  },
};
export default help;
