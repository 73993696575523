import { Theme, Tooltip, alpha, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CSSProperties, MouseEvent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

interface IconfontProps {
  icon: string;
  fontSize?: number;
  color?: string;
  mr?: number;
  ml?: number;
  my?: number;
  onClick?(ev: MouseEvent): any;
  onMouseDown?(ev: MouseEvent): any;
  pointer?: boolean;
  style?: CSSProperties;
  primary?: boolean;
  white?: boolean;
  disabled?: boolean;
  className?: string;
  tooltip?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.primary.main,
  },
  greyIcon: {
    color: theme.palette.action.active,
  },
}));

export default function Iconfont(props: IconfontProps) {
  const classes = useStyles();
  const { palette, custom } = useTheme();
  const { t } = useTranslation();
  const {
    icon,
    fontSize = 24,
    mr = 1,
    ml = 0,
    color = alpha(custom.fontBaseColor, 0.6),
    tooltip,
    my = 0,
    style = {},
    primary,
    white = false,
    disabled = false,
    onClick = () => {},
    onMouseDown = () => {},
    pointer = false,
  } = props;

  const className = primary ? classes.icon : classes.greyIcon;

  const computedClassName = props.className ? className + " " + props.className + " iconfont icon-" + icon : className + " iconfont icon-" + icon;

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (!disabled) {
        onClick(e);
      }
    },
    [disabled, onClick]
  );

  const computedStyle: CSSProperties = useMemo(() => {
    let computedColor = color;
    if (white) {
      computedColor = "#fff";
    }
    let cursor = null;
    if (pointer) {
      cursor = "pointer";
    }
    if (disabled) {
      computedColor = palette.action.disabled;
      cursor = "not-allowed";
    }
    return {
      fontSize,
      marginRight: mr * 8,
      marginLeft: ml * 8,
      marginTop: my * 8,
      marginBottom: my * 8,
      color: computedColor,
      cursor,
      height: fontSize,
      lineHeight: 1,
    };
  }, [color, fontSize, ml, my, mr, pointer, white, palette, disabled]);

  return (
    <Tooltip title={tooltip && t(tooltip)}>
      <i
        onClick={handleClick}
        className={computedClassName}
        style={{
          ...computedStyle,
          ...style,
        }}
        onMouseDown={onMouseDown}
      ></i>
    </Tooltip>
  );
}
