import { Box, FormControl, InputAdornment, SxProps, TextField, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { memo, useEffect, useMemo, useState } from "react";
import { CustomOperationMenu, Languages, MatFormItemProps } from "../../../models/base.model";
import { isNull } from "../../../utils";
import MatDropdown from "../mui/MatDropdown";
import { useSelector } from "react-redux";
import countryData, { CountryData } from "../../../utils/countryData";
import { selectLanguage } from "../../../store/mainSlice";
import MatFormLabel from "./MatFormLabel";

interface PhoneNumberInputProps {
  onCountryChange(country: string): void;
  phonePrefix?: string;
  inputProps: MatFormItemProps;
  multiline?: boolean;
  maxRows?: number;
  sx?: SxProps;
  fullWidth?: boolean;
}

const createCountrySelectOptions = (lang: Languages): CustomOperationMenu[] => {
  const key: keyof CountryData = lang === Languages.zh ? "name" : "enName";
  const options = countryData.map((v) => {
    return new CustomOperationMenu(v.code, v[key] + " " + v.phonePrefix, <span className={"flag flag-" + v.code}></span>);
  });
  // 中国下面加一个分隔线
  options[2].showDivider = true;
  return options;
};

const useStyle = makeStyles((theme: Theme) => {
  return {
    dropDowbTrigger: {
      padding: "8px 8px",
      marginLeft: "-14px",
      borderRadius: "4px 0 0 4px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      borderRight: "1px solid",
      borderRightColor: theme.palette.divider,
    },
    dropdownIcon: {
      border: "6px solid transparent",
      borderTopColor: "#0000008f",
      marginLeft: "8px",
      marginTop: "6px",
    },
  };
});

export default memo(function PhoneNumberInput(props: PhoneNumberInputProps) {
  const { width, size = "small" } = props.inputProps;
  const { fullWidth = true, inputProps, onCountryChange } = props;
  const value = isNull(inputProps.value) ? "" : inputProps.value;
  const classes = useStyle();
  const language = useSelector(selectLanguage);
  const [selectedCountry, setSelectedCountry] = useState<string>("us");

  const countrySelectOptions = useMemo(() => {
    return createCountrySelectOptions(language);
  }, [language]);

  const onMenuClick = (code: string) => {
    setSelectedCountry(code);
    onCountryChange(countryData.find((v) => v.code === code)?.phonePrefix);
  };

  const placeholder = useMemo(() => {
    return countryData.find((v) => v.code === selectedCountry)?.phonePrefix;
  }, [selectedCountry]);

  useEffect(() => {
    if (props.phonePrefix) {
      setSelectedCountry(countryData.find((v) => v.phonePrefix === props.phonePrefix)?.code);
    }
  }, [props.phonePrefix]);

  return (
    <FormControl error={inputProps.error} sx={{ maxWidth: width || 1 / 1, ...props.sx }} fullWidth={fullWidth}>
      <MatFormLabel error={inputProps.error}>{inputProps.label}</MatFormLabel>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MatDropdown<string>
                trigger={
                  <Box className={classes.dropDowbTrigger}>
                    <Box className={"flex-v-center"}>
                      <span className={"flag flag-" + selectedCountry}></span>
                      <Typography sx={{ mx: 1 }}>{placeholder}</Typography>
                    </Box>
                    <Box className={classes.dropdownIcon}></Box>
                  </Box>
                }
                selected={selectedCountry}
                menus={countrySelectOptions}
                onMenuClick={onMenuClick}
              ></MatDropdown>
            </InputAdornment>
          ),
          // placeholder,
        }}
        {...inputProps}
        size={size}
        label={null}
        value={value}
      ></TextField>
    </FormControl>
  );
});
