import { LocaleFile } from "../..";

const software: LocaleFile = {
  name: "名称",
  softName: "软件平台",
  firmwareName: "硬件平台",
  lastVersion: "最新版本",
  RUNNING: "进行中",
  ERROR: "异常",
  COMPLETED: "完成",
  STOPED: "停止",
  createTask: "创建任务",
  searchTask: "搜索任务...",
  taskName: "任务名",
  creator: "创建者",
  device: "设备",
  status: "状态",
  softwareType: "软件类型",
  packageName: "包名",
  targetVersion: "目标版本",
  currentVersion: "当前版本",
  createTime: "创建时间",
  desciption: "描述",
  action: "操作",
  viewDetails: "详情",
  copyTask: "复制任务",
  start: "开始",
  stop: "停止",
  report: "报告",
  delete: "删除",
  deleteTask: "删除任务",
  deleteTaskTip: "此操作无法撤消。是否确实要删除所选任务？",
  deleteTasksTip: "此操作无法撤消。是否确实要删除所选任务？",
  taskDetails: "任务详情",
  startTime: "开始时间",
  endTime: "结束",
  progress: "进度",
  successful: "成功",
  failed: "失败",
  taskInfo: "任务信息",
  devices: "设备",
  package: "升级包",
  description: "描述",
  releaseNote: "发布说明",
  deviceGroups: "设备组",
  selected: "已选择",
  clearAll: "清除全部",
  searchGroups: "搜索设备组...",
  searchDevices: "搜索设备...",
  system: "系统",
  application: "应用",
  agent: "Agent",
  uploadPackage: "上传包",
  uploadPackageTip: '点击"上传包"按钮上传',
  uploadLicenseTip: '点击"选择文件"按钮上传License',
  selectFile: "选择文件",
  upgradeMode: "升级方式",
  userTriggerUpgrade: "稍后启动",
  forceUpgrade: "立即升级",
  scheduleUpgrade: "计划升级",
  upgradeTime: "升级时间",
  agentName: "Agent名称",
  version: "版本",
  releaseDate: "发布日期",
  changeLog: "日志",
  linked: "关联: ",
  offline: "离线",
  online: "在线",
  running: "进行中",
  completed: "完成",
  partially: "部分完成",
  wating: "等待中",
  productName: "产品名",
  uploadTime: "上传时间",
  copyTaskTip: "在新任务中重用现有软件包",
  uploadLicense: "上传License",
  customerId: "Customer ID",
  appliedScenario: "Applied Scenario",
  runnintStatus: "Running Status In Basic License",
  licenseActivateDate: "License Activate Date",
  resourceName: "Resource Name",
  dimension: "Dimension",
  authorized: "Authorized",
  authInfo: "Authorization information in basic feature license",
  dynamicResource: "Dynamic resource info in capacity license",
  deviceLicenseInfo: "Device License info",
  activated: "激活",
  normal: "已激活",
  usedNum: "Used Num",
  productNumber: "Product Number",
  percent: "Percent(Used)",
  deviceName: "Device Name",
  activationTime: "Activation Time",
  expiration: "Expiration",
  installedLicense: "Installed License",
  createdTime: "创建时间",
  appName: "应用名",
  addSuccess: "创建成功",
  copySuccess: "复制成功",
  numberResult: "结果数",
  selectSavedSearch: "从保存的查询中选择",
  deviceAssociated: "关联设备",
  taskNameTip: "当前日期和时间会被作为默认任务名",
  selectSavedSearchFrom: "选择模块",
  table: "选择保存的搜索",
  platformBulletin: "平台公告",
  agentBulletin: "Agent公告",
  OSBulletin: "系统公告",
  priority: "优先级",
  high: "高",
  medium: "中",
  low: "低",
  advancedSettings: "高级设置",
  permanent: "永久",
  permanentTip: "将任务的有效期从72小时（默认值）更改为永久",
  invalid: "无效",
  deployOldVersion: "你正在安装一个旧版本，请问是否继续？",
  healthCheck: "健康检查",
  filePath: "文件路径",
  fileSize: "文件大小",
  tenants: "租户",
  buildTime: "发布时间",
  packageType: "包类型",
  title: "标题",
  merging: "合并中",
  tenant: "租户",
  linkTenant: "关联租户",
  deletePackage: "删除包",
  deletePackageTip: "此操作无法撤消。是否确实要删除所选包？",
  linkSuccess: "关联成功",
  keepSetting: "保持设置",
  friendlyName: "标识名称",
  download: "下载",
  executeMode: "执行方式",
  executeTime: "执行时间",
  period: "周期",
  date: "日期",
  property: "属性",
  trend: "趋势",
  customizedCheck: "自定义检测",
  periodicCheck: "周期性检测",
  selectDevices: "选择设备",
  healthCheckDetail: {
    condition: "条件",
    threshold: "门槛",
    watched: "监听",
    comparisonReport: "对比报告",
    generateComparisonReport: "生成对比报告",
    systemVersion: "系统版本",
    currentSystemVersion: "当前系统版本",
    appVersion: "应用版本",
    appStatus: "应用状态",
    appDuration: "应用运行时长",
    hardware: "硬件",
    hardwareModuleName: "硬件模块名称",
    firmwareVersion: "固件版本",
    temperature: "环境温度",
    articleNumber: "品号",
    status: "运行状态",
    interfaceNetworkStatus: "接口网络状态",
    kernel: "内核",
    cpu: "芯片",
    arch: "架构",
    bogomips: "可执行指令数(万条)",
    rtc: "实时时钟",
    boardName: "主板",
    hostName: "系统",
    model: "类型",
    bluetooth: "蓝牙",
    cellular: "蜂窝网络",
    croute: "当前链路",
    gps: "位置",
    version: "版本",
    capacity: "容量",
    disk: "硬盘",
    memory: "内存",
    trafficStatistics: "流量统计",
    systemException: "系统服务异常",
    abnormalPowerOff: "异常关机",
    ">": "大于(>)",
    "<": "小于(<)",
  },
  offlineStrategy: "离线策略",
  failureImmediately: "直接失败",
  onlineResend: "上线重发",
};

export default software;
