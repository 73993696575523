import { useSearchParams } from "react-router-dom";
import { Box, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import MatPassword from "../mui/MatPassword";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { userController } from "../../../controllers/user.controller";
import { $message } from "../../../utils";
import bluesphere_logo from "../../../assets/imgs/bluesphere_logo.svg";
// import logoTranslucence from "../../../assets/imgs/logo_translucence.svg";
import { t } from "i18next";
import login_bg from "../../../assets/imgs/login_bg.png";
import logo from "../../../assets/imgs/gwm_logo.svg";

const currentYear = new Date().getFullYear();

const useStyles = makeStyles((theme: Theme) => {
  return {
    loginContainer: {
      backgroundColor: "transparent",
      padding: "0 40px",
      width: 480,
      borderRadius: 8,
      margin: 0,
    },
    container: {
      height: "100%",
    },
    resetPwdContainer: {
      backgroundColor: theme.custom?.commonBg,
      padding: "32px 32px",
      width: 460,
      borderRadius: 8,
      margin: "0 8px",
    },
    resetPwdTitle: {
      color: theme.palette.primary.main,
      letterSpacing: 1.5,
    },
    translucenceImg: {
      right: -64,
      width: 480,
      bottom: -120,
      position: "absolute",
    },
  };
});

export default function ResetPassword() {
  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get("resetToken");
  const classes = useStyles();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: { password: "", passwordConfirm: "" },
    validateOnMount: true,
    validationSchema: Yup.object({
      password: Yup.string().max(255).min(8, t("user.createAdminErrors.passwordMin8")).required(t("user.createAdminErrors.password")),
      passwordConfirm: Yup.string().max(255).min(8, t("user.createAdminErrors.passwordMin8")).required(t("user.createAdminErrors.passwordConfirm")),
    }),
    onSubmit(values) {
      const { password, passwordConfirm } = values;
      if (password !== passwordConfirm) {
        $message.error("user.passwordNotSame");
        return;
      } else {
        const result = userController.resetPassword({ password }, resetToken);
        result
          .then((res) => {
            $message.success("user.resetPwdSuccessedTip");
            navigate("/login");
          })
          .catch((err) => {
            if (err?.data?.message) {
              $message.error(err?.data.message);
            }
          });
        return result;
      }
      // userController;
    },
  });

  const resetPassword = () => {
    formik.handleSubmit();
  };

  const goBack = () => {
    navigate("/login");
  };

  return (
    <Box className="flex" sx={{ height: 1 / 1, flexDirection: "column" }}>
      <Box className="flex-start border-box" sx={{ px: 10, width: 1 / 1, height: 64, bgcolor: "#fff", boxShadow: "0px 1px 4px 2px #0C185A0F" }}>
        <img height={48} src={bluesphere_logo} alt="" />
      </Box>
      <Box
        className="flex-row"
        sx={{
          width: 1 / 1,
          flex: 1,
          position: "relative",
        }}
      >
        <Box component={"img"} src={login_bg} alt="" sx={{ width: "calc(100% - 480px)", height: "calc(100vh - 112px)" }}></Box>
        <Box
          sx={{
            zIndex: 1,
          }}
          className={classes.loginContainer + " border-box flex-col flex-v-center"}
        >
          <Box component={"img"} sx={{ mt: 15, mb: 5 }} height={80} src={logo} alt="" />
          <fieldset
            style={{
              width: "100%",
              overflow: "hidden",
              margin: "0",
              padding: 0,
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "none",
              borderTop: `1px solid #D9D9D9`,
              fontSize: 14,
              cursor: "default",
            }}
          >
            <legend style={{ margin: "auto", padding: "8px", paddingTop: "auto", paddingBottom: "auto" }}>
              <Typography>{t("user.resetPwdTitle")}</Typography>
            </legend>
          </fieldset>
          <Box sx={{ mt: 5, height: 64 }} className="w-full">
            <MatPassword
              matstyle="true"
              width={400}
              {...formik.getFieldProps("password")}
              error={Boolean(formik.touched.password && formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              label="user.password"
            ></MatPassword>
          </Box>
          <Box sx={{ height: 64, my: 1 }} className="w-full">
            <MatPassword
              matstyle="true"
              width={400}
              {...formik.getFieldProps("passwordConfirm")}
              error={Boolean(formik.touched.passwordConfirm && formik.errors.passwordConfirm)}
              helperText={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
              label="user.passwordConfirm"
            ></MatPassword>
          </Box>
          <LoadingButton sx={{ height: 36, mb: 2 }} fullWidth onClick={goBack} variant="outlined">
            <span>{t("common.back")}</span>
          </LoadingButton>
          <LoadingButton sx={{ height: 36 }} loading={formik.isSubmitting} fullWidth onClick={resetPassword} variant="contained">
            <span>{t("user.reset")}</span>
          </LoadingButton>
        </Box>
      </Box>
      {/* footer */}
      <Box sx={{ width: 1 / 1, minHeight: 48, bgcolor: "#fff" }} className="flex flex-wrap">
        <Typography sx={{ mr: 3 }} variant="subtitle1">
          {`© ${currentYear} Vantron. All rights reserved.`}
        </Typography>
        {/* <CustomLink showUnderline>Privacy statement</CustomLink>
        <Divider orientation="vertical" sx={{ height: 24, mx: 1 }}></Divider>
        <CustomLink showUnderline>Terms of use</CustomLink>
        <Divider orientation="vertical" sx={{ height: 24, mx: 1 }}></Divider>
        <CustomLink showUnderline>All polices and guidelines</CustomLink> */}
      </Box>
    </Box>
  );
}
