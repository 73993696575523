import { FormControlLabel, FormControl, SxProps } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { MatFormItemProps } from "../../../models/base.model";
import { isString } from "lodash";

export interface MatCheckboxProps extends MatFormItemProps<boolean> {
  indeterminate?: boolean;
  labelSx?: SxProps;
}

export default function MatCheckbox(props: PropsWithChildren<MatCheckboxProps>) {
  const { sx = {}, labelSx = {}, disabled, onBlur, name, onChange, value, label, children, indeterminate } = props;
  const { t } = useTranslation();
  return (
    <FormControl
      sx={{
        height: 1 / 1,
        display: "flex",
        justifyContent: "center",
        ...sx,
      }}
    >
      <FormControlLabel
        sx={labelSx}
        control={<Checkbox disabled={disabled} onBlur={onBlur} name={name} onChange={onChange} checked={value || false} indeterminate={indeterminate} />}
        label={children || (isString(label) ? t(label as string) : label)}
      />
    </FormControl>
  );
}
