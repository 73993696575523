import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Languages, OperationMenu } from "../models/base.model";

export const languageOptions = [new OperationMenu(Languages.zh, "中文"), new OperationMenu(Languages.en, "English")];

export default function useLanguage() {
  const { i18n } = useTranslation();

  const { language: currentLang } = i18n;

  const setLang = (language: Languages) => {
    const lang = language || (currentLang === Languages.en ? Languages.zh : Languages.en);
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
  };

  const params = useMemo(() => {
    if (currentLang === Languages.zh) {
      return { icon: "ic_zn", text: "Zh" };
    }
    return { icon: "ic_en", text: "En" };
  }, [currentLang]);

  const initLanguage = useCallback(() => {
    const browserLang = navigator.language.replace("-", "_") as Languages;
    const storageLng = window.localStorage.getItem("language") || browserLang;
    i18n.changeLanguage(storageLng);
  }, [i18n]);

  return { params, setLang, currentLang, initLanguage };
}
