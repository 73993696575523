import styled from "@emotion/styled";
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(0, 10, 17, 0.7)",
    padding: "4px 8px",
  },
  // [`& .${tooltipClasses.arrow}`]: {
  //   color: "#323A62",
  // },
}));

export default HtmlTooltip;
